import { Box } from '@mui/system';
import { AnimationOptions, CameraBoundsOptions, data, Map as AzureMap, source } from 'azure-maps-control';
import React, { useEffect, useRef } from 'react';
import { MapActionBar } from '../../../../shared/components/MapActionBar/MapActionBar';
import { ScaleLoaderComponent } from '../../../../shared/components/ScaleLoader/ScaleLoaderComponent';
import { cameraAnimationDuration, cameraAnimationType, createMap, createShape, getMapChoiceValue, getSectionShapeId, setMapCursor } from '../../../../shared/Map/MapUtils';
import { MapCursorMode } from '../../../../shared/models/MapCursorMode';
import { Point } from '../../../../shared/models/Point';
import { ShapeEntityType } from '../../../../shared/models/ShapeEntityType';
import styles2 from '../../../../_variables2.scss';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { ScoreTypesColors } from '../../../RoadsCondition/models/ScoreTypesColors';
import { mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import './ScenariosMapStyles.scss';

interface ScenariosMapComponentProps {
    locationGeometry: Point,
    currentMergedProject: MergedProjectVersion,
    loading: boolean
}

export const ScenariosMapComponent = (props: ScenariosMapComponentProps): JSX.Element => {

    const azureMap = useRef<AzureMap>();

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.currentMergedProject) {
            initMap(azureMap.current, () => {
                createMapSectionsShapes(props.currentMergedProject);
                setMapZoom(azureMap.current, props.currentMergedProject);
            });
        }
    }, [props.currentMergedProject]);

    useEffect(() => {
        return function cleanup() {
            azureMap.current?.dispose();
        }
    }, [])

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (callback) {
                callback();
            }
        });
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion): void => {
        let datasource = createMainDatasource();

        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let shapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
            let sectionWidth = 6;
            let sectionShape = createShape(coordinates, shapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(sectionShape);
        });
    }

    const createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        azureMap.current.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        azureMap.current.layers.add(roadLayer);

        return datasource;
    }

    const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        };

        options.type = cameraAnimationType;
        options.duration = cameraAnimationDuration;

        map.setCamera(options);
    }

    return (
        <Box className="scenarios-map-content">
            {props.loading ? <ScaleLoaderComponent /> : ''}
            <div id="AzureMap"></div>
            <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                {azureMap.current &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[]}
                        onSelectedSectionChange={null}
                        selectedSectionsId={[]}
                        sections={null}
                        mainLayer={null}
                        currentMeasurementSystemType={null}
                    />
                }
            </Box>
        </Box>
    );
}
