import styles2 from "../../../../_variables2.scss";

let _sectionsGridHeightGapFromWindow: number = null;

export class SizingUtilities {

    public static rowHeight: number = 36;

    // sectionsGridOffsetFromWindowTop is the height of elements above grid.
    public static sectionsGridHeightGapFromWindow = () => {

        if (!_sectionsGridHeightGapFromWindow) {
            _sectionsGridHeightGapFromWindow = parseInt(styles2.highwaysGridHeightGapFromWindow);
        }

        return _sectionsGridHeightGapFromWindow;
    }

    // window.innerHeight get the hight of page from navigator size
    public static computeGridHeight = (gridHeightGapFromWindow: number) => {

        return window.innerHeight - gridHeightGapFromWindow;
    }

    // rowHeight is the row height defined in prop of the kendo grid
    public static computeGridPageSize = (gridHeight: number, rowHeight: number) => {
        return Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
    }
}