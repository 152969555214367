import { Box } from '@mui/system';
import React from 'react';
import Translate from '../../../../localization/Localization';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import './ScenarioBudgetSummaryStyles.scss';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';

interface ScenarioBudgetSummaryComponentProps {
    sectionsCostSum: number,
    currency: string,
    selectedScenario: Scenario,
    selectedSectionCount: number,
    totalSelectedSectionsLengthInMeters: number,
    totalSelectedSectionsSurface: number
}

export const ScenarioBudgetSummaryComponent = (props: ScenarioBudgetSummaryComponentProps): JSX.Element => {

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    return (
        <Box className="budget-summary">
            <Box display="flex" flexDirection="column">
                <div className="summary-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Scenario} / ${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Budget}`}</div>
                <div className="summary-value">
                    <span>{`${props.sectionsCostSum.toFixedLocalized(0)} ${props.currency} `}</span>
                    <span className="scenario-budget-value">{`/ ${props.selectedScenario?.totalAmount ? props.selectedScenario.totalAmount.toFixedLocalized(0) : 0} ${props.currency}`}</span>
                </div>
            </Box>
            <Box display="flex" flexDirection="column" className="sections-item">
                <div className="summary-title">{Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Sections}</div>
                <div className="summary-value">{props.selectedSectionCount ?? ''}</div>
            </Box>
            <Box display="flex" flexDirection="column">
                <div className="summary-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Linear} (${symbolOfLengthUnit})`}</div>
                <div className="summary-value">{props.totalSelectedSectionsLengthInMeters ? MeasurementSystem.getLengthInMetersOrYards(props.totalSelectedSectionsLengthInMeters).toFixedLocalized(0) : ''}</div>
            </Box>
            <Box display="flex" flexDirection="column">
                <div className="summary-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Surface} (${symbolOfSurfaceUnit})`}</div>
                <div className="summary-value">{props.totalSelectedSectionsSurface ? MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(props.totalSelectedSectionsSurface).toFixedLocalized(0) : ''}</div>
            </Box>
        </Box>
    );
}