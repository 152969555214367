import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Button, Dialog, DialogContent, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import illustrationImage from 'src/assets/images/new-programme-illustration.svg';
import Translate from '../../../../localization/Localization';
import Utilities from '../../../../utils/Utilities';
import { Programming } from '../../services/dataContracts/queryStack/Programming';
import './AddOrUpdateProgrammingStyles.scss';

interface AddOrUpdateProgrammingComponentProps {
    projectId: string,
    projectVersionId: number,
    inEdit: boolean,
    programmingInEdit: Programming,
    handleCancel: () => void,
    handleAddProgrammingClicked: (label: string, year: number) => void,
    handleUpdateProgrammingClicked: (label: string, year: number) => void
}

interface ProgrammeModel {
    label: string,
    year: number
}

export const AddOrUpdateProgrammingComponent = (props: AddOrUpdateProgrammingComponentProps): JSX.Element => {

    let initProgramme: ProgrammeModel = {
        label: '',
        year: new Date().getFullYear()
    };

    const [programme, setProgramme] = useState<ProgrammeModel>(initProgramme);
    const [isConfirmCancelDialogOpened, setIsConfirmCancelDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        let programmingInEdit = props.programmingInEdit;
        if (programmingInEdit) {
            let programmingToUpdate: ProgrammeModel = {
                label: programmingInEdit.label,
                year: programmingInEdit.year
            };

            setProgramme(programmingToUpdate);
        }
    }, [props.programmingInEdit])

    const handleAddProgrammingClicked = (): void => {
        props.handleAddProgrammingClicked(programme.label, programme.year);
    }

    const handleUpdateProgrammingClicked = (): void => {
        props.handleUpdateProgrammingClicked(programme.label, programme.year);
    }

    const isProgrammeInfoUnchanged = programme?.label?.trim() === props.programmingInEdit?.label && programme?.year === props?.programmingInEdit?.year;

    const handleCancel = (): void => {
        if (props.inEdit && !isProgrammeInfoUnchanged) {
            setIsConfirmCancelDialogOpened(true);
            return;
        }

        props.handleCancel();
    }

    return (
        <Box className="add-edit-programming">
            <Box className="content">
                <Box className="form">
                    <Box className="title-content">
                        <Box className="title">
                            {props.inEdit ? Translate.Resources.UI_Programming_ProgrammeCard_Menu_EditInformations : Translate.Resources.UI_Programming_AddOrUpdateProgramme_Title_NewProgramme}
                        </Box>
                        <Box className="indic">
                            <span className="required">*</span> {Translate.Resources.UI_Programming_AddOrUpdateProgramme_RequiredField}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">
                            <div className="input-title">
                                {Translate.Resources.UI_Programming_AddOrUpdateProgramme_Label} <span className="required">*</span>
                            </div>
                            <div>
                                <TextField
                                    hiddenLabel
                                    value={programme.label}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setProgramme({ ...programme, label: e.target.value })}
                                    className="input-text programme-label"
                                    placeholder={Translate.Resources.UI_Programming_AddOrUpdateProgramme_ProgrammeName}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                            {programme.label.trim() === '' &&
                                <div className="label-message">{Translate.Resources.UI_Programming_AddOrUpdateProgramme_Label_Message}</div>
                            }
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <div className="input-title">
                                {Translate.Resources.UI_Programming_AddOrUpdateProgramme_Year} <span className="required">*</span>
                            </div>
                            <div>
                                <Select className="drop-down-list"
                                    value={programme.year}
                                    onChange={(e) => setProgramme({ ...programme, year: e.target.value as number })}
                                >
                                    {Utilities.yearList.map((year, index) => {
                                        return <MenuItem key={`year-${index}`} value={year}>{year}</MenuItem>
                                    })}
                                </Select>
                            </div>
                        </Box>
                    </Box>
                </Box>
                {!props.inEdit &&
                    <Box className="programme-description">
                        <img src={illustrationImage} className="" alt="illustration" />
                        <div className="texts">
                            <div>{Translate.Resources.UI_Programming_AddOrUpdateProgramme_Text_YouHaveNotCreateAnyProgramme}</div>
                            <div>{Translate.Resources.UI_Programming_AddOrUpdateProgramme_Text_CLickButtonToGetStarted}</div>
                        </div>
                    </Box>
                }
                <Box className="btns-actions">
                    <Button className="btn-secondary" onClick={() => handleCancel()}>
                        {Translate.Resources.UI_Programming_AddOrUpdateProgramme_Cancel}
                    </Button>
                    {!props.inEdit &&
                        <Button className="btn-primary" disabled={programme.label.trim() === '' || !programme.year} onClick={handleAddProgrammingClicked}>
                            {Translate.Resources.UI_Programming_AddOrUpdateProgramme_CreateAWorksProgramme}
                        </Button>
                    }
                    {props.inEdit &&
                        <Button className="btn-primary" disabled={programme.label.trim() === '' || !programme.year || isProgrammeInfoUnchanged} onClick={handleUpdateProgrammingClicked}>
                            {Translate.Resources.UI_Programming_AddOrUpdateProgramme_Save}
                        </Button>
                    }
                </Box>
            </Box>
            {isConfirmCancelDialogOpened &&
                <Dialog id="cancel-programming-update-dialog" open={isConfirmCancelDialogOpened}>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReportProblemIcon className="warning-icon" />
                            <Box className="text">
                                {Translate.Resources.UI_Programming_UpdateProgramme_YourEntryWillNotBeSaved}
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button className="btn-secondary" onClick={() => setIsConfirmCancelDialogOpened(false)}>
                                    {Translate.Resources.UI_Programming_AddOrUpdateProgramme_Cancel}
                                </Button>
                                <Button className="btn-primary" onClick={props.handleCancel}>
                                    {Translate.Resources.UI_Programming_UpdateProgramme_Validate}
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </Box>
    );
}