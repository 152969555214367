// If you need to support IE11 uncomment the imports below
//import "react-app-polyfill/ie11";
//import "core-js/stable"; 
import { EventMessage, EventType, InteractionRequiredAuthError, IPublicClientApplication, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { MsalProvider, withMsal } from "@azure/msal-react";
import '@progress/kendo-theme-default/dist/default-blue.scss';
import axios, { AxiosRequestConfig } from 'axios';
import 'azure-maps-control/dist/atlas.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { App } from './App';
import buildinfo from './config/buildinfo.json';
import './index.scss';
import { Localization } from "./localization/Localization";
import { MsalConfig } from './MsalConfig';
import { SettingsProvider } from './SettingsProvider';
import { SettingsApiClient } from './shared/Settings/SettingsApiClient';
import './utils/JSON';

window.document.title += " v" + buildinfo.versionMajorMinor + "." + buildinfo.build.buildNumber;

Localization.SetUp();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

JSON.enableCustomDateParser();

var isMsalDebugMode = window.location.href.includes("_msalDebugMode", -1);

var msalSettingsPromise = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", 'config/msal.json');
    xhr.onload = () => resolve(xhr.responseText);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
});

msalSettingsPromise.then((res) => {

    MsalConfig.Setup(res);

    const msalConfig = MsalConfig.GetConfiguration();
    const msalInstance = new PublicClientApplication(msalConfig);

    msalInstance.addEventCallback((event: EventMessage) => {

        if (isMsalDebugMode) {
            console.log(`msalInstance.addEventCallback | eventType = ${event.eventType}`);
        }        

        switch (event.eventType) {

            case EventType.HANDLE_REDIRECT_END:

                const accounts = msalInstance.getAllAccounts();
                if (accounts.length > 0) {
                    msalInstance.setActiveAccount(accounts[0]);

                    //setup interceptors
                    setupAxiosInterceptors(msalInstance, displayGenericError);

                    SettingsApiClient.Get()
                        .then((res) => {
                            if (res?.data) {

                                var settings = res?.data;
                                SettingsProvider.Setup(settings);

                                renderApp(msalInstance);
                            }
                        });
                }
                break;

            //case EventType.LOGIN_START:
            //case EventType.LOGIN_SUCCESS:
            //case EventType.LOGIN_FAILURE:
            //case EventType.ACQUIRE_TOKEN_START:
            //case EventType.ACQUIRE_TOKEN_SUCCESS:
            //case EventType.ACQUIRE_TOKEN_FAILURE:
            //case EventType.ACQUIRE_TOKEN_NETWORK_START:
            //case EventType.SSO_SILENT_START:
            //case EventType.SSO_SILENT_SUCCESS:
            //case EventType.SSO_SILENT_FAILURE:
            //case EventType.HANDLE_REDIRECT_START:
            //case EventType.POPUP_OPENED:
            //case EventType.LOGOUT_START:
            //case EventType.LOGOUT_SUCCESS:
            //case EventType.LOGOUT_FAILURE:
            //case EventType.LOGOUT_END:
            default:
                break;
        }
    });

    renderApp(msalInstance);
});

const renderApp = (msalInstance: PublicClientApplication) => {

    //Since you can't use the useMsal hook to access the MSAL React context in a class component you 
    //have 2 other options.You can either use the raw context directly or you can use the withMsal higher order component to inject the context into your component's props.
    const AppWithMsalHoc = withMsal(App);


    root.render(
        <BrowserRouter basename={baseUrl}>
            <MsalProvider instance={msalInstance}>
                <AppWithMsalHoc />
            </MsalProvider>
        </BrowserRouter>);
}

const displayGenericError = (err) => {
    toast.error("L'action a échoué en raison d'une erreur technique. Si ce problème persiste, veuillez contacter le support.", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 10000
    });
}

const setupAxiosInterceptors = (msalInstance: IPublicClientApplication, genericErrorHandler: (error: any) => void) => {

    axios.interceptors.request.use(

        //onFulfilled
        (config: AxiosRequestConfig) => {

            const account = msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            return new Promise((resolve, reject) => {

                //See: Token Lifetimes, Expiration, and Renewal
                //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal

                const silentRequest: SilentRequest = { scopes: MsalConfig.GetAcquireTokenScopes(), account: account };
                return msalInstance.acquireTokenSilent(silentRequest)

                    .then((responseOfAcquireTokenSilent) => {
                        if (responseOfAcquireTokenSilent?.accessToken) {
                            config.headers.Authorization = `Bearer ${responseOfAcquireTokenSilent.accessToken}`;
                            resolve(config);
                        } else {
                            // Do something with error of acquiring the token
                            console.error(responseOfAcquireTokenSilent);
                            reject(config);
                        };
                    })

                    .catch((errorOfAcquireTokenSilent) => {
                        // fallback to interaction when silent call fails
                        if (errorOfAcquireTokenSilent instanceof InteractionRequiredAuthError) {
                            console.warn(errorOfAcquireTokenSilent);
                            return msalInstance.acquireTokenRedirect(silentRequest);
                        } else {
                            console.error(errorOfAcquireTokenSilent);
                            return Promise.reject(config);
                        };
                    });
            })
        },

        //onRejected
        (error: any) => {
            // Do something with error of the request
            return Promise.reject(error)
        });

    //En cas de session expirée, l'utilisateur est redirigé vers la page d'authentification
    axios.interceptors.response.use(

        undefined,

        err => {

            if (err && err.response && err.response.status === 401) {
                msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
            }

            if (!err.config?.__colas_xroad_disableGenericErrorHandler) {
                genericErrorHandler(err);
            }

            return Promise.reject(err);
        });
}
