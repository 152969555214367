export { }

declare global {

    interface Map<K, V> {
        fromObject(data: Map<K, V>, isNumericKey?: boolean): Map<K, V>;
    }
}

Map.prototype.fromObject = function <K, V>(data: Map<K, V>, isNumericKey?: boolean) {
    var map = new Map<number | string, V>();
    for (var name in data) {
        var key = isNumericKey ? parseFloat(name) : name;
        map.set(key, data[key]);
    }
    return map;
};
