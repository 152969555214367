import { Box, Button, ClickAwayListener, Popper } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import Translate from '../../../localization/Localization';
import { RoadsConditionAndScenariosShared } from '../RoadsConditionAndScenariosShared';
import { Environment } from '../services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../services/RoadsCondition/dataContracts/queryStack/Manager';
import { Traffic } from '../services/RoadsCondition/dataContracts/queryStack/Traffic';
import './AttributesListFilterStyles.scss';
import { SectionFilterComponent } from './SectionFilterComponent';
import { getEnvironmentList, getHierarchyList, getManagerList, getTrafficList } from 'src/shared/models/Attributes/AttributesLabels';
import { getOtherAttributesList } from '../models/OtherAttributesValues';

interface AttributesListFilterComponentProps {
    open: boolean,
    anchorEl: null | HTMLElement,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    onClose: () => void,
    handleDisplaySections: (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => void
}

export const AttributesListFilterComponent = (props: AttributesListFilterComponentProps): JSX.Element => {

    const [activeHierarchies, setActiveHierarchies] = useState<Set<Hierarchy>>(new Set<Hierarchy>(props.activeHierarchies));
    const [activeTraffics, setActiveTraffics] = useState<Set<Traffic>>(new Set<Traffic>(props.activeTraffics));
    const [activeEnvironments, setActiveEnvironments] = useState<Set<Environment>>(new Set<Environment>(props.activeEnvironments));
    const [activeManagers, setActiveManagers] = useState<Set<Manager>>(new Set<Manager>(props.activeManagers));
    const [activeImportances, setActiveImportances] = useState<Set<string>>(new Set<string>(props.activeImportances));
    const [activeOtherAttributes, setActiveOtherAttributes] = useState<Set<string>>(new Set<string>(props.activeOtherAttributes));

    const [isActiveHierarchyListOpened, setIsActiveHierarchyListOpened] = useState<boolean>(true);
    const [isActiveTrafficListOpened, setIsActiveTrafficListOpened] = useState<boolean>(true);
    const [isActiveEnvironmentListOpened, setIsActiveEnvironmentListOpened] = useState<boolean>(true);
    const [isActiveManagerListOpened, setIsActiveManagerListOpened] = useState<boolean>(true);
    const [isActiveImportanceListOpened, setIsActiveImportanceListOpened] = useState<boolean>(true);
    const [isActiveOtherAttributesListOpened, setIsActiveOtherAttributesListOpened] = useState<boolean>(true);

    const hierarchies = getHierarchyList();
    const traffics = getTrafficList();
    const environments = getEnvironmentList();
    const managers = getManagerList();
    const importances = RoadsConditionAndScenariosShared.SectionsImportanceValues;
    const otherAttributes = getOtherAttributesList();

    const handleAllHierarchiesChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeHierarchiesSet = new Set<Hierarchy>();
        if (e.target.checked) {

            hierarchies.forEach(e => {
                activeHierarchiesSet.add(e.value);
            });
            activeHierarchiesSet.add(null);
        }

        setActiveHierarchies(activeHierarchiesSet);
    }

    const handleHierarchyChanged = (hierarchy: Hierarchy, isActive: boolean) => {
        let activeHierarchiesSet = new Set(activeHierarchies);
        if (isActive) {
            activeHierarchiesSet.delete(hierarchy);
        }
        else {
            activeHierarchiesSet.add(hierarchy);
        }
        setActiveHierarchies(activeHierarchiesSet);
    }

    const handleAllTrafficsChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeTrafficsSet = new Set<Traffic>();
        if (e.target.checked) {
            traffics.forEach(e => {
                activeTrafficsSet.add(e.value);
            });
            activeTrafficsSet.add(null);
        }

        setActiveTraffics(activeTrafficsSet);
    }

    const handleTraficChanged = (trafic: Traffic, isActive: boolean) => {
        let activeTrafficsSet = new Set(activeTraffics);
        if (isActive) {
            activeTrafficsSet.delete(trafic);
        }
        else {
            activeTrafficsSet.add(trafic);
        }
        setActiveTraffics(activeTrafficsSet);
    }

    const handleAllEnvironmentsChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeEnvironmentsSet = new Set<Environment>();
        if (e.target.checked) {
            environments.forEach(e => {
                activeEnvironmentsSet.add(e.value);
            });
            activeEnvironmentsSet.add(null);
        }

        setActiveEnvironments(activeEnvironmentsSet);
    }

    const handleEnvironmentChanged = (environment: Environment, isActive: boolean) => {
        let activeEnvironmentsSet = new Set(activeEnvironments);
        if (isActive) {
            activeEnvironmentsSet.delete(environment);
        }
        else {
            activeEnvironmentsSet.add(environment);
        }
        setActiveEnvironments(activeEnvironmentsSet);
    }

    const handleAllManagersChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeManagersSet = new Set<Manager>();
        if (e.target.checked) {
            managers.forEach(e => {
                activeManagersSet.add(e.value);
            });
            activeManagersSet.add(null);
        }

        setActiveManagers(activeManagersSet);
    }

    const handleManagerChanged = (manager: Manager, isActive: boolean) => {
        let activeManagersSet = new Set(activeManagers);
        if (isActive) {
            activeManagersSet.delete(manager);
        }
        else {
            activeManagersSet.add(manager);
        }
        setActiveManagers(activeManagersSet);
    }

    const handleAllImportancesChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeImportancesSet = new Set<string>();
        if (e.target.checked) {
            importances.forEach((importance) => {
                activeImportancesSet.add(importance);
            });
            activeImportancesSet.add(null);
        }

        setActiveImportances(activeImportancesSet);
    }

    const handleImportanceChanged = (importance: string, isActive: boolean) => {
        let activeImportancesSet = new Set(activeImportances);
        if (isActive) {
            activeImportancesSet.delete(importance);
        }
        else {
            activeImportancesSet.add(importance);
        }
        setActiveImportances(activeImportancesSet);
    }

    const handleAllOtherAttributesChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeOtherAttributesSet = new Set<string>();
        if (e.target.checked) {
            otherAttributes.forEach(e => {
                activeOtherAttributesSet.add(e.value);
            });
            activeOtherAttributesSet.add(null);
        }

        setActiveOtherAttributes(activeOtherAttributesSet);
    }

    const handleOtherAttributeChanged = (otherAttribute: string, isActive: boolean) => {
        let activeOtherAttributesSet = new Set(activeOtherAttributes);
        if (isActive) {
            activeOtherAttributesSet.delete(otherAttribute);
        }
        else {
            activeOtherAttributesSet.add(otherAttribute);
        }
        setActiveOtherAttributes(activeOtherAttributesSet);
    }

    const handleValidate = (): void => {
        props.handleDisplaySections(activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes);
        props.onClose();
    }

    return (
        <ClickAwayListener onClickAway={props.onClose}>
            <Popper open={props.open} anchorEl={props.anchorEl} className="poper-menu attributes-list-filter">
                <Box className="popper-content" display="flex" flexDirection="column">
                    <Box className="filters">
                        <SectionFilterComponent
                            activeFilters={activeHierarchies}
                            listFilters={hierarchies}
                            isOpened={isActiveHierarchyListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Attributes_Hierarchy}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsActiveHierarchyListOpened}
                            handleAllFiltersChanged={handleAllHierarchiesChanged}
                            handleActiveFilterChanged={handleHierarchyChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeTraffics}
                            listFilters={traffics}
                            isOpened={isActiveTrafficListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Attributes_Traffic}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsActiveTrafficListOpened}
                            handleAllFiltersChanged={handleAllTrafficsChanged}
                            handleActiveFilterChanged={handleTraficChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeEnvironments}
                            listFilters={environments}
                            isOpened={isActiveEnvironmentListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Attributes_Environment}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsActiveEnvironmentListOpened}
                            handleAllFiltersChanged={handleAllEnvironmentsChanged}
                            handleActiveFilterChanged={handleEnvironmentChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeManagers}
                            listFilters={managers}
                            isOpened={isActiveManagerListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Attributes_Manager}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsActiveManagerListOpened}
                            handleAllFiltersChanged={handleAllManagersChanged}
                            handleActiveFilterChanged={handleManagerChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeImportances}
                            arrayFilters={importances}
                            isOpened={isActiveImportanceListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Attributes_Importance}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsActiveImportanceListOpened}
                            handleAllFiltersChanged={handleAllImportancesChanged}
                            handleActiveFilterChanged={handleImportanceChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeOtherAttributes}
                            listFilters={otherAttributes}
                            isOpened={isActiveOtherAttributesListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Attributes_OtherAttributes}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsActiveOtherAttributesListOpened}
                            handleAllFiltersChanged={handleAllOtherAttributesChanged}
                            handleActiveFilterChanged={handleOtherAttributeChanged}
                        />
                    </Box>
                    <Box className="actions-button">
                        <Button className="btn-cancel" onClick={() => props.onClose()}>
                            {Translate.Resources.UI_Global_Cancel}
                        </Button>
                        <Button className="btn-validate" onClick={() => handleValidate()}>
                            {Translate.Resources.UI_Global_Validate}
                        </Button>
                    </Box>
                </Box>
            </Popper>
        </ClickAwayListener>
    )
}