import { Box } from '@mui/system';
import { AnimationOptions, CameraBoundsOptions, data, Map as AzureMap, Shape, source } from 'azure-maps-control';
import React, { useEffect, useRef, useState } from 'react';
import { MapActionBar } from '../../../../shared/components/MapActionBar/MapActionBar';
import { ScaleLoaderComponent } from '../../../../shared/components/ScaleLoader/ScaleLoaderComponent';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, createMap, createShape, getAreaShapeId, getMapChoiceValue, getSectionShapeId, sectionWidth, setMapCursor } from '../../../../shared/Map/MapUtils';
import { MapCursorMode } from '../../../../shared/models/MapCursorMode';
import { Point } from '../../../../shared/models/Point';
import { ShapeEntityType } from '../../../../shared/models/ShapeEntityType';
import styles from '../../../../_variables.scss';
import styles2 from '../../../../_variables2.scss';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { ScoreTypesColors } from '../../../RoadsCondition/models/ScoreTypesColors';
import { mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { Programming } from '../../services/dataContracts/queryStack/Programming';
import './ProgrammingsMapStyles.scss';

interface ProgrammingsMapComponentProps {
    locationGeometry: Point,
    projectVersionId: number,
    mergedProjects: Map<number, MergedProjectVersion>,
    programmingInEdit: Programming,
    loading: boolean
}

export const ProgrammingsMapComponent = (props: ProgrammingsMapComponentProps): JSX.Element => {

    const azureMap = useRef<AzureMap>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.projectVersionId && props.mergedProjects && props.mergedProjects.size > 0) {
            setLoading(true);
            initMapView(props.projectVersionId);
        }

    }, [props.projectVersionId, props.mergedProjects]);

    useEffect(() => {
        if (props.projectVersionId && props.mergedProjects && props.mergedProjects.size > 0) {
            let mergedProject = props.mergedProjects.get(props.projectVersionId);
            if (mergedProject) {
                let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
                if (datasource) {
                    cleanDatasourceShapes(datasource);

                    if (props.programmingInEdit) {
                        mergedProject = props.mergedProjects.get(props.programmingInEdit.projectVersionId);

                        createMapUnfilteredSectionsShapes(mergedProject, datasource);
                        createMapAreasShapes(mergedProject, props.programmingInEdit, datasource);
                    }
                    else {
                        createMapSectionsShapes(mergedProject, datasource);
                    }
                }
            }
        }
    }, [props.programmingInEdit])

    useEffect(() => {
        return function cleanup() {
            azureMap.current?.dispose();
        }
    }, [])

    const initMapView = async (projectVersionId: number) => {
        let mergedProject = props.mergedProjects.get(projectVersionId);

        initMap(azureMap.current, () => {
            let datasource = createMainDatasource();
            if (props.programmingInEdit) {
                mergedProject = props.mergedProjects.get(props.programmingInEdit.projectVersionId);

                createMapUnfilteredSectionsShapes(mergedProject, datasource);
                createMapAreasShapes(mergedProject, props.programmingInEdit, datasource);
            }
            else {
                createMapSectionsShapes(mergedProject, datasource);
            }
            setMapZoom(azureMap.current, mergedProject);
            setLoading(false);
        });
    }

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (callback) {
                callback();
            }
        });
    }

    const cleanDatasourceShapes = (datasource: source.DataSource): void => {
        datasource.getShapes().forEach((section: Shape) => {
            let entityType = section.getProperties().EntityType;
            if (entityType === ShapeEntityType.area || entityType === ShapeEntityType.section) {
                datasource.remove(section);
            }
        });
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion, datasource: source.DataSource): void => {
        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let shapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
            let sectionShape = createShape(coordinates, shapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(sectionShape);
        });
    }

    const createMapUnfilteredSectionsShapes = (mergedProject: MergedProjectVersion, datasource: source.DataSource): void => {
        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let shapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let sectionShape = createShape(coordinates, shapeId, styles.unfilteredSectionColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(sectionShape);
        });
    }

    const createMapAreasShapes = (mergedProject: MergedProjectVersion, programming: Programming, datasource: source.DataSource): void => {
        if (programming) {
            programming.areas.forEach((area) => {
                area.sections.map(x => x.roadSectionId).forEach((sectionId) => {
                    let section = mergedProject.roadsSections.get(sectionId);
                    let coordinates = section.pathGeometry.coordinates;
                    let unselectedAreaShapeId = getAreaShapeId(sectionId);
                    let unselectedAreaShape = createShape(coordinates, unselectedAreaShapeId, area.hexColor, areaWidth, ShapeEntityType.area, sectionId);
                    datasource.add(unselectedAreaShape);
                });
            });
        }
    }

    const createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        azureMap.current.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        azureMap.current.layers.add(roadLayer);

        return datasource;
    }

    const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        };

        options.type = cameraAnimationType;
        options.duration = cameraAnimationDuration;

        map.setCamera(options);
    }

    return (
        <Box className="programming-map-content">
            <div id="measurementInfo" className="measure"></div>
            {(props.loading || loading) ? <ScaleLoaderComponent /> : ''}
            <div id="AzureMap"></div>
            <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                {azureMap.current &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[]}
                        onSelectedSectionChange={null}
                        selectedSectionsId={[]}
                        sections={null}
                        mainLayer={null}
                        currentMeasurementSystemType={null}
                    />
                }
            </Box>
        </Box>
    );
}
