import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailIcon from '@mui/icons-material/Mail';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import scenarioIcon from 'src/assets/icons/icon-scenario.svg';
import workDoneAreaConeIcon from 'src/assets/icons/icon-work-done-area-cone.svg';
import workToDoAreaConeIcon from 'src/assets/icons/icon-work-to-do-area-cone.svg';
import Translate from '../../../localization/Localization';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { AreaShapeProps } from '../../FollowUpWorks/models/AreaShapeProps';
import { ProgrammingAreaStatus } from '../../Programmings/services/dataContracts/queryStack/ProgrammingAreaStatus';
import './RoadWorksInformationDrawerStyles.scss';
import { SendRoadWorksAreaMessageDialog } from './SendRoadWorksAreaMessageDialog';

interface RoadWorksInformationDrawerProps {
    selectedAreas: Map<number, AreaShapeProps>,
    currencySymbole: string,
    handleClose: () => void,
    handleAreaEnlightenmentChange: (maintenanceAreaId: number) => void
}

const AccordionContentElement = (props: { title: string, value: string, handleMailIconClick?: () => void }): JSX.Element => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center" className="content" mb="10px">
            <Box width="40%" className="title">{props.title}</Box>
            <Box display="flex" flexDirection="row" alignItems="center" width="60%" className="value">
                {props.value}
                {props.handleMailIconClick &&
                    <MailIcon className="mail-icon" onClick={props.handleMailIconClick} />
                }
            </Box>
        </Box>
    );
}

const AccordionContentStatusElement = (props: { title: string, value: string, status: ProgrammingAreaStatus }): JSX.Element => {
    let valueClassName = "value";

    let status = props.status;
    if (status) {
        valueClassName += ` ${status}`;
    }

    return (
        <Box display="flex" flexDirection="row" className="content" alignItems="center" mb="10px">
            <Box width="40%" className="title">{props.title}</Box>
            <Box display="flex" flexDirection="row" className={valueClassName}>
                <img src={scenarioIcon} alt="cone" />
                <div>{props.value}</div>
            </Box>
        </Box>
    );
}

export const RoadWorksInformationDrawer = (props: RoadWorksInformationDrawerProps): JSX.Element => {

    const [isSendRoadWorksAreaMessageDialogOpened, setIsSendRoadWorksAreaMessageDialogOpened] = useState<boolean>(false);
    const [selectedAreaForSendingMessage, setSelectedAreaForSendingMessage] = useState<AreaShapeProps>(null);

    const handleDisplayOrHideAreaSections = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, maintenanceAreaId: number): void => {
        e.stopPropagation();
        props.handleAreaEnlightenmentChange(maintenanceAreaId);
    }

    const getAreaStatusLabel = (status: ProgrammingAreaStatus): string => {
        switch (status) {
            case ProgrammingAreaStatus.workToDo:
                return Translate.Resources.UI_RoadWorksInformationDrawer_WorkToDo;
            case ProgrammingAreaStatus.workDone:
                return Translate.Resources.UI_RoadWorksInformationDrawer_WorkDone;
            default:
                return "";
        }
    }

    //const handleMailIconClick = (area: AreaShapeProps): void => {
    //    setIsSendRoadWorksAreaMessageDialogOpened(true);
    //    setSelectedAreaForSendingMessage(area);
    //}

    const handleAreaMessageDialogClosed = (): void => {
        setIsSendRoadWorksAreaMessageDialogOpened(false);
        setSelectedAreaForSendingMessage(null);
    }

    let selectedAreas = Array.from(props.selectedAreas);

    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();
    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();

    return (
        <Box className="road-works-information" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="information-header">
                <div className="drawer-title">{Translate.Resources.UI_RoadWorksInformationDrawer_Title_WorkArea}</div>
                <IconButton onClick={props.handleClose} aria-label="close" className="btn-close">
                    <FontAwesomeIcon icon={faTimes} size="2x" />
                </IconButton>
            </Box>
            <Box className="maintenance-areas">
                {selectedAreas.map(([key, selectedArea]) => {
                    let length = selectedArea.lengthInLinearMeters ? MeasurementSystem.getLengthInMetersOrYards(selectedArea.lengthInLinearMeters).toFixedLocalized(0) : '';
                    let area = selectedArea.areaInSquareMeters ? MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedArea.areaInSquareMeters).toFixedLocalized(0) : '';
                    return (
                        <Accordion key={`area-${key}`} defaultExpanded={true}>
                            <AccordionSummary className="summary"
                                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    {(selectedArea.status === ProgrammingAreaStatus.workDone ? <img src={workDoneAreaConeIcon} alt="work done area cone icon" /> :
                                        (selectedArea.status === ProgrammingAreaStatus.workToDo ? <img src={workToDoAreaConeIcon} alt="work to do area cone icon" /> : ''))
                                    }
                                    <div className="summary-title">{selectedArea.label}</div>
                                    <Button className={selectedArea.isEnlightened ? "btn-hide-area-sections" : "btn-display-area-sections"} onClick={(e) => handleDisplayOrHideAreaSections(e, selectedArea.programmingAreaId)} />
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className="details">
                                <Box display="flex" flexDirection="column" width="100%">
                                    <AccordionContentStatusElement title={Translate.Resources.UI_RoadWorksInformationDrawer_Status} value={getAreaStatusLabel(selectedArea.status)} status={selectedArea.status} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_ProgrammeName} value={`${selectedArea.year} - ${selectedArea.programmingLabel}`} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_BeginDate} value={selectedArea.startDate ? `${(selectedArea.startDate.getMonth() + 1)}/${selectedArea.startDate.getFullYear()}` : ''} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_EndDate} value={selectedArea.endtDate ? `${(selectedArea.endtDate.getMonth() + 1)}/${selectedArea.endtDate.getFullYear()}` : ''} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_SelectedWorks} value={selectedArea.selectedWork} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_Budget} value={selectedArea.budgetAmount ? `${selectedArea.budgetAmount.toLocaleString()} ${props.currencySymbole}` : ''} />
                                    <AccordionContentElement title={`${Translate.Resources.UI_RoadWorksInformationDrawer_Linear} (${symbolOfLengthUnit})`} value={`${length} ${symbolOfLengthUnit}`} />
                                    <AccordionContentElement title={`${Translate.Resources.UI_RoadWorksInformationDrawer_SurfaceArea} (${symbolOfSurfaceUnit})`} value={`${area} ${symbolOfSurfaceUnit}`} />
                                    <AccordionContentElement title={Translate.Resources.UI_RoadWorksInformationDrawer_PreparedBy} value={selectedArea.lastModificationUser}
                                    //handleMailIconClick={() => handleMailIconClick(selectedArea)}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            {isSendRoadWorksAreaMessageDialogOpened &&
                <SendRoadWorksAreaMessageDialog
                    isSendRoadWorksAreaMessageDialogOpened={isSendRoadWorksAreaMessageDialogOpened}
                    area={selectedAreaForSendingMessage}
                    handleSendRoadWorksAreaMessageDialogClosed={() => handleAreaMessageDialogClosed()}
                />
            }
        </Box>
    )
}