import axios, { AxiosResponse } from "axios";
import { RoadSectionAttributes } from "./dataContracts/queryStack/RoadSectionAttributes";
import { UpdateRoadSectionAttributesBulkRequestArgs } from "./dataContracts/controller/UpdateRoadSectionAttributesBulkRequestArgs";
import { CommandResult } from "src/shared/models/CommandResult";

const controllerUrl = 'api/Highways/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export const getRoadSectionsAttributes = async (projectVersionId: number, auscultationsIds: string): Promise<AxiosResponse<RoadSectionAttributes[]>> =>
    axios({
        method: 'GET',
        url: `${controllerUrl}GetMergedRoadSectionAttributes?projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}`,
        headers: headers
    });

export const updateRoadSectionAttributes = (requestArgs: UpdateRoadSectionAttributesBulkRequestArgs)
    : Promise<AxiosResponse<CommandResult>> => {
    return axios({
        method: 'POST',
        url: `${controllerUrl}UpdateRoadSectionAttributes`,
        headers: headers,
        data: JSON.stringify(requestArgs)
    })
};
