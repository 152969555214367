import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import { MeasurementSystemType } from '../../../shared/models/MeasurementSystemType';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { CostRatioViewData } from '../models/CostRatioViewData';

export const CustomEditableNumericCellComponent = (props: GridCellProps): JSX.Element => {
    const dataItem = props.dataItem as CostRatioViewData;
    const getInitialValue = (): number => {
        let value = undefined;
        let fieldChangedValue = dataItem.changes?.get(props.field);

        if (fieldChangedValue !== undefined) {
            if (fieldChangedValue === null || fieldChangedValue === 0)
                value = fieldChangedValue
            else
                value = Number(dataItem.changes.get(props.field))
        }

        return value;
    }

    const [newValue, setNewValue] = useState<number>(getInitialValue());

    let defaultRendering: JSX.Element = null;

    const measurementSystemType = MeasurementSystem.getCurrentType();

    const handleChange = (event: NumericTextBoxChangeEvent, props: GridCellProps): void => {
        dataItem.inEdit = props.field;
        const newValue = event.value;

        let convertedValue = newValue;
        if (newValue && measurementSystemType !== MeasurementSystemType.Metric) {
            convertedValue = newValue * MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
        }

        dataItem.changes.set(props.field, convertedValue);
        setNewValue(convertedValue);
    }

    if (!dataItem.inEdit || dataItem.inEdit !== props.field) {
        let oldValue = dataItem[props.field];
        if (newValue === undefined || newValue === oldValue) {
            if (oldValue !== undefined && oldValue !== null && measurementSystemType !== MeasurementSystemType.Metric) {
                oldValue = oldValue / MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
            }

            defaultRendering = (
                <td className="number">
                    {oldValue !== undefined && oldValue !== null ? oldValue.toFixedLocalized(2, 2) : '-'}
                </td>
            );
        }
        else {
            let displayedValue = newValue;
            if (newValue !== undefined && newValue !== null && measurementSystemType !== MeasurementSystemType.Metric) {
                displayedValue = newValue / MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
            }

            defaultRendering = (
                <td className="number field-changed">
                    {newValue !== undefined && newValue !== null ? displayedValue.toFixedLocalized(2, 2) : '-'}
                </td>
            );
        }
    } else {

        let displayedValue = newValue !== undefined ? newValue : props.dataItem[props.field];
        if (measurementSystemType !== MeasurementSystemType.Metric) {
            displayedValue = displayedValue / MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio
        }

        defaultRendering = (
            <td>
                <NumericTextBox
                    value={displayedValue}
                    min={0}
                    format="n2"
                    onChange={(event) => handleChange(event, props)}
                />
            </td>
        );
    }

    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}