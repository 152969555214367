import axios, { AxiosResponse } from "axios";
import { Programming } from "../../Programmings/services/dataContracts/queryStack/Programming";
import { ProgrammingFilterData } from "../../Programmings/services/dataContracts/queryStack/ProgrammingFilterData";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class FollowUpWorksApiClient {

    public static GetRoadWorksFilterData = (projectId: string)
        : Promise<AxiosResponse<ProgrammingFilterData[]>> => {
        return axios({
            method: 'GET',
            url: `api/Programming/GetRoadWorksFilterData?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetRoadWorks = (projectId: string)
        : Promise<AxiosResponse<Programming[]>> => {
        return axios({
            method: 'GET',
            url: `api/Programming/GetRoadWorks?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetProjectCurrency = (projectId: string)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetProjectCurrency?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }
}
