import { Box } from '@mui/material';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridExpandChangeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import Translate, { Localization } from '../../../localization/Localization';
import { PieChartComponent } from '../../../shared/components/PieChart/PieChartComponent';
import { MetersOrYardsRoundedCell } from '../../../shared/GridUtils/GridUtils';
import { ScoreTypes } from '../../../shared/models/ScoreTypes';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import '../../../utils/Number';
import { ProjectVersionExtended } from '../models/ProjectVersionExtended';
import { RoadSectionViewData } from '../models/RoadSectionViewData';
import { RoadTrunkLabelScoreExtended } from '../models/RoadTrunkLabelScoreExtended';
import { SizingUtilities } from '../SizingUtilities';
import './RoadsDrawerStyles.scss';

//TODO HGA CMA voir si c'est encore nécessaire après mise à jour de package
function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

interface DetailsRoadGridComponentProps {
    dataItem: RoadTrunkLabelScoreExtended,
    displayedSectionsIds: Set<number>,
    handleSectionRowClick: (item: RoadSectionViewData) => void
}

const DetailsRoadGridComponent = (props: DetailsRoadGridComponentProps) => {
    let dataSections = props.dataItem.sections.filter(x => props.displayedSectionsIds.has(x.roadSectionId) || x.isSelected);

    return (
        <Grid
            className="sections-grid"
            selectedField="isSelected"
            data={dataSections}
            rowHeight={36}
            onRowClick={(e) => props.handleSectionRowClick(e.dataItem)}
        >
            <GridColumn field="score" width="8px"
                cell={(properties: GridCellProps) => <td className={`score-color ${properties.dataItem.scoreType === ScoreTypes.monitoring ? 'monitoring' :
                    (properties.dataItem.scoreType === ScoreTypes.localizedRepair ? 'localized-repair' :
                        (properties.dataItem.scoreType === ScoreTypes.generalMaintenance ? 'general-maintenance' :
                            (properties.dataItem.scoreType === ScoreTypes.reinforcement ? 'reinforcement' :
                                (properties.dataItem.scoreType === ScoreTypes.rehabilitation ? 'rehabilitation' : ''))))}`}>
                </td>} />
            <GridColumn field="roadSectionId" width="270px"
                cell={(properties: GridCellProps) => <td>{`Section ${properties.dataItem.roadSectionId}`}</td>} />
            <GridColumn field="score" width="100px"
                cell={(properties: GridCellProps) => <td className="column-number">{properties.dataItem.score}</td>} />
            <GridColumn field="lengthInMeters" width="130" cell={MetersOrYardsRoundedCell} />
        </Grid>
    )
}

interface RoadsGridComponentProps {
    filteredLabelsScores: RoadTrunkLabelScoreExtended[],
    displayedSectionsIds: Set<number>,
    role: string,
    onExpandChange: (event: GridExpandChangeEvent) => void,
    handleRowClick: (item: RoadTrunkLabelScoreExtended) => void,
    handleSectionRowClick: (item: RoadSectionViewData) => void
}

const RoadsGridComponent = (props: RoadsGridComponentProps): JSX.Element => {

    const forceUpdate = useForceUpdate();
    const initialSort: SortDescriptor[] = [{ field: 'hiddenFixedAverageScoreForSort', dir: 'desc' }, { field: 'fixedAverageScore', dir: 'desc' }];

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    const handleSortChange = (e: GridSortChangeEvent): void => {
        let sort = e.sort[0];
        if (sort && sort.field === "label") {
            setSort([{ field: 'hiddenLabelForSort', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "fixedAverageScore") {
            setSort([{ field: 'hiddenFixedAverageScoreForSort', dir: sort.dir }, sort]);
            return;
        }

        setSort(e.sort);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        let skip = event.page.skip;
        if (skip > props.filteredLabelsScores.length || isNaN(skip)) {
            skip = 0;
        }
        setSkip(skip);
    }

    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    var gridHeightGapFromWindow: number = SizingUtilities.labelScoreGridHeightGapFromWindow(props.role);
    var gridHeight: number = SizingUtilities.computeGridHeight(gridHeightGapFromWindow);
    var rowHeight: number = SizingUtilities.rowHeight;
    var gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    var gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridHeightGapFromWindow;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    let dataGrid = sortOrderBy(props.filteredLabelsScores, sort).slice(skip, skip + gridPageSize);

    return (
        <LocalizationProvider language={Localization.locale}>
            <IntlProvider locale={Localization.locale}>
                <Grid
                    className="roads-grid"
                    selectedField="isSelected"
                    data={dataGrid}
                    sortable
                    sort={sort}
                    scrollable="virtual"
                    skip={skip}
                    total={props.filteredLabelsScores.length}
                    rowHeight={rowHeight}
                    pageSize={gridPageSize}
                    style={gridStyle}
                    expandField="expanded"
                    onPageChange={pageChange}
                    onSortChange={handleSortChange}
                    onExpandChange={props.onExpandChange}
                    onRowClick={(e) => props.handleRowClick(e.dataItem)}
                    detail={(_props) => <DetailsRoadGridComponent displayedSectionsIds={props.displayedSectionsIds} dataItem={_props.dataItem} handleSectionRowClick={props.handleSectionRowClick} />}
                >
                    <GridColumn width="267px" field="label" title={Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_RoadLabel} />
                    <GridColumn width="100px" field="fixedAverageScore" title={Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_AverageRoadScore}
                        cell={(_props: GridCellProps) => {
                            return (
                                <td>
                                    <Box className="score-column">
                                        <Box className="value">{_props.dataItem.fixedAverageScore}</Box>
                                        <Box className="pie-chart">
                                            <PieChartComponent
                                                data={{
                                                    monitoringSectionsPercent: _props.dataItem.monitoringSectionsPercent,
                                                    localizedRepairSectionsPercent: _props.dataItem.localizedRepairSectionsPercent,
                                                    generalMaintenanceSectionsPercent: _props.dataItem.generalMaintenanceSectionsPercent,
                                                    reinforcementSectionsPercent: _props.dataItem.reinforcementSectionsPercent,
                                                    rehabilitationSectionsPercent: _props.dataItem.rehabilitationSectionsPercent
                                                }}
                                                innerRadius={8}
                                                outerRadius={15}
                                                paddingAngle={0}
                                                cornerRadius={0}
                                                startAngle={-180}
                                                endAngle={180}
                                                cx={10}
                                                cy={10}
                                            />
                                        </Box>
                                    </Box>
                                </td>
                            )
                        }}
                    />
                    <GridColumn width="135px" field="lengthInMeters" title={`${Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_Linear} (${symbolOfLengthUnit})`}
                        cell={MetersOrYardsRoundedCell}
                    />
                </Grid>
            </IntlProvider>
        </LocalizationProvider>
    )
}

interface RoadsDrawerComponentProps {
    roadTrunkLabelsScores: Map<string, RoadTrunkLabelScoreExtended>,
    projectVersion: ProjectVersionExtended,
    openedDrawer: boolean,
    role: string,
    displayedSectionsIds: Set<number>,
    onExpandChange: (event: GridExpandChangeEvent) => void,
    handleRowClick: (item: RoadTrunkLabelScoreExtended) => void,
    handleSectionRowClick: (item: RoadSectionViewData) => void
}

export const RoadsDrawerComponent = (props: RoadsDrawerComponentProps): JSX.Element => {
    const { openedDrawer, roadTrunkLabelsScores, displayedSectionsIds, onExpandChange } = props;

    let filteredLabelsScores: RoadTrunkLabelScoreExtended[] = [];
    roadTrunkLabelsScores.forEach((element) => {
        if (element.isVisible === true || element.isSelected === true) {
            filteredLabelsScores.push(element);
        }
    });

    return (
        <Box className={`roads-drawer ${openedDrawer ? 'opened' : ''}`}>
            {openedDrawer &&
                <Box display="flex" flexDirection="column" className="opened-roads-drawer-content">
                    <Box className="road-grid">
                        <Box display="flex" flexDirection="row" justifyContent="center" className="roads-content">
                            <RoadsGridComponent
                                filteredLabelsScores={filteredLabelsScores}
                                displayedSectionsIds={displayedSectionsIds}
                                role={props.role}
                                onExpandChange={onExpandChange}
                                handleRowClick={props.handleRowClick}
                                handleSectionRowClick={props.handleSectionRowClick}
                            />
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    );
}
