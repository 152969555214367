import axios, { AxiosResponse } from "axios";
import { Localization } from "../../../localization/Localization";
import { CommandResult } from "../../../shared/models/CommandResult";
import { MeasurementSystemType } from "../../../shared/models/MeasurementSystemType";
import { CostRatio } from "../../ProjectSettings/services/dataContracts/queryStack/CostRatio";
import { WorkPriority } from "../../ProjectSettings/services/dataContracts/queryStack/WorkPriority";
import { HigherProjectVersion } from "../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/HigherProjectVersion";
import { AddOrUpdateProgrammingAreaRequestArgs } from "./dataContracts/controller/AddOrUpdateProgrammingAreaRequestArgs";
import { AddProgrammingRequestArgs } from "./dataContracts/controller/AddProgrammingRequestArgs";
import { DeleteProgrammingAreaRequestArgs } from "./dataContracts/controller/DeleteProgrammingAreaRequestArgs";
import { DeleteProgrammingRequestArgs } from "./dataContracts/controller/DeleteProgrammingRequestArgs";
import { DuplicateProgrammingRequestArgs } from "./dataContracts/controller/DuplicateProgrammingRequestArgs";
import { GetRoadWorksRequestArgs } from "./dataContracts/controller/GetRoadWorksRequestArgs";
import { RequestProgrammingRequestArgs } from "./dataContracts/controller/RequestProgrammingRequestArgs";
import { UpdateLabelAndYearProgrammingRequestArgs } from "./dataContracts/controller/UpdateLabelAndYearProgrammingRequestArgs";
import { FilteredProgramming } from "./dataContracts/queryStack/FilteredProgramming";
import { Programming } from "./dataContracts/queryStack/Programming";

const controllerUrl = 'api/Programming/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class ProgrammingsApiClient {

    public static GetProgrammings = (projectId: string)
        : Promise<AxiosResponse<Programming[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProgrammings?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetProgramming = (projectId: string, programmingId: number)
        : Promise<AxiosResponse<Programming>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProgramming?projectId=${encodeURIComponent(projectId)}&programmingId=${encodeURIComponent(programmingId)}`,
            headers: headers
        });
    }

    public static GetTypesOfWorks = (projectId: string)
        : Promise<AxiosResponse<string[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetTypesOfWorks?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static AddProgramming = (requestArgs: AddProgrammingRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddProgramming`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DuplicateProgramming = (requestArgs: DuplicateProgrammingRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DuplicateProgramming`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static ForceDuplicateProgramming = (requestArgs: DuplicateProgrammingRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ForceDuplicateProgramming`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static AddOrUpdateProgrammingArea = (requestArgs: AddOrUpdateProgrammingAreaRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddOrUpdateProgrammingArea`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DeleteProgrammingArea = (requestArgs: DeleteProgrammingAreaRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteProgrammingArea`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DeleteProgramming = (requestArgs: DeleteProgrammingRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteProgramming`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateProgrammingLabelAndYear = (requestArgs: UpdateLabelAndYearProgrammingRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateProgrammingLabelAndYear`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static RequestProgramming = (requestArgs: RequestProgrammingRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}RequestProgramming`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetProjectCurrency = (projectId: string)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetProjectCurrency?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetWorkPriorities = (projectId: string)
        : Promise<AxiosResponse<Array<WorkPriority>>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetWorkPriorities?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetCostRatios = (projectId: string, year: number)
        : Promise<AxiosResponse<CostRatio[]>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetCostRatios?projectId=${encodeURIComponent(projectId)}&year=${encodeURIComponent(year)}`,
            headers: headers
        });
    }

    public static GetProjectHigherVersions = (projectId: string, currentProjectVersionId: number)
        : Promise<AxiosResponse<Array<HigherProjectVersion>>> => {
        return axios({
            method: 'GET',
            url: `api/RoadsCondition/GetProjectHigherVersions?projectId=${encodeURIComponent(projectId)}&currentProjectVersionId=${encodeURIComponent(currentProjectVersionId)}`,
            headers: headers
        });
    }

    public static GetRoadWorksByFilters = (projectId: string, data: GetRoadWorksRequestArgs)
        : Promise<AxiosResponse<FilteredProgramming[]>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}GetRoadWorksByFilters?projectId=${encodeURIComponent(projectId)}`,
            headers: headers,
            data: JSON.stringify(data)
        });
    }

    public static ExportExcel = (programmingId: number, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType, currency: string)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportExcel?programmingId=${encodeURIComponent(programmingId)}&language=${encodeURIComponent(Localization.locale)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&currency=${currency}`,
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportGeoJson = (programmingId: number, programmingLabel: string, projectLabel: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportGeoJson?programmingId=${encodeURIComponent(programmingId)}&projectLabel=${encodeURIComponent(projectLabel)}&programmingLabel=${encodeURIComponent(programmingLabel)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportShapefile = (programmingId: number, programmingLabel: string, projectLabel: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportShapefile?programmingId=${encodeURIComponent(programmingId)}&projectLabel=${encodeURIComponent(projectLabel)}&programmingLabel=${encodeURIComponent(programmingLabel)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportKml = (programmingId: number, programmingLabel: string, projectLabel: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportKml?programmingId=${encodeURIComponent(programmingId)}&projectLabel=${encodeURIComponent(projectLabel)}&programmingLabel=${encodeURIComponent(programmingLabel)}&ianaTimeZoneId=${encodeURIComponent(Localization.ianaTimeZoneId)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }
}