import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Paper, PaperProps, Select } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import Draggable from 'react-draggable';
import userIcon from 'src/assets/icons/icon-user-popup.svg';
import Translate from '../../../localization/Localization';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import Utilities from '../../../utils/Utilities';
import { MeasurementSystemType } from '../../models/MeasurementSystemType';
import { UserModel } from '../../User/services/dataContracts/queryStack/UserModel';
import './UserDialogStyles.scss';

interface UserDialogProps {
    isOpen: boolean,
    user: UserModel,
    onCancel: () => void,
    onValidate: (measurementSystemType: MeasurementSystemType) => void
}

export const UserDialog = (props: UserDialogProps): JSX.Element => {

    const [measurementSystemType, setMeasurementSystemType] = useState<MeasurementSystemType>(MeasurementSystem.getCurrentType());

    const handleValidateClicked = (): void => {
        props.onValidate(measurementSystemType);
    }

    const handleMeasurementSystemTypeChanged = (e: ChangeEvent<{ name: string, value: MeasurementSystemType }>): void => {
        setMeasurementSystemType(e.target.value);
    }

    const PaperComponent = (props: PaperProps) => {
        return (
            <Draggable handle="#user-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.onCancel}
            scroll="paper"
            className="user-dialog"
            PaperComponent={PaperComponent}
            aria-labelledby="user-dialog-title"
        >
            <DialogTitle id="user-dialog-title" >
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box className="dialog-title">
                        <img src={userIcon} alt="user icon" />
                        {(props.user?.fullName ? (
                            <Box className="text">{props.user?.fullName}</Box>
                        ) : (props.user?.firstName?.trim() && props.user?.lastName?.trim() ? (
                            <Box className="text">{`${props.user?.lastName} ${props.user?.firstName}`}</Box>
                        ) : (props.user?.firstName?.trim() && !props.user?.lastName?.trim() ? (
                            <Box className="text">{`${props.user?.firstName}`}</Box>
                        ) : (!props.user?.firstName?.trim() && props.user?.lastName?.trim() ? (
                            <Box className="text">{`${props.user?.lastName}`}</Box>
                        ) : ('')))))}
                    </Box>
                    <Box>
                        <IconButton onClick={props.onCancel} className="btn-close">
                            {Translate.Resources.UI_UserDialog_Close}
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" className="content">
                    <Box display="flex" flexDirection="column">
                        {props.user?.email &&
                            <Box display="flex" flexDirection="row" mb="10px" height="33px">
                                <Box width="40%" className="title">{Translate.Resources.UI_UserDialog_Email}</Box>
                                <Box width="60%" className="text">{props.user?.email}</Box>
                            </Box>
                        }
                        {props.user?.phoneNumber &&
                            <Box display="flex" flexDirection="row" mb="10px" height="33px">
                                <Box width="40%" className="title">{Translate.Resources.UI_UserDialog_Phone}</Box>
                                <Box width="60%" className="text">{Utilities.formatPhoneNumber(props.user?.phoneNumber)}</Box>
                            </Box>
                        }
                        {props.user?.role &&
                            <Box display="flex" flexDirection="row" mb="10px" height="33px">
                                <Box width="40%" className="title">{Translate.Resources.UI_UserDialog_Profile}</Box>
                                <Box width="60%" className="text">{props.user?.role}</Box>
                            </Box>
                        }
                        <Box display="flex" flexDirection="row" mb="10px" height="33px">
                            <Box width="40%" className="title">{Translate.Resources.UI_UserDialog_MeasurementSystemType}</Box>
                            <Box>
                                <Select
                                    className="select"
                                    value={measurementSystemType}
                                    onChange={(e) => handleMeasurementSystemTypeChanged(e as ChangeEvent<{ name: string, value: MeasurementSystemType }>)}
                                >
                                    <MenuItem value={MeasurementSystemType.Imperial}>{Translate.Resources.UI_UserDialog_ImperialSystem}</MenuItem>
                                    <MenuItem value={MeasurementSystemType.Metric}>{Translate.Resources.UI_UserDialog_MetricSystem}</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button className="btn-cancel" onClick={props.onCancel}>
                            {Translate.Resources.UI_UserDialog_Button_Cancel}
                        </Button>
                        <Button className="btn-save" onClick={handleValidateClicked}>
                            <SaveIcon className="btn-icon" />
                            {Translate.Resources.UI_UserDialog_Button_Save}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};