import { Box } from '@mui/material';
import { Map as AzureMap, MapMouseEvent } from 'azure-maps-control';
import React from 'react';
import { barAction, MapActionBar } from '../../../shared/components/MapActionBar/MapActionBar';
import { MeasurementSystemType } from '../../../shared/models/MeasurementSystemType';
import { ProjectThumbnailModel } from '../../Home/models/ProjectThumbnailModel';
import { RoadsSectionsSummaryModel } from '../models/RoadsSectionsSummaryModel';
import { roadLayerId } from '../RoadsConditionAndScenariosShared';
import { RoadsSectionsSummaryComponent } from './RoadsSectionsSummaryComponent';

interface MapWithImagesComponentProps {
    loading: boolean,
    role: string,
    selectedRoadsSectionsSummary: RoadsSectionsSummaryModel,
    activeQualities: Set<number>,
    displayedSectionsIds: Set<number>,
    auscultationsIdsString: string,
    selectedProject: ProjectThumbnailModel,
    azureMap: AzureMap,
    currentMeasurementSystemType: MeasurementSystemType,
    handleDisplayImageFromSectionClicked: (event: MapMouseEvent) => void,
    handleCLoseRoadSectionDetails: () => void
}

export const MapWithImagesComponent = (props: MapWithImagesComponentProps): JSX.Element => {

    const {
        loading,
        azureMap,
        selectedRoadsSectionsSummary
    } = props;

    const map: JSX.Element = (
        <>
            <div id="AzureMap"></div>
            {!loading && (
                <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                    {azureMap &&
                        <MapActionBar
                            azureMap={azureMap}
                            actions={[barAction.carSelect, barAction.Measure]}
                            onSelectedSectionChange={null}
                            selectedSectionsId={[]}
                            sections={null}
                            mainLayer={roadLayerId}
                            selectedDefaultAction={barAction.carSelect}
                            currentMeasurementSystemType={props.currentMeasurementSystemType}
                            handleDisplayImageFromSectionClicked={props.handleDisplayImageFromSectionClicked}
                            handleCLoseRoadSectionDetails={props.handleCLoseRoadSectionDetails}
                        />
                    }
                </Box>
            )}
        </>
    );

    return (
        <Box display="flex" flexDirection="column" className="map-content">
            {map}
            {!loading ?
                <RoadsSectionsSummaryComponent
                    selectedRoadsSectionsSummary={selectedRoadsSectionsSummary}
                    activeQualities={props.activeQualities}
                    role={props.role}
                    displayedSectionsIds={props.displayedSectionsIds}
                    selectedProject={props.selectedProject}
                    auscultationsIdsString={props.auscultationsIdsString}
                />
                : ''}
        </Box>
    );
}