import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Translate from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { ExportProgrammingDialog } from '../../components/ExportProgrammingDialog';
import { ProgrammingExtended } from '../../models/ProgrammingExtended';
import './AreasSynthesisStyles.scss';

interface AreasSynthesisComponentProps {
    loading: boolean,
    selectedProgramming: ProgrammingExtended,
    currency: string,
    mergedProject: MergedProjectVersion,
    handleAddZoneCLicked: () => void
}

export const AreasSynthesisComponent = (props: AreasSynthesisComponentProps): JSX.Element => {

    const programmingAreas = props.selectedProgramming ? props.selectedProgramming.relatedAreas : [];

    const [summary, setSummary] = useState<{
        totalBudget: number,
        totalLengthInLinearMeters: number,
        totalAreaInSquareMeters: number,
        totalSectionsIdsCount: number,
        firstStartDate: Date,
        lastEndDate: Date,
        monitoringSectionsPercent: number,
        localizedRepairSectionsPercent: number,
        generalMaintenanceSectionsPercent: number,
        reinforcementSectionsPercent: number,
        rehabilitationSectionsPercent: number
    }>({
        totalBudget: null,
        totalLengthInLinearMeters: null,
        totalAreaInSquareMeters: null,
        totalSectionsIdsCount: null,
        firstStartDate: null,
        lastEndDate: null,
        monitoringSectionsPercent: null,
        localizedRepairSectionsPercent: null,
        generalMaintenanceSectionsPercent: null,
        reinforcementSectionsPercent: null,
        rehabilitationSectionsPercent: null
    });

    const [exportProgrammingDialogOpened, setExportProgrammingDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        if (props.selectedProgramming) {
            const areas = props.selectedProgramming.relatedAreas;

            if (areas.length > 0) {
                let areasBudgets = areas.map(x => x.budgetAmount ?? 0);
                let totalBudget = areasBudgets.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areasLengthInLinearMeters = areas.map(x => x.lengthInLinearMeters ?? 0);
                let totalLengthInLinearMeters = areasLengthInLinearMeters.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areasAreaInSquareMeters = areas.map(x => x.areaInSquareMeters ?? 0);
                let totalAreaInSquareMeters = areasAreaInSquareMeters.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areaSectionsIdsCount = areas.map(x => x.sections.length ?? 0);
                let totalSectionsIdsCount = areaSectionsIdsCount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areasWithStartDate = areas.filter(x => x.startDate !== null && x.startDate !== undefined);
                let firstStartDate = areasWithStartDate.length > 0 ? areasWithStartDate.map(x => x.startDate).reduce((a, b) => a < b ? a : b) : null;

                let areasWithEndDate = areas.filter(x => x.startDate !== null && x.startDate !== undefined);
                let lastEndDate = areasWithEndDate.length > 0 ? areasWithEndDate.map(x => x.endtDate).reduce((a, b) => a > b ? a : b) : null;

                let monitoringSectionsLength = 0;
                let localizedRepairSectionsLength = 0;
                let generalMaintenanceSectionsLength = 0;
                let reinforcementSectionsLength = 0;
                let rehabilitationSectionsLength = 0;

                areas.forEach((area) => {
                    monitoringSectionsLength += (area.monitoringSectionsPercent * area.lengthInLinearMeters) / 100;
                    localizedRepairSectionsLength += (area.localizedRepairSectionsPercent * area.lengthInLinearMeters) / 100;
                    generalMaintenanceSectionsLength += (area.generalMaintenanceSectionsPercent * area.lengthInLinearMeters) / 100;
                    reinforcementSectionsLength += (area.reinforcementSectionsPercent * area.lengthInLinearMeters) / 100;
                    rehabilitationSectionsLength += (area.rehabilitationSectionsPercent * area.lengthInLinearMeters) / 100;
                });

                let monitoringSectionsPercent = (monitoringSectionsLength * 100) / totalLengthInLinearMeters;
                let localizedRepairSectionsPercent = (localizedRepairSectionsLength * 100) / totalLengthInLinearMeters;
                let generalMaintenanceSectionsPercent = (generalMaintenanceSectionsLength * 100) / totalLengthInLinearMeters;
                let reinforcementSectionsPercent = (reinforcementSectionsLength * 100) / totalLengthInLinearMeters;
                let rehabilitationSectionsPercent = (rehabilitationSectionsLength * 100) / totalLengthInLinearMeters;

                setSummary({
                    totalBudget: totalBudget,
                    totalLengthInLinearMeters: totalLengthInLinearMeters,
                    totalAreaInSquareMeters: totalAreaInSquareMeters,
                    totalSectionsIdsCount: totalSectionsIdsCount,
                    firstStartDate: firstStartDate,
                    lastEndDate: lastEndDate,
                    monitoringSectionsPercent: monitoringSectionsPercent,
                    localizedRepairSectionsPercent: localizedRepairSectionsPercent,
                    generalMaintenanceSectionsPercent: generalMaintenanceSectionsPercent,
                    reinforcementSectionsPercent: reinforcementSectionsPercent,
                    rehabilitationSectionsPercent: rehabilitationSectionsPercent
                });
            }
        }
    }, [props.selectedProgramming])

    const handleExportProgramming = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setExportProgrammingDialogOpened(true);
    }

    const handleProgrammingDialogClosed = (): void => {
        setExportProgrammingDialogOpened(false);
    }

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    return (
        <Box className="areas-synthesis">
            <Box display="flex" flexDirection="row">
                <Box className="item">
                    <div className="title">{Translate.Resources.UI_Programming_AreasManagement_Zones}</div>
                    <div className="value">{programmingAreas.length}</div>
                </Box>
                <Button className="btn-primary" disabled={props.loading} onClick={props.handleAddZoneCLicked}>
                    <AddIcon />
                    {Translate.Resources.UI_Programming_AreasManagement_AddAZone}
                </Button>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box className="item">
                    <div className="title">{Translate.Resources.UI_Programming_AreasManagement_Budget}</div>
                    <div className="value">
                        {`${summary.totalBudget?.toFixedLocalized(0) ?? 0} ${props.currency ?? ''}`}
                        <span className="scenario-amount">{`${props.selectedProgramming?.scenarioTotalAmount ? ' / ' + props.selectedProgramming.scenarioTotalAmount?.toFixedLocalized(0) + ' ' + (props.currency ?? '') : ''}`}</span>
                    </div>
                </Box>
                <Box className="pie-chart">
                    <PieChartComponent
                        data={programmingAreas.length > 0 ? {
                            monitoringSectionsPercent: summary.monitoringSectionsPercent ?? 0,
                            localizedRepairSectionsPercent: summary.localizedRepairSectionsPercent ?? 0,
                            generalMaintenanceSectionsPercent: summary.generalMaintenanceSectionsPercent ?? 0,
                            reinforcementSectionsPercent: summary.reinforcementSectionsPercent ?? 0,
                            rehabilitationSectionsPercent: summary.rehabilitationSectionsPercent ?? 0
                        } : null}
                        innerRadius={7}
                        outerRadius={16}
                        paddingAngle={0}
                        cornerRadius={0}
                        startAngle={-180}
                        endAngle={180}
                        cx={20}
                        cy={15}
                    />
                </Box>
                <Box className="item">
                    <div className="title">{Translate.Resources.UI_Programming_AreasManagement_NumberOfSections}</div>
                    <div className="value">{summary.totalSectionsIdsCount?.toFixedLocalized(0)}</div>
                </Box>
                <Box className="item">
                    <div className="title">{`${Translate.Resources.UI_Programming_AreasManagement_Linear} (${symbolOfLengthUnit})`}</div>
                    <div className="value">{summary.totalLengthInLinearMeters?.toFixedLocalized(0)}</div>
                </Box>
                <Box className="item">
                    <div className="title">{`${Translate.Resources.UI_Programming_AreasManagement_Surface} (${symbolOfSurfaceUnit})`}</div>
                    <div className="value">{summary.totalAreaInSquareMeters?.toFixedLocalized(0)}</div>
                </Box>
                <Box className="item">
                    <div className="title">{Translate.Resources.UI_Programming_AreasManagement_Begin}</div>
                    <div className="value">{summary.firstStartDate ? `${(summary.firstStartDate.getMonth() + 1) > 9 ? summary.firstStartDate.getMonth() + 1 : '0' + (summary.firstStartDate.getMonth() + 1)}/${summary.firstStartDate.getFullYear()}` : ''}</div>
                </Box>
                <Box className="item">
                    <div className="title">{Translate.Resources.UI_Programming_AreasManagement_End}</div>
                    <div className="value">{summary.lastEndDate ? `${(summary.lastEndDate.getMonth() + 1) > 9 ? summary.lastEndDate.getMonth() + 1 : '0' + (summary.lastEndDate.getMonth() + 1)}/${summary.lastEndDate.getFullYear()}` : ''}</div>
                </Box>
                <Button className="btn-primary" onClick={handleExportProgramming}>
                    {Translate.Resources.UI_ExportProgrammingDialog_Export}
                </Button>
            </Box>
            {exportProgrammingDialogOpened &&
                <ExportProgrammingDialog opened={exportProgrammingDialogOpened}
                    programmingId={props.selectedProgramming?.programmingId}
                    projectVersionId={props.mergedProject.projectVersionId}
                    auscultationsIdsString={props.mergedProject.auscultationsIdsString}
                    projectLabel={props.mergedProject.projectLabel}
                    programmingLabel={props.selectedProgramming?.label}
                    currency={props.currency ?? ''}
                    handleClose={handleProgrammingDialogClosed}
                />
            }
        </Box>
    );
}