import { Box } from '@mui/material';
import React from 'react';
import Translate from "../../localization/Localization";
import './NoAuscultationStyles.scss';

export const NoAuscultationComponent = (): JSX.Element => {

    return (
        <Box className="no-auscultation-component">
            <Box className="message">
                {Translate.Resources.UI_NoAuscultation_NoExaminationToVisualise}
                <br />
                {Translate.Resources.UI_NoAuscultation_ComingExaminationPatience}
            </Box>
        </Box>
    );
}