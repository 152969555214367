import { MenuItem, Select } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useState } from 'react';
import { getProgrammingWorkOrientationTextValues } from "../../models/WorkOrientation";
import { WorkOrientation } from "../../services/dataContracts/queryStack/WorkOrientation";

export const CustomWorkOrientationCell = (props: GridCellProps): JSX.Element => {

    const { render, dataItem } = props;

    const [workOrientation, setWorkOrientation] = useState<WorkOrientation>(dataItem.workOrientation);

    const handleWorkOrientationChanged = (value: WorkOrientation): void => {
        dataItem.workOrientation = value;
        setWorkOrientation(value);
    }

    const programmingWorkOrientationTextValues = getProgrammingWorkOrientationTextValues();

    const defaultRendering = (
        <td>
            <Select className="drop-down-list work-orientation"
                value={workOrientation ?? ""}
                onChange={(e) => handleWorkOrientationChanged(e.target.value as WorkOrientation)}
            >
                <MenuItem value={WorkOrientation.monitoring}>{programmingWorkOrientationTextValues.get(WorkOrientation.monitoring)}</MenuItem>
                <MenuItem value={WorkOrientation.localizedRepair}>{programmingWorkOrientationTextValues.get(WorkOrientation.localizedRepair)}</MenuItem>
                <MenuItem value={WorkOrientation.generalMaintenance}>{programmingWorkOrientationTextValues.get(WorkOrientation.generalMaintenance)}</MenuItem>
                <MenuItem value={WorkOrientation.reinforcement}>{programmingWorkOrientationTextValues.get(WorkOrientation.reinforcement)}</MenuItem>
                <MenuItem value={WorkOrientation.rehabilitation}>{programmingWorkOrientationTextValues.get(WorkOrientation.rehabilitation)}</MenuItem>
            </Select>
        </td>
    );

    return render?.call(undefined, defaultRendering, props);
}