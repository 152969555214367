import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, ClickAwayListener, FormControlLabel, Popper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import Translate from '../../../localization/Localization';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import { StepImageAnomalies } from '../services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { RoadsConditionApiClient } from '../services/RoadsCondition/RoadsConditionApiClient';
import './DetectionListFilterStyles2.scss';

interface DetectionItemProps {
    label: string,
    isActive: boolean,
    anomaly: string,
    isAnomaly?: boolean,
    onChange: (anomaly: string, isActive: boolean) => void
}

const DetectionItem = (props: DetectionItemProps): JSX.Element => {
    return (
        <Box className="detection-item">
            <FormControlLabel
                control={<Checkbox checked={props.isActive} onChange={() => props.onChange(props.anomaly, props.isActive)} />}
                label={
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <div className={`detection-color ${props.isAnomaly ? 'anomaly' : ''} ${props.anomaly}`}></div>
                        {props.label}
                    </Box>
                }
                className="form-control-label-checkbox" />
        </Box>
    );
}

interface DetectionListFilterComponentProps {
    open: boolean,
    anchorEl: null | HTMLElement,
    activeAnomalies: Set<string>,
    activeMarkingDetections: Set<string>,
    activeComplementaryDetections: Set<string>,
    activeWarningDetections: Set<string>,
    activeInformationDetections: Set<string>,
    activeRegulatoryDetections: Set<string>,
    mergedProject: MergedProjectVersion,
    projectVersionAnomalies: Map<string, string>,
    projectVersionMarking: Map<string, string>,
    projectVersionComplementary: Map<string, string>,
    projectVersionWarning: Map<string, string>,
    projectVersionInformation: Map<string, string>,
    projectVersionRegulatory: Map<string, string>,
    onClose: () => void,
    handleDisplayDetection: (activeAnomalies: Set<string>,
        activeMarkingDetections: Set<string>,
        activeComplementaryDetections: Set<string>,
        activeWarningDetections: Set<string>,
        activeInformationDetections: Set<string>,
        activeRegulatoryDetections: Set<string>) => void,
    setAnomaliesData: ({ anomalies,
        markingDetections,
        complementaryDetections,
        warningDetections,
        informationDetections,
        regulatoryDetections,
        perStepImagesAnomaliesMap
    }: {
        anomalies: Map<string, string>,
        markingDetections: Map<string, string>,
        complementaryDetections: Map<string, string>,
        warningDetections: Map<string, string>,
        informationDetections: Map<string, string>,
        regulatoryDetections: Map<string, string>,
        perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]>
    }) => void
}

export const DetectionListFilterComponent2 = (props: DetectionListFilterComponentProps): JSX.Element => {

    const [activeAnomalies, setActiveAnomalies] = useState<Set<string>>(new Set<string>(props.activeAnomalies));
    const [anomalies, setAnomalies] = useState<Map<string, string>>(new Map<string, string>());

    const [activeMarkingDetections, setActiveMarkingDetections] = useState<Set<string>>(new Set<string>(props.activeMarkingDetections));
    const [markingDetections, setMarkingDetections] = useState<Map<string, string>>(new Map<string, string>());

    const [activeComplementaryDetections, setActiveComplementaryDetections] = useState<Set<string>>(new Set<string>(props.activeComplementaryDetections));
    const [complementaryDetections, setComplementaryDetections] = useState<Map<string, string>>(new Map<string, string>());

    const [activeWarningDetections, setActiveWarningDetections] = useState<Set<string>>(new Set<string>(props.activeWarningDetections));
    const [warningDetections, setWarningDetections] = useState<Map<string, string>>(new Map<string, string>());

    const [activeInformationDetections, setActiveInformationDetections] = useState<Set<string>>(new Set<string>(props.activeInformationDetections));
    const [informationDetections, setInformationDetections] = useState<Map<string, string>>(new Map<string, string>());

    const [activeRegulatoryDetections, setActiveRegulatoryDetections] = useState<Set<string>>(new Set<string>(props.activeRegulatoryDetections));
    const [regulatoryDetections, setRegulatoryDetections] = useState<Map<string, string>>(new Map<string, string>());

    const [loading, setLoading] = useState<boolean>(false);

    const [isDetectionListOpened, setIsDetectionListOpened] = useState<boolean>(true);
    const [isHorizontalMarkingListOpened, setIsHorizontalMarkingListOpened] = useState<boolean>(true);
    const [isVerticalMarkingListOpened, setIsVerticalMarkingListOpened] = useState<boolean>(true);

    const [isComplementaryListOpened, setIsComplementaryListOpened] = useState<boolean>(true);
    const [isWarningListOpened, setIsWarningListOpened] = useState<boolean>(true);
    const [isInformationListOpened, setIsInformationListOpened] = useState<boolean>(true);
    const [isRegulatoryListOpened, setIsRegulatoryListOpened] = useState<boolean>(true);

    useEffect(() => {
        if (props.projectVersionAnomalies.size === 0 && props.projectVersionMarking.size === 0) {
            setLoading(true);

            Promise.all([
                RoadsConditionApiClient.GetProjectVersionAnomalies(props.mergedProject.projectVersionId),
                RoadsConditionApiClient.GetPerStepImagesAnomalies(props.mergedProject.projectVersionId)
            ]).then((results) => {
                let detectionsData = results[0].data;

                let anomalies = new Map<string, string>();
                let markingDetections = new Map<string, string>();
                let complementaryDetections = new Map<string, string>();
                let warningDetections = new Map<string, string>();
                let informationDetections = new Map<string, string>();
                let regulatoryDetections = new Map<string, string>();

                detectionsData.forEach((detection) => {
                    if (detection.startsWith('marking')) {
                        detection = detection.replace(/-/g, '_');
                        markingDetections.set(detection, Translate.GetAnomalyTypeLabel(detection));
                    }
                    else if (detection.startsWith('complementary')) {
                        detection = detection.replace(/-/g, '_');
                        complementaryDetections.set(detection, Translate.GetAnomalyTypeLabel(detection));
                    }
                    else if (detection.startsWith('warning') || detection === 'other') {
                        detection = detection.replace(/-/g, '_');
                        warningDetections.set(detection, Translate.GetAnomalyTypeLabel(detection));
                    }
                    else if (detection.startsWith('information')) {
                        detection = detection.replace(/-/g, '_');
                        informationDetections.set(detection, Translate.GetAnomalyTypeLabel(detection));
                    }
                    else if (detection.startsWith('regulatory')) {
                        detection = detection.replace(/-/g, '_');
                        regulatoryDetections.set(detection, Translate.GetAnomalyTypeLabel(detection));
                    }
                    else {
                        anomalies.set(detection, Translate.GetAnomalyTypeLabel(detection));
                    }
                });

                anomalies = new Map(Array.from(anomalies).sort((a, b) => a[1].localeCompare(b[1])));
                setAnomalies(anomalies);

                markingDetections = new Map(Array.from(markingDetections).sort((a, b) => a[1].localeCompare(b[1])));
                setMarkingDetections(markingDetections);

                complementaryDetections = new Map(Array.from(complementaryDetections).sort((a, b) => a[1].localeCompare(b[1])));
                setComplementaryDetections(complementaryDetections);

                warningDetections = new Map(Array.from(warningDetections).sort((a, b) => a[1].localeCompare(b[1])));
                setWarningDetections(warningDetections);

                informationDetections = new Map(Array.from(informationDetections).sort((a, b) => a[1].localeCompare(b[1])));
                setInformationDetections(informationDetections);

                regulatoryDetections = new Map(Array.from(regulatoryDetections).sort((a, b) => a[1].localeCompare(b[1])));
                setRegulatoryDetections(regulatoryDetections);

                let perStepImagesAnomaliesMap = new Map<number, StepImageAnomalies[]>();
                perStepImagesAnomaliesMap = perStepImagesAnomaliesMap.fromObject(results[1].data, true);

                perStepImagesAnomaliesMap.forEach((stepImageAnomalies, key) => {
                    stepImageAnomalies.forEach((anomaly) => {
                        let imageAnomalies = anomaly.imageAnomalies;
                        imageAnomalies.forEach((imageAnomaly) => {
                            imageAnomaly.labelType = imageAnomaly.labelType.replace(/-/g, '_');
                        });
                    });
                });

                props.setAnomaliesData({ anomalies, markingDetections, complementaryDetections, warningDetections, informationDetections, regulatoryDetections, perStepImagesAnomaliesMap });

                setLoading(false);
            })
        }
        else {
            setAnomalies(props.projectVersionAnomalies);
            setMarkingDetections(props.projectVersionMarking);
            setComplementaryDetections(props.projectVersionComplementary);
            setWarningDetections(props.projectVersionWarning);
            setInformationDetections(props.projectVersionInformation);
            setRegulatoryDetections(props.projectVersionRegulatory);
        }
    }, [props.mergedProject])

    const handleAnomalyChanged = (anomalyType: string, isActive: boolean): void => {
        let activeAnomaliesSet = new Set(activeAnomalies);
        if (isActive) {
            activeAnomaliesSet.delete(anomalyType);
        }
        else {
            activeAnomaliesSet.add(anomalyType);
        }
        setActiveAnomalies(activeAnomaliesSet);
    }

    const handleCheckAllDetections = (checked: boolean): void => {
        if (!checked) {
            setActiveAnomalies(new Set<string>());
            return;
        }

        let activeAnomaliesSet = new Set(activeAnomalies);
        anomalies.forEach((value, key) => {
            if (!activeAnomaliesSet.has(key))
                activeAnomaliesSet.add(key);
        });

        setActiveAnomalies(activeAnomaliesSet);
    }

    const handleMarkingChanged = (markingType: string, isActive: boolean): void => {
        let activeMarkingDetectionsSet = new Set(activeMarkingDetections);
        if (isActive) {
            activeMarkingDetectionsSet.delete(markingType);
        }
        else {
            activeMarkingDetectionsSet.add(markingType);
        }
        setActiveMarkingDetections(activeMarkingDetectionsSet);
    }

    const handleCheckAllMarkings = (checked: boolean): void => {
        if (!checked) {
            setActiveMarkingDetections(new Set<string>());
            return;
        }

        let activeMarkingDetectionsSet = new Set(activeMarkingDetections);
        markingDetections.forEach((value, key) => {
            if (!activeMarkingDetectionsSet.has(key))
                activeMarkingDetectionsSet.add(key);
        });

        setActiveMarkingDetections(activeMarkingDetectionsSet);
    }

    const handleCheckAllVerticalSignalisation = (checked: boolean) => {
        handleCheckAllComplementaries(checked);
        handleCheckAllWarnings(checked);
        handleCheckAllInformations(checked);
        handleCheckAllRegulatories(checked);
    }

    const handleComplementaryChanged = (complementaryType: string, isActive: boolean): void => {
        let activeComplementaryDetectionsSet = new Set(activeComplementaryDetections);
        if (isActive) {
            activeComplementaryDetectionsSet.delete(complementaryType);
        }
        else {
            activeComplementaryDetectionsSet.add(complementaryType);
        }
        setActiveComplementaryDetections(activeComplementaryDetectionsSet);
    }

    const handleCheckAllComplementaries = (checked: boolean) => {
        if (!checked) {
            setActiveComplementaryDetections(new Set<string>());
            return;
        }

        let activeComplementaryDetectionsSet = new Set(activeComplementaryDetections);
        complementaryDetections.forEach((value, key) => {
            if (!activeComplementaryDetectionsSet.has(key))
                activeComplementaryDetectionsSet.add(key);
        });

        setActiveComplementaryDetections(activeComplementaryDetectionsSet);
    }

    const handleWarningChanged = (warningType: string, isActive: boolean): void => {
        let activeWarningDetectionsSet = new Set(activeWarningDetections);
        if (isActive) {
            activeWarningDetectionsSet.delete(warningType);
        }
        else {
            activeWarningDetectionsSet.add(warningType);
        }
        setActiveWarningDetections(activeWarningDetectionsSet);
    }

    const handleCheckAllWarnings = (checked: boolean) => {
        if (!checked) {
            setActiveWarningDetections(new Set<string>());
            return;
        }

        let activeWarningDetectionsSet = new Set(activeWarningDetections);
        warningDetections.forEach((value, key) => {
            if (!activeWarningDetectionsSet.has(key))
                activeWarningDetectionsSet.add(key);
        });

        setActiveWarningDetections(activeWarningDetectionsSet);
    }

    const handleInformationChanged = (informationType: string, isActive: boolean): void => {
        let activeInformationDetectionsSet = new Set(activeInformationDetections);
        if (isActive) {
            activeInformationDetectionsSet.delete(informationType);
        }
        else {
            activeInformationDetectionsSet.add(informationType);
        }
        setActiveInformationDetections(activeInformationDetectionsSet);
    }

    const handleCheckAllInformations = (checked: boolean) => {
        if (!checked) {
            setActiveInformationDetections(new Set<string>());
            return;
        }

        let activeInformationDetectionsSet = new Set(activeInformationDetections);
        informationDetections.forEach((value, key) => {
            if (!activeInformationDetectionsSet.has(key))
                activeInformationDetectionsSet.add(key);
        });

        setActiveInformationDetections(activeInformationDetectionsSet);
    }

    const handleRegulatoryChanged = (regulatoryType: string, isActive: boolean): void => {
        let activeRegulatoryDetectionsSet = new Set(activeRegulatoryDetections);
        if (isActive) {
            activeRegulatoryDetectionsSet.delete(regulatoryType);
        }
        else {
            activeRegulatoryDetectionsSet.add(regulatoryType);
        }
        setActiveRegulatoryDetections(activeRegulatoryDetectionsSet);
    }

    const handleCheckAllRegulatories = (checked: boolean) => {
        if (!checked) {
            setActiveRegulatoryDetections(new Set<string>());
            return;
        }

        let activeRegulatoryDetectionsSet = new Set(activeRegulatoryDetections);
        regulatoryDetections.forEach((value, key) => {
            if (!activeRegulatoryDetectionsSet.has(key))
                activeRegulatoryDetectionsSet.add(key);
        });

        setActiveRegulatoryDetections(activeRegulatoryDetectionsSet);
    }

    const handleValidate = (): void => {
        props.handleDisplayDetection(activeAnomalies, activeMarkingDetections, activeComplementaryDetections, activeWarningDetections, activeInformationDetections, activeRegulatoryDetections);
        props.onClose();
    }

    return (
        <ClickAwayListener onClickAway={props.onClose}>
            <Popper open={props.open} anchorEl={props.anchorEl} className="poper-menu detection-list-filter">
                <Box display="flex" flexDirection="column" width="100%">
                    {loading && <ScaleLoader width={5} height={20} radius={50} color="#000000" />}
                    {!loading && <Box className="popper-content">
                        <Box className="filters">
                            <Box className="section-filter">
                                <Box className="details-header" display="flex" flexDirection="row" justifyContent="space-between">
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={anomalies.size > 0 && activeAnomalies.size === anomalies.size}
                                                indeterminate={activeAnomalies.size > 0 && activeAnomalies.size < anomalies.size}
                                                onChange={(e) => handleCheckAllDetections(e.target.checked)}
                                            />}
                                        label={
                                            <Box className="header-checkbox">
                                                <span className="popper-title">{Translate.Resources.UI_ActionsMenu_Detection_TypeOfDamage}</span>
                                                <Box className={`counter ${activeAnomalies.size > 0 ? 'filled' : ''}`}>{activeAnomalies.size > 0 ? activeAnomalies.size : ''}</Box>
                                            </Box>
                                        }
                                        className="form-control-label-checkbox" />
                                    {isDetectionListOpened ? <ExpandLessIcon onClick={() => setIsDetectionListOpened(false)} className="expand-icon" /> :
                                        <ExpandMoreIcon onClick={() => setIsDetectionListOpened(true)} className="expand-icon" />}
                                </Box>
                                {isDetectionListOpened &&
                                    <Box className="details-content">
                                        {Array.from(anomalies).map((anomaly, index) => {
                                            let isActive = activeAnomalies.has(anomaly[0]);
                                            return (
                                                <DetectionItem key={`detection-item-${index}`}
                                                    label={anomaly[1]}
                                                    isActive={isActive}
                                                    anomaly={anomaly[0]}
                                                    isAnomaly={true}
                                                    onChange={() => handleAnomalyChanged(anomaly[0], isActive)}
                                                />
                                            );
                                        })}
                                    </Box>}
                            </Box>
                            <Box className="section-filter">
                                <Box className="details-header" display="flex" flexDirection="row" justifyContent="space-between">
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={markingDetections.size > 0 && activeMarkingDetections.size === markingDetections.size}
                                                indeterminate={activeMarkingDetections.size > 0 && activeMarkingDetections.size < markingDetections.size}
                                                onChange={(e) => handleCheckAllMarkings(e.target.checked)}
                                            />}
                                        label={
                                            <Box className="header-checkbox">
                                                <span className="popper-title">{Translate.Resources.UI_ActionsMenu_HorizontalMarking}</span>
                                                <Box className={`counter ${activeMarkingDetections.size > 0 ? 'filled' : ''}`}>{activeMarkingDetections.size > 0 ? activeMarkingDetections.size : ''}</Box>
                                            </Box>}
                                        className="form-control-label-checkbox" />
                                    {isHorizontalMarkingListOpened ? <ExpandLessIcon onClick={() => setIsHorizontalMarkingListOpened(false)} className="expand-icon" /> :
                                        <ExpandMoreIcon onClick={() => setIsHorizontalMarkingListOpened(true)} className="expand-icon" />}
                                </Box>
                                {isHorizontalMarkingListOpened &&
                                    <Box className="details-content">
                                        {Array.from(markingDetections).map((marking, index) => {
                                            let isActive = activeMarkingDetections.has(marking[0]);
                                            return (
                                                <DetectionItem key={`detection-item-${index}`}
                                                    label={marking[1]}
                                                    isActive={isActive}
                                                    anomaly={marking[0]}
                                                    onChange={() => handleMarkingChanged(marking[0], isActive)}
                                                />
                                            );
                                        })}
                                    </Box>}
                            </Box>
                            <Box className="section-filter">
                                <Box className="details-header" display="flex" flexDirection="row" justifyContent="space-between">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(complementaryDetections.size > 0 && activeComplementaryDetections.size === complementaryDetections.size) &&
                                                    (warningDetections.size > 0 && activeWarningDetections.size === warningDetections.size) &&
                                                    (informationDetections.size > 0 && activeInformationDetections.size === informationDetections.size) &&
                                                    (regulatoryDetections.size > 0 && activeRegulatoryDetections.size === regulatoryDetections.size)}
                                                indeterminate={((activeComplementaryDetections.size > 0 || activeWarningDetections.size > 0 || activeInformationDetections.size > 0 || activeRegulatoryDetections.size > 0) &&
                                                    ((activeComplementaryDetections.size < complementaryDetections.size) ||
                                                        (activeWarningDetections.size < warningDetections.size) ||
                                                        (activeInformationDetections.size < informationDetections.size) ||
                                                        (activeRegulatoryDetections.size < regulatoryDetections.size)))}
                                                onChange={(e) => handleCheckAllVerticalSignalisation(e.target.checked)}
                                            />}
                                        label={
                                            <Box className="header-checkbox">
                                                <span className="popper-title">{Translate.Resources.UI_ActionsMenu_VerticalMarking}</span>
                                                <Box className={`counter ${(activeComplementaryDetections.size + activeWarningDetections.size + activeInformationDetections.size + activeRegulatoryDetections.size) > 0 ? 'filled' : ''}`}>{(activeComplementaryDetections.size + activeWarningDetections.size + activeInformationDetections.size + activeRegulatoryDetections.size) > 0 ? (activeComplementaryDetections.size + activeWarningDetections.size + activeInformationDetections.size + activeRegulatoryDetections.size) : ''}</Box>
                                            </Box>}
                                        className="form-control-label-checkbox" />
                                    {isVerticalMarkingListOpened ? <ExpandLessIcon onClick={() => setIsVerticalMarkingListOpened(false)} className="expand-icon" /> :
                                        <ExpandMoreIcon onClick={() => setIsVerticalMarkingListOpened(true)} className="expand-icon" />}
                                </Box>
                                {isVerticalMarkingListOpened &&
                                    <Box className="details-content">
                                        <Box display="flex" flexDirection="row" width="100" justifyContent="space-between">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={complementaryDetections.size > 0 && activeComplementaryDetections.size === complementaryDetections.size}
                                                        indeterminate={activeComplementaryDetections.size > 0 && activeComplementaryDetections.size < complementaryDetections.size}
                                                        onChange={(e) => handleCheckAllComplementaries(e.target.checked)}
                                                    />}
                                                label={
                                                    <Box className="header-checkbox">
                                                        <Box display="flex" flexDirection="row">
                                                            <div className="indic complementary"></div>
                                                            <span className="popper-title">{"Complémentaire"}</span>
                                                        </Box>
                                                        <Box className={`counter ${activeComplementaryDetections.size > 0 ? 'filled' : ''}`}>{activeComplementaryDetections.size > 0 ? activeComplementaryDetections.size : ''}</Box>
                                                    </Box>}
                                                className="form-control-label-checkbox" />
                                            {isComplementaryListOpened ? <ExpandLessIcon onClick={() => setIsComplementaryListOpened(false)} className="expand-icon" /> :
                                                <ExpandMoreIcon onClick={() => setIsComplementaryListOpened(true)} className="expand-icon" />}
                                        </Box>
                                        {isComplementaryListOpened &&
                                            <Box>
                                                {Array.from(complementaryDetections).map((complementary, index) => {
                                                    let isActive = activeComplementaryDetections.has(complementary[0]);
                                                    return (
                                                        <Box key={`detection-item-${index}`} className="detection-item">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={isActive} onChange={() => handleComplementaryChanged(complementary[0], isActive)} />}
                                                                label={
                                                                    <Box className="detection-label">
                                                                        {complementary[1]}
                                                                    </Box>
                                                                }
                                                                className="form-control-label-checkbox" />
                                                        </Box>
                                                    );
                                                })}
                                            </Box>}
                                        <Box display="flex" flexDirection="row" width="100" justifyContent="space-between">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={warningDetections.size > 0 && activeWarningDetections.size === warningDetections.size}
                                                        indeterminate={activeWarningDetections.size > 0 && activeWarningDetections.size < warningDetections.size}
                                                        onChange={(e) => handleCheckAllWarnings(e.target.checked)}
                                                    />}
                                                label={
                                                    <Box className="header-checkbox">
                                                        <Box display="flex" flexDirection="row">
                                                            <div className="indic warning"></div>
                                                            <span className="popper-title">{"Danger"}</span>
                                                        </Box>
                                                        <Box className={`counter ${activeWarningDetections.size > 0 ? 'filled' : ''}`}>{activeWarningDetections.size > 0 ? activeWarningDetections.size : ''}</Box>
                                                    </Box>}
                                                className="form-control-label-checkbox" />
                                            {isWarningListOpened ? <ExpandLessIcon onClick={() => setIsWarningListOpened(false)} className="expand-icon" /> :
                                                <ExpandMoreIcon onClick={() => setIsWarningListOpened(true)} className="expand-icon" />}
                                        </Box>
                                        {isWarningListOpened &&
                                            <Box>
                                                {Array.from(warningDetections).map((warning, index) => {
                                                    let isActive = activeWarningDetections.has(warning[0]);
                                                    return (
                                                        <Box key={`detection-item-${index}`} className="detection-item">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={isActive} onChange={() => handleWarningChanged(warning[0], isActive)} />}
                                                                label={
                                                                    <Box className="detection-label">
                                                                        {warning[1]}
                                                                    </Box>
                                                                }
                                                                className="form-control-label-checkbox" />
                                                        </Box>
                                                    );
                                                })}
                                            </Box>}
                                        <Box display="flex" flexDirection="row" width="100" justifyContent="space-between">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={informationDetections.size > 0 && activeInformationDetections.size === informationDetections.size}
                                                        indeterminate={activeInformationDetections.size > 0 && activeInformationDetections.size < informationDetections.size}
                                                        onChange={(e) => handleCheckAllInformations(e.target.checked)}
                                                    />}
                                                label={
                                                    <Box className="header-checkbox">
                                                        <Box display="flex" flexDirection="row">
                                                            <div className="indic information"></div>
                                                            <span className="popper-title">{"Information"}</span>
                                                        </Box>
                                                        <Box className={`counter ${activeInformationDetections.size > 0 ? 'filled' : ''}`}>{activeInformationDetections.size > 0 ? activeInformationDetections.size : ''}</Box>
                                                    </Box>
                                                }
                                                className="form-control-label-checkbox" />
                                            {isInformationListOpened ? <ExpandLessIcon onClick={() => setIsInformationListOpened(false)} className="expand-icon" /> :
                                                <ExpandMoreIcon onClick={() => setIsInformationListOpened(true)} className="expand-icon" />}
                                        </Box>
                                        {isInformationListOpened &&
                                            <Box>
                                                {Array.from(informationDetections).map((information, index) => {
                                                    let isActive = activeInformationDetections.has(information[0]);
                                                    return (
                                                        <Box key={`detection-item-${index}`} className="detection-item">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={isActive} onChange={() => handleInformationChanged(information[0], isActive)} />}
                                                                label={
                                                                    <Box className="detection-label">
                                                                        {information[1]}
                                                                    </Box>
                                                                }
                                                                className="form-control-label-checkbox" />
                                                        </Box>
                                                    );
                                                })}
                                            </Box>}
                                        <Box display="flex" flexDirection="row" width="100" justifyContent="space-between">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={regulatoryDetections.size > 0 && activeRegulatoryDetections.size === regulatoryDetections.size}
                                                        indeterminate={activeRegulatoryDetections.size > 0 && activeRegulatoryDetections.size < regulatoryDetections.size}
                                                        onChange={(e) => handleCheckAllRegulatories(e.target.checked)}
                                                    />}
                                                label={
                                                    <Box className="header-checkbox">
                                                        <Box display="flex" flexDirection="row">
                                                            <div className="indic regulatory"></div>
                                                            <span className="popper-title">{"Réglementaire"}</span>
                                                        </Box>
                                                        <Box className={`counter ${activeRegulatoryDetections.size > 0 ? 'filled' : ''}`}>{activeRegulatoryDetections.size > 0 ? activeRegulatoryDetections.size : ''}</Box>
                                                    </Box>
                                                }
                                                className="form-control-label-checkbox" />
                                            {isRegulatoryListOpened ? <ExpandLessIcon onClick={() => setIsRegulatoryListOpened(false)} className="expand-icon" /> :
                                                <ExpandMoreIcon onClick={() => setIsRegulatoryListOpened(true)} className="expand-icon" />}
                                        </Box>
                                        {isRegulatoryListOpened &&
                                            <Box>
                                                {Array.from(regulatoryDetections).map((regulatory, index) => {
                                                    let isActive = activeRegulatoryDetections.has(regulatory[0]);
                                                    return (
                                                        <Box key={`detection-item-${index}`} className="detection-item">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={isActive} onChange={() => handleRegulatoryChanged(regulatory[0], isActive)} />}
                                                                label={
                                                                    <Box className="detection-label">
                                                                        {regulatory[1]}
                                                                    </Box>
                                                                }
                                                                className="form-control-label-checkbox" />
                                                        </Box>
                                                    );
                                                })}
                                            </Box>}
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box className="btns-actions">
                            <Button className="btn-cancel" onClick={() => props.onClose()}>
                                {Translate.Resources.UI_Global_Cancel}
                            </Button>
                            <Button className="btn-validate" onClick={() => handleValidate()}>
                                {Translate.Resources.UI_Global_Validate}
                            </Button>
                        </Box>
                    </Box>}
                </Box>
            </Popper>
        </ClickAwayListener>
    );
}