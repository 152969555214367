import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Translate from '../../../localization/Localization';
import './GridCardStyles.scss';

interface GridCardComponentProps {
    gridTitle: string,
    gridDefinition: string,
    hasActionsButtons?: boolean,
    isActionsButtonsEnabled?: boolean,
    canEditCostsRatio?: boolean,
    yearDorpDownList?: JSX.Element,
    grid: JSX.Element,
    cancel?: () => void,
    save?: () => void
}

export const GridCardComponent = (props: GridCardComponentProps): JSX.Element => {
    const { gridTitle, gridDefinition, grid, yearDorpDownList, hasActionsButtons, isActionsButtonsEnabled, canEditCostsRatio } = props;

    return (
        <Box className="grid-card">
            <Box display="flex" flexDirection="row" justifyContent={props.yearDorpDownList ? "space-between" : ""}>
                <Box display="flex" flexDirection="column">
                    <div className="grid-title">{gridTitle}</div>
                    <div className="grid-definition">{gridDefinition}</div>
                </Box>
                {yearDorpDownList && <Box>{yearDorpDownList}</Box>}
            </Box>
            <div className="grid-content">
                {grid}
            </div>
            {hasActionsButtons && canEditCostsRatio &&
                <Box className="btns-actions" display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className="btn-secondary" disabled={!isActionsButtonsEnabled} onClick={() => props.cancel()}>
                        {Translate.Resources.UI_Global_Cancel}
                    </Button>
                    <Button className="btn-primary" disabled={!isActionsButtonsEnabled} onClick={() => props.save()}>
                        {Translate.Resources.UI_ProjectSettings_CostRatio_Grid_Button_Save}
                    </Button>
                </Box>
            }
        </Box>
    )
}