import axios, { AxiosResponse } from 'axios';
import { CommandResult } from '../../../shared/models/CommandResult';
import { AddOrUpdateCostRatiosRequestArgs } from './dataContracts/controller/AddOrUpdateCostRatiosRequestArgs';
import { CostRatio } from './dataContracts/queryStack/CostRatio';
import { RoadImportance } from './dataContracts/queryStack/RoadImportance';
import { WorkPriority } from './dataContracts/queryStack/WorkPriority';

const controllerUrl = 'api/ProjectSettings/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class ProjectSettingsApiClient {

    public static GetRoadImportances = (projectId: string)
        : Promise<AxiosResponse<Array<RoadImportance>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetRoadImportances?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetWorkPriorities = (projectId: string)
        : Promise<AxiosResponse<Array<WorkPriority>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetWorkPriorities?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetCostRatios = (projectId: string, year: number)
        : Promise<AxiosResponse<Array<CostRatio>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetCostRatios?projectId=${encodeURIComponent(projectId)}&year=${encodeURIComponent(year)}`,
            headers: headers
        });
    }

    public static GetProjectCurrency = (projectId: string)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProjectCurrency?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static AddOrUpdateCostRatio = (requestArgs: AddOrUpdateCostRatiosRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddOrUpdateCostRatio`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateProjectCurrency = (projectId: string, currency: string)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateProjectCurrency?projectId=${encodeURIComponent(projectId)}&projectCurrency=${encodeURIComponent(currency)}`,
            headers: headers
        });
    }
}
