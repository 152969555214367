import React from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";

export interface RouteComponentProps {
    location: Location,
    navigate: NavigateFunction
}

//https://stackoverflow.com/a/71097818
export function withRouter(Child) {
    return function withRouter(props, ref) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let location = useLocation();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let navigate = useNavigate();

        let routeComponentProps: RouteComponentProps = {
            location,
            navigate
        }

        return <Child {...props} {...routeComponentProps} ref={ref} />;
    };
}