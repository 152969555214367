import { RoadSectionViewData } from "../../RoadsCondition/models/RoadSectionViewData";

export interface RoadSection extends RoadSectionViewData {
    isSelected?: boolean,
    hiddenIsSelectedForSort?: number,
    isVisible?: boolean,
    lengthInMetersGridValue?: number,
    surface?: number,
    workPriority?: number,
    costRatio?: number,
    cost?: number
    costGridValue?: string
}

export const getHiddenIsSelectedForSort = (isSelected: boolean): number => isSelected === false ? 2 : (isSelected === null ? 1 : 0);
