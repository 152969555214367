import { GridCellProps } from '@progress/kendo-react-grid';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import { RoadSectionGridViewData } from '../../models/RoadSectionGridViewData';
import { MeasurementSystem } from 'src/utils/MeasurementSystem';
import { NumberFormatOptions } from '@progress/kendo-react-intl';

export const CustomEditableNumericCellComponent = (props: GridCellProps): JSX.Element => {
    const dataItem = props.dataItem as RoadSectionGridViewData;


    const [newValue, setNewValue] = useState<number>(dataItem.changes?.get(props.field) ? Number(dataItem.changes.get(props.field)) : undefined);

    let defaultRendering: JSX.Element = null;

    const handleChange = (event: NumericTextBoxChangeEvent, props: GridCellProps): void => {
        dataItem.inEdit = props.field;
        const newValue = event.value != null && event.value >= 0 ? event.value : dataItem[props.field];
        dataItem.changes.set(props.field, newValue);
        setNewValue(newValue);
    }

    if (!dataItem.inEdit || dataItem.inEdit !== props.field) {
        const oldValue = MeasurementSystem.getLengthInMetersOrYards(dataItem[props.field]);
        if (newValue === undefined || newValue === oldValue) {
            defaultRendering = (
                <td >
                    {oldValue}
                </td>
            );
        }
        else {
            defaultRendering = (
                <td className={"field-changed"}>
                    {!newValue ? '' : newValue}
                </td>
            );
        }
    } else {
        defaultRendering = (
            <td>
                <NumericTextBox
                    value={newValue !== undefined ? newValue : props.dataItem[props.field]}
                    min={0}
                    onChange={(event) => handleChange(event, props)}
                    format={{ maximumFractionDigits: 2 } as NumberFormatOptions}
                />
            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}