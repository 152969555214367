import { ClientAppSettings } from "./shared/Settings/dataContracts/controller/ClientAppSettings";

export class SettingsProvider {
    private static _settings: ClientAppSettings | null = null;

    private constructor() {
    }

    static Get = () => {
        if (SettingsProvider._settings)
            return SettingsProvider._settings;

        throw console.error('SettingsProvider has not been initialized. Please use the "Setup" function to initialize before accessing.');
    }

    static Setup = (settings: ClientAppSettings) => {

        if (!settings)
            throw console.error('settings is null or undefined');

        if (!settings.azureMapsApiKey)
            throw console.error('azureMapsApiKey is missing');
        
        SettingsProvider._settings = settings;
    }

    static IsReady = () => {
        return SettingsProvider._settings !== null;
    }
}