import { GridCellProps } from '@progress/kendo-react-grid';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { RoadSection } from '../../../models/RoadSection';

interface CustomCheckboxCellProperties extends GridCellProps {
    handleRowClick: (item: RoadSection) => void
}

export const CustomCheckboxCell = (props: CustomCheckboxCellProperties) => {

    let checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);
    let dataItem = props.dataItem as RoadSection;

    useEffect(() => {
        checkboxRef.current.indeterminate = dataItem.isSelected === true ? false : (dataItem.isSelected === false ? false : true);
    }, []);

    let checked: boolean = dataItem.isSelected === true ? true : (dataItem.isSelected === false ? false : null);

    return (
        <td >
            <input type="checkbox" className="k-checkbox" ref={checkboxRef} checked={checked} onChange={(e) => props.handleRowClick(dataItem)} />
        </td>
    );
}
