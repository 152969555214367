export interface Position extends Array<number> {
}

export default class MathExtensions {

    //NOTE HGA CMA calculer le point du milieu d'une LineString inspiré de cet exemple: https://stackoverflow.com/a/42375010
    public static interpolateMidPoint = (geometryPoints: Position[], distanceFraction?: number): Position => {
        var totalLength = MathExtensions.getGeometryLength(geometryPoints);
        var midDistance = totalLength * (distanceFraction ?? 0.5);
        var midPoint = MathExtensions.getPointByDistance(geometryPoints, midDistance);
        return midPoint;
    }

    // returns the total length of a linestring (multiple points) // pnts=[[x0,y0],[x1,y1],[x2,y2],...]
    private static getGeometryLength = (geometryPoints: Position[]): number => {
        var totalLength = 0;
        geometryPoints.forEach((point, i, points) => {
            totalLength += i ? MathExtensions.getLineLength(points[i - 1], point) : 0;
        });
        return totalLength;
    }

    // returns the length of a line (two points) // line=[[x0,y0],[x1,y1]]
    private static getLineLength = (startPoint: Position, endPoint: Position): number => {
        var xd = startPoint[0] - endPoint[0];
        var yd = startPoint[1] - endPoint[1];
        return Math.sqrt(xd * xd + yd * yd);
    }

    private static getPointByDistance = (geometryPoints: Position[], distance: number): Position => {
        var cl = 0;
        var ol: number;
        var result: Position;
        geometryPoints.forEach((point, i, points) => {
            ol = cl;
            cl += i ? MathExtensions.getLineLength(points[i - 1], point) : 0;
            if (distance <= cl && distance > ol) {
                var dd = distance - ol;
                result = MathExtensions.pointOnLine(points[i - 1], point, dd);
            }
        });
        return result;
    }

    // returns a point on a single line (two points) using distance // line=[[x0,y0],[x1,y1]]
    private static pointOnLine = (startPoint: Position, endPoint: Position, distance: number): Position => {
        var t = distance / MathExtensions.getLineLength(startPoint, endPoint);
        var xt = (1 - t) * startPoint[0] + (t * endPoint[0]);
        var yt = (1 - t) * startPoint[1] + (t * endPoint[1]);
        return [xt, yt];
    }
}