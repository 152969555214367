import { MenuItem, Select } from "@mui/material";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from 'react';
import { getProgrammingAreaStatusCssClassValues, getProgrammingAreaStatusTextValues } from "../../models/AreaStatus";
import { ProgrammingAreaStatus } from "../../services/dataContracts/queryStack/ProgrammingAreaStatus";

export const CustomProgrammingAreaStatusCell = (props: GridCellProps): JSX.Element => {

    const { render, dataItem } = props;

    const handleAreaStatusChanged = (value: ProgrammingAreaStatus): void => {
        dataItem.status = value;
    }

    const programmingAreaStatusTextValues = getProgrammingAreaStatusTextValues();
    const programmingAreaStatusCssClassValues = getProgrammingAreaStatusCssClassValues();

    const defaultRendering = (
        <td>
            <Select
                className={`drop-down-list ${programmingAreaStatusCssClassValues.get(dataItem.status) ?? ''}`}
                value={dataItem.status}
                onChange={(e) => handleAreaStatusChanged(e.target.value as ProgrammingAreaStatus)}
            >
                <MenuItem value={ProgrammingAreaStatus.draft}>{programmingAreaStatusTextValues.get(ProgrammingAreaStatus.draft)}</MenuItem>
                <MenuItem value={ProgrammingAreaStatus.workToDo}>{programmingAreaStatusTextValues.get(ProgrammingAreaStatus.workToDo)}</MenuItem>
                <MenuItem value={ProgrammingAreaStatus.workDone}>{programmingAreaStatusTextValues.get(ProgrammingAreaStatus.workDone)}</MenuItem>
            </Select>
        </td>
    );

    return render?.call(undefined, defaultRendering, props);
}