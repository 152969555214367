import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Translate from '../../../../localization/Localization';
import { ProgrammingExtended } from '../../models/ProgrammingExtended';
import './HeaderStyles.scss';

interface HeaderComponentProps {
    loading: boolean,
    selectedProgramming: ProgrammingExtended,
    handleCancel: () => void,
    handleUpdateProgrammeClicked: () => void
}

export const HeaderComponent = (props: HeaderComponentProps): JSX.Element => {

    return (
        <Box className="programme-header">
            <Box display="flex" flexDirection="row" alignItems="center">
                {!props.loading &&
                    <>
                        <ArrowBackIcon className="arrow-icon" onClick={() => props.handleCancel()} />
                        <div className="programme-label">{props.selectedProgramming ? `${props.selectedProgramming.year} - ${props.selectedProgramming.label}` : ""}</div>
                    </>
                }
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Button className="btn-secondary" disabled={props.loading} onClick={() => props.handleUpdateProgrammeClicked()}>
                    <EditIcon />
                    {Translate.Resources.UI_Programming_ProgrammeCard_Menu_EditInformations}
                </Button>
            </Box>
        </Box>
    );
}