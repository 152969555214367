import axios, { AxiosResponse } from 'axios';
import { ProjectThumbnailModel } from '../models/ProjectThumbnailModel';
import { ProjectVersion } from './dataContracts/queryStack/ProjectVersion';

const controllerUrl = 'api/Home/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class HomeApiClient {

    public static GetProjects = (searchText: string)
        : Promise<AxiosResponse<ProjectVersion[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProjects?searchText=${encodeURIComponent(searchText)}`,
            headers: headers
        });
    }

    public static GetProjectImageContent = async (project: ProjectVersion)
        : Promise<ProjectThumbnailModel> => {
        return await axios.get(`${controllerUrl}GetProjectImageContent?projectId=${encodeURIComponent(project.projectId)}`, { responseType: 'blob' })
            .then((response) => {
                return {
                    ...project,
                    projectmageBlob: response.data
                } as ProjectThumbnailModel;
            })
            .catch((error) => {
                return {
                    ...project,
                    projectmageBlob: null
                } as ProjectThumbnailModel;
            });
    }

    public static GetAllProjectsVersions = ()
        : Promise<AxiosResponse<ProjectVersion[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetAllProjectsVersions`,
            headers: headers
        });
    }

    public static GetVersionOfProject = (projectVersionId: number)
        : Promise<AxiosResponse<ProjectVersion[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetVersionOfProject?projectVersionId=${encodeURIComponent(projectVersionId)}`,
            headers: headers
        });
    }

}