import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import Translate from '../../../localization/Localization';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { ProgrammingsApiClient } from '../services/ProgrammingsApiClient';
import './ExportProgrammingDialogStyles.scss';

interface ExportProgrammingDialogProps {
    opened: boolean,
    programmingId: number,
    projectVersionId: number,
    auscultationsIdsString: string,
    programmingLabel: string,
    projectLabel: string,
    currency: string,
    handleClose: () => void
}

enum ExportChoices {
    excel = "excel",
    geojson = "geojson",
    shapefile = "shapefile",
    kml = "kml",
}

export const ExportProgrammingDialog = (props: ExportProgrammingDialogProps): JSX.Element => {

    const [exportChoice, setExportChoice] = useState<ExportChoices>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const measurementSystemType = MeasurementSystem.getCurrentType();

    const handleExport = async (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): Promise<void> => {
        event.stopPropagation();
        setLoading(true);

        switch (exportChoice) {
            case ExportChoices.excel:
                await ProgrammingsApiClient.ExportExcel(props.programmingId, props.projectVersionId, props.auscultationsIdsString, measurementSystemType, props.currency)
                    .then((res) => {
                        let fileName: string = getFileName(res);
                        let blob: Blob = new Blob([res.data]);
                        downloadFileFromBlob(blob, fileName);

                        setLoading(false);
                        props.handleClose();
                    });

                break;

            case ExportChoices.geojson:
                await ProgrammingsApiClient.ExportGeoJson(props.programmingId, props.programmingLabel, props.projectLabel, measurementSystemType)
                    .then((res) => {
                        let fileName: string = getFileName(res);
                        let blob: Blob = new Blob([res.data]);
                        downloadFileFromBlob(blob, fileName);

                        setLoading(false);
                        props.handleClose();
                    });

                break;

            case ExportChoices.shapefile:
                await ProgrammingsApiClient.ExportShapefile(props.programmingId, props.programmingLabel, props.projectLabel, measurementSystemType)
                    .then((res) => {
                        let fileName: string = getFileName(res);
                        let blob: Blob = new Blob([res.data]);
                        downloadFileFromBlob(blob, fileName);

                        setLoading(false);
                        props.handleClose();
                    });

                break;

            case ExportChoices.kml:
                await ProgrammingsApiClient.ExportKml(props.programmingId, props.programmingLabel, props.projectLabel, measurementSystemType)
                    .then((res) => {
                        let fileName: string = getFileName(res);
                        let blob: Blob = new Blob([res.data]);
                        downloadFileFromBlob(blob, fileName);

                        setLoading(false);
                        props.handleClose();
                    });

                break;

            default:
                break;
        }
    }

    return (
        <Dialog id="export-programming-dialog" open={props.opened} onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }}>
            <DialogContent>
                <Box display="flex" flexDirection="column">
                    <Box className="title">
                        <Box>{Translate.Resources.UI_ExportProgrammingDialog_Title}</Box>
                        {loading && <ScaleLoader width={5} height={20} radius={50} color="#000000" />}
                        <CloseIcon onClick={() => props.handleClose()} />
                    </Box>
                    <Box className="export-choices">
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={exportChoice ?? ''}
                            onChange={(e) => setExportChoice(e.target.value as ExportChoices)}
                        >
                            <FormControlLabel value={ExportChoices.excel} control={<Radio />} label={Translate.Resources.UI_ExportProgrammingDialog_Excel} />
                            <FormControlLabel value={ExportChoices.geojson} control={<Radio />} label={Translate.Resources.UI_ExportProgrammingDialog_Geojson} />
                            <FormControlLabel value={ExportChoices.shapefile} control={<Radio />} label={Translate.Resources.UI_ExportProgrammingDialog_Shapefile} />
                            <FormControlLabel value={ExportChoices.kml} control={<Radio />} label={Translate.Resources.UI_ExportProgrammingDialog_KML} />
                        </RadioGroup>
                    </Box>
                    <Box className="buttons" display="flex" flexDirection="row" justifyContent="end">
                        <Button className="btn-secondary" onClick={() => props.handleClose()}>
                            {Translate.Resources.UI_ExportProgrammingDialog_Cancel}
                        </Button>
                        <Button className="btn-primary" onClick={(e) => handleExport(e)} disabled={exportChoice === null}>
                            {Translate.Resources.UI_ExportProgrammingDialog_Export}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
