import { Currencies } from "../../../shared/models/Currencies";
import { CostRatio } from "../services/dataContracts/queryStack/CostRatio";
import { QualityKind } from "../services/dataContracts/queryStack/QualityKind";
import { Traffic } from "../services/dataContracts/queryStack/Traffic";

export interface CostRatioViewData {
    quality: QualityKind,
    score: number,
    mediumTrafficValue: number,
    strongTrafficValue: number,
    lowTrafficValue: number,
    inEdit?: string,
    changes: Map<string, number>
}

export const buildCostRatiosViewData = (costRatiosData: CostRatio[]): CostRatioViewData[] => {
    let costRatiosViewData: CostRatioViewData[] = [
        { quality: QualityKind.rehabilitation, score: 1, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.reinforcement, score: 2, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.reinforcement, score: 3, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.generalMaintenance, score: 4, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.generalMaintenance, score: 5, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.localizedRepair, score: 6, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.localizedRepair, score: 7, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.monitoring, score: 8, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.monitoring, score: 9, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() },
        { quality: QualityKind.monitoring, score: 10, strongTrafficValue: null, mediumTrafficValue: null, lowTrafficValue: null, changes: new Map<string, number>() }
    ];

    costRatiosData.forEach((costRatio) => {
        let element = costRatiosViewData.find(x => x.score === costRatio.score);

        if (costRatio.traffic === Traffic.low)
            element.lowTrafficValue = costRatio.costRatioValue;

        if (costRatio.traffic === Traffic.medium)
            element.mediumTrafficValue = costRatio.costRatioValue;

        if (costRatio.traffic === Traffic.strong)
            element.strongTrafficValue = costRatio.costRatioValue;
    });

    return costRatiosViewData;
}

export const getTrafficRequestArgsValue = (key: string): string => {
    switch (key) {
        case "strongTrafficValue":
            return "Strong";

        case "mediumTrafficValue":
            return "Medium";

        case "lowTrafficValue":
            return "Low";

        default:
            return null;
    }
}

export const getCurrencyRequestArgsValue = (currency: string): string => {
    switch (currency) {
        case Currencies.Euro:
            return "Euro";

        case Currencies.Dollar:
            return "Dollar";

        case Currencies.Pound:
            return "Pound";

        case Currencies.Franc:
            return "Franc";

        case Currencies.Dirham:
            return "Dirham";

        case Currencies.Danish:
            return "Danish";

        default:
            return null;
    }
}