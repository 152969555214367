import { Box, Button, ClickAwayListener, Popper } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import Translate from '../../../localization/Localization';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import './GeographyListFilterStyles.scss';
import { SectionFilterComponent } from './SectionFilterComponent';

interface GeographyListFilterComponentProps {
    open: boolean,
    anchorEl: null | HTMLElement,
    mergedProject: MergedProjectVersion,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    onClose: () => void,
    handleDisplaySections: (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => void
}

export const GeographyListFilterComponent = (props: GeographyListFilterComponentProps): JSX.Element => {

    const [activeMunicipalities, setActiveMunicipalities] = useState<Set<string>>(new Set<string>(props.activeMunicipalities));
    const [activeDistricts, setActiveDistricts] = useState<Set<string>>(new Set<string>(props.activeDistricts));
    const [activeCollaborativeDevelopmentZones, setActiveCollaborativeDevelopmentZones] = useState<Set<string>>(new Set<string>(props.activeCollaborativeDevelopmentZones));

    const [isMunicipalityListOpened, setIsMunicipalityListOpened] = useState<boolean>(true);
    const [isDistrictListOpened, setIsDistrictListOpened] = useState<boolean>(true);
    const [isCollaborativeDevelopmentZoneListOpened, setIsCollaborativeDevelopmentZoneListOpened] = useState<boolean>(true);

    const municipalities = Array.from(props.mergedProject.municipalities).sort();
    const districts = Array.from(props.mergedProject.districts).sort();
    const collaborativeDevelopmentZones = Array.from(props.mergedProject.collaborativeDevelopmentZones).sort();

    const handleAllMunicipalitiesChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeMunicipalitiesSet = new Set<string>();
        if (e.target.checked) {
            municipalities.forEach((municipality) => {
                activeMunicipalitiesSet.add(municipality);
            });
        }

        setActiveMunicipalities(activeMunicipalitiesSet);
    }

    const handleMunicipalityChanged = (municipality: string, isActive: boolean) => {
        let activeMunicipalitiesSet = new Set(activeMunicipalities);
        if (isActive) {
            activeMunicipalitiesSet.delete(municipality);
        }
        else {
            activeMunicipalitiesSet.add(municipality);
        }
        setActiveMunicipalities(activeMunicipalitiesSet);
    }

    const handleAllDistrictsChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeDistrictsSet = new Set<string>();
        if (e.target.checked) {
            districts.forEach((district) => {
                activeDistrictsSet.add(district);
            });
            activeDistrictsSet.add(null);
        }

        setActiveDistricts(activeDistrictsSet);
    }

    const handleDistrictChanged = (district: string, isActive: boolean) => {
        let setActiveDistrictsSet = new Set(activeDistricts);
        if (isActive) {
            setActiveDistrictsSet.delete(district);
        }
        else {
            setActiveDistrictsSet.add(district);
        }
        setActiveDistricts(setActiveDistrictsSet);
    }

    const handleAllCollaborativeDevelopmentZonesChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        let activeCollaborativeDevelopmentZonesSet = new Set<string>();
        if (e.target.checked) {
            collaborativeDevelopmentZones.forEach((zac) => {
                activeCollaborativeDevelopmentZonesSet.add(zac);
            });
            activeCollaborativeDevelopmentZonesSet.add(null);
        }

        setActiveCollaborativeDevelopmentZones(activeCollaborativeDevelopmentZonesSet);
    }

    const handleActiveCollaborativeDevelopmentZoneChanged = (activeCollaborativeDevelopmentZone: string, isActive: boolean) => {
        let activeCollaborativeDevelopmentZonesSet = new Set(activeCollaborativeDevelopmentZones);
        if (isActive) {
            activeCollaborativeDevelopmentZonesSet.delete(activeCollaborativeDevelopmentZone);
        }
        else {
            activeCollaborativeDevelopmentZonesSet.add(activeCollaborativeDevelopmentZone);
        }
        setActiveCollaborativeDevelopmentZones(activeCollaborativeDevelopmentZonesSet);
    }

    const handleValidate = (): void => {
        props.handleDisplaySections(activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones);
        props.onClose();
    }

    return (
        <ClickAwayListener onClickAway={props.onClose}>
            <Popper open={props.open} anchorEl={props.anchorEl} className="poper-menu geography-list-filter">
                <Box className="popper-content" display="flex" flexDirection="column">
                    <Box className="filters">
                        <SectionFilterComponent
                            activeFilters={activeMunicipalities}
                            arrayFilters={municipalities}
                            isOpened={isMunicipalityListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Geography_City}
                            hasEmptyCheck={false}
                            setIsFilterListOpened={setIsMunicipalityListOpened}
                            handleAllFiltersChanged={handleAllMunicipalitiesChanged}
                            handleActiveFilterChanged={handleMunicipalityChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeDistricts}
                            arrayFilters={districts}
                            isOpened={isDistrictListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Geography_District}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsDistrictListOpened}
                            handleAllFiltersChanged={handleAllDistrictsChanged}
                            handleActiveFilterChanged={handleDistrictChanged}
                        />
                        <SectionFilterComponent
                            activeFilters={activeCollaborativeDevelopmentZones}
                            arrayFilters={collaborativeDevelopmentZones}
                            isOpened={isCollaborativeDevelopmentZoneListOpened}
                            filterTitle={Translate.Resources.UI_ActionsMenu_Geography_CollaborativeDevelopmentZone}
                            hasEmptyCheck={true}
                            setIsFilterListOpened={setIsCollaborativeDevelopmentZoneListOpened}
                            handleAllFiltersChanged={handleAllCollaborativeDevelopmentZonesChanged}
                            handleActiveFilterChanged={handleActiveCollaborativeDevelopmentZoneChanged}
                        />
                    </Box>
                    <Box className="actions-button">
                        <Button className="btn-cancel" onClick={() => props.onClose()}>
                            {Translate.Resources.UI_Global_Cancel}
                        </Button>
                        <Button className="btn-validate" onClick={() => handleValidate()}>
                            {Translate.Resources.UI_Global_Validate}
                        </Button>
                    </Box>
                </Box>
            </Popper>
        </ClickAwayListener>
    )
}