import Translate from "src/localization/Localization";
import { Hierarchy } from "./Hierarchy";
import { Manager } from "./Manager";
import { Traffic } from "./Traffic";
import { Environment } from "./Environment";

/////////////////////////////////////
/////Enum order is Display Order/////
////////////////////////////////////

export const getBooleanList = (): { text: string, value: boolean }[] => [
    { text: Translate.Resources.UI_Grid_Value_Yes, value: true },
    { text: Translate.Resources.UI_Grid_Value_No, value: false }
];

export const getHierarchyList = (): { text: string, value: Hierarchy }[] => [
    { text: Translate.Resources.UI_ActionsMenu_Attributes_Hierarchy_Main, value: Hierarchy.main },
    { text: Translate.Resources.UI_ActionsMenu_Attributes_Hierarchy_Local, value: Hierarchy.local },
    { text: Translate.Resources.UI_ActionsMenu_Attributes_Hierarchy_Residential, value: Hierarchy.residential }
];

export const getManagerList = (): { text: string, value: Manager }[] => [
    { text: Translate.Resources.UI_Highways_Grid_Manager_Municipality, value: Manager.municipality },
    { text: Translate.Resources.UI_Highways_Grid_Manager_CommunityOfMunicipalities, value: Manager.communityOfMunicipalities },
    { text: Translate.Resources.UI_Highways_Grid_Manager_AgglomerationCommunity, value: Manager.agglomerationCommunity },
    { text: Translate.Resources.UI_Highways_Grid_Manager_Metropolis, value: Manager.metropolis },
    { text: Translate.Resources.UI_Highways_Grid_Manager_Department, value: Manager.department },
    { text: Translate.Resources.UI_Highways_Grid_Manager_State, value: Manager.state },
    { text: Translate.Resources.UI_Highways_Grid_Manager_Private, value: Manager.private }
];

export const getTrafficList = (): { text: string, value: Traffic }[] => [
    { text: Translate.Resources.UI_Highways_Grid_Traffic_Strong, value: Traffic.strong },
    { text: Translate.Resources.UI_Highways_Grid_Traffic_Moderate, value: Traffic.medium },
    { text: Translate.Resources.UI_Highways_Grid_Traffic_Low, value: Traffic.low },
];

export const getEnvironmentList = (): { text: string, value: Environment }[] => [
    { text: Translate.Resources.UI_Highways_Grid_Environment_Agglomeration, value: Environment.agglomeration },
    { text: Translate.Resources.UI_Highways_Grid_Environment_CityCenter, value: Environment.cityCenter },
    { text: Translate.Resources.UI_Highways_Grid_Environment_Countryside, value: Environment.countrySide },
];

export const getImportanceList = (): number[] => [
    1,
    2,
    3,
    4,
    5,
];