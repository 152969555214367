import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mdiMapSearch } from '@mdi/js';
import Icon from '@mdi/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Button, ButtonGroup, Input, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import Translate from '../../../../localization/Localization';
import { ProgrammingStatus } from '../../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { WorksFilterComponent } from '../../../../shared/components/ActionsMenu/WorksFilterComponent';
import { AttributesListFilterComponent } from '../../../RoadsCondition/components/AttributesListFilterComponent';
import { DetectionListFilterComponent } from '../../../RoadsCondition/components/DetectionListFilterComponent';
import { GeographyListFilterComponent } from '../../../RoadsCondition/components/GeographyListFilterComponent';
import { QualityListFilterComponent } from '../../../RoadsCondition/components/QualityListFilterComponent';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { Environment } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { StepImageAnomalies } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { ProgrammingExtended } from '../../models/ProgrammingExtended';
import './ActionsMenuStyles.scss';

interface ActionsMenuComponentProps {
    inputRef: React.RefObject<HTMLInputElement>,
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    mergedProject: MergedProjectVersion,
    loading: boolean,
    isDisabled: boolean,
    isRoadSectionDetailsOpened: boolean,
    isZonesListOpened: boolean,
    projectId: string,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    selectedProgramming?: ProgrammingExtended,
    handleZonesListClosed: () => void,
    handleZonesListOpened: () => void,
    handleSearchTextChanged: (value: string) => void,
    handleDisplayDetections: (activeAnomalies: Set<string>) => void,
    handleDisplaySections: (activeQualities: Set<number>) => void,
    handleDisplaySectionsFromGeographiesFilters: (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => void,
    handleDisplaySectionsFromAttributesFilters: (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => void,
    handleDisplayAreasFromWorksFilter: (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>) => void,
    setAnomaliesData: ({ anomalies, perStepImagesAnomaliesMap }: { anomalies: Map<string, string>, perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]> }) => void,
    projectVersionAnomalies: Map<string, string>
}

export const ActionsMenuComponent = (props: ActionsMenuComponentProps): JSX.Element => {

    let projectAuscultationsArray = props.mergedProject ? orderBy(props.mergedProject?.projectVersion?.auscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']) : null;
    let surveyTo = projectAuscultationsArray ? projectAuscultationsArray[projectAuscultationsArray.length - 1].videoDateTime.toLocaleDateString() : '';

    const [detectionListAnchorEl, setDetectionListAnchorEl] = useState<null | HTMLElement>(null);
    const isDetectionListOpened = Boolean(detectionListAnchorEl) && !props.isDisabled;

    const [qualityListAnchorEl, setQualityListAnchorEl] = useState<null | HTMLElement>(null);
    const isQualityListOpened = Boolean(qualityListAnchorEl) && !props.isDisabled;

    const [geographyListAnchorEl, setGeographyListAnchorEl] = useState<null | HTMLElement>(null);
    const isGeographyListOpened = Boolean(geographyListAnchorEl) && !props.isDisabled;

    const [attributeListAnchorEl, setAttributeListAnchorEl] = useState<null | HTMLElement>(null);
    const isAttributeListOpened = Boolean(attributeListAnchorEl) && !props.isDisabled;

    const [worksFilterAnchorEl, setWorksFilterAnchorEl] = useState<null | HTMLElement>(null);
    const isWorksFilterOpened = Boolean(worksFilterAnchorEl);

    const handleOpenDetectionListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isDetectionListOpened) {
            setDetectionListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenQualityListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isQualityListOpened) {
            setQualityListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenGeographyListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isGeographyListOpened) {
            setGeographyListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenAttributeListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isAttributeListOpened) {
            setAttributeListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenWorksFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isWorksFilterOpened) {
            setWorksFilterAnchorEl(e.currentTarget);
        }
    }

    const selectedQualitiesNotes: number[] = Array.from(props.activeQualities);
    const activeGeographies = props.activeMunicipalities.size + props.activeDistricts.size + props.activeCollaborativeDevelopmentZones.size;
    const activeAttributes = props.activeHierarchies.size + props.activeTraffics.size + props.activeEnvironments.size + props.activeManagers.size + props.activeImportances.size + props.activeOtherAttributes.size;

    return (
        <Box className="programming-actions-menu">
            <Box display="flex" flexDirection="row" alignItems="center">
                <ButtonGroup variant="contained" aria-label="button group" className="button-group" disabled={props.loading || !props.isDisabled || props.isRoadSectionDetailsOpened}>
                    <Button className={`button map ${props.isZonesListOpened ? '' : 'active'} ${props.loading || !props.isDisabled || props.isRoadSectionDetailsOpened ? 'disabled' : ''}`} onClick={props.handleZonesListClosed}>
                        <Icon className="map-icon" path={mdiMapSearch} size={1} />
                        <span className="label">{Translate.Resources.UI_ActionsMenu_Map}</span>
                    </Button>
                    <Button className={`button view-list ${props.isZonesListOpened ? 'active' : ''} ${props.loading || !props.isDisabled || props.isRoadSectionDetailsOpened ? 'disabled' : ''}`} onClick={props.handleZonesListOpened}>
                        <ViewListIcon className="view-list-icon" />
                        <span className="label">Zones</span>
                    </Button>
                </ButtonGroup>
                <Box className="search-input">
                    <Input disableUnderline className={`input-search-roads`} inputRef={props.inputRef}
                        endAdornment={
                            <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }
                        id="input-search-roads-text"
                        placeholder={Translate.Resources.UI_ActionsBar_Search_Placeholder}
                        onChange={(event) => props.handleSearchTextChanged(event.target.value)}
                        disabled={props.loading || props.isDisabled}
                    />
                </Box>
            </Box>
            {!props.loading &&
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isQualityListOpened ? 'selected' : ''} ${props.isDisabled ? 'disabled' : ''}`} onClick={(e) => !props.isDisabled ? handleOpenQualityListFilter(e) : {}}>
                        <Box className="counter filled">{selectedQualitiesNotes.length}</Box>
                        <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            {Translate.Resources.UI_ActionsMenu_Quality}
                            {isQualityListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Box>
                    </Box>
                    {isQualityListOpened && !props.isDisabled &&
                        <QualityListFilterComponent open={isQualityListOpened}
                            anchorEl={qualityListAnchorEl}
                            activeQualities={props.activeQualities}
                            onClose={() => setQualityListAnchorEl(null)}
                            handleDisplaySections={props.handleDisplaySections}
                        />
                    }
                    <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isDetectionListOpened ? 'selected' : ''} ${props.isDisabled ? 'disabled' : ''}`} onClick={(e) => !props.isDisabled ? handleOpenDetectionListFilter(e) : {}}>
                        <Box className={`counter ${props.activeAnomalies?.size > 0 ? 'filled' : ''}`}>{props.activeAnomalies.size > 0 ? props.activeAnomalies.size : ''}</Box>
                        <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            {Translate.Resources.UI_ActionsMenu_Detection}
                            {isDetectionListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Box>
                    </Box>
                    {isDetectionListOpened && !props.isDisabled &&
                        <DetectionListFilterComponent open={isDetectionListOpened}
                            anchorEl={detectionListAnchorEl}
                            activeAnomalies={props.activeAnomalies}
                            mergedProject={props.mergedProject}
                            onClose={() => setDetectionListAnchorEl(null)}
                            handleDisplayDetection={props.handleDisplayDetections}
                            setAnomaliesData={props.setAnomaliesData}
                            projectVersionAnomalies={props.projectVersionAnomalies}
                        />
                    }
                    <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isGeographyListOpened ? 'selected' : ''} ${props.isDisabled ? 'disabled' : ''}`} onClick={(e) => !props.isDisabled ? handleOpenGeographyListFilter(e) : {}}>
                        <Box className={`counter ${activeGeographies > 0 ? 'filled' : ''}`}>{activeGeographies > 0 ? activeGeographies : ''}</Box>
                        <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            {Translate.Resources.UI_ActionsMenu_Geography}
                            {isGeographyListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Box>
                    </Box>
                    {isGeographyListOpened && !props.isDisabled &&
                        <GeographyListFilterComponent open={isGeographyListOpened}
                            anchorEl={geographyListAnchorEl}
                            mergedProject={props.mergedProject}
                            activeMunicipalities={props.activeMunicipalities}
                            activeDistricts={props.activeDistricts}
                            activeCollaborativeDevelopmentZones={props.activeCollaborativeDevelopmentZones}
                            onClose={() => setGeographyListAnchorEl(null)}
                            handleDisplaySections={props.handleDisplaySectionsFromGeographiesFilters}
                        />
                    }
                    <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isAttributeListOpened ? 'selected' : ''} ${props.isDisabled ? 'disabled' : ''}`} onClick={(e) => !props.isDisabled ? handleOpenAttributeListFilter(e) : {}}>
                        <Box className={`counter ${activeAttributes > 0 ? 'filled' : ''}`}>{activeAttributes > 0 ? activeAttributes : ''}</Box>
                        <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            {Translate.Resources.UI_ActionsMenu_Attributes}
                            {isAttributeListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Box>
                    </Box>
                    {isAttributeListOpened && !props.isDisabled &&
                        <AttributesListFilterComponent open={isAttributeListOpened}
                            anchorEl={attributeListAnchorEl}
                            activeHierarchies={props.activeHierarchies}
                            activeTraffics={props.activeTraffics}
                            activeEnvironments={props.activeEnvironments}
                            activeManagers={props.activeManagers}
                            activeImportances={props.activeImportances}
                            activeOtherAttributes={props.activeOtherAttributes}
                            onClose={() => setAttributeListAnchorEl(null)}
                            handleDisplaySections={props.handleDisplaySectionsFromAttributesFilters}
                        />
                    }
                    <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isWorksFilterOpened ? 'selected' : ''} ${props.isRoadSectionDetailsOpened ? 'disabled' : ''}`} onClick={(e) => !props.isRoadSectionDetailsOpened ? handleOpenWorksFilter(e) : {}}>
                        {props.activeStatus.size > 0 &&
                            <Box className="counter filled">{props.activeStatus.size}</Box>
                        }
                        <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            {Translate.Resources.UI_ActionsMenu_Works}
                            {isWorksFilterOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Box>
                    </Box>
                    {isWorksFilterOpened && !props.isRoadSectionDetailsOpened &&
                        <WorksFilterComponent projectId={props.projectId}
                            selectedProgrammings={props.selectedProgrammings}
                            selectedYears={props.selectedYears}
                            activeStatus={props.activeStatus}
                            open={isWorksFilterOpened}
                            anchorEl={worksFilterAnchorEl}
                            selectedProgramming={props.selectedProgramming}
                            shouldDisplaySecondMessage={false}
                            onClose={() => setWorksFilterAnchorEl(null)}
                            handleDisplayAreasFromWorksFilter={(selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>) => {
                                props.handleDisplayAreasFromWorksFilter(selectedProgrammings, selectedYears, activeStatus);
                                setWorksFilterAnchorEl(null);
                            }}
                        />
                    }
                    <Box className="title" ml="5px">{`${Translate.Resources.UI_ActionsMenu_SurveyTo} ${surveyTo}`}</Box>
                </Box>
            }
        </Box>
    );
}