import Translate from "../../../localization/Localization"
import { WorkOrientation } from "../services/dataContracts/queryStack/WorkOrientation"

export const getProgrammingWorkOrientationTextValues = () => {
    return (
        new Map<WorkOrientation, string>([
            [WorkOrientation.monitoring, Translate.Resources.UI_ActionsMenu_Quality_Monitoring],
            [WorkOrientation.localizedRepair, Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair],
            [WorkOrientation.generalMaintenance, Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance],
            [WorkOrientation.reinforcement, Translate.Resources.UI_ActionsMenu_Quality_Reinforcement],
            [WorkOrientation.rehabilitation, Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation]
        ])
    )
}