import axios, { AxiosResponse } from 'axios';
import { UserModel } from './dataContracts/queryStack/UserModel';

const controllerUrl = 'api/User/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class UserApiClient {

    public static GetUserProfile = ()
        : Promise<AxiosResponse<UserModel>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetUserProfile`,
            headers: headers
        });
    }
}
