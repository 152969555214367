import styles from '../../_variables.scss';

var _labelScoreGridHeightGapFromWindow: number = null;
var _labelScoreGridHeightGapFromWindowWhenClientView: number = null;

export class SizingUtilities {

    //40 is the row height defined in prop of the kendo grid
    public static rowHeight: number = 50;

    // labelScoreGridOffsetFromWindowTop is the height of elements above grid.
    public static labelScoreGridHeightGapFromWindow = (role: string) => {
        if (role === 'CLI') {
            if (!_labelScoreGridHeightGapFromWindowWhenClientView) {
                _labelScoreGridHeightGapFromWindowWhenClientView = parseInt(styles.clientLabelScoreGridHeightGapFromWindow);
            }

            return _labelScoreGridHeightGapFromWindowWhenClientView;
        }

        if (!_labelScoreGridHeightGapFromWindow) {
            _labelScoreGridHeightGapFromWindow = parseInt(styles.labelScoreGridHeightGapFromWindow);
        }

        return _labelScoreGridHeightGapFromWindow;
    }
    
    // window.innerHeight get the hight of page from navigator size
    public static computeGridHeight = (gridHeightGapFromWindow: number) => {
        return window.innerHeight - gridHeightGapFromWindow;
    }

    // rowHeight is the row height defined in prop of the kendo grid
    public static computeGridPageSize = (gridHeight: number, rowHeight: number) => {
        return Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
    }

}