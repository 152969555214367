import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Button, Dialog, DialogContent, IconButton, Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Translate from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { ExportProgrammingDialog } from '../../components/ExportProgrammingDialog';
import { Programming } from '../../services/dataContracts/queryStack/Programming';
import { MigrateProgrammingDialog } from './MigrateProgrammingDialog';
import './ProgrammingCardStyles.scss';

interface ProgrammingCardProps {
    programming: Programming,
    currency: string,
    mergedProject: MergedProjectVersion,
    projectId: string,
    costRatiosCache: Map<number, CostRatio[]>,
    currentProjectVersionId: number,
    handleOpenProgramming: (programmingId: number) => void,
    handleEditProgramming: (programmingId: number) => void,
    handleDeleteProgramming: (programmingId: number) => void,
    handleDuplicateProgramming: (projectId: string, projectVersionId: number, projectVersionNumber: number, programmingId: number, programmingLabel: string, programmingDate: Date) => void
}

export const ProgrammingCardComponent = (props: ProgrammingCardProps): JSX.Element => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpened = Boolean(anchorEl);

    const [isDetailsOpened, setIsDetailsOpened] = useState<boolean>(false);
    const [isConfirmDeleteProgrammingDialogOpened, setIsConfirmDeleteProgrammingDialogOpened] = useState<boolean>(false);
    const [isMigrateProgrammingDialogOpened, setIsMigrateProgrammingDialogOpened] = useState<boolean>(false);

    const [selectedProjectVersionIdToMigrate, setSelectedProjectVersionIdToMigrate] = useState<number>(null);
    const [selectedProjectVersionNumberToMigrate, setSelectedProjectVersionNumberToMigrate] = useState<number>(null);

    const [exportProgrammingDialogOpened, setExportProgrammingDialogOpened] = useState<boolean>(false);

    const [summary, setSummary] = useState<{
        totalBudget: number,
        totalLengthInLinearMeters: number,
        totalAreaInSquareMeters: number,
        totalSectionsIdsCount: number,
        firstStartDate: Date,
        lastEndDate: Date,
        monitoringQualitySectionsCost: number,
        localizedRepairQualitySectionsCost: number,
        generalMaintenanceQualitySectionsCost: number,
        reinforcementQualitySectionsCost: number,
        rehabilitationQualitySectionsCost: number
    }>({
        totalBudget: null,
        totalLengthInLinearMeters: null,
        totalAreaInSquareMeters: null,
        totalSectionsIdsCount: null,
        firstStartDate: null,
        lastEndDate: null,
        monitoringQualitySectionsCost: null,
        localizedRepairQualitySectionsCost: null,
        generalMaintenanceQualitySectionsCost: null,
        reinforcementQualitySectionsCost: null,
        rehabilitationQualitySectionsCost: null
    });

    useEffect(() => {
        if (props.programming && props.mergedProject) {
            const areas = props.programming.areas;

            if (areas.length > 0) {
                let areasBudgets = areas.map(x => x.budgetAmount ?? 0);
                let totalBudget = areasBudgets.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areasLengthInLinearMeters = areas.map(x => x.lengthInLinearMeters ?? 0);
                let totalLengthInLinearMeters = areasLengthInLinearMeters.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areasAreaInSquareMeters = areas.map(x => x.areaInSquareMeters ?? 0);
                let totalAreaInSquareMeters = areasAreaInSquareMeters.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areaSectionsIdsCount = areas.map(x => x.sections.length ?? 0);
                let totalSectionsIdsCount = areaSectionsIdsCount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                let areasWithStartDate = areas.filter(x => x.startDate !== null && x.startDate !== undefined);
                let firstStartDate = areasWithStartDate.length > 0 ? areasWithStartDate.map(x => x.startDate).reduce((a, b) => a < b ? a : b) : null;

                let areasWithEndDate = areas.filter(x => x.startDate !== null && x.startDate !== undefined);
                let lastEndDate = areasWithEndDate.length > 0 ? areasWithEndDate.map(x => x.endtDate).reduce((a, b) => a > b ? a : b) : null;

                let monitoringQualitySectionsCost = 0;
                let localizedRepairQualitySectionsCost = 0;
                let generalMaintenanceQualitySectionsCost = 0;
                let reinforcementQualitySectionsCost = 0;
                let rehabilitationQualitySectionsCost = 0;

                let costRatios = props.costRatiosCache.get(props.programming.year);

                areas.forEach((area) => {
                    area.sections.map(x => x.roadSectionId).forEach((sectionId) => {
                        let section = props.mergedProject.roadsSections.get(sectionId);
                        let lengthInMeters = section.lengthInMeters ? Math.round(section.lengthInMeters) : null;
                        let surface = lengthInMeters && section.widthInMeters ? Math.round(lengthInMeters * section.widthInMeters) : null;
                        let costRatio = costRatios ? costRatios.find(x => x.traffic === section.traffic && x.score === section.score) : null;
                        let cost = surface && costRatio ? surface * costRatio.costRatioValue : null;

                        if (section.score === 10 || section.score === 9 || section.score === 8) {
                            monitoringQualitySectionsCost += cost ?? 0;
                        }

                        if (section.score === 7 || section.score === 6) {
                            localizedRepairQualitySectionsCost += cost ?? 0;
                        }

                        if (section.score === 5 || section.score === 4) {
                            generalMaintenanceQualitySectionsCost += cost ?? 0;
                        }

                        if (section.score === 3 || section.score === 2) {
                            reinforcementQualitySectionsCost += cost ?? 0;
                        }

                        if (section.score === 1) {
                            rehabilitationQualitySectionsCost += cost ?? 0;
                        }
                    });
                });

                setSummary({
                    totalBudget: totalBudget,
                    totalLengthInLinearMeters: totalLengthInLinearMeters,
                    totalAreaInSquareMeters: totalAreaInSquareMeters,
                    totalSectionsIdsCount: totalSectionsIdsCount,
                    firstStartDate: firstStartDate,
                    lastEndDate: lastEndDate,
                    monitoringQualitySectionsCost,
                    localizedRepairQualitySectionsCost,
                    generalMaintenanceQualitySectionsCost,
                    reinforcementQualitySectionsCost,
                    rehabilitationQualitySectionsCost
                });
            }
        }
    }, [props.programming, props.mergedProject])

    const handleMenuClicked = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAnchorEl(null);
    }

    const handleOpenProgramming = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);

        props.handleOpenProgramming(props.programming.programmingId);
    }

    const handleEditProgramming = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);

        props.handleEditProgramming(props.programming.programmingId);
    }

    const handleDeleteProgramming = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);
        setIsConfirmDeleteProgrammingDialogOpened(true);
    }

    const handleExportProgramming = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setExportProgrammingDialogOpened(true);
    }

    const handleProgrammingDialogClosed = (): void => {
        setExportProgrammingDialogOpened(false);
    }

    const handleExpandClicked = (event: React.MouseEvent<SVGSVGElement>, expanded: boolean): void => {
        event.stopPropagation();
        setIsDetailsOpened(expanded);
    }

    const handleDeleteCanceled = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        setIsConfirmDeleteProgrammingDialogOpened(false);
    }

    const handleDeleteConfirmed = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        setIsConfirmDeleteProgrammingDialogOpened(false);
        props.handleDeleteProgramming(props.programming.programmingId);
    }

    const handleMigrateProgramming = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);
        setIsMigrateProgrammingDialogOpened(true);
    }

    const handleMigrateCanceled = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        setIsMigrateProgrammingDialogOpened(false);
        setSelectedProjectVersionIdToMigrate(null);
        setSelectedProjectVersionNumberToMigrate(null);
    }

    const handleMigrateConfirmed = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        handleClose(event);
        props.handleDuplicateProgramming(props.programming.projectId, selectedProjectVersionIdToMigrate, selectedProjectVersionNumberToMigrate, props.programming.programmingId, props.programming.label, props.programming.creationDate);
        setIsMigrateProgrammingDialogOpened(false);
        setSelectedProjectVersionIdToMigrate(null);
        setSelectedProjectVersionNumberToMigrate(null);
    }

    const handleSelectedProjectVersionChanged = (selectedProjectVersion: { projectVersionId: number, projectVersionNumber: number }) => {
        if (selectedProjectVersion) {
            setSelectedProjectVersionIdToMigrate(selectedProjectVersion.projectVersionId);
            setSelectedProjectVersionNumberToMigrate(selectedProjectVersion.projectVersionNumber);
        }
        else {
            setSelectedProjectVersionIdToMigrate(null);
            setSelectedProjectVersionNumberToMigrate(null);
        }
    }

    const programming = props.programming;

    return (
        <Box className="programming-card" onClick={() => props.handleOpenProgramming(programming.programmingId)}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box className="programming-title-year">{`${programming.year} | ${programming.label}`}</Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={isMenuOpened ? 'long-menu' : undefined}
                        aria-expanded={isMenuOpened ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuClicked}
                    >
                        <MoreVertIcon className="more-vert-icon" />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={isMenuOpened}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleOpenProgramming}>{Translate.Resources.UI_Programmings_ProgrammingCard_Menu_Open}</MenuItem>
                        <MenuItem onClick={handleEditProgramming}>{Translate.Resources.UI_Programmings_ProgrammingCard_Menu_EditInformations}</MenuItem>
                        {props.currentProjectVersionId > programming.projectVersionId &&
                            <MenuItem onClick={handleMigrateProgramming}>{Translate.Resources.UI_Programmings_ProgrammingCard_Menu_Migrate}</MenuItem>
                        }
                        <MenuItem onClick={handleExportProgramming}>{Translate.Resources.UI_Programmings_Export}</MenuItem>
                        <MenuItem onClick={handleDeleteProgramming}>{Translate.Resources.UI_Programmings_ProgrammingCard_Menu_Delete}</MenuItem>
                    </Menu>
                </Box>
            </Box>
            <hr />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box className="programming-item">
                    <div className="item-title">{Translate.Resources.UI_Programming_AreasManagement_Budget}</div>
                    <div className="value">{`${summary?.totalBudget ? summary.totalBudget.toFixedLocalized(0) : 0} ${props.currency}`}</div>
                </Box>
                <Box className="programming-item">
                    <div className="item-title">{Translate.Resources.UI_Programming_AreasManagement_Zones}</div>
                    <div className="value">{`${programming?.areas.length}`}</div>
                </Box>
                <Box className="programming-item">
                    <div className="item-title">{Translate.Resources.UI_Programming_AreasManagement_NumberOfSections}</div>
                    <div className="value">{`${summary?.totalSectionsIdsCount ?? 0}`}</div>
                </Box>
                <Box className="programming-item">
                    <div className="item-title">{`${Translate.Resources.UI_Programming_AreasManagement_Linear} (${MeasurementSystem.getSymbolOfLengthUnit()})`}</div>
                    <div className="value">{summary?.totalLengthInLinearMeters ? MeasurementSystem.getLengthInMetersOrYards(summary.totalLengthInLinearMeters).toFixedLocalized(2) : 0}</div>
                </Box>
                <Box className="programming-item">
                    <div className="item-title">{`${Translate.Resources.UI_Programming_AreasManagement_Surface} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                    <div className="value">{summary?.totalAreaInSquareMeters ? MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(summary.totalAreaInSquareMeters)?.toFixedLocalized(0) : 0}</div>
                </Box>
                <Box className="pie-chart">
                    <PieChartComponent
                        data={{
                            monitoringSectionsPercent: summary?.totalBudget && summary?.monitoringQualitySectionsCost ? Math.round((summary.monitoringQualitySectionsCost * 100) / summary.totalBudget) : 0,
                            localizedRepairSectionsPercent: summary?.totalBudget && summary?.localizedRepairQualitySectionsCost ? Math.round((summary.localizedRepairQualitySectionsCost * 100) / summary.totalBudget) : 0,
                            generalMaintenanceSectionsPercent: summary?.totalBudget && summary?.generalMaintenanceQualitySectionsCost ? Math.round((summary.generalMaintenanceQualitySectionsCost * 100) / summary.totalBudget) : 0,
                            reinforcementSectionsPercent: summary?.totalBudget && summary?.reinforcementQualitySectionsCost ? Math.round((summary.reinforcementQualitySectionsCost * 100) / summary.totalBudget) : 0,
                            rehabilitationSectionsPercent: summary?.totalBudget && summary?.rehabilitationQualitySectionsCost ? Math.round((summary.rehabilitationQualitySectionsCost * 100) / summary.totalBudget) : 0
                        }}
                        innerRadius={14}
                        outerRadius={25}
                        paddingAngle={0}
                        cornerRadius={0}
                        startAngle={-180}
                        endAngle={180}
                        cx={20}
                        cy={20}
                    />
                </Box>
                <Box className="programming-item">
                    <div className="item-title">{Translate.Resources.UI_Programming_AreasManagement_Begin}</div>
                    <div className="value">{summary.firstStartDate ? `${(summary.firstStartDate.getMonth() + 1) > 9 ? summary.firstStartDate.getMonth() + 1 : '0' + (summary.firstStartDate.getMonth() + 1)}/${summary.firstStartDate.getFullYear()}` : ''}</div>
                </Box>
                <Box className="programming-item">
                    <div className="item-title">{Translate.Resources.UI_Programming_AreasManagement_End}</div>
                    <div className="value">{summary.lastEndDate ? `${(summary.lastEndDate.getMonth() + 1) > 9 ? summary.lastEndDate.getMonth() + 1 : '0' + (summary.lastEndDate.getMonth() + 1)}/${summary.lastEndDate.getFullYear()}` : ''}</div>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    {isDetailsOpened ? (<ExpandLessIcon className="expand-icon" onClick={(e) => handleExpandClicked(e, false)} />) : (<ExpandMoreIcon className="expand-icon" onClick={(e) => handleExpandClicked(e, true)} />)}
                </Box>
            </Box>
            {isDetailsOpened &&
                <>
                    <hr />
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color rehabilitation"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}</div>
                                <div className="quality-item-percent">{`${summary?.totalBudget && summary?.rehabilitationQualitySectionsCost ? ((summary.rehabilitationQualitySectionsCost * 100) / summary.totalBudget).toFixedLocalized(0) : ''} %`}</div>
                                <div className="quality-item-budget">{`${summary?.rehabilitationQualitySectionsCost ? summary.rehabilitationQualitySectionsCost.toFixedLocalized(0) : ''} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color reinforcement"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}</div>
                                <div className="quality-item-percent">{`${summary?.totalBudget && summary?.reinforcementQualitySectionsCost ? ((summary.reinforcementQualitySectionsCost * 100) / summary.totalBudget).toFixedLocalized(0) : ''} %`}</div>
                                <div className="quality-item-budget">{`${summary?.reinforcementQualitySectionsCost ? summary.reinforcementQualitySectionsCost.toFixedLocalized(0) : ''} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color general-maintenance"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}</div>
                                <div className="quality-item-percent">{`${summary?.totalBudget && summary?.generalMaintenanceQualitySectionsCost ? ((summary.generalMaintenanceQualitySectionsCost * 100) / summary.totalBudget).toFixedLocalized(0) : ''} %`}</div>
                                <div className="quality-item-budget">{`${summary?.generalMaintenanceQualitySectionsCost ? summary.generalMaintenanceQualitySectionsCost.toFixedLocalized(0) : ''} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color localized-repair"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}</div>
                                <div className="quality-item-percent">{`${summary?.totalBudget && summary?.localizedRepairQualitySectionsCost ? ((summary.localizedRepairQualitySectionsCost * 100) / summary.totalBudget).toFixedLocalized(0) : ''} %`}</div>
                                <div className="quality-item-budget">{`${summary?.localizedRepairQualitySectionsCost ? summary.localizedRepairQualitySectionsCost.toFixedLocalized(0) : ''} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color monitoring"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_Monitoring}</div>
                                <div className="quality-item-percent">{`${summary?.totalBudget && summary?.monitoringQualitySectionsCost ? ((summary.monitoringQualitySectionsCost * 100) / summary.totalBudget).toFixedLocalized(0) : ''} %`}</div>
                                <div className="quality-item-budget">{`${summary?.monitoringQualitySectionsCost ? summary.monitoringQualitySectionsCost.toFixedLocalized(0) : ''} ${props.currency}`}</div>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
            <hr />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                    {programming.lastModificationDate ? (
                        <div className="footer-label">{`${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_Modified_The} ${new Date(programming.lastModificationDate).toLocaleDateString()} ${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_By} ${programming.lastModificationUserFullName}`}</div>
                    ) : (
                        <div className="footer-label">{`${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_Created_The} ${new Date(programming.creationDate).toLocaleDateString()} ${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_By} ${programming.ownerUserFullName}`}</div>
                    )}
                    {programming.scenarioLabel && programming.scenarioYear ? (
                        <div className="footer-label">{`${Translate.Resources.UI_Programmings_ProgrammingCard_GeneratedFromTheScenario} ${programming.scenarioYear} - ${programming.scenarioLabel} ${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_By} ${programming.ownerUserFullName} ${Translate.Resources.UI_Programmings_ProgrammingCard_The} ${new Date(programming.creationDate).toLocaleDateString()}`}</div>
                    ) : ""}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    {programming.projectVersionIdMigrated ? (
                        <div className="footer-label">{`${Translate.Resources.UI_Programming_ProgrammingCard_MigratedTo} ${programming.videoDateTime ? new Date(programming.videoDateTime).toLocaleDateString() : ''} (V${programming.projectVersionNumber})`}</div>
                    ) : (
                        <div className="footer-label">{`${Translate.Resources.UI_Programming_ProgrammingCard_ProgrammingBasedOnSurveys} ${programming.videoDateTime ? new Date(programming.videoDateTime).toLocaleDateString() : ''}`}</div>
                    )}
                    {props.currentProjectVersionId > programming.projectVersionId &&
                        <div className="footer-label warning">{Translate.Resources.UI_Programming_ProgrammingCard_AnUpdateIsAvailable}</div>
                    }
                </Box>
            </Box>
            {isConfirmDeleteProgrammingDialogOpened &&
                <Dialog id="delete-programming-dialog" open={isConfirmDeleteProgrammingDialogOpened}>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReportProblemIcon className="warning-icon" />
                            <Box className="text">
                                {Translate.Resources.UI_Programmings_ProgrammingCard_Menu_ConfirmDeleteProgrammingDialog_AreYouSureWantToRemoveProgramme}
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button className="btn-secondary" onClick={(e) => handleDeleteCanceled(e)}>
                                    {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_ConfirmDeleteScenarioDialog_Button_Cancel}
                                </Button>
                                <Button className="btn-primary" onClick={(e) => handleDeleteConfirmed(e)}>
                                    {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_ConfirmDeleteScenarioDialog_Button_Delete}
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
            {isMigrateProgrammingDialogOpened &&
                <MigrateProgrammingDialog
                    isMigrateProgrammingDialogOpened={isMigrateProgrammingDialogOpened}
                    programmingLabel={programming.label}
                    projectId={programming.projectId}
                    selectedProjectVersion={selectedProjectVersionIdToMigrate}
                    currentProjectVersionId={programming.projectVersionId}
                    handleMigrateCanceled={handleMigrateCanceled}
                    handleMigrateConfirmed={handleMigrateConfirmed}
                    handleSelectedProjectVersionChanged={handleSelectedProjectVersionChanged}
                />
            }
            {exportProgrammingDialogOpened &&
                <ExportProgrammingDialog opened={exportProgrammingDialogOpened}
                    programmingId={programming.programmingId}
                    projectVersionId={props.mergedProject.projectVersionId}
                    auscultationsIdsString={props.mergedProject.auscultationsIdsString}
                    projectLabel={props.mergedProject.projectLabel}
                    programmingLabel={props.programming.label}
                    currency={props.currency ?? ''}
                    handleClose={handleProgrammingDialogClosed}
                />
            }
        </Box>
    );
}