import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box } from '@mui/system';
import { data } from 'azure-maps-control';
import { debounce, orderBy } from 'lodash';
import React, { useState } from 'react';
import { InputActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import Translate from '../../../localization/Localization';
import { ProgrammingStatus } from '../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { WorksFilterComponent } from '../../../shared/components/ActionsMenu/WorksFilterComponent';
import { Address, MapsService } from '../../../shared/Map/MapsService';
import { Programming } from '../../Programmings/services/dataContracts/queryStack/Programming';
import { QualityListFilterComponent } from '../../RoadsCondition/components/QualityListFilterComponent';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import { AddressSelectItem } from '../models/AddressSelectItem';
import './ActionsMenuStyles.scss';

interface ActionsMenuProps {
    activeQualities: Set<number>,
    mergedProject: MergedProjectVersion,
    programmings: Programming[],
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    isRoadSectionDetailsOpened: boolean,
    handleDisplaySections: (activeQualities: Set<number>) => void,
    removeAddressIconPin: () => void,
    createAddressIconPin: (position: data.Position) => void,
    handleDisplayAreasFromWorksFilter: (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>) => void
}

export const ActionsMenuComponent = (props: ActionsMenuProps): JSX.Element => {

    const [qualityListAnchorEl, setQualityListAnchorEl] = useState<null | HTMLElement>(null);
    const isQualityListOpened = Boolean(qualityListAnchorEl);

    const [worksFilterAnchorEl, setWorksFilterAnchorEl] = useState<null | HTMLElement>(null);
    const isWorksFilterOpened = Boolean(worksFilterAnchorEl);

    const [isInputAddressLoading, setIsInputAddressLoading] = useState<boolean>(false);
    const [inputAddressValue, setInputAddressValue] = useState<string>("");
    const [inputAddress, setInputAddress] = useState<string>("");

    const handleOpenQualityListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isQualityListOpened) {
            setQualityListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenWorksFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isWorksFilterOpened) {
            setWorksFilterAnchorEl(e.currentTarget);
        }
    }

    const loadInputAddressesOptions = debounce((inputValue: string, callback): void => {
        loadOptions(inputValue, callback);
    }, 500);

    const loadOptions = (inputValue: string, callback): void => {
        if (inputValue.trim().length > 2) {
            setIsInputAddressLoading(true);

            MapsService.SearchAddress(inputValue)
                .then((res: Address[]) => {
                    let listAddress: AddressSelectItem[] = [{ label: "", value: "", latitude: null, longitude: null }];
                    res.forEach(x => {
                        const address: AddressSelectItem = {
                            value: x.id,
                            label: x.freeformAddress,
                            latitude: x.position.latitude,
                            longitude: x.position.longitude
                        };

                        listAddress.push(address);
                    });

                    callback(listAddress);
                    setIsInputAddressLoading(false);
                });
        }
    }

    const onFreeFormAddressFocus = (inputAddress): void => {
        setInputAddressValue(inputAddress ?? '');
    }

    const handleChangeAddress = (e: AddressSelectItem): void => {
        props.removeAddressIconPin();

        if (e) {
            setInputAddressValue(e.label);
            setInputAddress(e.label);

            if (e.value) {
                let position = new data.Position(e.longitude, e.latitude);
                props.createAddressIconPin(position);
            }
        }
        else {
            setInputAddressValue("");
            setInputAddress("");
        }
    }

    const handleInputAddressChange = (value: string, inputActionMeta: InputActionMeta): void => {
        if (inputActionMeta.action === "input-change") {
            setInputAddressValue(value);
            setInputAddress(value);
        }
    }

    const selectedQualitiesNotes: number[] = Array.from(props.activeQualities);
    const projectAuscultationsArray = props.mergedProject ? orderBy(props.mergedProject?.projectVersion?.auscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']) : null;
    const surveyTo = projectAuscultationsArray ? projectAuscultationsArray[projectAuscultationsArray.length - 1].videoDateTime.toLocaleDateString() : '';

    return (
        <Box className="follow-up-works-actions-menu">
            <AsyncSelect
                value={inputAddress ? { label: inputAddress, value: inputAddress } : null}
                inputValue={inputAddressValue}
                className="search-address" classNamePrefix="address"
                loadOptions={loadInputAddressesOptions}
                onFocus={() => onFreeFormAddressFocus(inputAddress)}
                onChange={(e) => handleChangeAddress(e as AddressSelectItem)}
                onInputChange={(value, inputActionMeta) => handleInputAddressChange(value, inputActionMeta)}
                blurInputOnSelect={true}
                openMenuOnClick={false}
                isClearable={true}
                placeholder={Translate.Resources.UI_RoadWorksView_InputSearchAddress_PlaceHolder}
                isLoading={isInputAddressLoading}
                isDisabled={props.isRoadSectionDetailsOpened}
            />
            <Box className="title"></Box>
            <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isQualityListOpened ? 'selected' : ''} ${props.isRoadSectionDetailsOpened ? 'disabled' : ''}`} onClick={(e) => !props.isRoadSectionDetailsOpened ? handleOpenQualityListFilter(e) : {}}>
                <Box className="counter filled">{selectedQualitiesNotes.length}</Box>
                <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    {Translate.Resources.UI_ActionsMenu_Quality}
                    {isQualityListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
            </Box>
            {isQualityListOpened && !props.isRoadSectionDetailsOpened &&
                <QualityListFilterComponent open={isQualityListOpened}
                    anchorEl={qualityListAnchorEl}
                    activeQualities={props.activeQualities}
                    onClose={() => setQualityListAnchorEl(null)}
                    handleDisplaySections={props.handleDisplaySections}
                />
            }
            <Box className="title">{`${Translate.Resources.UI_ActionsMenu_SurveyTo} ${surveyTo}`}</Box>
            <Box className="title">{`${Translate.Resources.UI_ActionsMenu_Display} :`}</Box>
            <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${isWorksFilterOpened ? 'selected' : ''} ${props.isRoadSectionDetailsOpened ? 'disabled' : ''}`} onClick={(e) => !props.isRoadSectionDetailsOpened ? handleOpenWorksFilter(e) : {}}>
                {props.activeStatus.size > 0 &&
                    <Box className="counter filled">{props.activeStatus.size}</Box>
                }
                <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    {Translate.Resources.UI_ActionsMenu_Works}
                    {isWorksFilterOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
            </Box>
            {isWorksFilterOpened && !props.isRoadSectionDetailsOpened &&
                <WorksFilterComponent programmings={props.programmings}
                    selectedProgrammings={props.selectedProgrammings}
                    selectedYears={props.selectedYears}
                    activeStatus={props.activeStatus}
                    open={isWorksFilterOpened}
                    anchorEl={worksFilterAnchorEl}
                    onClose={() => setWorksFilterAnchorEl(null)}
                    shouldDisplaySecondMessage={true}
                    handleDisplayAreasFromWorksFilter={(selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>) => {
                        props.handleDisplayAreasFromWorksFilter(selectedProgrammings, selectedYears, activeStatus);
                        setWorksFilterAnchorEl(null);
                    }}
                />
            }
        </Box>
    )
}