import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import Translate from '../../../localization/Localization';
import './ConfirmSaveChangesDialogStyles.scss';
import WarningIcon from '@mui/icons-material/Warning';

interface ConfirmSaveChangesDialogProps {
    isOpen: boolean,
    handleValidate: () => void,
    handleCancel: () => void
}

export const ConfirmSaveChangesDialog = (props: ConfirmSaveChangesDialogProps): JSX.Element => {
    return (
        <Dialog id="confirm-save-changes-dialog" open={props.isOpen}>
            <DialogContent>
                <Box className="content">
                    <WarningIcon />
                    {Translate.Resources.UI_ProjectSettings_ConfirmSaveChangesDialog_WantToRecordChanges}
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className="btn-cancel" onClick={props.handleCancel}>
                        {Translate.Resources.UI_ProjectSettings_ConfirmSaveChangesDialog_Cancel}
                    </Button>
                    <Button className="btn-validate" onClick={props.handleValidate}>
                        {Translate.Resources.UI_ProjectSettings_ConfirmSaveChangesDialog_Validate}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}