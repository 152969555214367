import { Box } from '@mui/material';
import { data, MapMouseEvent } from 'azure-maps-control';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { Localization } from '../../../../localization/Localization';
import { ProgrammingStatus } from '../../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { PageLoaderComponent } from '../../../../shared/components/PageLoader/PageLoaderComponent';
import { RoadSectionDetailsComponent } from '../../../../shared/components/RoadSectionDetails/RoadSectionDetailsComponent';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { Point } from '../../../../shared/models/Point';
import { ShapeEntityType } from '../../../../shared/models/ShapeEntityType';
import ToastService from '../../../../ToastService';
import BusinessMessages from '../../../../utils/BusinessMessages';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../../../withRouter';
import { ProjectVersion } from '../../../Home/services/dataContracts/queryStack/ProjectVersion';
import { GetRoadWorksRequestArgs } from '../../../Programmings/services/dataContracts/controller/GetRoadWorksRequestArgs';
import { FilteredProgramming } from '../../../Programmings/services/dataContracts/queryStack/FilteredProgramming';
import { ProgrammingsApiClient } from '../../../Programmings/services/ProgrammingsApiClient';
import { ImageExtended } from '../../../RoadsCondition/models/ImageExtended';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { OtherAttributes } from '../../../RoadsCondition/models/OtherAttributes';
import { RoadSectionViewData } from '../../../RoadsCondition/models/RoadSectionViewData';
import { RouteLocationStateModel } from '../../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { Environment } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { StepImageAnomalies } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { RoadsConditionApiClient } from '../../../RoadsCondition/services/RoadsCondition/RoadsConditionApiClient';
import { getHiddenIsSelectedForSort, RoadSection } from '../../models/RoadSection';
import { UpdateScenarioSectionsRequestArgs } from '../../services/dataContracts/controller/UpdateScenarioSectionsRequestArgs';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import { ScenariosApiClient } from '../../services/ScenariosApiClient';
import { ActionsMenuComponent } from '../CommonComponents/ActionsMenuComponent';
import { HeaderComponent } from '../CommonComponents/HeaderComponent';
import { SectionsSummaryComponent } from '../CommonComponents/SectionsSummaryComponent';
import { SectionsSummaryModel } from '../CommonComponents/SectionsSummaryModel';
import ScenariosUtilities from '../ScenariosUtilities';
import { MaintenanceScenarioMapComponent } from './components/MaintenanceScenarioMapComponent';
import { SectionsSelectorComponent } from './components/SectionsSelectorComponent';
import './ScenarioSectionsManagementStyles.scss';

interface ScenarioSectionsManagementViewState {
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    mergedProject: MergedProjectVersion,
    selectedScenario: Scenario,
    selectedScenarioSections: Map<number, RoadSection>,
    currentCurrency: string,
    sections: Map<number, RoadSection>,
    filteredAndSelectedSectionsIds: Set<number>,
    selectedSectionsIds: Set<number>,
    oldScenarioSelectedSectionsIds: Set<number>,
    sectionsSummaryModel: SectionsSummaryModel,
    filteredSectionsPositions: data.Position[],
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    measurementSystemType: MeasurementSystemType,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    filteredProgrammingsFromFilter: FilteredProgramming[],
    filteredSectionsIdsFromGrid: Set<number>
    perStepImagesAnomalies: Map<number, StepImageAnomalies[]>,
    projectVersionAnomalies: Map<string, string>,
    isRoadSectionDetailsOpened: boolean,
    selectedImage: ImageExtended,
    selectedRoadSection: RoadSectionViewData
}

const initialState: ScenarioSectionsManagementViewState = {
    loading: false,
    isSectionsDrawerOpened: true,
    mergedProject: null,
    selectedScenario: null,
    selectedScenarioSections: new Map<number, RoadSection>(),
    currentCurrency: null,
    sections: new Map<number, RoadSection>(),
    filteredAndSelectedSectionsIds: new Set<number>(),
    selectedSectionsIds: new Set<number>(),
    oldScenarioSelectedSectionsIds: new Set<number>(),
    sectionsSummaryModel: null,
    filteredSectionsPositions: [],
    activeAnomalies: new Set<string>(),
    activeQualities: new Set<number>([]),
    activeMunicipalities: new Set<string>(),
    activeDistricts: new Set<string>(),
    activeCollaborativeDevelopmentZones: new Set<string>(),
    activeHierarchies: new Set<Hierarchy>(),
    activeTraffics: new Set<Traffic>(),
    activeEnvironments: new Set<Environment>(),
    activeManagers: new Set<Manager>(),
    activeImportances: new Set<string>(),
    activeOtherAttributes: new Set<string>(),
    measurementSystemType: null,
    selectedProgrammings: [],
    selectedYears: [],
    activeStatus: new Set<ProgrammingStatus>(),
    filteredProgrammingsFromFilter: null,
    filteredSectionsIdsFromGrid: new Set<number>([]),
    perStepImagesAnomalies: new Map<number, StepImageAnomalies[]>(),
    projectVersionAnomalies: new Map<string, string>(),
    isRoadSectionDetailsOpened: false,
    selectedImage: null,
    selectedRoadSection: null
}

interface ScenarioSectionsManagementViewProps {
    role: string
}

export class ScenarioSectionsManagementView extends Component<RouteComponentProps & ScenarioSectionsManagementViewProps, ScenarioSectionsManagementViewState> {
    _isMounted: boolean;
    projectId: string;
    projectVersionId: number;
    locationGeometry: Point;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>;
    hasScoreAnalysisAccess: boolean;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.inputSearchRoadsRef = React.createRef();
        this.hasScoreAnalysisAccess = this.props.role === "ADM" || this.props.role === "ATXPLUS" || this.props.role === "CLIPLUS" || this.props.role === "CLITECH";

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();
        initialState.activeQualities = RoadsConditionAndScenariosShared.getInitialActiveQualities();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.setState({
            loading: true
        });

        this.projectId = locationState.projectId;
        this.projectVersionId = locationState.projectVersionId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const scenarioId = Number(query.get('scenarioId'));

        let data = await ScenariosUtilities.getScenarioAndProjectSettingsData(this.projectId, scenarioId, this.mergedProjectAuscultationsCache, this.projectVersionsCache);

        let scenario = data.scenario;
        let mergedProject = data.mergedProject;
        let projectCurrency = data.projectCurrency;
        let workPriorities = data.workPriorities;
        let costRatios = data.costRatios;

        let sections = new Map<number, RoadSection>();
        let selectedScenarioSections = new Map<number, RoadSection>();
        let filteredSectionsIds = new Set<number>();
        let selectedSectionsIds = new Set<number>();
        let oldScenarioSelectedSectionsIds = new Set<number>();

        mergedProject.roadsSections.forEach((element) => {
            let sectionId = element.roadSectionId;
            let isSelected: boolean = false;
            let section = { ...element } as RoadSection;
            if (scenario.sections.map(e => e.roadSectionId).includes(sectionId)) {
                selectedScenarioSections.set(sectionId, section);
                isSelected = true;
                selectedSectionsIds.add(sectionId);
                oldScenarioSelectedSectionsIds.add(sectionId);
            }

            let sectionData = ScenariosUtilities.getSectionDataWhenHasImportance(section.importance, section.score, section.traffic, workPriorities, costRatios);
            ScenariosUtilities.updateSectionData(section, sectionData);

            sections.set(sectionId, { ...section, isSelected: isSelected, hiddenIsSelectedForSort: getHiddenIsSelectedForSort(isSelected), isVisible: true });
            filteredSectionsIds.add(sectionId);
        });

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(selectedSectionsIds, mergedProject.roadsSections, sections);

        this.setState({
            mergedProject,
            selectedScenario: scenario,
            selectedScenarioSections: selectedScenarioSections,
            currentCurrency: projectCurrency,
            sections: sections,
            filteredAndSelectedSectionsIds: filteredSectionsIds,
            selectedSectionsIds: selectedSectionsIds,
            oldScenarioSelectedSectionsIds: oldScenarioSelectedSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            loading: false
        });

    }

    updateSelectedSections = (selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>, state: ScenarioSectionsManagementViewState): void => {
        let sectionsSummaryModel = ScenariosUtilities.computeSummary(selectedSectionsIds, state.mergedProject.roadsSections, sections);

        this.setState({
            sections: new Map<number, RoadSection>(sections),
            selectedSectionsIds: selectedSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel
        });
    }

    handleSaveScenarioSections = (state: ScenarioSectionsManagementViewState): void => {
        let scenarioId = state.selectedScenario.scenarioId;
        let sectionsIds = Array.from(state.sections).filter(x => x[1].isSelected).map(x => x[0]);
        let requestArgs: UpdateScenarioSectionsRequestArgs = {
            scenarioId: scenarioId,
            ianaTimeZoneId: Localization.ianaTimeZoneId,
            sectionsIds: sectionsIds
        };

        this.setState({
            loading: true
        });

        ScenariosApiClient.UpdateScenarioSections(requestArgs)
            .then((res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                this.navigateToScenarioSectionsVisualisation(scenarioId);
            });
    }

    handleCancelUpdateScenarioSections = (state: ScenarioSectionsManagementViewState): void => {
        this.navigateToScenarioSectionsVisualisation(state.selectedScenario.scenarioId);
    }

    handleCancel = (state: ScenarioSectionsManagementViewState): void => {
        this.navigateToScenarioSectionsVisualisation(state.selectedScenario.scenarioId);
    }

    navigateToScenarioSectionsVisualisation = (scenarioId: number): void => {
        let urlRedirect = `/ScenarioSectionsVisualisation?scenarioId=${scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleSectionsDrawerClosed = (): void => {
        this.setState({
            isSectionsDrawerOpened: false
        });
    }

    handleSectionsDrawerOpened = (): void => {
        this.setState({
            isSectionsDrawerOpened: true
        });
    }

    handleSectionFilteredChanged = (filteredSectionsIdsFromGrid: Set<number>, state: ScenarioSectionsManagementViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let sections = state.sections;
        let sectionsVisibilities = this.updateSectionsVisibility(sections, state.activeQualities, inputValue, filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filteredSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(sectionsVisibilities.filteredSections, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filteredAndSelectedSectionsIds: sectionsVisibilities.filteredAndSelectedSections,
            filteredSectionsPositions: filteredSectionsPositions,
            filteredSectionsIdsFromGrid: filteredSectionsIdsFromGrid
        });
    }

    handleDisplayDetections = (activeAnomalies: Set<string>): void => {
        this.setState({
            activeAnomalies: activeAnomalies
        });
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>, state: ScenarioSectionsManagementViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let sections = state.sections;
        let sectionsVisibilities = this.updateSectionsVisibility(sections, activeQualities, inputValue, state.filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filteredSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(sectionsVisibilities.filteredSections, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filteredAndSelectedSectionsIds: sectionsVisibilities.filteredAndSelectedSections,
            filteredSectionsPositions: filteredSectionsPositions,
            activeQualities: activeQualities
        });
    }

    handleDisplaySectionsFromGeographiesFilters = (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, state: ScenarioSectionsManagementViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let sections = state.sections;
        let sectionsVisibilities = this.updateSectionsVisibility(sections, state.activeQualities, inputValue, state.filteredSectionsIdsFromGrid, activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filteredSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(sectionsVisibilities.filteredSections, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filteredAndSelectedSectionsIds: sectionsVisibilities.filteredAndSelectedSections,
            filteredSectionsPositions: filteredSectionsPositions,
            activeMunicipalities: activeMunicipalities,
            activeDistricts: activeDistricts,
            activeCollaborativeDevelopmentZones: activeCollaborativeDevelopmentZones
        });
    }

    handleDisplaySectionsFromAttributesFilters = (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: ScenarioSectionsManagementViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let sections = state.sections;
        let sectionsVisibilities = this.updateSectionsVisibility(sections, state.activeQualities, inputValue, state.filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state);
        let filteredSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(sectionsVisibilities.filteredSections, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filteredAndSelectedSectionsIds: sectionsVisibilities.filteredAndSelectedSections,
            filteredSectionsPositions: filteredSectionsPositions,
            activeHierarchies: activeHierarchies,
            activeTraffics: activeTraffics,
            activeEnvironments: activeEnvironments,
            activeManagers: activeManagers,
            activeImportances: activeImportances,
            activeOtherAttributes: activeOtherAttributes
        });
    }

    updateSectionsVisibility = (sections: Map<number, RoadSection>, activeQualities: Set<number>, inputSearchText: string, filteredSectionsIdsFromGrid: Set<number>, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: ScenarioSectionsManagementViewState): { filteredAndSelectedSections: Set<number>, filteredSections: Set<number> } => {
        let filteredAndSelectedSectionsIds = new Set<number>(state.filteredAndSelectedSectionsIds);
        let filteredSections = new Set<number>();

        sections.forEach((section) => {
            if ((activeQualities.size > 0 && activeQualities.has(section.score)) &&
                (section.labelLowerWithoutDiacritics?.includes(inputSearchText)) &&
                ((activeMunicipalities.size > 0 && activeMunicipalities.has(section.municipality)) || activeMunicipalities.size === 0) &&
                ((activeDistricts.size > 0 && activeDistricts.has(section.district)) || activeDistricts.size === 0) &&
                ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(section.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                ((activeHierarchies.size > 0 && activeHierarchies.has(section.hierarchy)) || activeHierarchies.size === 0) &&
                ((activeTraffics.size > 0 && activeTraffics.has(section.traffic)) || activeTraffics.size === 0) &&
                ((activeEnvironments.size > 0 && activeEnvironments.has(section.environment)) || activeEnvironments.size === 0) &&
                ((activeManagers.size > 0 && activeManagers.has(section.manager)) || activeManagers.size === 0) &&
                ((activeImportances.size > 0 && activeImportances.has(section.importance ? section.importance.toString() : null)) || activeImportances.size === 0) &&
                ((activeOtherAttributes.size > 0 && (
                    (section.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                    (section.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                    (section.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                    (section.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                    (section.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                    (!section.bus && !section.bikeLase && !section.border && !section.ditch && !section.side && activeOtherAttributes.has(null))
                )) || activeOtherAttributes.size === 0) &&
                ((filteredSectionsIdsFromGrid.size > 0 && filteredSectionsIdsFromGrid.has(section.roadSectionId)) || filteredSectionsIdsFromGrid.size === 0)) {

                section.isVisible = true;
                filteredSections.add(section.roadSectionId);

                if (!filteredAndSelectedSectionsIds.has(section.roadSectionId)) {
                    filteredAndSelectedSectionsIds.add(section.roadSectionId);
                }
            }
            else {
                section.isVisible = false;
                filteredSections.delete(section.roadSectionId);

                if (filteredAndSelectedSectionsIds.has(section.roadSectionId) && !state.selectedSectionsIds.has(section.roadSectionId)) {
                    filteredAndSelectedSectionsIds.delete(section.roadSectionId);
                }
            }
        });

        return {
            filteredAndSelectedSections: filteredAndSelectedSectionsIds,
            filteredSections: filteredSections
        };
    }

    onSelectedSectionChange = (sectionsIds: number[], state: ScenarioSectionsManagementViewState): void => {
        let selectedSectionsIds = new Set<number>(sectionsIds);

        let sections = state.sections;
        sections.forEach((section) => {
            if (selectedSectionsIds.has(section.roadSectionId)) {
                section.isSelected = true;
                section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(true);
            }
            else {
                section.isSelected = false;
                section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(false);
            }
        });

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(selectedSectionsIds, state.mergedProject.roadsSections, sections);

        this.setState({
            sections: new Map<number, RoadSection>(sections),
            selectedSectionsIds: selectedSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel
        });
    }

    handleUpdateScenarioClicked = (state: ScenarioSectionsManagementViewState): void => {
        let urlRedirect = `/ScenariosManagement?scenarioId=${state.selectedScenario.scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType
        });
    }

    handleDisplayAreasFromWorksFilter = (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>): void => {
        this.setState({
            loading: true
        });

        let status: number[] = Array.from(activeStatus).map(x => {
            return x === ProgrammingStatus.finished ? 2 : (x === ProgrammingStatus.toBeCompleted ? 1 : null);
        });

        let data: GetRoadWorksRequestArgs = {
            years: selectedYears,
            labels: selectedProgrammings,
            status
        };

        ProgrammingsApiClient.GetRoadWorksByFilters(this.projectId, data)
            .then((res) => {
                this.setState({
                    filteredProgrammingsFromFilter: res.data,
                    selectedProgrammings,
                    selectedYears,
                    activeStatus, loading: false
                });
            });
    }

    setAnomaliesData = ({ anomalies, perStepImagesAnomaliesMap }: { anomalies: Map<string, string>, perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]> }): void => {
        this.setState({
            projectVersionAnomalies: anomalies,
            perStepImagesAnomalies: perStepImagesAnomaliesMap
        })
    }

    handleChangeRoadsSearchText = debounce((value: string, state: ScenarioSectionsManagementViewState): void => {
        let inputValue = value;
        let sections = state.sections;
        let sectionsVisibilities: { filteredAndSelectedSections: Set<number>, filteredSections: Set<number> } = { filteredAndSelectedSections: new Set<number>(), filteredSections: new Set<number>() };
        if (inputValue.length > 2) {
            sectionsVisibilities = this.updateSectionsVisibility(sections, state.activeQualities, inputValue.trim().toLowerCase().removeDiacritics(), state.filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        }
        else if (inputValue.length === 0) {
            sectionsVisibilities = this.updateSectionsVisibility(sections, state.activeQualities, "", state.filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        }

        let filteredSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(sectionsVisibilities.filteredSections, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filteredAndSelectedSectionsIds: sectionsVisibilities.filteredAndSelectedSections,
            filteredSectionsPositions: filteredSectionsPositions
        });
    }, 500);

    handleCloseRoadSectionDetails = (): void => {
        this.setState({
            selectedImage: null,
            selectedRoadSection: null,
            isRoadSectionDetailsOpened: false
        });
    }

    handleImageChanged = async (imageId: number, state: ScenarioSectionsManagementViewState): Promise<void> => {
        if (imageId === null)
            return;

        let image: ImageExtended = state.mergedProject.imagesDico.get(imageId);
        if (image) {
            let roadSection = state.mergedProject.roadsSections.get(image.roadSectionId);

            if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
            }

            this.showImageAndSectionDetails(image, state.mergedProject);
        }
        else {
            await RoadsConditionApiClient.GetPerRoadSectionImagesFromImageId(imageId)
                .then((res) => {
                    let images = res.data as ImageExtended[];
                    let roadSection = state.mergedProject.roadsSections.get(images[0].roadSectionId);
                    RoadsConditionAndScenariosShared.buildViewDataFromSectionImages(state.mergedProject, images, roadSection);

                    image = state.mergedProject.imagesDico.get(imageId);

                    if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                        RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
                    }

                    this.showImageAndSectionDetails(image, state.mergedProject);
                });
        }
    }

    handleStepChanged = (selectedImage: ImageExtended, searchByNext: boolean, state: ScenarioSectionsManagementViewState): void => {
        let image: ImageExtended = state.mergedProject.imagesDico.get(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId);
        while (image?.roadStepId === selectedImage.roadStepId) {
            image = state.mergedProject.imagesDico.get(searchByNext ? image.nextImageId : image.previousImageId);
        }

        if (image) {
            this.handleImageChanged(image?.imageId, state);
        }
        else {
            this.handleImageChanged(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId, state);
        }
    }

    showImageAndSectionDetails = (image: ImageExtended, mergedProject: MergedProjectVersion): void => {
        this.setState((prevState, props) => {
            let roadSection = mergedProject.roadsSections.get(image.roadSectionId);

            return {
                selectedImage: image,
                selectedRoadSection: roadSection,
                isRoadSectionDetailsOpened: true,
                mergedProject: mergedProject,
                isSectionsDrawerOpened: false
            };
        });
    }

    handleDisplayImageFromSectionClicked = async (e: MapMouseEvent, state: ScenarioSectionsManagementViewState): Promise<void> => {
        const { mergedProject } = state;

        let isAltKeyPressed = (e.originalEvent as any).altKey;
        let isCtrlKeyPressed = (e.originalEvent as any).ctrlKey;
        let isShiftKeyPressed = (e.originalEvent as any).shiftKey;

        let clickedSectionId: number = null;
        for (var i = 0; i < e.shapes.length; i++) {
            let shape = (e.shapes[i] as any);
            let entityType = shape?.data?.properties?.EntityType;
            if (entityType === ShapeEntityType.section ||
                entityType === ShapeEntityType.sectionSelected) {
                clickedSectionId = shape?.data?.properties?.RoadSectionId;
                break;
            }
        }

        let section: RoadSectionViewData = mergedProject.roadsSections.get(clickedSectionId);
        if (!section || section.roadSectionScoreId === null)
            return;

        console.log("section : ");
        console.log(section);

        let clickedPosition: data.Position = e.position;
        if (clickedPosition) {
            if (!isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
                //Click simple: dans ce cas il faut positionner l'icone voiture dans la position de l'image la plus proche de la sélection
                let image = await RoadsConditionAndScenariosShared.handleSectionClicked(section, clickedPosition, state.mergedProject, this.hasScoreAnalysisAccess, false);
                this.showImageAndSectionDetails(image, mergedProject);
                return;
            }
        }
    }

    render() {
        const state = this.state;

        return (
            <Box className="maintenance_scenario">
                {state.loading ? <PageLoaderComponent /> : null}
                <HeaderComponent selectedScenario={state.selectedScenario} loading={state.loading} handleCancel={() => this.handleCancel(state)} handleUpdateScenarioClicked={() => this.handleUpdateScenarioClicked(state)} />
                <ActionsMenuComponent
                    inputRef={this.inputSearchRoadsRef}
                    projectId={this.projectId}
                    activeAnomalies={state.activeAnomalies}
                    activeQualities={state.activeQualities}
                    activeMunicipalities={state.activeMunicipalities}
                    activeDistricts={state.activeDistricts}
                    activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                    activeHierarchies={state.activeHierarchies}
                    activeTraffics={state.activeTraffics}
                    activeEnvironments={state.activeEnvironments}
                    activeManagers={state.activeManagers}
                    activeImportances={state.activeImportances}
                    activeOtherAttributes={state.activeOtherAttributes}
                    mergedProject={state.mergedProject}
                    loading={state.loading}
                    isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    isRoadSectionDetailsOpened={state.isRoadSectionDetailsOpened}
                    handleSectionsDrawerClosed={this.handleSectionsDrawerClosed}
                    handleSectionsDrawerOpened={this.handleSectionsDrawerOpened}
                    handleSearchTextChanged={(value) => this.handleChangeRoadsSearchText(value, state)}
                    handleDisplayDetections={(activeAnomalies: Set<string>) => this.handleDisplayDetections(activeAnomalies)}
                    handleDisplaySections={(activeQualities: Set<number>) => this.handleDisplaySectionsFromQualityFilters(activeQualities, state)}
                    handleDisplaySectionsFromGeographiesFilters={(activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => this.handleDisplaySectionsFromGeographiesFilters(activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state)}
                    handleDisplaySectionsFromAttributesFilters={(activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => this.handleDisplaySectionsFromAttributesFilters(activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state)}
                    handleDisplayAreasFromWorksFilter={this.handleDisplayAreasFromWorksFilter}
                    setAnomaliesData={this.setAnomaliesData}
                    projectVersionAnomalies={state.projectVersionAnomalies}
                />
                <Box className="main-content">
                    {state.isRoadSectionDetailsOpened &&
                        <RoadSectionDetailsComponent
                            selectedRoadSection={state.selectedRoadSection}
                            selectedImage={state.selectedImage}
                            hasScoreAnalysisAccess={this.hasScoreAnalysisAccess}
                            role={this.props.role}
                            shouldDisplayDetails={true}
                            handleStepChanged={(selectedImage: ImageExtended, searchByNext: boolean) => this.handleStepChanged(selectedImage, searchByNext, state)}
                            handleImageChanged={(imageId) => this.handleImageChanged(imageId, state)}
                            onClose={() => this.handleCloseRoadSectionDetails()}
                        />
                    }
                    <Box className="sections-and-map-content">
                        {state.isSectionsDrawerOpened &&
                            <SectionsSelectorComponent selectedScenario={state.selectedScenario}
                                currency={state.currentCurrency}
                                sections={state.sections}
                                selectedSectionsIds={state.selectedSectionsIds}
                                loading={state.loading}
                                isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                                measurementSystemType={state.measurementSystemType}
                                updateSelectedSections={(selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>) => this.updateSelectedSections(selectedSectionsIds, sections, state)}
                                handleSaveScenarioSections={() => this.handleSaveScenarioSections(state)}
                                handleCancelUpdateScenarioSections={() => this.handleCancelUpdateScenarioSections(state)}
                                handleSectionFilteredChanged={(filteredSectionsIdsFromGrid) => this.handleSectionFilteredChanged(filteredSectionsIdsFromGrid, state)}
                            />
                        }
                        {this.locationGeometry &&
                            <MaintenanceScenarioMapComponent
                                locationGeometry={this.locationGeometry}
                                mergedProject={state.mergedProject}
                                filteredSectionsPositions={state.filteredSectionsPositions}
                                filteredAndSelectedSectionsIds={state.filteredAndSelectedSectionsIds}
                                selectedSectionsIds={state.selectedSectionsIds}
                                activeAnomalies={state.activeAnomalies}
                                loading={state.loading}
                                isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                                onSelectedSectionChange={(sectionsIds) => this.onSelectedSectionChange(sectionsIds, state)}
                                currentMeasurementSystemType={state.measurementSystemType}
                                filteredProgrammingsFromFilter={state.filteredProgrammingsFromFilter}
                                perStepImagesAnomalies={state.perStepImagesAnomalies}
                                selectedImage={state.selectedImage}
                                handleDisplayImageFromSectionClicked={(e) => this.handleDisplayImageFromSectionClicked(e, state)}
                                handleCLoseRoadSectionDetails={this.handleCloseRoadSectionDetails}
                            />
                        }
                        {state.sectionsSummaryModel && <SectionsSummaryComponent selectedScenario={state.selectedScenario} sectionsSummaryModel={state.sectionsSummaryModel} sections={state.sections} currency={state.currentCurrency} inEdit={true} measurementSystemType={state.measurementSystemType} />}
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ScenarioSectionsManagementView));
