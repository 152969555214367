import Translate from "../../../localization/Localization";
import { Hierarchy } from "../services/dataContracts/queryStack/Hierarchy";
import { RoadImportance } from "../services/dataContracts/queryStack/RoadImportance";
import { Traffic } from "../services/dataContracts/queryStack/Traffic";

export interface RoadImportanceViewData {
    traffic: Traffic,
    mainHierarchyValue: number,
    localHierarchyValue: number,
    residentialHierarchyValue: number
}

export const buildRoadImportancesViewData = (roadImportancesData: RoadImportance[]): RoadImportanceViewData[] => {
    let importancesViewData: RoadImportanceViewData[] = [];

    let strongTrafficRoadImportances = roadImportancesData.filter(x => x.traffic === Traffic.strong);
    let strongTrafficImportancesViewDataRow = getImportancesViewDataRowByTraffic(strongTrafficRoadImportances, Traffic.strong);
    importancesViewData.push(strongTrafficImportancesViewDataRow);

    let mediumTrafficRoadImportances = roadImportancesData.filter(x => x.traffic === Traffic.medium);
    let mediumTrafficImportancesViewDataRow = getImportancesViewDataRowByTraffic(mediumTrafficRoadImportances, Traffic.medium);
    importancesViewData.push(mediumTrafficImportancesViewDataRow);

    let lowTrafficRoadImportances = roadImportancesData.filter(x => x.traffic === Traffic.low);
    let lowTrafficImportancesViewDataRow = getImportancesViewDataRowByTraffic(lowTrafficRoadImportances, Traffic.low);
    importancesViewData.push(lowTrafficImportancesViewDataRow);

    return importancesViewData;
}

const getImportancesViewDataRowByTraffic = (trafficRoadImportances: RoadImportance[], traffic: Traffic): RoadImportanceViewData => {
    let mainHierarchyValue = null;
    let localHierarchyValue = null;
    let residentialHierarchyValue = null;

    trafficRoadImportances.forEach((item: RoadImportance) => {
        if (item.hierarchy === Hierarchy.main) {
            mainHierarchyValue = item.value;
        }

        if (item.hierarchy === Hierarchy.local) {
            localHierarchyValue = item.value;
        }

        if (item.hierarchy === Hierarchy.residential) {
            residentialHierarchyValue = item.value;
        }
    });

    return {
        traffic: traffic,
        mainHierarchyValue,
        localHierarchyValue,
        residentialHierarchyValue
    };
}


const getTrafficList = (): { text: string, value: Traffic }[] => {
    return [
        { text: Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Strong, value: Traffic.strong },
        { text: Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Moderate, value: Traffic.medium },
        { text: Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Low, value: Traffic.low }
    ];
}

export const getTrafficText = (traffic: Traffic) => {
    let trafficRow = getTrafficList().find(x => x.value === traffic);
    return trafficRow ? trafficRow.text : traffic;
}