import { Box, Button, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Translate, { Localization } from '../../../../localization/Localization';
import Utilities from '../../../../utils/Utilities';
import { getScenarioStatusCssClassValues, getScenarioStatusTextValues } from '../../models/ScenarioStatus';
import { AddScenarioRequestArgs } from '../../services/dataContracts/controller/AddScenarioRequestArgs';
import { UpdateScenarioRequestArgs } from '../../services/dataContracts/controller/UpdateScenarioRequestArgs';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import { ScenarioStatus } from '../../services/dataContracts/queryStack/ScenarioStatus';
import './AddOrEditScenarioStyles.scss';
import { DistributionItemComponent } from './DistributionItemComponent';
import { NumericTextboxComponent } from './NumericTextboxComponent';

interface AddOrEditScenarioComponentProps {
    projectId: string,
    projectVersionId: number,
    currency: string,
    inEdit: boolean,
    scenarioInEdit: Scenario,
    handleAddScenarioClicked: (args: AddScenarioRequestArgs) => void,
    handleUpdateScenarioClicked: (args: UpdateScenarioRequestArgs) => void,
    handleCancel: () => void
}

interface ScenarioModel {
    label: string,
    year: number,
    budget: number,
    rehabilitation: { percent: number, value: number },
    reinforcement: { percent: number, value: number },
    generalMaintenance: { percent: number, value: number },
    localizedRepair: { percent: number, value: number },
    monitoring: { percent: number, value: number },
    status: ScenarioStatus,
    totalDistribution: number
}

export const AddOrEditScenarioComponent = (props: AddOrEditScenarioComponentProps): JSX.Element => {

    let initScenario: ScenarioModel = {
        label: '',
        year: new Date().getFullYear(),
        budget: 0,
        rehabilitation: { percent: 0.05, value: 0 },
        reinforcement: { percent: 0.4, value: 0 },
        generalMaintenance: { percent: 0.35, value: 0 },
        localizedRepair: { percent: 0.15, value: 0 },
        monitoring: { percent: 0.05, value: 0 },
        status: ScenarioStatus.draft,
        totalDistribution: 1
    };

    const [scenario, setScenario] = useState<ScenarioModel>(initScenario);

    const scenarioStatusTextValues = getScenarioStatusTextValues();
    const scenarioStatusCssClassValues = getScenarioStatusCssClassValues();

    useEffect(() => {
        let scenarioInEdit = props.scenarioInEdit;
        if (scenarioInEdit) {
            let scenarioToUpdate: ScenarioModel = {
                label: scenarioInEdit.label,
                year: scenarioInEdit.year,
                budget: scenarioInEdit.totalAmount,
                rehabilitation: { percent: scenarioInEdit.rehabilitationPercentage / 100, value: scenarioInEdit.totalAmount * (scenarioInEdit.rehabilitationPercentage / 100) },
                reinforcement: { percent: scenarioInEdit.reinforcementPercentage / 100, value: scenarioInEdit.totalAmount * (scenarioInEdit.reinforcementPercentage / 100) },
                generalMaintenance: { percent: scenarioInEdit.generalMaintenancePercentage / 100, value: scenarioInEdit.totalAmount * (scenarioInEdit.generalMaintenancePercentage / 100) },
                localizedRepair: { percent: scenarioInEdit.localizedRepairPercentage / 100, value: scenarioInEdit.totalAmount * (scenarioInEdit.localizedRepairPercentage / 100) },
                monitoring: { percent: scenarioInEdit.monitoringPercentage / 100, value: scenarioInEdit.totalAmount * (scenarioInEdit.monitoringPercentage / 100) },
                status: scenarioInEdit.status,
                totalDistribution: 1
            };

            setScenario(scenarioToUpdate);
        }
    }, [props.scenarioInEdit])

    const onBudgetChanged = (inputValue: number): void => {
        let value = inputValue ?? 0;
        setScenario({
            ...scenario,
            budget: value,
            rehabilitation: { ...scenario.rehabilitation, value: value * scenario.rehabilitation.percent },
            reinforcement: { ...scenario.reinforcement, value: value * scenario.reinforcement.percent },
            generalMaintenance: { ...scenario.generalMaintenance, value: value * scenario.generalMaintenance.percent },
            localizedRepair: { ...scenario.localizedRepair, value: value * scenario.localizedRepair.percent },
            monitoring: { ...scenario.monitoring, value: value * scenario.monitoring.percent }
        });
    }

    const onStatusChanged = (e: SelectChangeEvent<ScenarioStatus>): void => {
        setScenario({
            ...scenario,
            status: e.target.value as ScenarioStatus
        });
    }

    const handleAddScenarioClicked = (): void => {
        let args: AddScenarioRequestArgs = {
            label: scenario.label,
            totalAmount: scenario.budget,
            year: scenario.year,
            rehabilitationPercentage: Math.trunc(scenario.rehabilitation.percent * 100),
            reinforcementPercentage: Math.trunc(scenario.reinforcement.percent * 100),
            generalMaintenancePercentage: Math.trunc(scenario.generalMaintenance.percent * 100),
            localizedRepairPercentage: Math.trunc(scenario.localizedRepair.percent * 100),
            monitoringPercentage: Math.trunc(scenario.monitoring.percent * 100),
            status: scenario.status,
            projectId: props.projectId,
            projectVersionId: props.projectVersionId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        props.handleAddScenarioClicked(args);
    }

    const handleUpdateScenarioClicked = (): void => {
        let args: UpdateScenarioRequestArgs = {
            scenarioId: props.scenarioInEdit.scenarioId,
            label: scenario.label,
            totalAmount: scenario.budget,
            year: scenario.year,
            rehabilitationPercentage: Math.trunc(scenario.rehabilitation.percent * 100),
            reinforcementPercentage: Math.trunc(scenario.reinforcement.percent * 100),
            generalMaintenancePercentage: Math.trunc(scenario.generalMaintenance.percent * 100),
            localizedRepairPercentage: Math.trunc(scenario.localizedRepair.percent * 100),
            monitoringPercentage: Math.trunc(scenario.monitoring.percent * 100),
            status: scenario.status,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        props.handleUpdateScenarioClicked(args);
    }

    let isValidDistribution = Number((scenario.totalDistribution * 100).toFixedLocalized(0)) === 100;

    return (
        <Box className="add-edit-scenario">
            <Box className="content">
                <Box className="title-content">
                    <Box className="title">
                        {props.inEdit ? Translate.Resources.UI_Scenarios_ScenarioCard_Menu_EditInformations : Translate.Resources.UI_Scenarios_NewScenario_Title_NewScenario}
                    </Box>
                    <Box className="indic">
                        <span className="required">*</span> {Translate.Resources.UI_Scenarios_NewScenario_RequiredFields}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="column">
                        <div className="input-title">
                            {Translate.Resources.UI_Scenarios_NewScenario_InputTitle_Label} <span className="required">*</span>
                        </div>
                        <div>
                            <TextField
                                hiddenLabel
                                value={scenario.label}
                                variant="outlined"
                                size="small"
                                onChange={(e) => setScenario({ ...scenario, label: e.target.value })}
                                className="input-text scenario-label"
                                placeholder={Translate.Resources.UI_Scenarios_NewScenario_InputTitle_Placeholder_NewScenario}
                                inputProps={{ maxLength: 100 }}
                            />
                        </div>
                        {scenario.label.trim() === '' &&
                            <div className="label-message">{Translate.Resources.UI_Scenarios_NewScenario_Label_Message}</div>
                        }
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <div className="input-title">
                            {Translate.Resources.UI_Scenarios_NewScenario_InputTitle_Year} <span className="required">*</span>
                        </div>
                        <div>
                            <Select className="drop-down-list"
                                value={scenario.year}
                                onChange={(e) => setScenario({ ...scenario, year: e.target.value as number })}
                            >
                                {Utilities.yearList.map((year, index) => {
                                    return <MenuItem key={`year-${index}`} value={year}>{year}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </Box>
                </Box>
                <Box className="budget-content">
                    <div className="section-title">
                        {Translate.Resources.UI_Scenarios_NewScenario_InputTitle_OverallBudget}
                    </div>
                    <div>
                        <NumericTextboxComponent
                            value={scenario.budget}
                            min={0}
                            className="numeric-textbox budget"
                            format={{
                                style: "currency",
                                currency: props.currency,
                                currencyDisplay: "symbol",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0
                            }}
                            onChange={(value) => onBudgetChanged(value)}
                        />
                    </div>
                </Box>
                <Box className="budget-distribution">
                    <div className="section-title">
                        {Translate.Resources.UI_Scenarios_NewScenario_BudgetDistribution}
                    </div>
                    <DistributionItemComponent qualityText={Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}
                        qualityValue={scenario.rehabilitation}
                        cssColorValue="rehabilitation"
                        currency={props.currency}
                        onChange={(value) => setScenario({
                            ...scenario,
                            rehabilitation: { percent: value, value: Math.round(scenario.budget * value) },
                            totalDistribution: value + scenario.reinforcement.percent + scenario.generalMaintenance.percent + scenario.localizedRepair.percent + scenario.monitoring.percent
                        })}
                    />
                    <DistributionItemComponent qualityText={Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}
                        qualityValue={scenario.reinforcement}
                        cssColorValue="reinforcement"
                        currency={props.currency}
                        onChange={(value) => setScenario({
                            ...scenario,
                            reinforcement: { percent: value, value: Math.round(scenario.budget * value) },
                            totalDistribution: scenario.rehabilitation.percent + value + scenario.generalMaintenance.percent + scenario.localizedRepair.percent + scenario.monitoring.percent
                        })}
                    />
                    <DistributionItemComponent qualityText={Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}
                        qualityValue={scenario.generalMaintenance}
                        cssColorValue="general-maintenance"
                        currency={props.currency}
                        onChange={(value) => setScenario({
                            ...scenario,
                            generalMaintenance: { percent: value, value: Math.round(scenario.budget * value) },
                            totalDistribution: scenario.rehabilitation.percent + scenario.reinforcement.percent + value + scenario.localizedRepair.percent + scenario.monitoring.percent
                        })}
                    />
                    <DistributionItemComponent qualityText={Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}
                        qualityValue={scenario.localizedRepair}
                        cssColorValue="localized-repair"
                        currency={props.currency}
                        onChange={(value) => setScenario({
                            ...scenario,
                            localizedRepair: { percent: value, value: Math.round(scenario.budget * value) },
                            totalDistribution: scenario.rehabilitation.percent + scenario.reinforcement.percent + scenario.generalMaintenance.percent + value + scenario.monitoring.percent
                        })}
                    />
                    <DistributionItemComponent qualityText={Translate.Resources.UI_ActionsMenu_Quality_Monitoring}
                        qualityValue={scenario.monitoring}
                        cssColorValue="monitoring"
                        currency={props.currency}
                        onChange={(value) => setScenario({
                            ...scenario,
                            monitoring: { percent: value, value: Math.round(scenario.budget * value) },
                            totalDistribution: scenario.rehabilitation.percent + scenario.reinforcement.percent + scenario.generalMaintenance.percent + scenario.localizedRepair.percent + value
                        })}
                    />
                    <Box className="distribution-item total">
                        <Box className="quality-content">
                            <div className="quality">{Translate.Resources.UI_Scenarios_NewScenario_Total}</div>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="slider"></div>
                            <NumericTextboxComponent
                                value={scenario.totalDistribution}
                                disabled={true}
                                className={`numeric-textbox distribution-percent total-percent ${isValidDistribution ? 'valid' : 'invalid'}`}
                                format="p"
                            />
                            <div className="total-distribution-message">{!isValidDistribution ? Translate.Resources.UI_Scenarios_NewScenario_Total_Distribution_Message : ''}</div>
                        </Box>
                    </Box>
                </Box>
                <Box className="statut">
                    <div className="title">{Translate.Resources.UI_Scenarios_NewScenario_Status}</div>
                    <div>
                        <Select className={`drop-down-list ${scenarioStatusCssClassValues.get(scenario.status) ?? ''}`}
                            value={scenario.status}
                            disabled={!props.inEdit}
                            onChange={(e) => onStatusChanged(e)}
                        >
                            <MenuItem value={ScenarioStatus.draft}>{scenarioStatusTextValues.get(ScenarioStatus.draft)}</MenuItem>
                            <MenuItem value={ScenarioStatus.inProgramming}>{scenarioStatusTextValues.get(ScenarioStatus.inProgramming)}</MenuItem>
                            <MenuItem value={ScenarioStatus.programmed}>{scenarioStatusTextValues.get(ScenarioStatus.programmed)}</MenuItem>
                        </Select>
                    </div>
                </Box>
                <Box className="btns-actions">
                    <Button className="btn-secondary" onClick={() => props.handleCancel()}>
                        {Translate.Resources.UI_Scenarios_NewScenario_Button_Cancel}
                    </Button>
                    {!props.inEdit &&
                        <>
                            <Button className="btn-primary" disabled={scenario.label.trim() === '' || !isValidDistribution || scenario.year === null || scenario.year === undefined || scenario.budget === null || scenario.budget === undefined} onClick={handleAddScenarioClicked}>
                                {Translate.Resources.UI_Scenarios_NewScenario_Button_CreateAManualScenario}
                            </Button>
                            <Button className="btn-primary" disabled onClick={() => { }}>
                                {Translate.Resources.UI_Scenarios_NewScenario_Button_CreateAPreFilledScenario}
                            </Button>
                        </>
                    }
                    {props.inEdit &&
                        <Button className="btn-primary" disabled={scenario.label.trim() === '' || !isValidDistribution || scenario.year === null || scenario.year === undefined || scenario.budget === null || scenario.budget === undefined} onClick={handleUpdateScenarioClicked}>
                            {Translate.Resources.UI_Scenarios_EditScenario_Button_Save}
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
    );
}