import styles2 from "../../../_variables2.scss";

export const ScoreColors = {
    monitoring: styles2.monitoringQualityColor,
    generalMaintenance: styles2.generalMaintenanceQualityColor,
    localizedRepair: styles2.localizedRepairQualityColor,
    reinforcement: styles2.reinforcementQualityColor,
    rehabilitation: styles2.rehabilitationQualityColor,
    undetermined: "white",
    black: "black"
}