import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, ListItemIcon, MenuItem, Select } from '@mui/material';
import { data, layer, Map as AzureMap, MapMouseEvent, Popup, Shape, source } from 'azure-maps-control';
import { orderBy } from 'lodash';
import React, { Component } from 'react';
import Translate from '../../localization/Localization';
import { MapActionBar } from '../../shared/components/MapActionBar/MapActionBar';
import { PageLoaderComponent } from '../../shared/components/PageLoader/PageLoaderComponent';
import { createMap, getMapChoiceValue, setMapCameraFromPositions, setMapCursor } from '../../shared/Map/MapUtils';
import { MapCursorMode } from '../../shared/models/MapCursorMode';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { RouteLocationStateModel } from '../RoadsCondition/models/RouteLocationStateModel';
import './GPSControlStyles.scss';
import { Image } from './services/dataContracts/queryStack/Image';
import { GPSControlApiClient } from './services/GPSControlApiClient';

const datasourceId = "datasourceId";
const layerId = "layerId";
const regex = /anonym-(.*?)#t/;

interface GPSControlViewState {
    auscultationsDates: { label: string, value: number }[],
    selectedAuscultationId: number,
    perAuscultationImages: Map<number, Image[]>,
    loading: boolean
}

export class GPSControlView extends Component<RouteComponentProps, GPSControlViewState> {
    _isMounted: boolean;
    map: AzureMap;
    popup: Popup;

    constructor(props) {
        super(props);
        this.state = {
            auscultationsDates: [],
            selectedAuscultationId: null,
            perAuscultationImages: new Map<number, Image[]>(),
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.map = null;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.setState({
            loading: true
        });

        let projectVersionId = locationState.projectVersionId;
        GPSControlApiClient.GetPerAuscultationImages(projectVersionId)
            .then((res) => {
                let data = res.data;

                let perAuscultationImagesMap = new Map<number, Image[]>();
                perAuscultationImagesMap = perAuscultationImagesMap.fromObject(data, true);

                let auscultations = this.getAuscultationList(perAuscultationImagesMap);

                let mapChoice = getMapChoiceValue();
                this.map = createMap('map', 4, locationState.locationGeometry, mapChoice);
                this.map.resize("100%", "100%");

                this.initMap(this.map, () => {
                    let datasource = this.recreateDatasource(this.map);
                    let auscultationImages = perAuscultationImagesMap.get(auscultations.selectedAuscultationId);
                    this.createMapShapes(datasource, auscultationImages);
                    setMapCameraFromPositions(this.map, auscultationImages.map(x => x.locationGeometry.coordinates));
                });

                this.setState({
                    auscultationsDates: auscultations.auscultationsDates,
                    selectedAuscultationId: auscultations.selectedAuscultationId,
                    perAuscultationImages: perAuscultationImagesMap,
                    loading: false
                });
            });
    }

    getAuscultationList = (perAuscultationImagesMap: Map<number, Image[]>): { auscultationsDates: { label: string, value: number }[], selectedAuscultationId: number } => {
        let auscultations = [];
        perAuscultationImagesMap.forEach((a) => {
            auscultations.push({
                auscultationId: a[0].auscultationId,
                videoDateTime: a[0].videoDateTime,
                processingDateTime: a[0].processingDateTime,
                auscultationNumber: a[0].auscultationNumber
            });
        });

        let projectAuscultationsArray = orderBy(auscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']);
        let auscultationsDates: { label: string, value: number }[] = [];
        let selectedAuscultation: { label: string, value: number } = null;
        projectAuscultationsArray.forEach((auscultation: any, index: number) => {
            let value = auscultation.auscultationId;
            let label = new Date(auscultation.videoDateTime).toLocaleDateString();
            let item = { label: label, value: value };
            auscultationsDates.push(item);

            if (index === projectAuscultationsArray.length - 1) {
                selectedAuscultation = item;
            }
        });

        return {
            auscultationsDates: auscultationsDates,
            selectedAuscultationId: selectedAuscultation.value
        }
    }

    initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (!this._isMounted)
                return;

            this.popup = new Popup({ closeButton: false });
            this.popup.attach(this.map);

            if (callback) {
                callback();
            }
        });
    }

    recreateDatasource = (map: AzureMap): source.DataSource => {
        let datasource = map.sources.getById(datasourceId) as source.DataSource;
        if (datasource) {
            this.removeDatasource(map);
        }

        datasource = this.createDatasource(map);
        return datasource;
    }

    removeDatasource = (map: AzureMap): void => {
        let existingLayer = map.layers.getLayerById(layerId) as layer.BubbleLayer;
        if (existingLayer) {
            map.layers.remove(layerId);

            map.events.remove('mouseover', existingLayer, this.layerMouseover);
            map.events.remove('mouseout', existingLayer, this.layerMouseout);
        }

        let datasource = map.sources.getById(datasourceId) as source.DataSource;
        if (datasource) {
            datasource.clear();
            map.sources.remove(datasource);
        }
    }

    createDatasource = (map: AzureMap): source.DataSource => {
        let datasource = new source.DataSource(datasourceId);
        map.sources.add(datasource);

        let layer = this.createLayer(datasource, layerId);

        map.events.add('mouseover', layer, this.layerMouseover);
        map.events.add('mouseout', layer, this.layerMouseout);

        map.layers.add(layer);

        return datasource;
    }

    createLayer = (datasource: source.DataSource, layerId: string): layer.BubbleLayer => {
        return new layer.BubbleLayer(datasource, layerId, {
            strokeOpacity: 0,
            color: ['get', 'color'],
            radius: ['get', 'radius']
        });
    }

    layerMouseover = (e: MapMouseEvent) => {
        setMapCursor(this.map, MapCursorMode.Pointer);
        let shape = e.shapes[0] as Shape;

        let shapeProps = shape.getProperties();

        this.popup.setOptions({
            content: `<div style="padding: 10px; display: flex; flex-direction: column;">
                <div>section_id : ${shapeProps.roadSectionId}</div>
                <div>timestamp : ${shapeProps.timestamp}</div>
                <div>step_id : ${shapeProps.roadStepId}</div>
                <div>video : ${shapeProps.video}</div>
            </div>`,
            position: e.position,
            pixelOffset: [0, -18]
        });

        this.popup.open(this.map);
    }

    layerMouseout = () => {
        setMapCursor(this.map, MapCursorMode.Auto);

        this.popup.close();
    }

    createMapShapes = (datasource: source.DataSource, images: Image[]): void => {
        images.forEach((image: Image) => {
            let roadSectionId = image.roadSectionId;
            let roadStepId = image.roadStepId;
            let timestampDate = new Date(image.timestamp);
            let timestamp = timestampDate.toLocaleDateString() + ' ' + timestampDate.toLocaleTimeString();

            let video = "";
            let originalVideo = image.originalFilePath.match(regex);
            if (originalVideo && originalVideo[1]) {
                video = originalVideo[1];
            }

            let coordinates = image.locationGeometry.coordinates;
            let color = 'red';
            let shape = new Shape(new data.Point(coordinates), `point-${image.roadStepId}`, { color: color, radius: 4, roadSectionId: roadSectionId, roadStepId: roadStepId, timestamp: timestamp, video: video });
            datasource.add(shape);

            let rawCoordinates = image.rawLocationGeometry.coordinates;
            let rawColor = '#FFEA00';
            let rawShape = new Shape(new data.Point(rawCoordinates), `raw-point-${image.roadStepId}`, { color: rawColor, radius: 4, roadSectionId: roadSectionId, roadStepId: roadStepId, timestamp: timestamp, video: video });
            datasource.add(rawShape);
        });
    }

    handleAuscultationChanged = (value: number, state: GPSControlViewState): void => {
        let datasource = this.recreateDatasource(this.map);
        let auscultationImages = state.perAuscultationImages.get(value);
        this.createMapShapes(datasource, auscultationImages);
        setMapCameraFromPositions(this.map, auscultationImages.map(x => x.locationGeometry.coordinates));

        this.setState({
            selectedAuscultationId: value
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        return (
            <Box className="gps-control">
                {this.state.loading ? <PageLoaderComponent /> : null}
                <Box className="date">
                    <Box className="title">{Translate.Resources.UI_GPSControlView_AuscultationOf}</Box>
                    <Box>
                        <Select
                            className="select"
                            value={this.state.selectedAuscultationId ?? ''}
                            onChange={(e) => this.handleAuscultationChanged(e.target.value as number, this.state)}
                        >
                            {this.state.auscultationsDates.map((item, index: number) => {
                                return (
                                    <MenuItem key={`auscultation-${index}`} value={item.value}>
                                        <ListItemIcon className="item-icon">
                                            <CalendarMonthIcon />
                                        </ListItemIcon>
                                        {item.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Box>
                </Box>
                <Box className="map">
                    <div id="map"></div>
                    <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                        {this.map &&
                            <MapActionBar
                                azureMap={this.map}
                                actions={[]}
                                onSelectedSectionChange={null}
                                selectedSectionsId={[]}
                                sections={null}
                                mainLayer={null}
                                currentMeasurementSystemType={null}
                            />
                        }
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(GPSControlView));