import { Box } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import React from 'react';
import styles from '../../../_variables.scss';
import styles2 from '../../../_variables2.scss';

interface PieChartComponentProps {
    data: {
        monitoringSectionsPercent: number,
        localizedRepairSectionsPercent: number,
        generalMaintenanceSectionsPercent: number,
        reinforcementSectionsPercent: number,
        rehabilitationSectionsPercent: number
    }
    innerRadius: number,
    outerRadius: number,
    paddingAngle: number,
    cornerRadius: number,
    startAngle: number,
    endAngle: number,
    cx: number,
    cy: number
}

const CustomItemTooltipContent = (props) => {
    const { itemData, series } = props;
    let item = series.data[itemData.dataIndex];

    return (
        <Box className="pie-chart-tooltip">
            <div className="data-color" style={{ backgroundColor: item.color }}></div>
            {`${item.value} %`}
        </Box>
    );
};

export const PieChartComponent = (props: PieChartComponentProps): JSX.Element => {
    const { data } = props;

    let seriesData = [];
    let colors: string[] = [];
    let id = 0;

    if (!data) {
        seriesData.push({ id: id, value: 100 });
        colors.push(styles.unfilteredSectionColor);
    }
    else {
        if (Math.round(data.monitoringSectionsPercent) !== 0) {
            seriesData.push({ id: id, value: Math.round(data.monitoringSectionsPercent) });
            id++;
            colors.push(styles2.monitoringQualityColor);
        }

        if (Math.round(data.localizedRepairSectionsPercent) !== 0) {
            seriesData.push({ id: id, value: Math.round(data.localizedRepairSectionsPercent) });
            id++;
            colors.push(styles2.localizedRepairQualityColor);
        }

        if (Math.round(data.generalMaintenanceSectionsPercent) !== 0) {
            seriesData.push({ id: id, value: Math.round(data.generalMaintenanceSectionsPercent) });
            id++;
            colors.push(styles2.generalMaintenanceQualityColor);
        }

        if (Math.round(data.reinforcementSectionsPercent) !== 0) {
            seriesData.push({ id: id, value: Math.round(data.reinforcementSectionsPercent) });
            id++;
            colors.push(styles2.reinforcementQualityColor);
        }

        if (Math.round(data.rehabilitationSectionsPercent) !== 0) {
            seriesData.push({ id: id, value: Math.round(data.rehabilitationSectionsPercent) });
            id++;
            colors.push(styles2.rehabilitationQualityColor);
        }
    }

    return (
        <PieChart
            colors={colors}
            series={[
                {
                    data: seriesData,
                    innerRadius: props.innerRadius,
                    outerRadius: props.outerRadius,
                    paddingAngle: props.paddingAngle,
                    cornerRadius: props.cornerRadius,
                    startAngle: props.startAngle,
                    endAngle: props.endAngle,
                    cx: props.cx,
                    cy: props.cy
                }
            ]}
            tooltip={{ trigger: "item", itemContent: CustomItemTooltipContent }}
        />
    );
}