export const UserRoleIds = {
    administrator: "ADM",
    agency: "ATX",
    agencyPlus: "ATXPLUS",
    customer: "CLI",
    customerPlus: "CLIPLUS",
    customerTech: "CLITECH",
    viewer: "VISU",
    prospect: "PSP"
}
