import { Box } from '@mui/system';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridExpandChangeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useRef, useState } from 'react';
import Translate, { Localization } from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MetersOrYardsRoundedCell } from '../../../../shared/GridUtils/GridUtils';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { OtherAttributes } from '../../../RoadsCondition/models/OtherAttributes';
import { Environment } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { RoadTrunkLabelScore } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/RoadTrunkLabelScore';
import { Traffic } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { RoadSectionViewDataExtended } from '../../models/RoadSectionViewDataExtended';
import { getHiddenIsSelectedForSort, RoadTrunkLabelScoreExtended } from '../../models/RoadTrunkLabelScoreExtended';
import { SizingUtilities } from '../SizingUtilities';
import { CustomRoadCheckbox } from './CustomRoadCheckbox';
import { CustomRoadHeaderCheckbox } from './CustomRoadHeaderCheckbox';
import { DetailsRoadGridComponent } from './DetailsRoadGridComponent';
import './SectionsSelectorStyles.scss';

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

interface SectionsSelectorComponentProps {
    mergedProject: MergedProjectVersion,
    selectedSectionsIds: Set<number>,
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    inputSearchValue: string,
    isShowOnlySectionsOfTheAreaChecked: boolean,
    handleSelectedSectionsIdsChanged: (selectedSectionsIds: Set<number>) => void
}

export const SectionsSelectorComponent = (props: SectionsSelectorComponentProps): JSX.Element => {
    const gridRef = useRef<Grid>();

    const forceUpdate = useForceUpdate();
    const initialSort: SortDescriptor[] = [{ field: 'hiddenIsSelectedForSort', dir: 'asc' }, { field: 'isSelected', dir: 'asc' }];

    const [roadTrunkLabelsScores, setRoadTrunkLabelsScores] = useState<Map<string, RoadTrunkLabelScoreExtended>>(new Map<string, RoadTrunkLabelScoreExtended>());
    const [selectedSectionsIds, setSelectedSectionsIds] = useState<Set<number>>(new Set<number>());

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);

    useEffect(() => {
        if (props.mergedProject) {
            let roads = new Map<string, RoadTrunkLabelScoreExtended>(roadTrunkLabelsScores);
            if (roadTrunkLabelsScores.size === 0) {
                roads = buildRoadsData(props.mergedProject);
            }
            manageRoadsSectionsSelection(roads);

        }
    }, [props.mergedProject, props.selectedSectionsIds])

    useEffect(() => {
        if (roadTrunkLabelsScores.size > 0) {
            let roads = new Map(roadTrunkLabelsScores);
            updateRoadTrunkLabelsScoresVisibility(roads, props.activeQualities, props.inputSearchValue, props.activeMunicipalities, props.activeDistricts, props.activeCollaborativeDevelopmentZones, props.activeHierarchies, props.activeTraffics, props.activeEnvironments, props.activeManagers, props.activeImportances, props.activeOtherAttributes);
            gridRef.current.scrollIntoView({ rowIndex: 0 });
            setRoadTrunkLabelsScores(roads);
        }
    }, [props.activeAnomalies, props.activeQualities, props.activeMunicipalities, props.activeDistricts, props.activeCollaborativeDevelopmentZones, props.activeHierarchies, props.activeTraffics, props.activeEnvironments, props.activeManagers, props.activeImportances, props.activeOtherAttributes, props.inputSearchValue])

    const buildRoadsData = (mergedProject: MergedProjectVersion): Map<string, RoadTrunkLabelScoreExtended> => {
        let roadTrunkLabelsScoreRanking = mergedProject.roadTrunkLabelsScoreRanking;
        let roadTrunkLabelsScores = new Map<string, RoadTrunkLabelScoreExtended>();
        if (roadTrunkLabelsScoreRanking) {
            roadTrunkLabelsScoreRanking.roadTrunkLabelScores.forEach((element: RoadTrunkLabelScore, index: number) => {
                let fixedAverageScore = element.averageScore;
                let sectionIds: number[] = element.roadTrunkIds;
                let sections: RoadSectionViewDataExtended[] = [];
                sectionIds.forEach((sectionId: number) => {
                    let section = mergedProject.roadsSections.get(sectionId) as RoadSectionViewDataExtended;
                    section.isSelected = false;
                    section.isVisible = true;
                    sections.push(section);
                });

                let roadLabelLengthInMeters = Math.round(element.lengthInMetersScored)
                let monitoringSections = sections.filter(s => s.score === 10 || s.score === 9 || s.score === 8);
                let monitoringSectionsLengthInMeters: number = 0;
                monitoringSections.forEach(section => {
                    monitoringSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let monitoringSectionsPercent = (monitoringSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let localizedRepairSections = sections.filter(s => s.score === 7 || s.score === 6);
                let localizedRepairSectionsLengthInMeters: number = 0;
                localizedRepairSections.forEach(section => {
                    localizedRepairSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let localizedRepairSectionsPercent = (localizedRepairSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let generalMaintenanceSections = sections.filter(s => s.score === 5 || s.score === 4);
                let generalMaintenanceSectionsLengthInMeters: number = 0;
                generalMaintenanceSections.forEach(section => {
                    generalMaintenanceSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let generalMaintenanceSectionsPercent = (generalMaintenanceSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let reinforcementSections = sections.filter(s => s.score === 3 || s.score === 2);
                let reinforcementSectionsLengthInMeters: number = 0;
                reinforcementSections.forEach(section => {
                    reinforcementSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let reinforcementSectionsPercent = (reinforcementSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let rehabilitationSections = sections.filter(s => s.score === 1);
                let rehabilitationSectionsLengthInMeters: number = 0;
                rehabilitationSections.forEach(section => {
                    rehabilitationSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let rehabilitationSectionsPercent = (rehabilitationSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let item: RoadTrunkLabelScoreExtended = {
                    ...element,
                    index: index + 1,
                    fixedAverageScore: fixedAverageScore?.toFixedLocalized(2, 2),
                    lengthInMeters: Math.round(element.lengthInMetersScored),
                    hiddenFixedAverageScoreForSort: fixedAverageScore,
                    isVisible: true,
                    isSelected: false,
                    hiddenIsSelectedForSort: getHiddenIsSelectedForSort(false),
                    labelLowerWithoutDiacritics: element.label?.toLowerCase()?.removeDiacritics(),
                    hiddenLabelForSort: element.labelIsRoadTrunkId ? `zzz${element.label}` : element.label,
                    sectionIds: sectionIds,
                    sections: sections,
                    expanded: false,
                    monitoringSectionsPercent: monitoringSectionsPercent,
                    localizedRepairSectionsPercent: localizedRepairSectionsPercent,
                    generalMaintenanceSectionsPercent: generalMaintenanceSectionsPercent,
                    reinforcementSectionsPercent: reinforcementSectionsPercent,
                    rehabilitationSectionsPercent: rehabilitationSectionsPercent
                };
                roadTrunkLabelsScores.set(item.label, item);
            });
        }

        return roadTrunkLabelsScores;
    }

    const manageRoadsSectionsSelection = (roads: Map<string, RoadTrunkLabelScoreExtended>): void => {
        let selectedSections = new Set<number>();
        roads.forEach((road) => {
            road.sections.forEach((section) => {
                if (props.selectedSectionsIds.has(section.roadSectionId)) {
                    section.isSelected = true;
                    if (!selectedSections.has(section.roadSectionId)) {
                        selectedSections.add(section.roadSectionId);
                    }
                }
                else {
                    section.isSelected = false;
                    if (selectedSections.has(section.roadSectionId)) {
                        selectedSections.delete(section.roadSectionId);
                    }
                }
            });

            let visibleSections = road.sections.filter(x => x.isVisible || x.isSelected);
            if (visibleSections.length === 0) {
                road.isSelected = false;
            }
            else {
                if (visibleSections.every(x => x.isSelected)) {
                    road.isSelected = true;
                }
                else if (visibleSections.every(x => !x.isSelected)) {
                    road.isSelected = false;
                }
                else {
                    road.isSelected = null;
                }
            }

            road.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(road.isSelected);
        });

        setRoadTrunkLabelsScores(roads);
        setSelectedSectionsIds(selectedSections);
    }

    const updateRoadTrunkLabelsScoresVisibility = (roadTrunkLabelsScores: Map<string, RoadTrunkLabelScoreExtended>, activeQualities: Set<number>, inputSearchText: string, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>): void => {
        roadTrunkLabelsScores.forEach((road) => {
            road.sections.forEach((section) => {
                if ((activeQualities.size > 0 && activeQualities.has(section.score)) &&
                    ((road.labelLowerWithoutDiacritics.includes(inputSearchText)) || (!inputSearchText && road.averageScore === null)) &&
                    ((activeMunicipalities.size > 0 && activeMunicipalities.has(section.municipality)) || activeMunicipalities.size === 0) &&
                    ((activeDistricts.size > 0 && activeDistricts.has(section.district)) || activeDistricts.size === 0) &&
                    ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(section.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                    ((activeHierarchies.size > 0 && activeHierarchies.has(section.hierarchy)) || activeHierarchies.size === 0) &&
                    ((activeTraffics.size > 0 && activeTraffics.has(section.traffic)) || activeTraffics.size === 0) &&
                    ((activeEnvironments.size > 0 && activeEnvironments.has(section.environment)) || activeEnvironments.size === 0) &&
                    ((activeManagers.size > 0 && activeManagers.has(section.manager)) || activeManagers.size === 0) &&
                    ((activeImportances.size > 0 && activeImportances.has(section.importance ? section.importance.toString() : null)) || activeImportances.size === 0) &&
                    ((activeOtherAttributes.size > 0 && (
                        (section.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                        (section.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                        (section.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                        (section.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                        (section.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                        (!section.bus && !section.bikeLase && !section.border && !section.ditch && !section.side && activeOtherAttributes.has(null))
                    )) || activeOtherAttributes.size === 0)) {
                    section.isVisible = true;
                }
                else {
                    section.isVisible = false;
                }
            });

            if (road.sections.some(x => x.isVisible)) {
                road.isVisible = true;
            }
            else {
                road.isVisible = false;
            }

        });
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        let sort = e.sort[0];
        if (sort && sort.field === "label") {
            setSort([{ field: 'hiddenLabelForSort', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "fixedAverageScore") {
            setSort([{ field: 'hiddenFixedAverageScoreForSort', dir: sort.dir }, sort]);
            return;
        }

        setSort(e.sort);
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const onExpandChange = (event: GridExpandChangeEvent): void => {
        let item = roadTrunkLabelsScores.get(event.dataItem.label);
        item.expanded = !event.dataItem.expanded;
        setRoadTrunkLabelsScores(new Map<string, RoadTrunkLabelScoreExtended>(roadTrunkLabelsScores));
    }

    const handleRoadClick = (e: React.ChangeEvent<HTMLInputElement>, dataItem: RoadTrunkLabelScoreExtended): void => {
        let checked = e.target.checked;

        let roads = new Map<string, RoadTrunkLabelScoreExtended>(roadTrunkLabelsScores);
        let item = roads.get(dataItem.label);
        item.isSelected = checked;
        item.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(checked);

        let selectedSections = new Set(selectedSectionsIds);
        if (checked) {
            item.sections.forEach((section) => {
                if (section.isVisible || section.isSelected) {
                    section.isSelected = checked;
                    if (!selectedSections.has(section.roadSectionId)) {
                        selectedSections.add(section.roadSectionId);
                    }
                }
            });
        }
        else {
            item.sections.forEach((section) => {
                if (section.isVisible || section.isSelected) {
                    section.isSelected = checked;
                    if (selectedSections.has(section.roadSectionId)) {
                        selectedSections.delete(section.roadSectionId);
                    }
                }
            });
        }

        setRoadTrunkLabelsScores(roads);
        setSelectedSectionsIds(selectedSections);

        props.handleSelectedSectionsIdsChanged(selectedSections);
    }

    const handleSelectSectionChanged = (dataItem: RoadTrunkLabelScoreExtended, sectionId: number, checked: boolean): void => {
        let roads = new Map<string, RoadTrunkLabelScoreExtended>(roadTrunkLabelsScores);
        let item = roads.get(dataItem.label);
        let sections = item.sections;
        let sectionIndex = sections.findIndex(x => x.roadSectionId === sectionId);
        sections[sectionIndex].isSelected = checked;

        let visibleSections = sections.filter(x => x.isVisible || x.isSelected);
        if (visibleSections.length === 0) {
            item.isSelected = false;
        }
        else {
            if (visibleSections.every(x => x.isSelected)) {
                item.isSelected = true;
            }
            else if (visibleSections.every(x => !x.isSelected)) {
                item.isSelected = false;
            }
            else {
                item.isSelected = null;
            }
        }

        item.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(item.isSelected);

        let selectedSections = new Set(selectedSectionsIds);
        if (checked) {
            if (!selectedSections.has(sectionId)) {
                selectedSections.add(sectionId);
            }
        }
        else {
            if (selectedSections.has(sectionId)) {
                selectedSections.delete(sectionId);
            }
        }

        setRoadTrunkLabelsScores(roads);
        setSelectedSectionsIds(selectedSections);

        props.handleSelectedSectionsIdsChanged(selectedSections);
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const handleHeaderCheckboxChanged = (checked: boolean): void => {
        let selectedSections = new Set(selectedSectionsIds);
        let roads = new Map<string, RoadTrunkLabelScoreExtended>(roadTrunkLabelsScores);
        roads.forEach(road => {
            if (road.isVisible || road.isSelected) {
                road.isSelected = checked;
                road.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(road.isSelected);
                road.sections.forEach((section) => {
                    if (section.isVisible || section.isSelected) {
                        section.isSelected = checked;
                        if (selectedSections.has(section.roadSectionId) && !checked) {
                            selectedSections.delete(section.roadSectionId);
                        }
                        if (!selectedSections.has(section.roadSectionId) && checked) {
                            selectedSections.add(section.roadSectionId);
                        }
                    }
                });
            }
        });

        setRoadTrunkLabelsScores(roads);
        setSelectedSectionsIds(selectedSections);

        props.handleSelectedSectionsIdsChanged(selectedSections);
    }

    let gridHeightGapFromWindow: number = SizingUtilities.areasSectionsGridHeightGapFromWindow();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridHeightGapFromWindow);
    let rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    let gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridHeightGapFromWindow;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    let filteredLabelsScores: RoadTrunkLabelScoreExtended[] = [];
    roadTrunkLabelsScores.forEach((element) => {
        if ((element.isVisible === true && !props.isShowOnlySectionsOfTheAreaChecked) || element.isSelected || element.sections.some(x => x.isSelected === true)) {
            filteredLabelsScores.push(element);
        }
    });
    let dataGrid = sortOrderBy(filteredLabelsScores, sort).slice(skip, skip + gridPageSize);

    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    return (
        <Box className="sections">
            <LocalizationProvider language={Localization.locale}>
                <IntlProvider locale={Localization.locale}>
                    <Grid
                        ref={gridRef}
                        className="roads-sections-grid"
                        data={dataGrid}
                        sortable
                        sort={sort}
                        scrollable="virtual"
                        skip={skip}
                        total={filteredLabelsScores.length}
                        rowHeight={rowHeight}
                        pageSize={gridPageSize}
                        style={gridStyle}
                        expandField="expanded"
                        onPageChange={pageChange}
                        onSortChange={handleSortChange}
                        onExpandChange={onExpandChange}
                        detail={(_props) => <DetailsRoadGridComponent dataItem={_props.dataItem} handleSelectSectionChanged={(checked, sectionId) => handleSelectSectionChanged(_props.dataItem, sectionId, checked)} />}
                    >
                        <GridColumn field="isSelected" width="60px"
                            headerCell={(_props: GridHeaderCellProps) => <CustomRoadHeaderCheckbox {..._props}
                                sortingField="hiddenIsSelectedForSort"
                                sort={sort}
                                filteredLabelsScores={filteredLabelsScores}
                                handleSortColumnChange={handleSortColumnChange}
                                handleHeaderCheckboxChanged={handleHeaderCheckboxChanged}
                            />}
                            cell={(_props: GridCellProps) => <CustomRoadCheckbox {..._props} handleRoadClick={handleRoadClick} />}
                        />
                        <GridColumn width="212px" field="label" title={Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_RoadLabel} />
                        <GridColumn width="100px" field="fixedAverageScore" title={Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_AverageRoadScore}
                            cell={(_props: GridCellProps) =>
                                <td>
                                    <Box className="score-column">
                                        <Box className="value">{_props.dataItem.fixedAverageScore}</Box>
                                        <Box className="pie-chart">
                                            <PieChartComponent
                                                data={{
                                                    monitoringSectionsPercent: _props.dataItem.monitoringSectionsPercent,
                                                    localizedRepairSectionsPercent: _props.dataItem.localizedRepairSectionsPercent,
                                                    generalMaintenanceSectionsPercent: _props.dataItem.generalMaintenanceSectionsPercent,
                                                    reinforcementSectionsPercent: _props.dataItem.reinforcementSectionsPercent,
                                                    rehabilitationSectionsPercent: _props.dataItem.rehabilitationSectionsPercent
                                                }}
                                                innerRadius={8}
                                                outerRadius={15}
                                                paddingAngle={0}
                                                cornerRadius={0}
                                                startAngle={-180}
                                                endAngle={180}
                                                cx={10}
                                                cy={10}
                                            />
                                        </Box>
                                    </Box>
                                </td>
                            }
                        />
                        <GridColumn width="130px" field="lengthInMeters" title={`${Translate.Resources.UI_Home_AuscultationSummaryDrawer_RedRoadsScoreGrid_Columns_Linear} (${symbolOfLengthUnit})`}
                            cell={MetersOrYardsRoundedCell}
                        />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}