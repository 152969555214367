import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { getMapChoiceValue } from '../../../Map/MapUtils';
import { CustomMapChoice } from '../../../models/CustomMapChoice';
import './MapStyleSelector.scss';
import { MapStyleSelectorResources } from './MapStyleSelectorResources';

const ModuleKey = AppModule.MapStyleSelector;
const MapChoiceKey = 'mapChoice';

interface MapStyleSelectorProps {
    handleMapStyleChange: (style: CustomMapChoice) => void
}

export const MapStyleSelector = (props: MapStyleSelectorProps): JSX.Element => {
    const [isMapStyleSelectorOpen, setIsMapStyleSelectorOpen] = useState<boolean>(false);
    const [mapStyle, setMapStyle] = useState<CustomMapChoice>(getMapChoiceValue());

    const handleMapStyleClick = (choice: CustomMapChoice) => {
        LocalStorage.SetItem(ModuleKey, MapChoiceKey, choice);
        setMapStyle(choice);
        props.handleMapStyleChange(choice);
    }

    return (
        <Box
            className={`map-style-selector ${isMapStyleSelectorOpen ? 'opened' : ''}`}
            onMouseEnter={() => setIsMapStyleSelectorOpen(true)}
            onMouseLeave={() => setIsMapStyleSelectorOpen(false)}
        >
            <Box className={isMapStyleSelectorOpen ? "map-style-selector__options" : "hidden"} >
                <ArrowLeftIcon />
                <Box display="flex" alignItems="center">
                    {Object.keys(CustomMapChoice).map((mapChoice) => (
                        <Box key={mapChoice} mr="3px">
                            <Tooltip className="white" title={<Box className="map-style-selector__tooltip">{MapStyleSelectorResources.GetLabel(CustomMapChoice[mapChoice])}</Box>} placement="top">
                                <IconButton className={mapStyle === CustomMapChoice[mapChoice] ? "square selected--checkmark" : "square"} onClick={() => handleMapStyleClick(CustomMapChoice[mapChoice])}>
                                    <img className="iconbutton-image" src={MapStyleSelectorResources.GetIconData(CustomMapChoice[mapChoice])} alt={mapChoice[0]} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ))}
                </Box>
            </Box>
            <IconButton className="square map-style-selector__button">
                <img className="iconbutton-image" src={MapStyleSelectorResources.GetIconData(mapStyle)} alt="map style" />
            </IconButton>
        </Box>
    );
}