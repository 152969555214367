import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import Translate from '../../../localization/Localization';
import { PieChartComponent } from '../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { ProjectThumbnailModel } from '../../Home/models/ProjectThumbnailModel';
import { QualityType } from '../models/QualityType';
import { RoadsSectionsSummaryModel } from '../models/RoadsSectionsSummaryModel';
import { ExportChoicesDialog } from './ExportChoicesDialog';
import './RoadsSectionsSummaryStyles.scss';

interface QualityRoadsSectionsSummaryProps {
    quality: QualityType,
    qualityLabel: string,
    percent: string,
    length: string,
    surface: string
}

const QualityRoadsSectionsSummaryComponent = (props: QualityRoadsSectionsSummaryProps): JSX.Element => {
    return (
        <Box className="quality-details" display="flex" flexDirection="row" height="100%">
            <div className={`quality-color ${props.quality === QualityType.monitoring ? 'monitoring' :
                (props.quality === QualityType.localizedRepair ? 'localized-repair' :
                    (props.quality === QualityType.generalMaintenance ? 'general-maintenance' :
                        (props.quality === QualityType.reinforcement ? 'reinforcement' :
                            (props.quality === QualityType.rehabilitation ? 'rehabilitation' : ''))))}`}></div>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                <div className="quality">{props.qualityLabel}</div>
                <Box display="flex" flexDirection="column">
                    <div className="percent">{props.percent}</div>
                    <div className="length">{props.length}</div>
                    <div className="surface">{props.surface}</div>
                </Box>
            </Box>
        </Box>
    );
}

interface RoadsSectionsSummaryComponentProps {
    selectedRoadsSectionsSummary: RoadsSectionsSummaryModel,
    activeQualities: Set<number>,
    role: string,
    displayedSectionsIds: Set<number>,
    selectedProject: ProjectThumbnailModel,
    auscultationsIdsString: string
}

export const RoadsSectionsSummaryComponent = (props: RoadsSectionsSummaryComponentProps): JSX.Element => {

    const { selectedRoadsSectionsSummary } = props;

    const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false);
    const [isExportDialogOpened, setIsExportDialogOpened] = useState<boolean>(false);

    const symbolOfKilometersOrMiles = MeasurementSystem.getSymbolOfKilometersOrMiles();
    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();

    let totalSectionsLength = selectedRoadsSectionsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSectionsSummary.totalSectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let totalSectionsSurface = selectedRoadsSectionsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSectionsSummary.totalSectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    let rehabilitationQualitySectionsPercent = selectedRoadsSectionsSummary ? `${selectedRoadsSectionsSummary.rehabilitationQualitySectionsPercent.toFixedLocalized(2)} %` : '';
    let rehabilitationQualitySectionsLengthInMeters = selectedRoadsSectionsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSectionsSummary.rehabilitationQualitySectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let rehabilitationQualitySectionsSurface = selectedRoadsSectionsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSectionsSummary.rehabilitationQualitySectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    let reinforcementQualitySectionsPercent = selectedRoadsSectionsSummary ? `${selectedRoadsSectionsSummary.reinforcementQualitySectionsPercent.toFixedLocalized(2)} %` : '';
    let reinforcementQualitySectionsLengthInMeters = selectedRoadsSectionsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSectionsSummary.reinforcementQualitySectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let reinforcementQualitySectionsSurface = selectedRoadsSectionsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSectionsSummary.reinforcementQualitySectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    let generalMaintenanceQualitySectionsPercent = selectedRoadsSectionsSummary ? `${selectedRoadsSectionsSummary.generalMaintenanceQualitySectionsPercent.toFixedLocalized(2)} %` : '';
    let generalMaintenanceQualitySectionsLengthInMeters = selectedRoadsSectionsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSectionsSummary.generalMaintenanceQualitySectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let generalMaintenanceQualitySectionsSurface = selectedRoadsSectionsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSectionsSummary.generalMaintenanceQualitySectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    let localizedRepairQualitySectionsPercent = selectedRoadsSectionsSummary ? `${selectedRoadsSectionsSummary.localizedRepairQualitySectionsPercent.toFixedLocalized(2)} %` : '';
    let localizedRepairQualitySectionsLengthInMeters = selectedRoadsSectionsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSectionsSummary.localizedRepairQualitySectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let localizedRepairQualitySectionsSurface = selectedRoadsSectionsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSectionsSummary.localizedRepairQualitySectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    let monitoringQualitySectionsPercent = selectedRoadsSectionsSummary ? `${selectedRoadsSectionsSummary.monitoringQualitySectionsPercent.toFixedLocalized(2)} %` : '';
    let monitoringQualitySectionsLengthInMeters = selectedRoadsSectionsSummary ? `${MeasurementSystem.getLenghtInKilometersOrMiles(selectedRoadsSectionsSummary.monitoringQualitySectionsLengthInMeters).toFixedLocalized(3)} ${symbolOfKilometersOrMiles}` : '';
    let monitoringQualitySectionsSurface = selectedRoadsSectionsSummary ? `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(selectedRoadsSectionsSummary.monitoringQualitySectionsSurface).toFixedLocalized(0)} ${symbolOfSurfaceUnit}` : '';

    let selectedQualitiesNotes: number[] = Array.from(props.activeQualities);
    let emptyQualityNoteForSort = Math.max(...selectedQualitiesNotes) + 1;

    return (
        <Box className={`roads-sections-summary ${isDetailsExpanded ? 'opened-details' : ''}`} display="flex" flexDirection="column">
            <Box className="summary" display="flex" flexDirection="row">
                <Box className="total" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <FontAwesomeIcon className="route-icon" icon={faRoute} />
                    <div className="total-km">{totalSectionsLength}</div>
                    <div className="total-surface">{`≈ ${totalSectionsSurface}`}</div>
                </Box>
                <Box className="pie-chart">
                    {selectedRoadsSectionsSummary &&
                        <PieChartComponent
                            data={{
                                monitoringSectionsPercent: selectedRoadsSectionsSummary.monitoringQualitySectionsPercent,
                                localizedRepairSectionsPercent: selectedRoadsSectionsSummary.localizedRepairQualitySectionsPercent,
                                generalMaintenanceSectionsPercent: selectedRoadsSectionsSummary.generalMaintenanceQualitySectionsPercent,
                                reinforcementSectionsPercent: selectedRoadsSectionsSummary.reinforcementQualitySectionsPercent,
                                rehabilitationSectionsPercent: selectedRoadsSectionsSummary.rehabilitationQualitySectionsPercent
                            }}
                            innerRadius={14}
                            outerRadius={30}
                            paddingAngle={0}
                            cornerRadius={0}
                            startAngle={-180}
                            endAngle={180}
                            cx={28}
                            cy={30}
                        />
                    }
                </Box>
                <QualityRoadsSectionsSummaryComponent
                    quality={QualityType.rehabilitation}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}
                    percent={rehabilitationQualitySectionsPercent}
                    length={rehabilitationQualitySectionsLengthInMeters}
                    surface={rehabilitationQualitySectionsSurface}
                />
                <QualityRoadsSectionsSummaryComponent
                    quality={QualityType.reinforcement}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}
                    percent={reinforcementQualitySectionsPercent}
                    length={reinforcementQualitySectionsLengthInMeters}
                    surface={reinforcementQualitySectionsSurface}
                />
                <QualityRoadsSectionsSummaryComponent
                    quality={QualityType.generalMaintenance}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}
                    percent={generalMaintenanceQualitySectionsPercent}
                    length={generalMaintenanceQualitySectionsLengthInMeters}
                    surface={generalMaintenanceQualitySectionsSurface}
                />
                <QualityRoadsSectionsSummaryComponent
                    quality={QualityType.localizedRepair}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}
                    percent={localizedRepairQualitySectionsPercent}
                    length={localizedRepairQualitySectionsLengthInMeters}
                    surface={localizedRepairQualitySectionsSurface}
                />
                <QualityRoadsSectionsSummaryComponent
                    quality={QualityType.monitoring}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Monitoring}
                    percent={monitoringQualitySectionsPercent}
                    length={monitoringQualitySectionsLengthInMeters}
                    surface={monitoringQualitySectionsSurface}
                />
            </Box>
            <Box className="filters">
                <Box className="indications">
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="row" mr="10px" alignItems="center">
                            <Box className="counter filled">{selectedQualitiesNotes.length}</Box>
                            <Box className="filter-text">{Translate.Resources.UI_RoadsSectionsSummaryComponent_QualitySelected}</Box>
                        </Box>
                        {selectedRoadsSectionsSummary &&
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box className="counter filled">{selectedRoadsSectionsSummary.totalSectionsCount}</Box>
                                <Box className="filter-text">{Translate.Resources.UI_RoadsSectionsSummaryComponent_Sections}</Box>
                            </Box>
                        }
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box className="display-link" onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}>
                            {isDetailsExpanded ?
                                (
                                    <>
                                        <div className="display-filters-link">{Translate.Resources.UI_RoadsSectionsSummaryComponent_HideFilters}</div>
                                        <ExpandLessIcon />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="display-filters-link">{Translate.Resources.UI_RoadsSectionsSummaryComponent_ShowFilters}</div>
                                        <ExpandMoreIcon />
                                    </>
                                )
                            }
                        </Box>
                        {props.role !== 'CLI' &&
                            <>
                                <Button className="export-button" onClick={() => setIsExportDialogOpened(true)}>
                                    {Translate.Resources.UI_RoadsSectionsSummaryComponent_Export}
                                </Button>
                                {isExportDialogOpened &&
                                    <ExportChoicesDialog isOpened={isExportDialogOpened}
                                        displayedSectionsIds={props.displayedSectionsIds}
                                        auscultationsIdsString={props.auscultationsIdsString}
                                        selectedProject={props.selectedProject}
                                        onClose={() => setIsExportDialogOpened(false)} />
                                }
                            </>
                        }
                    </Box>
                </Box>
                {isDetailsExpanded &&
                    <Box className="details" display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column">
                            <div className="detail-title">{Translate.Resources.UI_RoadsSectionsSummaryComponent_filters_Qualities}</div>
                            <div>
                                {selectedQualitiesNotes.sort((a, b) => (a === null ? emptyQualityNoteForSort : a) - (b === null ? emptyQualityNoteForSort : b)).map((note, index) => {
                                    return <div className="detail-text quality-note" key={`quality-note-${index}`}>{`+ ${note ?? Translate.Resources.UI_ActionsMenu_Empty}`}</div>
                                })}
                            </div>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
}