import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import React from 'react';
import { RoadSectionGridViewData } from '../../models/RoadSectionGridViewData';

interface CustomCheckboxCellProperties extends GridCellProps {
    onCheckboxSelected: (roadSectionId: number, checked: boolean) => void
}

export const CustomCheckboxCell = (props: CustomCheckboxCellProperties) => {

    let dataItem = props.dataItem as RoadSectionGridViewData;

    return (
        <td >
            <Checkbox onChange={(event) => props.onCheckboxSelected(dataItem.roadSectionId, event.value)} checked={dataItem.isSelected} className='kendo-checkbox'></Checkbox>
        </td>
    );
}