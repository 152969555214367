import { GridCellProps } from "@progress/kendo-react-grid";
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { RoadTrunkLabelScoreExtended } from "../../models/RoadTrunkLabelScoreExtended";

interface CustomRoadCheckboxCellProps {
    handleRoadClick: (e: React.ChangeEvent<HTMLInputElement>, dataItem: RoadTrunkLabelScoreExtended) => void
}

export const CustomRoadCheckbox = (props: GridCellProps & CustomRoadCheckboxCellProps): JSX.Element => {

    let roadCheckboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        let isAllSectionsSelected = false;
        let isAllSectionsUnSelected = false;

        let visibleSections = props.dataItem.sections.filter(x => x.isVisible || x.isSelected);
        if (visibleSections.length === 0) {
            isAllSectionsSelected = false;
            isAllSectionsUnSelected = true;
        }
        else {
            isAllSectionsSelected = visibleSections.every(x => x.isSelected === true);
            isAllSectionsUnSelected = visibleSections.every(x => x.isSelected === false);
        }

        let isIndeterminate = isAllSectionsSelected || isAllSectionsUnSelected ? false : true;
        roadCheckboxRef.current.indeterminate = isIndeterminate;
        roadCheckboxRef.current.checked = isAllSectionsSelected;
    }, []);

    const defaultRendering = (
        <td>
            <input ref={roadCheckboxRef} type="checkbox" className="k-checkbox" onChange={(e) => props.handleRoadClick(e, props.dataItem)} />
        </td>
    );

    return defaultRendering;
}