import Translate from "../../../localization/Localization";
import { QualityKind } from "../services/dataContracts/queryStack/QualityKind";
import { WorkPriority } from "../services/dataContracts/queryStack/WorkPriority";

export interface WorkPriorityViewData {
    quality: QualityKind,
    importanceOneValue: string,
    importanceTwoValue: string,
    importanceThreeValue: string,
    importanceFourValue: string,
    importanceFiveValue: string
}

export const buildWorkPrioritiesViewData = (importanceWorkPriorities: WorkPriority[]): WorkPriorityViewData[] => {
    let workPrioritiesViewData: WorkPriorityViewData[] = [];

    let rehabilitationImportanceWorkPriorities = importanceWorkPriorities.filter(x => x.quality === QualityKind.rehabilitation);
    let rehabilitationImportanceWorkPrioritiesViewDataRow = getWorkPrioritiesDataRowByQuality(rehabilitationImportanceWorkPriorities, QualityKind.rehabilitation);
    workPrioritiesViewData.push(rehabilitationImportanceWorkPrioritiesViewDataRow);

    let reinforcementImportanceWorkPriorities = importanceWorkPriorities.filter(x => x.quality === QualityKind.reinforcement);
    let reinforcementImportanceWorkPrioritiesViewDataRow = getWorkPrioritiesDataRowByQuality(reinforcementImportanceWorkPriorities, QualityKind.reinforcement);
    workPrioritiesViewData.push(reinforcementImportanceWorkPrioritiesViewDataRow);

    let generalMaintenanceImportanceWorkPriorities = importanceWorkPriorities.filter(x => x.quality === QualityKind.generalMaintenance);
    let generalMaintenanceImportanceWorkPrioritiesViewDataRow = getWorkPrioritiesDataRowByQuality(generalMaintenanceImportanceWorkPriorities, QualityKind.generalMaintenance);
    workPrioritiesViewData.push(generalMaintenanceImportanceWorkPrioritiesViewDataRow);

    let localizedRepairImportanceWorkPriorities = importanceWorkPriorities.filter(x => x.quality === QualityKind.localizedRepair);
    let localizedRepairImportanceWorkPrioritiesViewDataRow = getWorkPrioritiesDataRowByQuality(localizedRepairImportanceWorkPriorities, QualityKind.localizedRepair);
    workPrioritiesViewData.push(localizedRepairImportanceWorkPrioritiesViewDataRow);

    let monitoringImportanceWorkPriorities = importanceWorkPriorities.filter(x => x.quality === QualityKind.monitoring);
    let monitoringImportanceWorkPrioritiesViewDataRow = getWorkPrioritiesDataRowByQuality(monitoringImportanceWorkPriorities, QualityKind.monitoring);
    workPrioritiesViewData.push(monitoringImportanceWorkPrioritiesViewDataRow);

    return workPrioritiesViewData;
}

const getWorkPrioritiesDataRowByQuality = (qualityImportanceWorkPriorities: WorkPriority[], quality: QualityKind): WorkPriorityViewData => {
    let importanceOneValue: string = null;
    let importanceTwoValue: string = null;
    let importanceThreeValue: string = null;
    let importanceFourValue: string = null;
    let importanceFiveValue: string = null;

    qualityImportanceWorkPriorities.forEach((item: WorkPriority) => {
        let value = item.value ? item.value.toFixedLocalized(2, 2) : null;

        if (item.importance === 1) {
            importanceOneValue = value;
        }

        if (item.importance === 2) {
            importanceTwoValue = value;
        }

        if (item.importance === 3) {
            importanceThreeValue = value;
        }

        if (item.importance === 4) {
            importanceFourValue = value;
        }

        if (item.importance === 5) {
            importanceFiveValue = value;
        }
    });

    return {
        quality: quality,
        importanceOneValue,
        importanceTwoValue,
        importanceThreeValue,
        importanceFourValue,
        importanceFiveValue
    };
}

const getQualityList = (): { text: string, value: QualityKind, workPrioritiesGridCssValue: string }[] => {
    return [
        { text: Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_Monitoring, value: QualityKind.monitoring, workPrioritiesGridCssValue: "monitoring" },
        { text: Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_GeneralMaintenance, value: QualityKind.generalMaintenance, workPrioritiesGridCssValue: "general-maintenance" },
        { text: Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_LocalizedRepair, value: QualityKind.localizedRepair, workPrioritiesGridCssValue: "localized-repair" },
        { text: Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_Reinforcement, value: QualityKind.reinforcement, workPrioritiesGridCssValue: "reinforcement" },
        { text: Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_Rehabilitation, value: QualityKind.rehabilitation, workPrioritiesGridCssValue: "rehabilitation" }
    ];
}

export const getQualityText = (quality: QualityKind) => {
    let qualityRow = getQualityList().find(x => x.value === quality);
    return qualityRow ? qualityRow.text : quality;
}

export const getQualityWorkPrioritiesGridCssValue = (quality: QualityKind) => {
    let qualityRow = getQualityList().find(x => x.value === quality);
    return qualityRow ? qualityRow.workPrioritiesGridCssValue : '';
}