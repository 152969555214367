import axios, { AxiosResponse } from 'axios';
import buildinfo from '../../../config/buildinfo.json';
import { Image } from './dataContracts/queryStack/Image';

const buildNumber: string = buildinfo.build.buildNumber;

const controllerUrl = 'api/GPSControl/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class GPSControlApiClient {

    public static GetPerAuscultationImages = (projectVersionId: number)
        : Promise<AxiosResponse<Map<number, Image[]>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerAuscultationImages?projectVersionId=${encodeURIComponent(projectVersionId)}&v=${buildNumber}`,
            headers: headers
        });
    }
}
