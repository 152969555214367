import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import React, { ChangeEvent } from 'react';
import Translate from '../../../localization/Localization';
import { Environment } from '../services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../services/RoadsCondition/dataContracts/queryStack/Manager';
import { Traffic } from '../services/RoadsCondition/dataContracts/queryStack/Traffic';
import './SectionFilterStyles.scss';

interface SectionFilterComponentProps {
    activeFilters: Set<string>,
    arrayFilters?: string[],
    listFilters?: { text: Hierarchy | Traffic | Environment | Manager | string, value: string }[],
    isOpened: boolean,
    filterTitle: string,
    hasEmptyCheck: boolean,
    setIsFilterListOpened: (isOpened: boolean) => void,
    handleAllFiltersChanged: (e: ChangeEvent<HTMLInputElement>) => void,
    handleActiveFilterChanged: (filter: string, isActive: boolean) => void
}

export const SectionFilterComponent = (props: SectionFilterComponentProps): JSX.Element => {

    const activeFiltersSize = props.activeFilters.size;
    const filtersSize = props.arrayFilters ? (props.hasEmptyCheck ? props.arrayFilters.length + 1 : props.arrayFilters.length) :
        (props.listFilters ? (props.hasEmptyCheck ? props.listFilters.length + 1 : props.listFilters.length) : null);

    return (
        <Box className="section-filter">
            <Box className="details-header" display="flex" flexDirection="row" justifyContent="space-between">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={activeFiltersSize === filtersSize}
                            indeterminate={activeFiltersSize > 0 && activeFiltersSize < filtersSize}
                            onChange={(e) => props.handleAllFiltersChanged(e)}
                        />
                    }
                    label={
                        <Box className="header-checkbox">
                            <div>{props.filterTitle}</div>
                            <div className={`counter ${activeFiltersSize > 0 ? 'filled' : ''}`}>{activeFiltersSize ?? ''}</div>
                        </Box>
                    }
                    className="form-control-label-checkbox"
                />
                {props.isOpened ? <ExpandLessIcon onClick={() => props.setIsFilterListOpened(false)} className="expand-icon" /> :
                    <ExpandMoreIcon onClick={() => props.setIsFilterListOpened(true)} className="expand-icon" />}
            </Box>
            {props.isOpened &&
                <Box className="details-content">
                    {props.arrayFilters ? (props.arrayFilters.sort((a, b) => a.localeCompare(b)).map((filter, index) => {
                        let isActive = props.activeFilters.has(filter);
                        return (
                            <FormControlLabel
                                key={`filter-${index}`}
                                control={<Checkbox checked={isActive} onChange={() => props.handleActiveFilterChanged(filter, isActive)} />}
                                label={filter}
                                className="form-control-label-checkbox"
                            />
                        )
                    })) : ''}

                    {props.listFilters ? Object.keys(props.listFilters).map((e, index) => {
                        let isActive = props.activeFilters.has(props.listFilters[e].value);
                        return (
                            <FormControlLabel
                                key={`filter-${index}`}
                                control={<Checkbox checked={isActive} onChange={() => props.handleActiveFilterChanged(props.listFilters[e].value, isActive)} />}
                                label={props.listFilters[e].text}
                                className="form-control-label-checkbox"
                            />
                        )
                    }) : ''}

                    {props.hasEmptyCheck &&
                        <FormControlLabel
                            control={<Checkbox checked={props.activeFilters.has(null)} onChange={() => props.handleActiveFilterChanged(null, props.activeFilters.has(null))} />}
                            label={Translate.Resources.UI_ActionsMenu_Empty}
                            className="form-control-label-checkbox"
                        />
                    }
                </Box>
            }
        </Box>
    )
}