import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Dialog, DialogContent, NativeSelect } from '@mui/material';
import { Box } from '@mui/system';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Translate from '../../../../localization/Localization';
import { HigherProjectVersion } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/HigherProjectVersion';
import { ScenariosApiClient } from '../../services/ScenariosApiClient';
import './MigrateScenarioDialogStyles.scss';

interface dropdownItem {
    label: string,
    value: number
}

interface MigrateScenarioDialogProps {
    isMigrateScenarioDialogOpened: boolean,
    scenarioLabel: string,
    projectId: string,
    currentProjectVersionId: number,
    selectedProjectVersion: number,
    handleMigrateCanceled: (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void,
    handleMigrateConfirmed: (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void,
    handleSelectedProjectVersionChanged: (selectedProjectVersion: { projectVersionId: number, projectVersionNumber: number }) => void
}

export const MigrateScenarioDialog = (props: MigrateScenarioDialogProps): JSX.Element => {

    const [projectHigherVersions, setProjectHigherVersions] = useState<dropdownItem[]>([]);
    const [projectVersions, setProjectVersions] = useState<Map<number, HigherProjectVersion>>(new Map<number, HigherProjectVersion>());

    useEffect(() => {
        ScenariosApiClient.GetProjectHigherVersions(props.projectId, props.currentProjectVersionId)
            .then((res) => {
                let list: dropdownItem[] = [];
                let projectVersionsMap = new Map<number, HigherProjectVersion>();
                let data = res.data;
                data.forEach((projectVersion) => {
                    let projectAuscultationsArray = orderBy(projectVersion.auscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']);
                    let surveyTo = projectAuscultationsArray ? new Date(projectAuscultationsArray[projectAuscultationsArray.length - 1].videoDateTime).toLocaleDateString() : '';
                    list.push({ label: `${surveyTo} (V${projectVersion.versionNumber})`, value: projectVersion.projectVersionId });
                    projectVersionsMap.set(projectVersion.projectVersionId, projectVersion);
                });

                setProjectHigherVersions(list);
                setProjectVersions(projectVersionsMap);
            })
    }, [])

    const handleProjectVersionSelected = (e: ChangeEvent<HTMLSelectElement>): void => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();


        if (e.target.value) {
            let selectedProjectVersionId = Number(e.target.value);
            let projectVersion = projectVersions.get(selectedProjectVersionId);

            props.handleSelectedProjectVersionChanged({
                projectVersionId: selectedProjectVersionId,
                projectVersionNumber: projectVersion.versionNumber
            });
        }
        else {
            props.handleSelectedProjectVersionChanged(null);
        }

    }

    return (
        <Dialog id="migrate-scenario-dialog" open={props.isMigrateScenarioDialogOpened} onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }}>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" flexDirection="row" className="title" alignItems="center">
                        <ReplayIcon className="migrate-icon" />
                        <div>{Translate.Resources.UI_Scenarios_ScenarioCard_Menu_MigrateScenarioDialog_StatementMigration}</div>
                    </Box>
                    <Box className="text">
                        {`${Translate.Resources.UI_Scenarios_ScenarioCard_Menu_MigrateScenarioDialog_YourScenario} '${props.scenarioLabel}' ${Translate.Resources.UI_Scenarios_ScenarioCard_Menu_MigrateScenarioDialog_WillBeDuplicatedAndUpdated}`}
                    </Box>
                    <Box className="list">
                        <NativeSelect
                            className="select"
                            value={props.selectedProjectVersion ?? ""}
                            onChange={(e) => handleProjectVersionSelected(e)}
                        >
                            <option value=""></option>
                            {projectHigherVersions.map((projectVersion, index: number) => {
                                return (
                                    <option key={`project-version-option-${index}`} value={projectVersion.value}>
                                        {projectVersion.label}
                                    </option>
                                )
                            })}
                        </NativeSelect>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                        <Button className="btn-secondary" onClick={(e) => props.handleMigrateCanceled(e)}>
                            {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_MigrateScenarioDialog_Button_Cancel}
                        </Button>
                        <Button className="btn-primary" onClick={(e) => props.handleMigrateConfirmed(e)} disabled={!props.selectedProjectVersion}>
                            {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_MigrateScenarioDialog_Button_Validate}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}