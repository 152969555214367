import { GridCellProps, GridRowProps } from "@progress/kendo-react-grid";
import * as React from "react";
import { ProgrammingArea } from "../../services/dataContracts/queryStack/ProgrammingArea";

interface CellRenderProps {
    originalProps: GridCellProps;
    td: React.ReactElement<HTMLTableCellElement>;
    enterEdit: (dataItem: ProgrammingArea, field: string | undefined) => void;
    editField: string | undefined;
}

interface RowRenderProps {
    originalProps: GridRowProps;
    tr: React.ReactElement<HTMLTableRowElement>;
    exitEdit: () => void;
    editField: string | undefined;
}

export const CellRender = (props: CellRenderProps) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ""];
    const additionalProps =
        cellField && cellField === inEditField
            ? {
                ref: (td) => {
                    const input = td && td.querySelector("input");
                    const activeElement = document.activeElement;

                    if (
                        !input ||
                        !activeElement ||
                        input === activeElement ||
                        !activeElement.contains(input)
                    ) {
                        return;
                    }

                    if (input.type === "checkbox") {
                        input.focus();
                    } else {
                        input.select();
                    }
                },
            }
            : {
                onClick: () => {
                    props.enterEdit(dataItem, cellField);
                },
            };

    const clonedProps: any = { ...props.td.props, ...additionalProps };
    const childNodes: any = props.td.props.children;
    return React.cloneElement(props.td, clonedProps, childNodes);
}

export const RowRender = (props: RowRenderProps) => {
    let preventExit = false;
    const trProps = {
        ...props.tr.props,
        onMouseDown: () => {
            preventExit = true;
        },
        onBlur: () => {
            if (!preventExit) {
                props.exitEdit();
            }
            preventExit = false;
        },
    };
    const childNodes: any = props.tr.props.children;
    return React.cloneElement(props.tr, { ...trProps }, childNodes);
}