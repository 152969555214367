import { Box, Button, Checkbox, ClickAwayListener, FormControlLabel, Popper } from '@mui/material';
import React, { useState } from 'react';
import Translate from '../../../localization/Localization';
import { QualityType } from '../models/QualityType';
import { RoadsConditionAndScenariosShared } from '../RoadsConditionAndScenariosShared';
import './QualityListFilterStyles.scss';

interface QualitiesGroupItemProps {
    qualityType: QualityType,
    qualityLabel: string,
    qualitiesScores: number[],
    activeQualities: Set<number>,
    handleQualityChanged: (qualityNote: number, checked: boolean) => void,
    handleQualityGroupChanged: (qualityType: QualityType, checked: boolean) => void
}

const QualitiesGroupItem = (props: QualitiesGroupItemProps) => {

    let isAllGroupQualitiesChecked = props.qualitiesScores.every(x => props.activeQualities.has(x));
    let isSomeGroupQualitiesChecked = props.qualitiesScores.some(x => props.activeQualities.has(x));

    return (
        <Box display="flex" flexDirection="column" className="quality-item">
            <Box className="item">
                <FormControlLabel control={<Checkbox checked={isAllGroupQualitiesChecked} indeterminate={!isAllGroupQualitiesChecked && isSomeGroupQualitiesChecked} onChange={(e) => props.handleQualityGroupChanged(props.qualityType, e.target.checked)} />}
                    label={
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <div className={`quality-color ${props.qualityType}`}></div>
                            <div>{props.qualityLabel}</div>
                        </Box>
                    }
                    className="form-control-label-checkbox" />
            </Box>
            <Box className="scores-notes">
                {props.qualitiesScores.map((score, index) => {
                    return (
                        <FormControlLabel key={`quality-${score}-${index}`} control={<Checkbox checked={props.activeQualities.has(score)} onChange={(e) => props.handleQualityChanged(score, e.target.checked)} />} label={score} className="form-control-label-checkbox" />
                    )
                })}
            </Box>
        </Box>
    );
}

interface QualityListFilterComponentProps {
    open: boolean,
    anchorEl: null | HTMLElement,
    activeQualities: Set<number>,
    onClose: () => void,
    handleDisplaySections: (activeQualities: Set<number>) => void
}

export const QualityListFilterComponent = (props: QualityListFilterComponentProps): JSX.Element => {
    const qualitiesScores = RoadsConditionAndScenariosShared.QualitiesScores;

    const monitoringQualities = qualitiesScores.get(QualityType.monitoring);
    const localizedRepairQualities = qualitiesScores.get(QualityType.localizedRepair);
    const generalMaintenanceQualities = qualitiesScores.get(QualityType.generalMaintenance);
    const reinforcementQualities = qualitiesScores.get(QualityType.reinforcement);
    const rehabilitationQualities = qualitiesScores.get(QualityType.rehabilitation);

    const qualitiesCount = (monitoringQualities.length + localizedRepairQualities.length + generalMaintenanceQualities.length + reinforcementQualities.length + rehabilitationQualities.length + 1);

    const [activeQualities, setActiveQualities] = useState<Set<number>>(props.activeQualities);

    const handleQualityChanged = (score: number, checked: boolean): void => {
        let activeQualitiesSet = new Set(activeQualities);
        if (checked) {
            activeQualitiesSet.add(score);
        }
        else {
            activeQualitiesSet.delete(score);
        }
        setActiveQualities(activeQualitiesSet);
    }

    const handleQualityGroupChanged = (qualityType: QualityType, checked: boolean): void => {
        let activeQualitiesSet = new Set(activeQualities);

        const qualities = qualitiesScores.get(qualityType);
        qualities.forEach(score => {
            if (checked)
                activeQualitiesSet.add(score);
            else
                activeQualitiesSet.delete(score);
        });

        setActiveQualities(activeQualitiesSet);
    }

    const handleAllQualitiesChanged = (checked: boolean): void => {
        let activeQualitiesSet = new Set<number>([]);

        if (checked) {
            monitoringQualities.forEach(score => activeQualitiesSet.add(score));
            localizedRepairQualities.forEach(score => activeQualitiesSet.add(score));
            generalMaintenanceQualities.forEach(score => activeQualitiesSet.add(score));
            reinforcementQualities.forEach(score => activeQualitiesSet.add(score));
            rehabilitationQualities.forEach(score => activeQualitiesSet.add(score));
            activeQualitiesSet.add(null);
        }

        setActiveQualities(activeQualitiesSet);
    }

    const handleValidate = (): void => {
        props.handleDisplaySections(activeQualities);
        props.onClose();
    }

    return (
        <ClickAwayListener onClickAway={props.onClose}>
            <Popper open={props.open} anchorEl={props.anchorEl} className="poper-menu quality-list-filter">
                <Box display="flex" flexDirection="column">
                    <Box className="quality-header">
                        <FormControlLabel
                            control={
                                <Checkbox checked={activeQualities.size === qualitiesCount}
                                    indeterminate={activeQualities.size > 0 && activeQualities.size < qualitiesCount}
                                    onChange={(e) => handleAllQualitiesChanged(e.target.checked)}
                                />}
                            label={
                                <Box className="label">
                                    <span className="popper-title">{Translate.Resources.UI_ActionsMenu_QualityIndicators}</span>
                                    <Box className={`counter ${activeQualities.size > 0 ? 'filled' : ''}`}>{activeQualities.size ?? ''}</Box>
                                </Box>
                            }
                            className="form-control-label-checkbox" />
                    </Box>
                    <Box className="popper-content">
                        <Box display="flex" flexDirection="column" className="quality-list">
                            <QualitiesGroupItem
                                qualityType={QualityType.monitoring}
                                qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Monitoring}
                                qualitiesScores={qualitiesScores.get(QualityType.monitoring)}
                                activeQualities={activeQualities}
                                handleQualityChanged={handleQualityChanged}
                                handleQualityGroupChanged={handleQualityGroupChanged}
                            />
                            <QualitiesGroupItem
                                qualityType={QualityType.localizedRepair}
                                qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}
                                qualitiesScores={qualitiesScores.get(QualityType.localizedRepair)}
                                activeQualities={activeQualities}
                                handleQualityChanged={handleQualityChanged}
                                handleQualityGroupChanged={handleQualityGroupChanged}
                            />
                            <QualitiesGroupItem
                                qualityType={QualityType.generalMaintenance}
                                qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}
                                qualitiesScores={qualitiesScores.get(QualityType.generalMaintenance)}
                                activeQualities={activeQualities}
                                handleQualityChanged={handleQualityChanged}
                                handleQualityGroupChanged={handleQualityGroupChanged}
                            />
                            <QualitiesGroupItem
                                qualityType={QualityType.reinforcement}
                                qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}
                                qualitiesScores={qualitiesScores.get(QualityType.reinforcement)}
                                activeQualities={activeQualities}
                                handleQualityChanged={handleQualityChanged}
                                handleQualityGroupChanged={handleQualityGroupChanged}
                            />
                            <QualitiesGroupItem
                                qualityType={QualityType.rehabilitation}
                                qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}
                                qualitiesScores={qualitiesScores.get(QualityType.rehabilitation)}
                                activeQualities={activeQualities}
                                handleQualityChanged={handleQualityChanged}
                                handleQualityGroupChanged={handleQualityGroupChanged}
                            />
                            <QualitiesGroupItem
                                qualityType={QualityType.empty}
                                qualityLabel={Translate.Resources.UI_ActionsMenu_Empty}
                                qualitiesScores={[null]}
                                activeQualities={activeQualities}
                                handleQualityChanged={handleQualityChanged}
                                handleQualityGroupChanged={handleQualityGroupChanged}
                            />
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" className="actions-button">
                            <Button className="btn-cancel" onClick={() => props.onClose()}>
                                {Translate.Resources.UI_Global_Cancel}
                            </Button>
                            <Button className="btn-validate" onClick={() => handleValidate()}>
                                {Translate.Resources.UI_Global_Validate}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popper >
        </ClickAwayListener>
    )
}