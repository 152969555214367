import { useMatomo } from '@datapunt/matomo-tracker-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import allProjectsVersionsIcon from 'src/assets/icons/icon-all-projects-versions.svg';
import iconArrow from 'src/assets/icons/icon-arrow.svg';
import helpIcon from 'src/assets/icons/icon-help.svg';
import userIcon from 'src/assets/icons/icon-user.svg';
import { AlgorithmsModelVersionInfoDialog } from '../shared/components/AlgorithmsModelVersionInfoDialog/AlgorithmsModelVersionInfoDialog';
import AllProjectsVersionsDialog from '../shared/components/AllProjectsVersionsDialog/AllProjectsVersionsDialog';
import { HelpDialogContentComponent } from '../shared/components/HelpDialogContentComponent/HelpDialogContentComponent';
import NavMenu from '../shared/components/NavMenu/NavMenu';
import { UserDialog } from '../shared/components/UserDialog/UserDialog';
import { MeasurementSystemType } from '../shared/models/MeasurementSystemType';
import { UserModel } from '../shared/User/services/dataContracts/queryStack/UserModel';
import { RouteComponentProps, withRouter } from '../withRouter';
import { ProjectVersion } from './Home/services/dataContracts/queryStack/ProjectVersion';
import './Layout.scss';
import { RouteLocationStateModel } from './RoadsCondition/models/RouteLocationStateModel';

interface LayoutProps {
    user: UserModel,
    projects: ProjectVersion[],
    children: JSX.Element | JSX.Element[],
    handleMeasurementSystemTypeChanged: (measurementSystemType: MeasurementSystemType) => void
}

const Layout = (props: RouteComponentProps & LayoutProps): JSX.Element => {

    const location = useLocation();
    let pathname = location.pathname.trim().toLowerCase()
    if (pathname !== "/" && !pathname.endsWith("/")) {
        pathname = pathname + "/";
    }
    let shouldDisplayProjectHeader = pathname !== "/"
        && pathname !== "/usersadmin/"
        && pathname !== "/scenariosectionsmanagement/" && pathname !== "/scenariosectionsvisualisation/"
        && pathname !== "/programmingareasmanagement/";

    const { trackPageView } = useMatomo();

    const [isAllProjectsVersionsDialogOpen, setIsProjectsVersionsDialogOpen] = useState<boolean>(false);
    const [isUserDialogOpen, setIsUserDialogOpen] = useState<boolean>(false);
    const [isHelpDialogOpen, setIsHelpDialogOpen] = useState<boolean>(false);
    const [isAlgorithmsModelVersionInfoDialogOpen, setIsAlgorithmsModelVersionInfoDialogOpen] = useState<boolean>(false);

    let locationState = (props.location.state as RouteLocationStateModel);
    //juste pour le POC
    if (!locationState) {
        let routeLocationState = sessionStorage.getItem("routeLocationState");
        locationState = JSON.parse(routeLocationState) as RouteLocationStateModel;
    }

    const projectId = locationState?.projectId;
    const projectVersionId = locationState?.projectVersionId;
    const project = props.projects.find(x => x.projectId === projectId && x.projectVersionId === projectVersionId);

    useEffect(() => {
        trackPageView({ href: location.pathname });
    }, [location.pathname]);

    const handleClickCloseHelpDialog = (): void => setIsHelpDialogOpen(false);
    const handleClickOpenHelpDialog = (): void => setIsHelpDialogOpen(true);

    const handleClickOpenProjectsVersionsDialog = (): void => setIsProjectsVersionsDialogOpen(true);
    const handleValidateProjectsVersionsDialog = (): void => setIsProjectsVersionsDialogOpen(false);
    const handleClickCancelProjectsVersionsDialog = (): void => setIsProjectsVersionsDialogOpen(false);

    const handleClickOpenUserDialog = (): void => setIsUserDialogOpen(true);
    const handleValidateUserDialog = (measurementSystemType: MeasurementSystemType): void => {
        setIsUserDialogOpen(false);
        props.handleMeasurementSystemTypeChanged(measurementSystemType);
    }
    const handleClickCancelUserDialog = (): void => setIsUserDialogOpen(false);

    const handleToggleAlgorithmsModelVersionInfoDialog = () => setIsAlgorithmsModelVersionInfoDialogOpen(!isAlgorithmsModelVersionInfoDialogOpen);

    return (
        <Container>
            <Box display="flex" flexDirection="row" width="100vw" height="100vh">
                <NavMenu role={props.user?.role} />
                <Box display="flex" flexDirection="column" className={`content ${pathname === "/" ? 'home' : ''}`}>
                    {shouldDisplayProjectHeader &&
                        <Box display="flex" flexDirection="row" className="project-header">
                            <img src={iconArrow} alt="icon header" />
                            <div className="project-label">
                                {project?.label}
                            </div>
                            <IconButton className={props.user.role === "ADM" ? "" : "project-infobutton--hidden"} onClick={handleToggleAlgorithmsModelVersionInfoDialog} >
                                <InfoOutlinedIcon className={isAlgorithmsModelVersionInfoDialogOpen ? "project-infobutton--active" : "project-infobutton--inactive"} />
                            </IconButton>
                        </Box>
                    }
                    <Box className={`child ${!shouldDisplayProjectHeader ? 'home' : ''} `}>
                        {props.children}
                    </Box>
                </Box>
            </Box>
            <div className="commmon-component">
                {props.user?.role === "ADM" &&
                    <IconButton className="header-icon" onClick={handleClickOpenProjectsVersionsDialog}>
                        <img src={allProjectsVersionsIcon} alt="all projects versions icon" />
                    </IconButton>
                }
                <IconButton className="header-icon" onClick={handleClickOpenHelpDialog}>
                    <img src={helpIcon} alt="help icon" />
                </IconButton>
                <IconButton className="header-icon" onClick={handleClickOpenUserDialog}>
                    <img src={userIcon} alt="user icon" />
                </IconButton>
            </div>
            {isHelpDialogOpen &&
                <HelpDialogContentComponent isOpen={isHelpDialogOpen} handleClickCloseHelpDialog={handleClickCloseHelpDialog} />
            }
            {isAllProjectsVersionsDialogOpen &&
                <AllProjectsVersionsDialog {...props} projects={props.projects} onValidate={handleValidateProjectsVersionsDialog} isOpen={isAllProjectsVersionsDialogOpen} onCancel={handleClickCancelProjectsVersionsDialog} />
            }
            {isUserDialogOpen &&
                <UserDialog isOpen={isUserDialogOpen} user={props.user} onValidate={handleValidateUserDialog} onCancel={handleClickCancelUserDialog} />
            }
            {isAlgorithmsModelVersionInfoDialogOpen &&
                <AlgorithmsModelVersionInfoDialog
                    auscultations={project?.auscultations}
                    projectLabel={project?.label}
                    versionNumber={project?.versionNumber}
                    isOpen={isAlgorithmsModelVersionInfoDialogOpen}
                    toggleIsDialogOpen={handleToggleAlgorithmsModelVersionInfoDialog}
                />
            }
        </Container>
    );
}

export default React.forwardRef(withRouter(Layout));