import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { MetersOrYardsRoundedCell } from '../../../../shared/GridUtils/GridUtils';
import { ScoreTypes } from '../../../../shared/models/ScoreTypes';
import { RoadTrunkLabelScoreExtended } from '../../models/RoadTrunkLabelScoreExtended';
import './SectionsSelectorStyles.scss';

interface DetailsRoadGridComponentProps {
    dataItem: RoadTrunkLabelScoreExtended,
    handleSelectSectionChanged: (checked: boolean, roadSectionId: number) => void
}

export const DetailsRoadGridComponent = (props: DetailsRoadGridComponentProps) => {
    let dataSections = props.dataItem.sections.filter(x => x.isVisible || x.isSelected);

    return (
        <Grid
            className="sections-details-grid"
            data={dataSections}
            rowHeight={36}
        >
            <GridColumn field="score" width="8px"
                cell={(properties: GridCellProps) => <td className={`score-color ${properties.dataItem.scoreType === ScoreTypes.monitoring ? 'monitoring' :
                    (properties.dataItem.scoreType === ScoreTypes.localizedRepair ? 'localized-repair' :
                        (properties.dataItem.scoreType === ScoreTypes.generalMaintenance ? 'general-maintenance' :
                            (properties.dataItem.scoreType === ScoreTypes.reinforcement ? 'reinforcement' :
                                (properties.dataItem.scoreType === ScoreTypes.rehabilitation ? 'rehabilitation' : ''))))}`}>
                </td>}
            />
            <GridColumn field="isSelected" width="50px"
                cell={(_props: GridCellProps) =>
                    <td >
                        <input type="checkbox" className="k-checkbox" checked={_props.dataItem.isSelected} onChange={(e) => props.handleSelectSectionChanged(e.target.checked, _props.dataItem.roadSectionId)} />
                    </td>}
            />
            <GridColumn field="roadSectionId" width="210px"
                cell={(properties: GridCellProps) => <td>{`Section ${properties.dataItem.roadSectionId}`}</td>} />
            <GridColumn field="score" width="100px"
                cell={(properties: GridCellProps) => <td className="column-number">{properties.dataItem.score}</td>} />
            <GridColumn field="lengthInMeters" width="130" cell={MetersOrYardsRoundedCell} />
        </Grid>
    )
}