export { }

declare global {

    interface Number {
        toFixedLocalized(fixedDigits?: number, minimumFractionDigits?: number): string;
    }
}

Number.prototype.toFixedLocalized = function (fixedDigits: number = 2, minimumFractionDigits: number = 0) {
    return this.toLocaleString(undefined, { 'minimumFractionDigits': minimumFractionDigits, 'maximumFractionDigits': fixedDigits });
};
