//SYNC HGA with RoadsConditionController.cs _roadTypesWidthInMeters and AuscultationImporter.cs _precalculatedRoadSectionAttributes
export const roadTypesWidthInMeters: Map<string, number> = new Map<string, number>([
    ['abandoned', 0.1],
    ['bridleway', 3],
    ['bus_guideway', 4],
    ['busway', 4],
    ['construction', 0.1],
    ['corridor', 0.1],
    ['cycleway', 3],
    ['elevator', 0.1],
    ['emergency_bay', 5],
    ['escalator', 0.1],
    ['escape', 5],
    ['footway', 5],
    ['living_street', 3],
    ['motorway_link', 4],
    ['path', 3],
    ['pedestrian', 5],
    ['planned', 0.1],
    ['platform', 0.1],
    ['primary', 8],
    ['primary_link', 8],
    ['proposed', 3],
    ['raceway', 5],
    ['residential', 5],
    ['road', 0.1],
    ['secondary', 7],
    ['secondary_link', 7],
    ['service', 5],
    ['steps', 0.1],
    ['tertiary', 6],
    ['tertiary_link', 5],
    ['track', 4],
    ['trunk', 8],
    ['trunk_link', 5],
    ['unclassified', 5],
    ['via_ferrata', 0.1],
    ['Motorway', 10],
    ['services', 5],
    ['rest_area', 5],
    ['autres', 0.1]
]);