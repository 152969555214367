import { CustomMapChoice } from 'src/shared/models/CustomMapChoice';
import Translate from 'src/localization/Localization';
import iconMapStyleOpenStreetMap from '../../../../assets/icons/mapstyle-osm_carto.png';
import iconMapStyleHybrid from '../../../../assets/icons/mapstyle-satellite_road_labels.png';

export class MapStyleSelectorResources {
  private static mapStyleIcons = new Map<CustomMapChoice, string>([
    [CustomMapChoice.OpenStreetMap, iconMapStyleOpenStreetMap],
    [CustomMapChoice.AzureMapStyleHybrid, iconMapStyleHybrid],
  ]);

  public static GetIconData(mapChoice: CustomMapChoice): string {
    return this.mapStyleIcons.get(mapChoice) ?? iconMapStyleOpenStreetMap;
  }

  public static GetLabel(mapChoice: string): string {
    const key = `UI_MapStyle_${mapChoice}`;
    return Translate.Resources[key] ?? `!! ${key} !!`;
  }
}