import axios, { AxiosResponse } from 'axios';
import { CommandResult } from '../../../shared/models/CommandResult';
import { AddOrUpdateUserRequestArgs } from './dataContracts/controller/AddOrUpdateUserRequestArgs';
import { UserSignupInvitationRequestArgs } from './dataContracts/controller/UserSignupInvitationRequestArgs';
import { CustomerModel } from './dataContracts/queryStack/CustomerModel';
import { UserModel } from './dataContracts/queryStack/UserModel';

const controllerUrl = 'api/UsersAdmin/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class UsersAdminApiClient {

    public static GetUsers = ()
        : Promise<AxiosResponse<Array<UserModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetUsers`,
            headers: headers
        });
    }

    public static GetCustomers = ()
        : Promise<AxiosResponse<Array<CustomerModel>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetCustomers`,
            headers: headers
        });
    }

    public static CheckUserHasScenarios = (userId: string)
        : Promise<AxiosResponse<boolean>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}CheckUserHasScenarios?userId=${encodeURIComponent(userId)}`,
            headers: headers
        });
    }

    public static SendSignupInvitation = (requestArgs: UserSignupInvitationRequestArgs)
        : Promise<AxiosResponse<number>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'SendSignupInvitation',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static AddUser = (requestArgs: AddOrUpdateUserRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'AddUser',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateUser = (requestArgs: AddOrUpdateUserRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: controllerUrl + 'UpdateUser',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

}
