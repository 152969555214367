import { AnimationOptions, AuthenticationType, CameraBoundsOptions, control, ControlPosition, ControlStyle, data, layer, Map as AzureMap, Shape, source } from 'azure-maps-control';
import { SettingsProvider } from '../../SettingsProvider';
import { AppModule, LocalStorage } from '../../utils/Storage';
import { MergedProjectVersion } from '../../views/RoadsCondition/models/MergedProjectVersion';
import styles2 from '../../_variables2.scss';
import { CustomMapChoice } from '../models/CustomMapChoice';
import { MapCursorMode } from "../models/MapCursorMode";
import { Point } from '../models/Point';

export const cameraAnimationType = "fly";
export const cameraAnimationDuration = 500;
export const transparentColor = 'transparent';

export const sectionWidth = 6;
export const enlightenedAreaWidth = 18;
export const areaWidth = 6;
export const selectedSectionWidth = 18;

export const createShape = (coordinates: data.Position[], id: number | string, strokeColor: string, strokeWidth: number, entityType: string, roadSectionId: number, sectionScoreType?: string, sectionScore?: number, municipality?: string, district?: string, collaborativeDevelopmentZone?: string, areaProps?: any): Shape =>
    new Shape(new data.LineString(coordinates), id, { strokeColor, strokeWidth, EntityType: entityType, RoadSectionId: roadSectionId, sectionScoreType: sectionScoreType ?? null, sectionScore: sectionScore ?? null, municipality: municipality ?? null, district: district ?? null, collaborativeDevelopmentZone: collaborativeDevelopmentZone ?? null, areaProps: areaProps ?? null });

export const getSectionShapeId = (roadSectionId: number): string =>
    `${roadSectionId}`;

export const getLayerSectionShapeId = (roadSectionId: number, shapeId: string): string =>
    `${roadSectionId}/${shapeId}`;

export const getSelectedSectionShapeId = (roadSectionId: number): string =>
    `${roadSectionId}/selected`;

export const getEnlightenedAreaShapeId = (roadSectionId: number): string =>
    `${roadSectionId}/area/enlightened`;

export const getAreaShapeId = (roadSectionId: number): string =>
    `${roadSectionId}/area`;

export const showSectionSelectedShape = (datasource: source.DataSource, sectionId: number): void => {
    var shapeId = getSelectedSectionShapeId(sectionId);
    var shape: Shape = datasource.getShapeById(shapeId);
    if (shape) {
        showSectionSelected(shape);
    }
}

export const showSectionSelected = (shape: Shape): void => {
    let props = shape.getProperties();
    props.strokeColor = styles2.selectedSectionColor;
    props.strokeWidth = selectedSectionWidth;
    shape.setProperties(props);
}

export const hideSectionSelectedShape = (datasource: source.DataSource, sectionId: number): void => {
    var shapeId = getSelectedSectionShapeId(sectionId);
    var shape: Shape = datasource.getShapeById(shapeId);
    if (shape) {
        hideShape(shape);
    }
}

export const showAreaEnlightenedShape = (shapeId: string, datasource: source.DataSource): void => {
    let shape: Shape = datasource.getShapeById(shapeId);
    if (shape) {
        let props = shape.getProperties();
        props.strokeColor = styles2.enlightenedAreaColor;
        props.strokeWidth = enlightenedAreaWidth;
        shape.setProperties(props);
    }
}

export const hideAreaEnlightenedShape = (shapeId: string, datasource: source.DataSource): void => {
    let shape: Shape = datasource.getShapeById(shapeId);
    if (shape) {
        hideShape(shape);
    }
}

export const hideShape = (shape: Shape): void => {
    let props = shape.getProperties();
    props.strokeColor = transparentColor;
    props.strokeWidth = 0;
    shape.setProperties(props);
}

export const setMapCursor = (map: AzureMap, cursor: MapCursorMode): void => {
    let classList = map.getCanvasContainer().classList;

    const cursorPointerClassName = "cursor-pointer";
    const cursorAutoClassName = "cursor-auto";
    const cursorCrosshairClassName = "cursor-crosshair";
    const cursorCarClassName = "cursor-car";

    let cursorClassName = null;

    switch (cursor) {
        case MapCursorMode.Car:
            cursorClassName = cursorCarClassName;
            break;
        case MapCursorMode.Pointer:
            cursorClassName = cursorPointerClassName;
            break;
        case MapCursorMode.Crosshair:
            cursorClassName = cursorCrosshairClassName;
            break;
        case MapCursorMode.Auto:
        default:
            cursorClassName = cursorAutoClassName;
            break;
    }

    if (cursorClassName !== cursorCarClassName && classList.contains(cursorCarClassName)) {
        classList.remove(cursorCarClassName);
    }
    if (cursorClassName !== cursorAutoClassName && classList.contains(cursorAutoClassName)) {
        classList.remove(cursorAutoClassName);
    }
    if (cursorClassName !== cursorCrosshairClassName && classList.contains(cursorCrosshairClassName)) {
        classList.remove(cursorCrosshairClassName);
    }
    if (cursorClassName !== cursorPointerClassName && classList.contains(cursorPointerClassName)) {
        classList.remove(cursorPointerClassName);
    }

    if (!classList.contains(cursorClassName)) {
        classList.add(cursorClassName);
    }
}

export const ensureCustomMapChoice = (map: AzureMap, choice: CustomMapChoice) => {
    const tileLayerId = 'tileLayer';

    let tileLayer = map.layers.getLayerById(tileLayerId) as layer.TileLayer;
    if (!tileLayer) {
        tileLayer = new layer.TileLayer(
            {
                tileUrl: null,
                tileSize: 256,
                minSourceZoom: 0,
                maxSourceZoom: 19
            },
            tileLayerId);

        map.layers.add(tileLayer);
    }

    // NOTE AME : Quand une carte autre qu'Azure est sélectionnée
    // => on set le style de la carte Azure à 'blank'
    // Cela empêche le téléchargements des tiles Azure en plus des tiles de l'autre carte.
    switch (choice) {

        //OpenStreetMap's Standard tile layer : (free, funded by donations)
        //https://wiki.openstreetmap.org/wiki/Standard_tile_layer
        //Front page   : https://www.openstreetmap.org/
        //Usage policy : https://operations.osmfoundation.org/policies/tiles/
        //Compare      : https://mc.bbbike.org/mc/?num=2&mt0=mapnik&mt1=mapnik
        //See also     : https://wiki.openstreetmap.org/wiki/Tile_servers
        case CustomMapChoice.OpenStreetMap: {
            tileLayer.setOptions({ tileUrl: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png' });
            map.setStyle({ style: 'blank' });
            break;
        }

        default:
            tileLayer.setOptions({ tileUrl: null });
            map.setStyle({ style: choice });
            break;
    }
}

export const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion, padding: number): void => {
    let options: CameraBoundsOptions & AnimationOptions = {
        bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
        padding: padding,
        type: cameraAnimationType,
        duration: cameraAnimationDuration
    };

    map.setCamera(options);
}

export const createMap = (id: string, zoom: number, center: Point, mapChoice: CustomMapChoice): AzureMap => {
    let map = new AzureMap(id, {
        zoom: zoom,
        center: center ? [center.coordinates[0], center.coordinates[1]] : null,
        view: 'Auto',
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: SettingsProvider.Get().azureMapsApiKey
        }
    });

    map.events.add('ready', () => {
        ensureCustomMapChoice(map, mapChoice);

        let zoomControl = new control.ZoomControl({
            zoomDelta: 1,
            style: ControlStyle.light
        });

        map.controls.add(zoomControl, {
            position: ControlPosition.BottomRight
        });
    });

    return map;
}

export const getMapChoiceValue = (): CustomMapChoice => {
    const ModuleKey = AppModule.MapStyleSelector;
    const MapChoiceKey = 'mapChoice';

    const defaultMapChoice = CustomMapChoice.OpenStreetMap;

    let mapChoice: CustomMapChoice | any = LocalStorage.GetItem(ModuleKey, MapChoiceKey) || defaultMapChoice;
    let isMapChoice: boolean = mapChoice && Object.values(CustomMapChoice).includes(mapChoice);
    mapChoice = isMapChoice ? mapChoice : defaultMapChoice;

    return mapChoice;
}

export const setMapCameraFromPositions = (azureMap: AzureMap, positions: data.Position[]): void => {
    azureMap.setCamera({
        bounds: data.BoundingBox.fromPositions(positions),
        padding: 20,
        type: cameraAnimationType,
        duration: cameraAnimationDuration
    });
}

export const getAreaTooltip = (year: number, programmingLabel: string, areaLabel: string, typeOfWork: string): string => {
    if (programmingLabel?.trim() && areaLabel?.trim() && typeOfWork?.trim())
        return `${year} - ${programmingLabel} /  ${areaLabel} (${typeOfWork})`;

    if (programmingLabel?.trim() && areaLabel?.trim() && !typeOfWork?.trim())
        return `${year} - ${programmingLabel} / ${areaLabel}`;

    if (programmingLabel?.trim() && !areaLabel?.trim() && typeOfWork?.trim())
        return `${year} - ${programmingLabel} (${typeOfWork})`;

    if (programmingLabel?.trim() && !areaLabel?.trim() && !typeOfWork?.trim())
        return `${year} - ${programmingLabel}`;

    if (!programmingLabel?.trim() && areaLabel?.trim() && typeOfWork?.trim())
        return `${year} / ${areaLabel} (${typeOfWork})`;

    if (!programmingLabel?.trim() && areaLabel?.trim() && !typeOfWork?.trim())
        return `${year} / ${areaLabel}`;

    if (!programmingLabel?.trim() && !areaLabel?.trim() && typeOfWork?.trim())
        return `${year} - (${typeOfWork})`;

    if (!programmingLabel?.trim() && !areaLabel?.trim() && !typeOfWork?.trim())
        return null;

    return null;
}