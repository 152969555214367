import { Box } from '@mui/system';
import React from 'react';
import { ScaleLoader } from 'react-spinners';
import './PageLoaderComponentStyle.scss';

export const PageLoaderComponent = (): JSX.Element => (
    <div>
        <Box className="page-loader"></Box>
        <Box className="loader">
            <ScaleLoader width={5} height={20} radius={50} color="#000000" />
        </Box>
    </div>
);