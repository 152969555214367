import { Box } from '@mui/system';
import { AnimationOptions, CameraBoundsOptions, data, Map as AzureMap, MapMouseEvent, Popup, Shape, source } from 'azure-maps-control';
import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { barAction, MapActionBar } from '../../../../../shared/components/MapActionBar/MapActionBar';
import { ScaleLoaderComponent } from '../../../../../shared/components/ScaleLoader/ScaleLoaderComponent';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, createMap, createShape, getAreaTooltip, getMapChoiceValue, getSectionShapeId, getSelectedSectionShapeId, hideSectionSelectedShape, sectionWidth, setMapCameraFromPositions, setMapCursor, showSectionSelectedShape, transparentColor } from '../../../../../shared/Map/MapUtils';
import { MapCursorMode } from '../../../../../shared/models/MapCursorMode';
import { MeasurementSystemType } from '../../../../../shared/models/MeasurementSystemType';
import { Point } from '../../../../../shared/models/Point';
import { ShapeEntityType } from '../../../../../shared/models/ShapeEntityType';
import styles from '../../../../../_variables.scss';
import styles2 from '../../../../../_variables2.scss';
import { FilteredProgramming } from '../../../../Programmings/services/dataContracts/queryStack/FilteredProgramming';
import { ImageExtended } from '../../../../RoadsCondition/models/ImageExtended';
import { MergedProjectVersion } from '../../../../RoadsCondition/models/MergedProjectVersion';
import { ScoreTypesColors } from '../../../../RoadsCondition/models/ScoreTypesColors';
import { mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from '../../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { StepImageAnomalies } from '../../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { RoadSection } from '../../../models/RoadSection';
import './ScenarioMapStyles.scss';

interface ScenarioMapComponentProps {
    locationGeometry: Point,
    scenarioSections: Map<number, RoadSection>,
    mergedProject: MergedProjectVersion,
    filterdSectionsIds: Set<number>,
    filterdSectionsPositions: data.Position[],
    activeAnomalies: Set<string>,
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    currentMeasurementSystemType: MeasurementSystemType,
    filteredProgrammingsFromFilter: FilteredProgramming[],
    perStepImagesAnomalies: Map<number, StepImageAnomalies[]>,
    selectedSectionsIds: Set<number>,
    selectedImage: ImageExtended,
    lastSelectedSection: number,
    handleDisplayImageFromSectionClicked: (event: MapMouseEvent) => void,
    handleCLoseRoadSectionDetails: () => void
}

export const ScenarioMapComponent = (props: ScenarioMapComponentProps): JSX.Element => {

    const azureMap = useRef<AzureMap>();
    const selectedAction = useRef<barAction>();
    const areaTooltipPopup: Popup = new Popup({ closeButton: false });

    const filteredAreasDatasource = "filteredAreasDatasource";
    const filteredArea = "filteredArea";
    const filteredAreasRoadLayerId = "filteredAreasRoadLayerId";

    const [activeAnomalies, setActiveAnomalies] = React.useState<Set<string>>(new Set<string>());
    const [selectedSectionsId, setSelectedSectionsId] = React.useState<number[]>([]);

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.mergedProject && props.scenarioSections) {
            initMap(azureMap.current, () => {
                createMapSectionsShapes(props.mergedProject, props.scenarioSections);
                setMapZoom(azureMap.current, props.mergedProject);
            });
        }
    }, [props.mergedProject, props.scenarioSections]);

    useEffect(() => {
        if (props.filterdSectionsPositions.length > 0) {
            setMapCameraFromPositions(azureMap.current, props.filterdSectionsPositions);
        }
        else if (props.filterdSectionsPositions.length === 0 && props.mergedProject) {
            setMapZoom(azureMap.current, props.mergedProject);
        }
    }, [props.filterdSectionsPositions]);

    useEffect(() => {
        if (!isEqual(props.activeAnomalies, activeAnomalies)) {
            let hasAnomaliesLayerMapEvent = true;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(azureMap.current, props.mergedProject, hasAnomaliesLayerMapEvent);

            if (props.activeAnomalies.size > 0) {
                props.perStepImagesAnomalies.forEach((value, key) => {
                    let step = props.mergedProject.roadsSteps.get(key);
                    let scoringParameters = step.scoringParameters;
                    let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, props.activeAnomalies);

                    if (anomalies.size >= 1) {
                        let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                        anomaliesDatasource.add(anomalyPoint);
                    }
                });
            }

            setActiveAnomalies(props.activeAnomalies);
        }
    }, [props.activeAnomalies]);

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource) {
            datasource.getShapes().forEach((section: Shape) => {
                let properties = section.getProperties();
                let entityType = properties.EntityType;
                if (entityType === ShapeEntityType.section) {
                    let sectionId = properties.RoadSectionId;
                    let sectionView = props.mergedProject.roadsSections.get(sectionId);

                    let strokeColor = styles.unfilteredSectionColor;
                    let sectionScoreType = sectionView.scoreType;

                    if (props.filterdSectionsIds.has(sectionId)) {
                        strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
                    }

                    if (strokeColor !== properties.strokeColor) {
                        properties.strokeColor = strokeColor;
                        section.setProperties(properties);
                    }
                }
            });
        }
    }, [props.filterdSectionsIds]);

    useEffect(() => {
        removeFilteredAreasDatasourceShapes();

        if (props.filteredProgrammingsFromFilter) {
            let datasource = createFilteredAreasDatasource();

            props.filteredProgrammingsFromFilter.forEach((p) => {
                createFilteredMapAreasShapes(p, datasource);
            });
        }
    }, [props.filteredProgrammingsFromFilter])

    useEffect(() => {
        if (props.selectedSectionsIds) {
            let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
            if (datasource) {
                let unselectedSection = selectedSectionsId.filter(x => !props.selectedSectionsIds.has(x));

                unselectedSection.forEach((sectionId: number) => {
                    hideSectionSelectedShape(datasource, sectionId);
                });

                props.selectedSectionsIds.forEach((sectionId: number) => {
                    showSectionSelectedShape(datasource, sectionId);
                });
            }

            setSelectedSectionsId(Array.from(props.selectedSectionsIds.keys()));
        }
    }, [props.selectedSectionsIds]);

    useEffect(() => {
        if (props.selectedImage) {
            RoadsConditionAndScenariosShared.setImagePosition(azureMap.current, props.selectedImage, props.mergedProject);
            return;
        }

        RoadsConditionAndScenariosShared.removeCarDatasource(azureMap.current);
    }, [props.selectedImage])

    useEffect(() => {
        if (props.lastSelectedSection) {
            let roadSection = props.mergedProject.roadsSections.get(props.lastSelectedSection);
            if (roadSection) {
                let pathGeometry = roadSection.pathGeometry;
                let selectedSectionPositions: data.Position[] = [];
                pathGeometry.coordinates.forEach((position: data.Position) => {
                    selectedSectionPositions.push(position);
                });

                setMapCameraFromPositions(azureMap.current, selectedSectionPositions);
            }
        }
        else if (props.mergedProject) {
            setMapZoom(azureMap.current, props.mergedProject);
        }
    }, [props.lastSelectedSection])

    useEffect(() => {
        azureMap.current.resize("100%", "100%");
    }, [props.isSectionsDrawerOpened])

    useEffect(() => {
        return function cleanup() {
            azureMap.current?.dispose();
        }
    }, [])

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (callback) {
                callback();
            }
        });
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion, selectedScenarioSections: Map<number, RoadSection>): void => {
        let datasource = createMainDatasource();

        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let selectedSectionShapeId = getSelectedSectionShapeId(roadSectionId);
            let selectedSectionShape = createShape(coordinates, selectedSectionShapeId, transparentColor, 0, ShapeEntityType.sectionSelected, roadSectionId);
            datasource.add(selectedSectionShape);

            let sectionShapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;

            if (selectedScenarioSections.has(roadSectionId)) {
                let strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
                let sectionShape = createShape(coordinates, sectionShapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
                datasource.add(sectionShape);
            }
            else {
                let strokeColor = styles.unfilteredSectionColor;
                let sectionShape = createShape(coordinates, sectionShapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
                datasource.add(sectionShape);
            }
        });
    }

    const createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        azureMap.current.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        azureMap.current.layers.add(roadLayer);

        return datasource;
    }

    const createFilteredAreasDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(filteredAreasDatasource);
        azureMap.current.sources.add(datasource);

        let filteredAreasRoadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, filteredAreasRoadLayerId);
        azureMap.current.layers.add(filteredAreasRoadLayer);

        azureMap.current.events.add('mouseover', filteredAreasRoadLayer, (e) => handleRoadLayerMouseover(e));
        azureMap.current.events.add('mouseout', filteredAreasRoadLayer, handleRoadLayerMouseout);

        return datasource;
    }

    const removeFilteredAreasDatasourceShapes = (): void => {
        let filteredAreasRoadLayer = azureMap.current.layers.getLayerById(filteredAreasRoadLayerId);
        if (filteredAreasRoadLayer) {
            azureMap.current.events.remove('mouseover', filteredAreasRoadLayer, (e) => handleRoadLayerMouseover(e as MapMouseEvent));
            azureMap.current.events.remove('mouseout', filteredAreasRoadLayer, handleRoadLayerMouseout);
            azureMap.current.layers.remove(filteredAreasRoadLayer);
        }

        let datasource = azureMap.current.sources.getById(filteredAreasDatasource) as source.DataSource;
        if (datasource) {
            datasource.clear();
            azureMap.current.sources.remove(datasource);
        }
    }

    const createFilteredMapAreasShapes = (programming: FilteredProgramming, datasource: source.DataSource): void => {
        if (programming) {
            programming.areas.forEach((area) => {
                area.sections.forEach((section) => {
                    let sectionId = section.roadSectionId;
                    let filteredAreaShapeId = getFilteredAreaShapeId(sectionId);
                    let unselectedAreaShape = createShape(section.pathGeometry.coordinates, filteredAreaShapeId, area.hexColor, areaWidth, filteredArea, sectionId);
                    datasource.add(unselectedAreaShape);
                });
            });
        }
    }

    const getFilteredAreaShapeId = (roadSectionId: number): string =>
        `${roadSectionId}/filteredArea`;

    const handleRoadLayerMouseover = (e: MapMouseEvent): void => {
        handleLayerMouseover();

        let tooltips: string[] = [];
        let areasAlreadyChecked = new Map<number, number>();
        e.shapes.forEach((s) => {
            let shape = s as Shape;
            let shapeProps = shape.getProperties();
            if (shapeProps.EntityType === filteredArea) {
                let sectionId = shapeProps.RoadSectionId;

                props.filteredProgrammingsFromFilter.forEach(p => {
                    let areas = p.areas;
                    areas.forEach((area) => {
                        if (!areasAlreadyChecked.has(area.programmingAreaId) && area.sections.some(s => s.roadSectionId === sectionId)) {
                            areasAlreadyChecked.set(area.programmingAreaId, area.programmingAreaId);
                            let tooltip = getAreaTooltip(p.year, p.label, area.label, area.selectedWork);
                            if (tooltip) {
                                tooltips.push(tooltip);
                            }
                        }
                    });
                });
            }
        });

        if (tooltips.length > 0) {
            let content = `<div style="padding:10px;">`;
            tooltips.forEach(tooltip => {
                content += `<p style="margin:0;">${tooltip}</p>`;
            });
            content += `</div>`;

            areaTooltipPopup.setOptions({
                content: content,
                position: e.position,
                pixelOffset: [0, -18]
            });
            areaTooltipPopup.open(azureMap.current);
        }
    }

    const handleRoadLayerMouseout = (): void => {
        handleLayerMouseout();

        if (areaTooltipPopup.isOpen()) {
            areaTooltipPopup.close();
        }
    }

    const handleLayerMouseover = (): void => {
        if (selectedAction.current === barAction.carSelect) {
            setMapCursor(azureMap.current, MapCursorMode.Car);
            return;
        }

        setMapCursor(azureMap.current, MapCursorMode.Pointer);
    }

    const handleLayerMouseout = (): void => {
        setMapCursor(azureMap.current, MapCursorMode.Auto);
    }

    const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        };

        options.type = cameraAnimationType;
        options.duration = cameraAnimationDuration;

        map.setCamera(options);
    }

    const selectedActionChanged = (action: barAction): void => {
        selectedAction.current = action;
    }

    return (
        <Box className={`scenario-map-content ${props.isSectionsDrawerOpened ? 'sections-opened' : ''}`}>
            {props.loading ? <ScaleLoaderComponent /> : ''}
            <div id="measurementInfo" className="measure"></div>
            <div id="AzureMap"></div>
            <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                {azureMap.current &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[barAction.carSelect]}
                        onSelectedSectionChange={null}
                        selectedSectionsId={[]}
                        sections={null}
                        mainLayer={roadLayerId}
                        currentMeasurementSystemType={props.currentMeasurementSystemType}
                        handleDisplayImageFromSectionClicked={props.handleDisplayImageFromSectionClicked}
                        handleCLoseRoadSectionDetails={props.handleCLoseRoadSectionDetails}
                        selectedActionChanged={selectedActionChanged}
                    />
                }
            </Box>
        </Box>
    );
}
