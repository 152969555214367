import { Slider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import './DistributionItemStyles.scss';
import { NumericTextboxComponent } from './NumericTextboxComponent';

interface DistributionItemComponentProps {
    qualityText: string,
    qualityValue: { percent: number, value: number }
    cssColorValue: string,
    currency: string
    onChange: (value: number) => void
}

export const DistributionItemComponent = (props: DistributionItemComponentProps): JSX.Element => {

    function valueLabelFormat(value: number) {
        return value.toFixedLocalized(0);
    }

    return (
        <Box className="distribution-item">
            <Box className="quality-content">
                <div className={`score-color ${props.cssColorValue}`}></div>
                <div className="quality">{props.qualityText}</div>
            </Box>
            <Box display="flex" flexDirection="row">
                <div className="slider">
                    <Slider value={props.qualityValue.percent * 100} valueLabelDisplay="auto" step={5} onChange={(e: any) => props.onChange(e.target.value / 100)}
                        valueLabelFormat={valueLabelFormat}
                    />
                </div>
                <NumericTextboxComponent
                    value={props.qualityValue.percent}
                    min={0}
                    max={1}
                    step={0.05}
                    className="numeric-textbox distribution-percent"
                    format="p"
                    onChange={(value) => props.onChange(value)}
                />
                <NumericTextboxComponent
                    value={props.qualityValue.value}
                    min={0}
                    className="numeric-textbox distribution-budget"
                    disabled={true}
                    format={{
                        style: "currency",
                        currency: props.currency,
                        currencyDisplay: "symbol",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                    }}
                />
            </Box>
        </Box>
    );
}