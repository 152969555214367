import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { MeasurementSystem } from 'src/utils/MeasurementSystem';

export const MetersOrYardsRoundedCell = (props: GridCellProps): JSX.Element => {
    if (props.rowType !== "groupHeader") {
        if (!props.dataItem[props.field])
            return <td></td>

        let value = MeasurementSystem.getLengthInMetersOrYards(props.dataItem[props.field]);
        return (<td style={{ textAlign: 'end' }}>{Math.round(value)}</td>)
    }
    return null;
}

export const MetersOrYardsFixedCell = (props: GridCellProps): JSX.Element => {
    if (props.rowType !== "groupHeader") {
        if (!props.dataItem[props.field])
            return <td></td>

        let value = MeasurementSystem.getLengthInMetersOrYards(props.dataItem[props.field]);
        return (<td style={{ textAlign: 'end' }}>{value.toFixedLocalized(2, 2)}</td>)
    }
    return null;
}
