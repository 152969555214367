import { IconWrap } from '@progress/kendo-react-common';
import { GridColumnMenuColumnsList, GridColumnMenuProps, GridColumnProps } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { columnsIcon, filterIcon } from '@progress/kendo-svg-icons';
import React from 'react';
import { Localization } from '../../../../localization/Localization';
import { RoadSectionGridViewData } from '../../models/RoadSectionGridViewData';
import { CustomCheckboxFilter } from './CustomCheckboxFilter';

interface ColumnMenuProps {
    columnsprops: GridColumnMenuProps,
    data: RoadSectionGridViewData[],
    field: string,
    enableFilter: boolean,
    columns: GridColumnProps[],
    columnsState: GridColumnProps[],
    onColumnsChange: (cols: GridColumnProps[]) => void
}

export const ColumnMenu = (props: ColumnMenuProps): JSX.Element => {

    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e: TabStripSelectEventArguments): void => {
        setSelected(e.selected);
    }

    const onCloseMenu = (): void => props.columnsprops.onCloseMenu();

    return (
        <TabStrip selected={selected} onSelect={handleSelect} animation={false}>
            {props.enableFilter &&
                <TabStripTab title={<TabStripTabTitle iconName='filter' svgIcon={filterIcon} />}>
                    <CustomCheckboxFilter {...props.columnsprops} data={props.data} field={props.field} />
                </TabStripTab>
            }
            <TabStripTab contentClassName={`columns-management ${Localization.locale}`} title={<TabStripTabTitle iconName='columns' svgIcon={columnsIcon} />}>
                <GridColumnMenuColumnsList columnsState={props.columnsState} columns={props.columns} onColumnsChange={props.onColumnsChange} onCloseMenu={onCloseMenu} />
            </TabStripTab>
        </TabStrip>
    );
}

const TabStripTabTitle = props => {
    return <span className='k-icon-wrapper-host' title={props.title}>
        <IconWrap name={props.iconName} icon={props.svgIcon} />
    </span>;
}