import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import Translate from '../../../localization/Localization';
import { downloadFileFromBlob, getFileName } from '../../../utils/DownloadFile';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { ProjectThumbnailModel } from '../../Home/models/ProjectThumbnailModel';
import { Export } from '../models/Export';
import { RoadsConditionApiClient } from '../services/RoadsCondition/RoadsConditionApiClient';
import './ExportChoicesDialogStyles.scss';

interface ExportChoicesDialogProps {
    isOpened: boolean,
    displayedSectionsIds: Set<number>,
    auscultationsIdsString: string,
    selectedProject: ProjectThumbnailModel,
    onClose: () => void
}

export const ExportChoicesDialog = (props: ExportChoicesDialogProps): JSX.Element => {

    const { selectedProject, auscultationsIdsString } = props;

    const [exportChoice, setExportChoice] = useState<Export>(null);
    const [exportOnlyFilteredSections, setExportOnlyFilteredSections] = useState<boolean>(false);
    const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

    const displayedSectionsIds = Array.from(props.displayedSectionsIds);
    const measurementSystemType = MeasurementSystem.getCurrentType();

    useEffect(() => {
        if (displayedSectionsIds.length === 0) {
            setExportOnlyFilteredSections(false);
        }
    }, [displayedSectionsIds])

    const handleExport = (): void => {
        setIsExportLoading(true);

        switch (exportChoice) {
            case Export.xls:
                handleExportExcelSynthesis();
                break;
            case Export.excel:
                handleExportExcel();
                break;
            case Export.geojson:
                handleExportGeoJson();
                break;
            case Export.kml:
                handleExportKml();
                break;
            case Export.shapefile:
                handleExportShapefile();
                break;
            default:
                return;
        }

    }

    const handleExportExcelSynthesis = (): void => {
        if (exportOnlyFilteredSections)
            exportExcelSynthesisSections();
        else
            exportExcelSynthesis();
    }

    const downloadFileAndCloseDialog = (res: AxiosResponse<Blob>): void => {
        let fileName: string = getFileName(res);
        let blob: Blob = new Blob([res.data]);
        downloadFileFromBlob(blob, fileName);
        props.onClose();
    }

    const exportExcelSynthesis = (): void => {
        RoadsConditionApiClient.ExportSynthesisExcel(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const exportExcelSynthesisSections = (): void => {
        RoadsConditionApiClient.ExportSynthesisExcelOfSections(displayedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const handleExportExcel = (): void => {
        if (exportOnlyFilteredSections)
            exportExcelSections();
        else
            exportExcel();
    }

    const exportExcel = (): void => {
        RoadsConditionApiClient.ExportExcel(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const exportExcelSections = (): void => {
        RoadsConditionApiClient.ExportExcelOfSections(displayedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const handleExportShapefile = (): void => {
        if (exportOnlyFilteredSections)
            exportShapefileSections();
        else
            exportShapefile();
    }

    const exportShapefile = (): void => {
        RoadsConditionApiClient.ExportShapefile(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const exportShapefileSections = (): void => {
        RoadsConditionApiClient.ExportShapefileOfSections(displayedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const handleExportGeoJson = (): void => {
        if (exportOnlyFilteredSections)
            exportGeoJsonSections();
        else
            exportGeoJson();
    }

    const exportGeoJson = (): void => {
        RoadsConditionApiClient.ExportGeoJson(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const exportGeoJsonSections = (): void => {
        RoadsConditionApiClient.ExportGeoJsonOfSctions(displayedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const handleExportKml = (): void => {
        if (exportOnlyFilteredSections)
            exportKmlSections();
        else
            exportKml();
    }

    const exportKml = (): void => {
        RoadsConditionApiClient.ExportKml(selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    const exportKmlSections = (): void => {
        RoadsConditionApiClient.ExportKmlOfSections(displayedSectionsIds, selectedProject.projectId, selectedProject.projectVersionId, auscultationsIdsString, measurementSystemType)
            .then(res => {
                downloadFileAndCloseDialog(res);
            });
    }

    return (
        <Dialog id="export-choices-dialog" open={props.isOpened}>
            <DialogContent>
                <Box className="dialog-content">
                    <Box className="export-header">
                        <span className="popper-title">{Translate.Resources.UI_RoadsSectionsSummaryComponent_SelectFormat}</span>
                        <ScaleLoader width={5} height={20} radius={50} color="#000000" loading={isExportLoading} />
                        <CloseIcon onClick={() => props.onClose()} className="poper-close-icon" />
                    </Box>
                    <Box className="export-choices">
                        <RadioGroup
                            aria-labelledby="radio-buttons-group"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel className="radio-button" value={Export.xls} control={<Radio disabled={isExportLoading} checked={exportChoice === Export.xls} onChange={() => setExportChoice(Export.xls)} />} label={Translate.Resources.UI_RoadsSectionsSummaryComponent_Export_XLS_overview} />
                            <FormControlLabel className="radio-button" value={Export.excel} control={<Radio disabled={isExportLoading} checked={exportChoice === Export.excel} onChange={() => setExportChoice(Export.excel)} />} label="EXCEL" />
                            <FormControlLabel className="radio-button" value={Export.geojson} control={<Radio disabled={isExportLoading} checked={exportChoice === Export.geojson} onChange={() => setExportChoice(Export.geojson)} />} label="GEOJSON" />
                            <FormControlLabel className="radio-button" value={Export.shapefile} control={<Radio disabled={isExportLoading} checked={exportChoice === Export.shapefile} onChange={() => setExportChoice(Export.shapefile)} />} label="SHAPEFILE" />
                            <FormControlLabel className="radio-button" value={Export.kml} control={<Radio disabled={isExportLoading} checked={exportChoice === Export.kml} onChange={() => setExportChoice(Export.kml)} />} label="KML" />
                        </RadioGroup>
                    </Box>
                    <Box className="filtered-sections-checkbox">
                        <FormControlLabel
                            className="form-control-label-checkbox"
                            control={
                                <Checkbox checked={exportOnlyFilteredSections}
                                    disabled={displayedSectionsIds.length === 0 || isExportLoading}
                                    onChange={(e) => setExportOnlyFilteredSections(e.target.checked)}
                                />}
                            label={Translate.Resources.UI_RoadsSectionsSummaryComponent_Checkbox_ExportOnlyFilteredSections}
                        />
                    </Box>
                    <Box className="buttons-actions">
                        <Button className="cancel-button" onClick={() => props.onClose()}>
                            {Translate.Resources.UI_RoadsSectionsSummaryComponent_Cancel}
                        </Button>
                        <Button className={`export-button ${exportChoice === null || isExportLoading ? 'disabled' : ''}`} onClick={handleExport} disabled={exportChoice === null}>
                            {Translate.Resources.UI_RoadsSectionsSummaryComponent_Export}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
