import { Box, Button } from '@mui/material';
import React from 'react';
import Translate from '../../../../localization/Localization';
import { ProjectVersion } from '../../../Home/services/dataContracts/queryStack/ProjectVersion';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { WorkPriority } from '../../../ProjectSettings/services/dataContracts/queryStack/WorkPriority';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import { ScenarioCardComponent } from './ScenarioCardComponent';
import './ScenariosListStyles.scss';

interface ScenariosListComponentProps {
    currentProjectVersionId: number,
    scenariosList: Scenario[],
    currency: string,
    workPriorities: WorkPriority[],
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>,
    projectVersionsCache: Map<number, ProjectVersion>,
    costRatiosCache: Map<number, CostRatio[]>,
    handleCreateScenarioClicked: () => void,
    handleOpenScenario: (scenarioId: number) => void,
    handleEditScenario: (scenarioId: number) => void,
    handleDeleteScenario: (scenarioId: number) => void,
    handleDuplicateScenario: (projectId: string, projectVersionId: number, projectVersionNumber: number, scenarioId: number, scenarioLabel: string, scenarioDate: Date) => void
}

export const ScenariosListComponent = (props: ScenariosListComponentProps): JSX.Element => {

    return (
        <Box className="scenarios-list">
            <Box className="description-module">
                <Box className="description-module-text">
                    <div className="text s1">{Translate.Resources.UI_Scenarios_Description_ThisModuleHelpsYouSelectTheSections}</div>
                    <div className="text s2">{Translate.Resources.UI_Scenarios_Description_InfracareCanMakeYouAProposal}</div>
                </Box>
                <Button className="btn-primary" onClick={props.handleCreateScenarioClicked}>
                    {Translate.Resources.UI_Scenarios_Description_button_CreateScenario}
                </Button>
            </Box>
            <Box className="scenarios">
                <div className="scenarios-count">{`${props.scenariosList.length} ${Translate.Resources.UI_Scenarios_List_Scenarios}`}</div>
                <Box className="list">
                    {props.scenariosList.map((scenario, index) => {
                        return (
                            <ScenarioCardComponent key={`scenario-${index}`}
                                currentProjectVersionId={props.currentProjectVersionId}
                                scenario={scenario}
                                currency={props.currency}
                                workPriorities={props.workPriorities}
                                mergedProjectAuscultationsCache={props.mergedProjectAuscultationsCache}
                                projectVersionsCache={props.projectVersionsCache}
                                costRatiosCache={props.costRatiosCache}
                                handleOpenScenario={props.handleOpenScenario}
                                handleEditScenario={props.handleEditScenario}
                                handleDeleteScenario={props.handleDeleteScenario}
                                handleDuplicateScenario={props.handleDuplicateScenario}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
}