import { ColorPicker, ColorPickerChangeEvent, ColorPickerPaletteSettings } from "@progress/kendo-react-inputs";
import React, { useState } from 'react';
import { ProgrammingAreaExtended } from "../../models/ProgrammingAreaExtended";
import './CustomColorPickerCellStyles.scss';

const AreasPaletteColors = [
    "#00eaff",
    "#fc36c5",
    "#db00f0",
    "#8075ff",
    "#5b80a4",
    "#2b9cff",
    "#bbff00",
    "#2fefbf",
    "#89dca0",
    "#f4ae71",
    "#f66f6f",
    "transparent"
];

interface CustomColorPickerCellProps {
    dataItem: ProgrammingAreaExtended,
    disabled?: boolean,
    handleColorChanged: (dataItem: ProgrammingAreaExtended, hexColor: string) => void
}

export const CustomColorPickerCell = (props: CustomColorPickerCellProps): JSX.Element => {
    let dataItem = props.dataItem;
    let paletteColors = AreasPaletteColors;
    if (dataItem && dataItem.hexColor && paletteColors && !paletteColors.includes(dataItem.hexColor)) {
        paletteColors = Array.from(paletteColors);
        paletteColors.unshift(dataItem.hexColor);
    }

    const paletteSettings: ColorPickerPaletteSettings = {
        palette: paletteColors,
        columns: 4,
        tileSize: 30
    };

    const [hexColor, setHexColor] = useState<string>(dataItem.hexColor);

    const handleOnChangeColor = (event: ColorPickerChangeEvent): void => {
        let hexCode = rgbaToHex(event.value);
        let hexColor = hexCode === "#ffffff" ? "transparent" : hexCode;
        dataItem.hexColor = hexColor;

        setHexColor(hexColor);
        props.handleColorChanged(dataItem, hexColor);
    }

    const rgbaToHex = (rgb: string): string => {
        var inParts = rgb.substring(rgb.indexOf("(")).split(","),
            r = parseInt(trim(inParts[0].substring(1)), 10),
            g = parseInt(trim(inParts[1]), 10),
            b = parseInt(trim(inParts[2]), 10);

        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16)
        ];

        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        });

        return ('#' + outParts.join(''));
    }

    const trim = (str: string): string => {
        return str.replace(/^\s+|\s+$/gm, '');
    }

    const defaultRendering = (
        <td>
            <div className={`color-picker ${dataItem.hexColor === "transparent" ? ' transparent' : ''} ${props.disabled ? 'disabled' : ''}`}>
                <ColorPicker
                    value={hexColor}
                    paletteSettings={paletteSettings}
                    onChange={handleOnChangeColor}
                />
            </div>
        </td>
    );

    return defaultRendering;
}