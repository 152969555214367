import { Box } from '@mui/system';
import { Grid, GridCellProps, GridColumn, GridRowProps } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import Translate, { Localization } from '../../../localization/Localization';
import { CostRatioViewData } from '../models/CostRatioViewData';
import { getQualityText, getQualityWorkPrioritiesGridCssValue } from '../models/WorkPriorityViewData';
import { CustomEditableNumericCellComponent } from './CustomEditableNumericCellComponent';

interface EditedFieldModel {
    field: string,
    score: number,
    lowTrafficValue: number,
    mediumTrafficValue: number,
    strongTrafficValue: number
}

interface CostRatiosGridComponentProps {
    costRatiosViewData: CostRatioViewData[],
    canEditCostsRatio: boolean,
    updateCostRatiosViewData: (costRatiosViewData: CostRatioViewData[]) => void
}

const editFieldKey = "inEdit";

export const CostRatiosGridComponent = (props: CostRatiosGridComponentProps): JSX.Element => {
    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;

    const [editField, setEditField] = useState<EditedFieldModel>(null);

    const enterEdit = (dataItem: CostRatioViewData, field: string) => {
        if (!props.canEditCostsRatio)
            return

        if (dataItem.inEdit && field === editField.field &&
            editField.score === dataItem.score &&
            editField.lowTrafficValue === dataItem.lowTrafficValue &&
            editField.mediumTrafficValue === dataItem.mediumTrafficValue &&
            editField.strongTrafficValue === dataItem.strongTrafficValue)
            return;

        exitEdit();
        dataItem.inEdit = field;
        setEditField({
            field: field,
            score: dataItem.score,
            lowTrafficValue: dataItem.lowTrafficValue,
            mediumTrafficValue: dataItem.mediumTrafficValue,
            strongTrafficValue: dataItem.strongTrafficValue
        });
    };

    const exitEdit = () => {
        let wasChanged = false;

        props.costRatiosViewData.forEach(dataItem => {
            if (dataItem.inEdit !== undefined) {
                wasChanged = true;
            }
            dataItem.inEdit = undefined;
        });

        setEditField(null);

        if (!wasChanged)
            return;

        props.updateCostRatiosViewData(props.costRatiosViewData);
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem = cellProps.dataItem;
        const field: string = cellProps.field;

        if (!tdElement)
            return tdElement;

        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td: any) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
    }

    return (
        <LocalizationProvider language={Localization.locale}>
            <IntlProvider locale={Localization.locale}>
                <Grid
                    className="grid"
                    data={props.costRatiosViewData}
                    rowHeight={40}
                    scrollable="none"
                    cellRender={cellRender}
                    rowRender={rowRender}
                    editField={props.canEditCostsRatio ? editFieldKey : ""}
                >
                    <GridColumn field="score"
                        headerCell={() => <div className="first-col-header">{`${Translate.Resources.UI_ProjectSettings_CostRatio_Grid_Score} / ${Translate.Resources.UI_ProjectSettings_CostRatio_Grid_Traffic}`}</div>}
                        cell={(properties: GridCellProps) => <td>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box className="quality">
                                    <div className={`color ${getQualityWorkPrioritiesGridCssValue(properties.dataItem.quality)}`}></div>
                                    <div>{getQualityText(properties.dataItem.quality)}</div>
                                </Box>
                                <div>{properties.dataItem.score}</div>
                            </Box>
                        </td>}
                    />
                    <GridColumn field="strongTrafficValue" editable={props.canEditCostsRatio} width="150"
                        headerCell={() => <>{Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Strong}</>}
                        cell={(properties: GridCellProps) => <CustomEditableNumericCellComponent  {...properties} />}
                    />
                    <GridColumn field="mediumTrafficValue" editable={props.canEditCostsRatio} width="150"
                        headerCell={() => <>{Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Moderate}</>}
                        cell={(properties: GridCellProps) => <CustomEditableNumericCellComponent  {...properties} />}
                    />
                    <GridColumn field="lowTrafficValue" editable={props.canEditCostsRatio} width="150"
                        headerCell={() => <>{Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Low}</>}
                        cell={(properties: GridCellProps) => <CustomEditableNumericCellComponent  {...properties} />}
                    />
                </Grid>
            </IntlProvider>
        </LocalizationProvider>
    );
}