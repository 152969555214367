import Translate from "../../../localization/Localization"
import { ScenarioStatus } from "../services/dataContracts/queryStack/ScenarioStatus"

export const getScenarioStatusTextValues = () => {
    return (
        new Map<ScenarioStatus, string>([
            [ScenarioStatus.draft, Translate.Resources.UI_Scenarios_NewScenario_Status_Draft],
            [ScenarioStatus.inProgramming, Translate.Resources.UI_Scenarios_NewScenario_Status_InProgramming],
            [ScenarioStatus.programmed, Translate.Resources.UI_Scenarios_NewScenario_Status_Programmed]
        ])
    )
}

export const getScenarioStatusCssClassValues = () => {
    return (
        new Map<ScenarioStatus, string>([
            [ScenarioStatus.draft, 'draft'],
            [ScenarioStatus.inProgramming, 'in-programming'],
            [ScenarioStatus.programmed, 'programmed']
        ])
    )
}