export { }

declare global {
    interface DateConstructor {
        _doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified: boolean;
        _doesNativeDateParserAssumesLocalZeroWhenOnlyDate: boolean;

        parseJSON(s: string): Date;
        isValid(date: Date): boolean;
        equals(d1: Date, d2: Date): boolean;
    }
}

Date.parseJSON = function (s) {

    if (s == null)
        return new Date(s);

    if (Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified === undefined) {
        let d = new Date("2016-01-01T00:00:00");
        if (d.getHours() === 0) {
            //ex: Edge, IE11
            Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified = true;
        }
        else {
            //ex: CHROME
            Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified = false;
        }
    };

    if (Date._doesNativeDateParserAssumesLocalZeroWhenOnlyDate === undefined) {
        let d = new Date("2016-01-01");
        if (d.getHours() === 0) {
            //ex: ?
            Date._doesNativeDateParserAssumesLocalZeroWhenOnlyDate = true;
        }
        else {
            //ex: CHROME, Edge, IE11
            Date._doesNativeDateParserAssumesLocalZeroWhenOnlyDate = false;
        }
    };

    //ex: 2020-06-10T09:26:18.7425323+00:00
    if (s.length > 13 && s[4] === "-" && s[7] === "-" && s[10] === "T" && s[13] === ":") {
        let withoutOffset = s;
        let offsetSeparatorIndex = s.lastIndexOf("+");
        if (offsetSeparatorIndex > 0) {
            // c'est temporaire en attendant de revenir sur le parsing des dates et la gestion des fuseaux.
            // on s'assure que les dates aux fuseaux 0/utc s'affiche à l'heure locale.
            if (s.endsWith("00:00"))
                return new Date(withoutOffset);

            withoutOffset = s.substring(0, offsetSeparatorIndex);

            if (Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified === true)
                return new Date(withoutOffset);

            //when there is no timezone offset nor Z indication in the string
            //the native implementation will consider the date as being UTC and adjust/convert the time
            //to browser's local time zone.
            //the trick will be to rollback this adjustement
            let result = new Date(s);
            let hoursOffset = result.getTimezoneOffset() / 60;
            result.setHours(result.getHours() + hoursOffset);
            return result;
        }

        offsetSeparatorIndex = s.lastIndexOf("-");
        if (offsetSeparatorIndex > 10) {
            withoutOffset = s.substring(0, offsetSeparatorIndex);

            if (Date._doesNativeDateParserAssumesLocalTimeWhenZoneNotUnspecified === true)
                return new Date(withoutOffset);

            //when there is no timezone offset nor Z indication in the string
            //the native implementation will consider the date as being UTC and adjust/convert the time
            //to browser's local time zone.
            //the trick will be to rollback this adjustement
            let result = new Date(s);
            let hoursOffset = result.getTimezoneOffset() / 60;
            result.setHours(result.getHours() + hoursOffset);
            return result;
        }

        if (s.endsWith('Z')) {
            //use the faster native implem for UTC date strings
            return new Date(s);
        }
    }
    return new Date('');
};

Date.isValid = function (date: Date) {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
};

Date.equals = function (d1: Date, d2: Date): boolean {

    if (d1 === null && d2 === null)
        return true;

    // Toujours créer des dates à partir des valeurs passés en paramètres puisque on n'est pas toujours sur du typage des données.
    var date1 = new Date(d1);
    if (!Date.isValid(date1))
        return false;

    var date2 = new Date(d2);
    if (!Date.isValid(date2))
        return false;

    var result = (date1.getTime() === date2.getTime());
    return result;
};