import './Date'

export { }

declare global {
    interface JSON {
        _nativeParseFunction: (text: string, reviver?: (key: any, value: any) => any) => any;

        enableCustomDateParser();
        disableCustomDateParser();
    }
}

JSON.enableCustomDateParser = function () {
    /// <summary>
    /// Make sure to call JSON.enableCustomDateParser(); before any JSON conversions
    /// replaces the default JSON parser 
    /// </summary>    

    var customDateParser = function (key, value) {
        if (typeof value === 'string') {
            var result = Date.parseJSON(value);

            //return the original value if not a date / 'Invalid Date'
            if (isNaN(result.getTime()))
                return value;

            return result;
        }

        return value;
    };

    var parseWithCustomDateParser = function (json) {
        var parse = JSON._nativeParseFunction ? JSON._nativeParseFunction : JSON.parse;
        var result = parse(json, customDateParser);
        return result;
    };

    if (!JSON._nativeParseFunction) {
        JSON._nativeParseFunction = JSON.parse;
        JSON.parse = parseWithCustomDateParser;
    }
};

JSON.disableCustomDateParser = function () {
    if (JSON._nativeParseFunction) {
        JSON.parse = JSON._nativeParseFunction;
        JSON._nativeParseFunction = null;
    }
};
