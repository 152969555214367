import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { Component } from 'react';
import Translate, { Localization } from '../../localization/Localization';
import { Currencies } from '../../shared/models/Currencies';
import { MeasurementSystemType } from '../../shared/models/MeasurementSystemType';
import { UserPermissionIds } from '../../shared/models/UserPermissionIds';
import ToastService from '../../ToastService';
import BusinessMessages from '../../utils/BusinessMessages';
import { MeasurementSystem } from '../../utils/MeasurementSystem';
import Utilities from '../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { RouteLocationStateModel } from '../RoadsCondition/models/RouteLocationStateModel';
import { ConfirmSaveChangesDialog } from './components/ConfirmSaveChangesDialog';
import { CostRatiosGridComponent } from './components/CostRatiosGridComponent';
import { GridCardComponent } from './components/GridCardComponent';
import { buildCostRatiosViewData, CostRatioViewData, getCurrencyRequestArgsValue, getTrafficRequestArgsValue } from './models/CostRatioViewData';
import { buildRoadImportancesViewData, getTrafficText, RoadImportanceViewData } from './models/RoadImportanceViewData';
import { buildWorkPrioritiesViewData, getQualityText, getQualityWorkPrioritiesGridCssValue, WorkPriorityViewData } from './models/WorkPriorityViewData';
import './ProjectSettingsStyles.scss';
import { AddOrUpdateCostRatiosRequestArgs } from './services/dataContracts/controller/AddOrUpdateCostRatiosRequestArgs';
import { CostRatioRequestArgs } from './services/dataContracts/controller/CostRatioRequestArgs';
import { ProjectSettingsApiClient } from './services/ProjectSettingsApiClient';

const currencyList = [Currencies.Euro, Currencies.Dollar, Currencies.Pound, Currencies.Danish, Currencies.Dirham, Currencies.Franc];

interface ProjectSettingsViewState {
    currentMeasurementSystemType: MeasurementSystemType,
    roadImportancesViewData: RoadImportanceViewData[],
    workPrioritiesViewData: WorkPriorityViewData[],
    costRatiosViewData: CostRatioViewData[],
    selectedCurrency: string,
    selectedYear: number,
    changesYear: number,
    isConfirmSaveChangesDialogOpened: boolean
}

interface ProjectSettingsViewProps {
    userPermissions: string[]
}

const initialState: ProjectSettingsViewState = {
    currentMeasurementSystemType: null,
    roadImportancesViewData: [],
    workPrioritiesViewData: [],
    costRatiosViewData: [],
    selectedCurrency: null,
    selectedYear: new Date().getFullYear(),
    changesYear: null,
    isConfirmSaveChangesDialogOpened: false
};

export class ProjectSettingsView extends Component<RouteComponentProps & ProjectSettingsViewProps, ProjectSettingsViewState> {
    _isMounted: boolean;
    canEditCostsRatio: boolean;
    projectId: string;

    constructor(props) {
        super(props);

        initialState.currentMeasurementSystemType = MeasurementSystem.getCurrentType();
        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;
        this.canEditCostsRatio = this.props.userPermissions && this.props.userPermissions.filter(e => e === UserPermissionIds.editCostsRatio).length > 0;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        let projectId = locationState.projectId;
        this.projectId = projectId;

        await Promise.all([
            ProjectSettingsApiClient.GetRoadImportances(projectId),
            ProjectSettingsApiClient.GetWorkPriorities(projectId),
            ProjectSettingsApiClient.GetCostRatios(projectId, this.state.selectedYear),
            ProjectSettingsApiClient.GetProjectCurrency(projectId)
        ])
            .then((results) => {
                let roadImportancesData = results[0].data;
                let roadImportancesViewData: RoadImportanceViewData[] = buildRoadImportancesViewData(roadImportancesData);

                let workPrioritiesData = results[1].data;
                let workPrioritiesViewData: WorkPriorityViewData[] = buildWorkPrioritiesViewData(workPrioritiesData);

                let costRatiosData = results[2].data;
                let costRatiosViewData: CostRatioViewData[] = buildCostRatiosViewData(costRatiosData);

                let currency = Currencies[results[3].data];

                this.setState({
                    roadImportancesViewData,
                    workPrioritiesViewData,
                    costRatiosViewData,
                    selectedCurrency: currency
                });
            });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            currentMeasurementSystemType: measurementSystemType
        });
    }

    handleCurrencyChanged = (e: SelectChangeEvent<string>): void => {
        let currency = e.target.value;

        ProjectSettingsApiClient.UpdateProjectCurrency(this.projectId, getCurrencyRequestArgsValue(currency))
            .then((res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    return;
                }

                this.setState({
                    selectedCurrency: currency
                });
            });
    }

    handleYearChanged = (e: SelectChangeEvent<number>, state: ProjectSettingsViewState): void => {
        let newYear = e.target.value as number;
        let oldYear = state.selectedYear;
        if (state.costRatiosViewData.some(x => x.changes.size > 0)) {
            this.setState({
                isConfirmSaveChangesDialogOpened: true,
                changesYear: oldYear,
                selectedYear: newYear
            });

            return;
        }

        this.getCostRatios(this.projectId, newYear);
    }

    getCostRatios = (projectId: string, year: number) => {
        ProjectSettingsApiClient.GetCostRatios(projectId, year)
            .then((res) => {
                let costRatiosData = res.data;
                let costRatiosViewData: CostRatioViewData[] = buildCostRatiosViewData(costRatiosData);

                this.setState({
                    costRatiosViewData,
                    selectedYear: year,
                    isConfirmSaveChangesDialogOpened: false
                });
            });
    }

    handleConfirmSaveChanges = (state: ProjectSettingsViewState): void => {
        this.saveCostRatiosChanges(state, state.changesYear);
    }

    handleCancelSaveChanges = (state: ProjectSettingsViewState): void => {
        this.getCostRatios(this.projectId, state.selectedYear);
    }

    cancelCostRatiosChanges = (state: ProjectSettingsViewState): void => {
        let costRatiosViewDataArray = state.costRatiosViewData;
        costRatiosViewDataArray.forEach(e => e.changes = new Map());

        this.setState({
            costRatiosViewData: costRatiosViewDataArray
        });
    }

    saveCostRatiosChanges = (state: ProjectSettingsViewState, year: number): void => {
        let costRatiosData = state.costRatiosViewData.filter(e => e.changes.size > 0);
        let costRatios: CostRatioRequestArgs[] = [];
        costRatiosData.forEach((costRatio) => {
            costRatio.changes.forEach((value, key) => {
                let costRatioArgs: CostRatioRequestArgs = {
                    score: costRatio.score,
                    costRatioValue: value,
                    traffic: getTrafficRequestArgsValue(key)
                };
                costRatios.push(costRatioArgs)
            });
        });

        let args: AddOrUpdateCostRatiosRequestArgs = {
            costRatios: costRatios,
            year: year,
            projectId: this.projectId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        }

        ProjectSettingsApiClient.AddOrUpdateCostRatio(args)
            .then((res) => {
                let data = res.data;
                let warnings = BusinessMessages.GetWarnings(data);
                if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }

                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                }
                else {
                    this.getCostRatios(this.projectId, state.selectedYear);
                }
            });
    }

    updateCostRatiosViewData = (costRatiosViewData: CostRatioViewData[]): void => {
        this.setState({
            costRatiosViewData
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const state = this.state;

        const roadImportancesGrid = <LocalizationProvider language={Localization.locale}>
            <IntlProvider locale={Localization.locale}>
                <Grid
                    className="grid"
                    data={state.roadImportancesViewData}
                    rowHeight={40}
                    scrollable="none"
                >
                    <GridColumn field="traffic"
                        headerCell={() => <div className="first-col-header">{`${Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Traffic} / ${Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Hierarchy}`}</div>}
                        cell={(properties: GridCellProps) => <td className="traffic">{getTrafficText(properties.dataItem.traffic)}</td>}
                    />
                    <GridColumn field="mainHierarchyValue"
                        headerCell={() => <>{`${Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Main}`}</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.mainHierarchyValue ?? '-'}</td>}
                    />
                    <GridColumn field="localHierarchyValue"
                        headerCell={() => <>{`${Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Local}`}</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.localHierarchyValue ?? '-'}</td>}
                    />
                    <GridColumn field="residentialHierarchyValue"
                        headerCell={() => <>{`${Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Residential}`}</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.residentialHierarchyValue ?? '-'}</td>}
                    />
                </Grid>
            </IntlProvider>
        </LocalizationProvider>;

        const workPrioritiesGrid = <LocalizationProvider language={Localization.locale}>
            <IntlProvider locale={Localization.locale}>
                <Grid
                    className="grid"
                    data={state.workPrioritiesViewData}
                    rowHeight={40}
                    scrollable="none"
                >
                    <GridColumn field="quality"
                        headerCell={() => <div className="first-col-header">{`${Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_ConditionOfThePavement} / ${Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_ImportanceOfTheRoad}`}</div>}
                        cell={(properties: GridCellProps) => <td>
                            <Box className="quality">
                                <div className={`color ${getQualityWorkPrioritiesGridCssValue(properties.dataItem.quality)}`}></div>
                                <div>{getQualityText(properties.dataItem.quality)}</div>
                            </Box>
                        </td>}
                    />
                    <GridColumn field="importanceOneValue"
                        headerCell={() => <>1</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.importanceOneValue ?? '-'}</td>}
                    />
                    <GridColumn field="importanceTwoValue"
                        headerCell={() => <>2</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.importanceTwoValue ?? '-'}</td>}
                    />
                    <GridColumn field="importanceThreeValue"
                        headerCell={() => <>3</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.importanceThreeValue ?? '-'}</td>}
                    />
                    <GridColumn field="importanceFourValue"
                        headerCell={() => <>4</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.importanceFourValue ?? '-'}</td>}
                    />
                    <GridColumn field="importanceFiveValue"
                        headerCell={() => <>5</>}
                        cell={(properties: GridCellProps) => <td className="number">{properties.dataItem.importanceFiveValue ?? '-'}</td>}
                    />
                </Grid>
            </IntlProvider>
        </LocalizationProvider>;

        const costRatiosGrid = <CostRatiosGridComponent
            costRatiosViewData={state.costRatiosViewData}
            canEditCostsRatio={this.canEditCostsRatio}
            updateCostRatiosViewData={this.updateCostRatiosViewData}
        />;

        const yearDorpDownList = <Box display="flex" flexDirection="column">
            <div className="drop-down-title">{Translate.Resources.UI_ProjectSettings_CostRatio_Year}</div>
            <Select className="drop-down-list"
                value={state.selectedYear}
                onChange={(e) => this.handleYearChanged(e, state)}
            >
                {Utilities.yearList.map((year, index) => {
                    return <MenuItem key={`year-${index}`} value={year}>{year}</MenuItem>
                })}
            </Select>
        </Box>

        return (
            <Box className="project-settings">
                <Box display="flex" flexDirection="column" width="49%">
                    <div className="module-title">{Translate.Resources.UI_ProjectSettings_Settings}</div>
                    <div className="module-definition">{Translate.Resources.UI_ProjectSettings_ModuleText}</div>
                    <GridCardComponent
                        gridTitle={Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Title}
                        gridDefinition={Translate.Resources.UI_ProjectSettings_RoadImportance_Grid_Definition}
                        grid={roadImportancesGrid}
                    />
                    <GridCardComponent
                        gridTitle={Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_Title}
                        gridDefinition={Translate.Resources.UI_ProjectSettings_WorkPriority_Grid_Definition}
                        grid={workPrioritiesGrid}
                    />
                </Box>
                <Box display="flex" flexDirection="column" width="49%">
                    <Box className="currency-content">
                        <div className="drop-down-title">{Translate.Resources.UI_ProjectSettings_CostRatio_Currency}</div>
                        <Select className="drop-down-list"
                            value={state.selectedCurrency ?? Currencies.Euro}
                            onChange={(e) => this.handleCurrencyChanged(e)}
                            disabled={!this.canEditCostsRatio}
                        >
                            {currencyList.map((currency, index) => {
                                return <MenuItem key={`currency-${index}`} value={currency}>{currency}</MenuItem>
                            })}
                        </Select>
                    </Box>
                    <GridCardComponent
                        gridTitle={`${Translate.Resources.UI_ProjectSettings_CostRatio_Grid_Title} (${state.selectedCurrency}/${MeasurementSystem.getSymbolOfSurfaceUnit()})`}
                        gridDefinition={Translate.Resources.UI_ProjectSettings_CostRatio_Grid_Definition}
                        hasActionsButtons={true}
                        isActionsButtonsEnabled={state.costRatiosViewData && state.costRatiosViewData.some(x => x.changes.size > 0)}
                        canEditCostsRatio={this.canEditCostsRatio}
                        yearDorpDownList={yearDorpDownList}
                        grid={costRatiosGrid}
                        cancel={() => this.cancelCostRatiosChanges(state)}
                        save={() => this.saveCostRatiosChanges(state, state.selectedYear)}
                    />
                </Box>
                {state.isConfirmSaveChangesDialogOpened &&
                    <ConfirmSaveChangesDialog isOpen={state.isConfirmSaveChangesDialogOpened}
                        handleValidate={() => this.handleConfirmSaveChanges(state)}
                        handleCancel={() => this.handleCancelSaveChanges(state)}
                    />
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ProjectSettingsView));
