import { NumericTextBox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider, NumberFormatOptions } from '@progress/kendo-react-intl';
import React from 'react';
import { Localization } from '../../../../localization/Localization';

interface NumericTextboxComponentProps {
    value: number,
    min?: number,
    max?: number,
    disabled?: boolean,
    step?: number
    className: string,
    format: string | NumberFormatOptions,
    onChange?: (value: number) => void
}

export const NumericTextboxComponent = (props: NumericTextboxComponentProps): JSX.Element => {
    return (
        <LocalizationProvider language={Localization.locale}>
            <IntlProvider locale={Localization.locale}>
                <NumericTextBox
                    value={props.value}
                    spinners={false}
                    min={props.min}
                    max={props.max}
                    disabled={props.disabled}
                    step={props.step}
                    className={props.className}
                    format={props.format}
                    onChange={(e) => props.onChange(e.target.value)}
                    onFocus={(e) => { setTimeout(() => e.target.element.select()) }}
                />
            </IntlProvider>
        </LocalizationProvider>
    );
}
