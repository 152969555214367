import Translate from "../../../localization/Localization";
import { RoadSectionViewData } from "../../RoadsCondition/models/RoadSectionViewData";

export interface RoadSectionGridViewData {

    roadSectionAttributesId?: number;
    roadSectionId: number;
    lengthInMeters: number;
    roadLabel: string;
    district: string;
    collaborativeDevelopmentZone: string;
    hierarchy?: string;
    traffic?: string;
    environment?: string;
    manager?: string;
    importance?: number;
    bus?: boolean;
    bikeLase?: boolean;
    border?: boolean;
    ditch?: boolean;
    side?: boolean;
    municipality: string;
    widthInMeters: number;
    roadType: string;
    osmRoadLabel: string,
    osmMunicipality: string,
    isSelected: boolean;
    hiddenIsSelectedForSort: number;
    inEdit?: string;
    changes: Map<string, any>;
}

export const getHiddenIsSelectedForSort = (isSelected: boolean): number => isSelected === false ? 2 : (isSelected === null ? 1 : 0);

export const getRoadSectionGridViewDatas = (roadSectionViewData: Map<number, RoadSectionViewData>): RoadSectionGridViewData[] => {
    let roadSectionAttributesModels: RoadSectionGridViewData[] = [];
    roadSectionViewData.forEach(e => {
        roadSectionAttributesModels.push(getRoadSectionGridViewData(e));
    })
    return roadSectionAttributesModels;
}

const getRoadSectionGridViewData = (roadSectionViewData: RoadSectionViewData): RoadSectionGridViewData => {

    let trafficLocalized: string = null;
    let environmentLocalized: string = null;
    let managerLocalized: string = null;
    let hierarchyLocalized: string = null

    if (roadSectionViewData.traffic) {
        trafficLocalized = roadSectionViewData.traffic;
    }
    if (roadSectionViewData.environment) {
        environmentLocalized = roadSectionViewData.environment;
    }
    if (roadSectionViewData.manager) {
        managerLocalized = roadSectionViewData.manager;
    }
    if (roadSectionViewData.hierarchy) {
        hierarchyLocalized = roadSectionViewData.hierarchy;
    }

    return {
        roadSectionAttributesId: roadSectionViewData.roadSectionAttributeId,
        roadSectionId: roadSectionViewData.roadSectionId,
        lengthInMeters: roadSectionViewData.lengthInMeters,
        roadLabel: roadSectionViewData.roadLabel,
        district: roadSectionViewData.district,
        collaborativeDevelopmentZone: roadSectionViewData.collaborativeDevelopmentZone,
        traffic: trafficLocalized,
        environment: environmentLocalized,
        manager: managerLocalized,
        hierarchy: hierarchyLocalized,
        importance: roadSectionViewData.importance,
        bus: roadSectionViewData.bus,
        bikeLase: roadSectionViewData.bikeLase,
        border: roadSectionViewData.border,
        ditch: roadSectionViewData.ditch,
        side: roadSectionViewData.side,
        municipality: roadSectionViewData.municipality,
        widthInMeters: roadSectionViewData.widthInMeters,
        osmRoadLabel: roadSectionViewData.osmRoadLabel,
        osmMunicipality: roadSectionViewData.osmMunicipality,
        roadType: getRoadTypeText(roadSectionViewData.roadType),
        isSelected: false,
        hiddenIsSelectedForSort: getHiddenIsSelectedForSort(false),
        changes: new Map()
    } as RoadSectionGridViewData;
}

const getRoadTypeText = (roadType: string): string => {
    let roadTypeTextValues = getRoadTypeTextValues();
    return roadTypeTextValues.get(roadType) ?? roadType
}

export const getRoadTypeTextValues = () => {
    return (
        new Map<string, string>([
            ['abandoned', Translate.Resources.RoadType_abandoned],
            ['bridleway', Translate.Resources.RoadType_bridleway],
            ['busway', Translate.Resources.RoadType_busway],
            ['bus_guideway', Translate.Resources.RoadType_bus_guideway],
            ['construction', Translate.Resources.RoadType_construction],
            ['corridor', Translate.Resources.RoadType_corridor],
            ['cycleway', Translate.Resources.RoadType_cycleway],
            ['elevator', Translate.Resources.RoadType_elevator],
            ['emergency_bay', Translate.Resources.RoadType_emergency_bay],
            ['escalator', Translate.Resources.RoadType_escalator],
            ['escape', Translate.Resources.RoadType_escape],
            ['footway', Translate.Resources.RoadType_footway],
            ['living_street', Translate.Resources.RoadType_living_street],
            ['Motorway', Translate.Resources.RoadType_Motorway],
            ['motorway_link', Translate.Resources.RoadType_motorway_link],
            ['path', Translate.Resources.RoadType_path],
            ['pedestrian', Translate.Resources.RoadType_pedestrian],
            ['planned', Translate.Resources.RoadType_planned],
            ['platform', Translate.Resources.RoadType_platform],
            ['primary', Translate.Resources.RoadType_primary],
            ['primary_link', Translate.Resources.RoadType_primary_link],
            ['proposed', Translate.Resources.RoadType_proposed],
            ['raceway', Translate.Resources.RoadType_raceway],
            ['residential', Translate.Resources.RoadType_residential],
            ['rest_area', Translate.Resources.RoadType_rest_area],
            ['road', Translate.Resources.RoadType_road],
            ['secondary', Translate.Resources.RoadType_secondary],
            ['secondary_link', Translate.Resources.RoadType_secondary_link],
            ['service', Translate.Resources.RoadType_service],
            ['services', Translate.Resources.RoadType_services],
            ['steps', Translate.Resources.RoadType_steps],
            ['tertiary', Translate.Resources.RoadType_tertiary],
            ['tertiary_link', Translate.Resources.RoadType_tertiary_link],
            ['track', Translate.Resources.RoadType_track],
            ['trunk', Translate.Resources.RoadType_trunk],
            ['trunk_link', Translate.Resources.RoadType_trunk_link],
            ['unclassified', Translate.Resources.RoadType_unclassified],
            ['via_ferrata', Translate.Resources.RoadType_via_ferrata]
        ])
    )
}