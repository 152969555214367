import { Box, Button } from '@mui/material';
import React from 'react';
import illustrationImage from 'src/assets/images/illustration-programmation.svg';
import undrawAddFilesImage from 'src/assets/images/undraw_add_files.svg';
import Translate from '../../../../localization/Localization';
import './ProgrammingsModuleDescriptionStyles.scss';

interface ProgrammingsModuleDescriptionComponentProps {
    handleCreateProgrammingClicked: () => void
}

export const ProgrammingsModuleDescriptionComponent = (props: ProgrammingsModuleDescriptionComponentProps): JSX.Element => {

    return (
        <Box className="programming-description">
            <Box className="description-text">
                <div className="text s1">{Translate.Resources.UI_Programming_Description_ThisModuleEnablesYouRefineAnEstablishedScenario}</div>
                <div className="text s3">
                    {Translate.Resources.UI_Programming_Description_YouHaveNotCreatedAnyProgramming}
                    <Button className="btn-primary" onClick={props.handleCreateProgrammingClicked}>
                        {Translate.Resources.UI_Programming_button_CreateProgramme}
                    </Button>
                </div>
            </Box>
            <img src={undrawAddFilesImage} className="img undraw-add-files" alt="undraw add files" />
            <img src={illustrationImage} className="img illustration" alt="illustration" />
        </Box>
    );
}