import Translate from "../../../localization/Localization";
import { OtherAttributes } from "./OtherAttributes";

export const getOtherAttributesList = (): { text: string, value: string }[] => [
    { text: Translate.Resources.UI_ActionsMenu_Attributes_OtherAttributes_Bus, value: OtherAttributes.Bus },
    { text: Translate.Resources.UI_ActionsMenu_Attributes_OtherAttributes_BikeLase, value: OtherAttributes.BikeLase },
    { text: Translate.Resources.UI_ActionsMenu_Attributes_OtherAttributes_Border, value: OtherAttributes.Border },
    { text: Translate.Resources.UI_ActionsMenu_Attributes_OtherAttributes_Ditch, value: OtherAttributes.Ditch },
    { text: Translate.Resources.UI_ActionsMenu_Attributes_OtherAttributes_Side, value: OtherAttributes.Side }
];
