import BuildIcon from '@mui/icons-material/Build';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import followupworksSelectedIcon from 'src/assets/icons/icon-followupworks-selected.svg';
import followupworksIcon from 'src/assets/icons/icon-followupworks.svg';
import gpscontrolSelectedIcon from 'src/assets/icons/icon-gpscontrol-selected.svg';
import gpscontrolIcon from 'src/assets/icons/icon-gpscontrol.svg';
import highwaysSelectedtIcon from 'src/assets/icons/icon-highway-selected.svg';
import highwaysIcon from 'src/assets/icons/icon-highway.svg';
import scenarioSelectedIcon from 'src/assets/icons/icon-menu-maintenance-scenario-selected.svg';
import scenarioIcon from 'src/assets/icons/icon-menu-maintenance-scenario.svg';
import roadConditionsSelectedtIcon from 'src/assets/icons/icon-road-conditions-selected.svg';
import roadConditionsIcon from 'src/assets/icons/icon-road-conditions.svg';
import infracareLogo from 'src/assets/logos/Infracare-logo.svg';
import Translate from '../../../localization/Localization';
import { RouteLocationStateModel } from '../../../views/RoadsCondition/models/RouteLocationStateModel';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { UserRoleIds } from '../../models/UserRoleIds';
import './NavMenuStyles.scss';

interface NavMenuProps {
    role: string
}

const NavMenu = (props: NavMenuProps & RouteComponentProps): JSX.Element => {

    const location = useLocation();
    let pathname = location.pathname.trim().toLowerCase()
    if (pathname !== "/" && !pathname.endsWith("/")) {
        pathname = pathname + "/";
    }

    let shouldDisplayNavMenu = pathname !== "/";

    let locationState = location.state as RouteLocationStateModel;

    //juste pour le POC
    if (!locationState) {
        let routeLocationState = sessionStorage.getItem("routeLocationState");
        locationState = JSON.parse(routeLocationState) as RouteLocationStateModel;
    }

    let isCurrentVersionProject = locationState?.isCurrentVersion;

    let environmentLabel = (window.location.origin.includes("localhost", -1)
        ? "LOCAL"
        : (window.location.origin.includes("2nd-dev", -1)
            ? "2ND-DEV"
            : window.location.origin.includes("2nd-rec", -1)
                ? "2ND-REC"
                : ""));

    const getHasScenarioAndProgrammingAccess = (role: string, isCurrentVersionProject: boolean): boolean => {
        return (role === UserRoleIds.administrator || role === UserRoleIds.agencyPlus || role === UserRoleIds.customerPlus || role === UserRoleIds.customerTech) && isCurrentVersionProject;
    }

    const [hasScenarioAndProgrammingAccess, setHasScenarioAndProgrammingAccess] = useState<boolean>(shouldDisplayNavMenu ? getHasScenarioAndProgrammingAccess(props.role, isCurrentVersionProject) : false);

    const onHighwaysButtonClick = () => {
        let urlRedirect = "/Highways";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onScenariosButtonClick = (): void => {
        let urlRedirect = "/ScenariosManagement";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onRoadsConditionButtonClick = () => {
        let urlRedirect = "/RoadsCondition";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onProgrammingsButtonClick = () => {
        let urlRedirect = "/ProgrammingsManagement";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onFollowUpWorksButtonClick = () => {
        let urlRedirect = "/FollowUpWorks";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onSettingsButtonClick = () => {
        let urlRedirect = "/ProjectSettings";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    const onGPSControlButtonClick = () => {
        let urlRedirect = "/GPSControl";
        let locationState = location.state as RouteLocationStateModel;

        props.navigate(urlRedirect, { state: locationState });
    }

    useEffect(() => {
        setHasScenarioAndProgrammingAccess(getHasScenarioAndProgrammingAccess(props.role, isCurrentVersionProject));
    }, [isCurrentVersionProject, props.role])

    let ret: JSX.Element = <></>;

    if (shouldDisplayNavMenu) {
        ret = <Box className="nav-menu" display="flex" flexDirection="column">
            <NavLink className="logo" to="/">
                <img src={infracareLogo} alt="infraCare Logo" />
                {environmentLabel !== "" &&
                    <Box style={{ color: "red" }} >
                        <span className="environment-label"><b>{environmentLabel}</b></span>
                    </Box>
                }
            </NavLink>
            {pathname !== "/usersadmin/" &&
                <>
                    <Box className={`item ${pathname === "/highways/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                        <IconButton className="item-link" onClick={onHighwaysButtonClick}>
                            <img src={pathname === "/highways/" ? highwaysSelectedtIcon : highwaysIcon} alt="Highways" />
                            <span className={`link-label ${pathname === "/highways/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_Highways}</span>
                        </IconButton>
                    </Box>
                    <Box className={`item ${pathname === "/roadscondition/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                        <IconButton className="item-link" onClick={onRoadsConditionButtonClick}>
                            <img src={pathname === "/roadscondition/" ? roadConditionsSelectedtIcon : roadConditionsIcon} alt="roads condition" />
                            <span className={`link-label ${pathname === "/roadscondition/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_PavementConditions}</span>
                        </IconButton>
                    </Box>
                    {hasScenarioAndProgrammingAccess &&
                        <>
                            <Box className={`item ${pathname === "/scenariosmanagement/" || pathname === "/scenariosectionsmanagement/" || pathname === "/scenariosectionsvisualisation/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                                <IconButton className="item-link" onClick={onScenariosButtonClick}>
                                    <img src={pathname === "/scenariosmanagement/" || pathname === "/scenariosectionsmanagement/" || pathname === "/scenariosectionsvisualisation/" ? scenarioSelectedIcon : scenarioIcon} alt="scenario" />
                                    <span className={`link-label ${pathname === "/scenariosmanagement/" || pathname === "/scenariosectionsmanagement/" || pathname === "/scenariosectionsvisualisation/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_MaintenanceScenario}</span>
                                </IconButton>
                            </Box>
                            <Box className={`item ${pathname === "/programmingsmanagement/" || pathname === "/programmingareasmanagement/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                                <IconButton className="item-link" onClick={onProgrammingsButtonClick}>
                                    <EditRoadIcon fontSize="large" className={pathname === "/programmingsmanagement/" || pathname === "/programmingareasmanagement/" ? 'icon active' : 'icon'} />
                                    <span className={`link-label ${pathname === "/programmingsmanagement/" || pathname === "/programmingareasmanagement/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_Programming_Works}</span>
                                </IconButton>
                            </Box>
                        </>
                    }
                    {isCurrentVersionProject &&
                        <Box className={`item ${pathname === "/followupworks/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                            <IconButton className="item-link" onClick={onFollowUpWorksButtonClick}>
                                <img src={pathname === "/followupworks/" ? followupworksSelectedIcon : followupworksIcon} alt="works Map" />
                                <span className={`link-label ${pathname === "/followupworks/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_FollowUpWorks}</span>
                            </IconButton>
                        </Box>
                    }
                    {(props.role === UserRoleIds.administrator || props.role === UserRoleIds.agency || props.role === UserRoleIds.agencyPlus || props.role === UserRoleIds.customerPlus || props.role === UserRoleIds.customerTech) &&
                        <Box className={`item ${pathname === "/projectsettings/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                            <IconButton className="item-link" onClick={onSettingsButtonClick}>
                                <BuildIcon className={pathname === "/projectsettings/" ? 'icon active' : 'icon'} />
                                <span className={`link-label ${pathname === "/projectsettings/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_Settings}</span>
                            </IconButton>
                        </Box>
                    }
                    {props.role === UserRoleIds.administrator &&
                        <Box className={`item ${pathname === "/gpscontrol/" ? 'active' : ''}`} display="flex" alignItems="center" justifyContent="center">
                            <IconButton className="item-link" onClick={onGPSControlButtonClick}>
                                <img src={pathname === "/gpscontrol/" ? gpscontrolSelectedIcon : gpscontrolIcon} alt="works Map" />
                                <span className={`link-label ${pathname === "/gpscontrol/" ? 'active' : ''}`}>{Translate.Resources.UI_NavMenu_GPSControl}</span>
                            </IconButton>
                        </Box>
                    }
                </>
            }
        </Box>
    }

    return ret;
}

export default React.forwardRef(withRouter(NavMenu));
