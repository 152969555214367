import { Box } from '@mui/system';
import React from 'react';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { QualityType } from '../../../RoadsCondition/models/QualityType';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';

interface QualityDetailsItemProps {
    quality: QualityType,
    qualityLabel: string,
    colorClassName: string,
    selectedScenario: Scenario,
    currency: string,
    qualityBudgetPercent: number,
    qualitySelectedSectionsLengthInMeters: number,
    qualitySelectedSectionsSurface: number,
    qualitySelectedSectionsBudget: number,
    qualitySelectedSectionsBudgetPercent: number
}

export const QualityDetailsItem = (props: QualityDetailsItemProps): JSX.Element => {

    const symbolOfKilometersOrMiles = MeasurementSystem.getSymbolOfKilometersOrMiles();
    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();

    return (
        <Box className="quality-details-item">
            <Box className={`quality-color ${props.colorClassName}`}></Box>
            <Box className="details" display="flex" flexDirection="column">
                <div className="percent">
                    <span>{props.qualitySelectedSectionsBudgetPercent ? `${props.qualitySelectedSectionsBudgetPercent?.toFixedLocalized(0)} %` : '-'}</span>
                    <span className="scenario-budget-value-s1">{` / ${props.qualityBudgetPercent} %`}</span>
                </div>
                <div className="quality">{props.qualityLabel}</div>
                <div className="budget">
                    <span>{`${props.qualitySelectedSectionsBudget?.toFixedLocalized(0)} ${props.currency}`}</span>
                    <span className="scenario-budget-value-s2">{` / ${Math.round((props.selectedScenario?.totalAmount * props.qualityBudgetPercent) / 100).toFixedLocalized(0)} ${props.currency}`}</span>
                </div>
                <div className="length">{`${MeasurementSystem.getLenghtInKilometersOrMiles(props.qualitySelectedSectionsLengthInMeters).toFixedLocalized(2)} ${symbolOfKilometersOrMiles} ≈ ${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(props.qualitySelectedSectionsSurface)?.toFixedLocalized(0)} ${symbolOfSurfaceUnit}`}</div>
            </Box>
        </Box>
    );
}