import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Translate from '../../../../localization/Localization';
import { getScenarioStatusCssClassValues, getScenarioStatusTextValues } from '../../models/ScenarioStatus';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import './HeaderStyles.scss';

interface HeaderComponentProps {
    selectedScenario: Scenario,
    loading: boolean,
    handleCancel: () => void,
    handleUpdateScenarioClicked: () => void
}

export const HeaderComponent = (props: HeaderComponentProps): JSX.Element => {

    const scenarioStatusTextValues = getScenarioStatusTextValues();
    const scenarioStatusCssClassValues = getScenarioStatusCssClassValues();

    return (
        <Box className="header">
            <Box display="flex" flexDirection="row" alignItems="center">
                {!props.loading &&
                    <>
                        <ArrowBackIcon className="arrow-icon" onClick={() => props.handleCancel()} />
                        <div className="scenario-label">{props.selectedScenario ? `${props.selectedScenario.year} - ${props.selectedScenario.label}` : ""}</div>
                    </>
                }
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                {!props.loading &&
                    <div className={`scenario-status ${scenarioStatusCssClassValues.get(props.selectedScenario?.status) ?? ''}`}>{props.selectedScenario ? (scenarioStatusTextValues.get(props.selectedScenario?.status) ?? `!!${props.selectedScenario?.status}!!`) : ""}</div>
                }
                <Button className="btn-secondary" disabled={props.loading} onClick={() => props.handleUpdateScenarioClicked()}>
                    <EditIcon />
                    {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_EditInformations}
                </Button>
            </Box>
        </Box>
    );

}