import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridColumnMenuCheckboxFilter, GridColumnMenuProps } from "@progress/kendo-react-grid";
import * as React from "react";
import Translate from "../../../../localization/Localization";
import { getBooleanList, getEnvironmentList, getHierarchyList, getImportanceList, getManagerList, getTrafficList } from "../../../../shared/models/Attributes/AttributesLabels";
import { RoadSectionGridViewData } from "../../models/RoadSectionGridViewData";

interface CustomCheckboxFilterProps {
    data: RoadSectionGridViewData[];
    field: string;
}

export const CustomCheckboxFilter = (props: GridColumnMenuProps & CustomCheckboxFilterProps) => {

    const hierarchieList = getHierarchyList();
    const trafficList = getTrafficList();
    const environmentList = getEnvironmentList();
    const managerList = getManagerList();
    const booleanList = getBooleanList();
    const importanceList = getImportanceList();

    let customCheckboxFilterProps = {};

    let newProps = Object.assign({}, props)
    if (newProps.filter?.filters && newProps.filter.filters && newProps.filter.filters[0]) {
        let newRootFilter = (newProps.filter.filters as CompositeFilterDescriptor[]).map((rootFilter) => {
            let newFilters = (rootFilter.filters as FilterDescriptor[]).map(filter => {
                if (filter.value === Translate.Resources.UI_Highways_Grid_Value_Empty)
                    return filter;

                if (filter.value === null || filter.value === '') {
                    filter.value = Translate.Resources.UI_Highways_Grid_Value_Empty;
                }

                else if (filter.field === "hierarchy" && !hierarchieList.find((c) => c.text === filter.value)) {
                    filter.value = hierarchieList.find((c) => c.value === filter.value)?.text
                }
                else if (filter.field === "environment" && !environmentList.find((c) => c.text === filter.value)) {
                    filter.value = environmentList.find((c) => c.value === filter.value)?.text
                }
                else if (filter.field === "manager" && !managerList.find((c) => c.text === filter.value)) {
                    filter.value = managerList.find((c) => c.value === filter.value)?.text
                }
                else if (filter.field === "traffic" && !trafficList.find((c) => c.text === filter.value)) {
                    filter.value = trafficList.find((c) => c.value === filter.value)?.text
                }
                else if ((filter.field === "border"
                    || filter.field === "bus"
                    || filter.field === "bikeLase"
                    || filter.field === "ditch"
                    || filter.field === "side")
                    && !booleanList.find((c) => c.text === filter.value)) {
                    filter.value = booleanList.find((c) => c.value === filter.value.toString())?.text
                }

                return filter;
            });

            rootFilter.filters = newFilters;
            return rootFilter;
        });

        newProps.filter.filters = newRootFilter;
    }

    let localizedDistinctData: { [x: string]: string }[] = [];

    if (props.column.field === "hierarchy") {
        hierarchieList.forEach(x => {
            localizedDistinctData.push({ [`${props.column.field}`]: x.text })
        });
    }
    else if (props.column.field === "traffic") {
        trafficList.forEach(x => {
            localizedDistinctData.push({ [`${props.column.field}`]: x.text })
        });
    }
    else if (props.column.field === "environment") {
        environmentList.forEach(x => {
            localizedDistinctData.push({ [`${props.column.field}`]: x.text })
        });
    }
    else if (props.column.field === "manager") {
        managerList.forEach(x => {
            localizedDistinctData.push({ [`${props.column.field}`]: x.text })
        });
    }
    else if (props.column.field === "importance") {
        importanceList.forEach(x => {
            localizedDistinctData.push({ [`${props.column.field}`]: x.toString() })
        });
    }
    else if (props.column.field === "border"
        || props.column.field === "bus"
        || props.column.field === "bikeLase"
        || props.column.field === "ditch"
        || props.column.field === "side") {
        booleanList.forEach(x => {
            localizedDistinctData.push({ [`${props.column.field}`]: x.text })
        });
    }
    else {
        let notSortedDistinctData: { [x: string]: string }[] = [];
        let DistinctData = new Set(props.data.map(e => e[props.column.field]?.toString()));
        DistinctData.delete("")
        DistinctData.delete(null)
        DistinctData.delete(undefined)

        DistinctData.forEach(value => {
            let localizedData = {
                [`${props.column.field}`]: value
            };
            notSortedDistinctData.push(localizedData)
        });

        localizedDistinctData = notSortedDistinctData.sort(function (a, b) {
            var x = a[`${props.column.field}`].toLowerCase();
            var y = b[`${props.column.field}`].toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    localizedDistinctData.push({ [`${props.column.field}`]: Translate.Resources.UI_Highways_Grid_Value_Empty });

    return (
        <div className="kendo-checkbox">
            <GridColumnMenuCheckboxFilter
                {...newProps}
                {...customCheckboxFilterProps}
                data={localizedDistinctData}
                expanded={true}
                searchBoxFilterOperator={"contains"}
            />
        </div>
    );
};