export enum DetectionLabels {
    affaissement_de_rive = "affaissement_de_rive",
    arrachement = "arrachement",
    bordure_trottoir = "bordure_trottoir",
    emergence_sur_chaussee = "emergence_sur_chaussee",
    faiençage = "faiençage",
    feu_de_circulation = "feu_de_circulation",
    fiss_long_ponte = "fiss_long_ponte",
    fiss_trans_ponte = "fiss_trans_ponte",
    fissure_longitudinale = "fissure_longitudinale",
    fissure_transversale = "fissure_transversale",
    joint_longitudinal = "joint_longitudinal",
    joint_longitudinal_ponte = "joint_longitudinal_ponte",
    nid_de_poule = "nid_de_poule",
    passage_pieton = "passage_pieton",
    pontage = "pontage",
    reparation = "reparation",
    ressuage = "ressuage",
    tampon = "tampon",
    tranchee = "tranchee",
    tranchee_transversale = "tranchee_transversale",
    fissuration_multidirectionnelle = "fissuration_multidirectionnelle",
    reparation_pata = "reparation_pata",
    pelade = "pelade",
    nid_de_poule_repare = "nid_de_poule_repare",
    joint_long_ponte = "joint_long_ponte"
}