import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Input, InputAdornment } from '@mui/material';
import { AuthenticationType, data, layer, Map as AzureMap, MapMouseEvent, Popup, source } from 'azure-maps-control';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { ScaleLoader } from 'react-spinners';
import azureMapPinIcon from 'src/assets/icons/icon-azure-map-pin.png';
import infracareLogo from 'src/assets/logos/Infracare-logo-home.svg';
import Translate from '../../localization/Localization';
import { SettingsProvider } from '../../SettingsProvider';
import { ensureCustomMapChoice } from '../../shared/Map/MapUtils';
import { CustomMapChoice } from '../../shared/models/CustomMapChoice';
import { MapCursorMode } from '../../shared/models/MapCursorMode';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { RouteLocationStateModel } from '../RoadsCondition/models/RouteLocationStateModel';
import './HomeStyles.scss';
import { GpsCoordinates } from './models/GpsCoordinates';
import { ProjectThumbnailModel } from './models/ProjectThumbnailModel';
import { ProjectVersion } from './services/dataContracts/queryStack/ProjectVersion';
import { HomeApiClient } from './services/HomeApiClient';

const projectDatasource = "project-ds";
const projectEntityType = "project";
const projectPinSymbolLayerId = "projectPinSymbolLayerId";

interface HomeViewState {
    loading: boolean,
    projects: ProjectThumbnailModel[]
}

class HomeView extends Component<RouteComponentProps, HomeViewState> {
    _isMounted: boolean;
    _environmentLabel: string;
    inputSearchProjectsRef: React.RefObject<HTMLInputElement>;
    popup: Popup;
    map: AzureMap;

    constructor(props: RouteComponentProps) {
        super(props);
        this.inputSearchProjectsRef = React.createRef();
        this.map = null;

        this._environmentLabel = (window.location.origin.includes("localhost", -1)
            ? "LOCAL"
            : (window.location.origin.includes("2nd-dev", -1)
                ? "2ND-DEV"
                : window.location.origin.includes("2nd-rec", -1)
                    ? "2ND-REC"
                    : ""));

        this.state = {
            loading: false,
            projects: []
        };
    }

    componentDidMount() {
        this._isMounted = true;

        //Pour le POC, nettoyer la session 
        sessionStorage.removeItem("routeLocationState");

        //Création de la Map
        this.map = this.createMap();

        this.refreshProjects("", this.map);
    }

    createMap = (): AzureMap => {
        var map = new AzureMap('project-map', {
            view: 'Auto',
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: SettingsProvider.Get().azureMapsApiKey
            }
        });

        map.events.add('ready', () => {
            ensureCustomMapChoice(map, CustomMapChoice.OpenStreetMap);
        });

        return map;
    }

    refreshProjects = async (searchText: string, map: AzureMap): Promise<void> => {
        this.setState({ loading: true });

        var projectsRawData = await this.getProjectsRawData(searchText)
        var extendedProjects = await this.buildExtendedProjects(projectsRawData);
        this.showProjectsOnMap(map, projectsRawData);

        this.setState({
            projects: extendedProjects,
            loading: false
        });
    }

    getProjectsRawData = (searchText: string): Promise<ProjectVersion[]> => {
        return HomeApiClient.GetProjects(searchText)
            .then(async (res) => {
                if (this._isMounted && res) {
                    return res.data;
                }

                return null;
            });
    }

    buildExtendedProjects = async (projects: ProjectVersion[]): Promise<ProjectThumbnailModel[]> => {
        if (projects) {
            var projectApiCalls: Promise<ProjectThumbnailModel>[] = [];
            projects.forEach((project: ProjectVersion) => projectApiCalls.push(HomeApiClient.GetProjectImageContent(project)));
            return await Promise.all(projectApiCalls);
        }

        return null
    }

    showProjectsOnMap = (map: AzureMap, projects: ProjectVersion[]) => {
        this.setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {

            if (!this._isMounted)
                return;

            let datasource = map.sources.getById(projectDatasource) as source.DataSource;
            if (datasource) {
                this.removeProjectDatasource(map);
            }

            datasource = this.createProjectsDatasource();
            this.createProjectPinSymbolLayer(map, datasource);

            var projectsGpsCoordinates: GpsCoordinates[] = [];
            projects.forEach((project: ProjectVersion) => {
                if (project.locationGeometry) {
                    this.createProjectPin(project, datasource);

                    projectsGpsCoordinates.push({
                        latitude: project.locationGeometry?.coordinates[0],
                        longitude: project.locationGeometry?.coordinates[1]
                    });
                }
            });

            if (projectsGpsCoordinates.length > 0) {
                this.setMapCameraInAverageLocationOfProjects(map, projectsGpsCoordinates);
            }
        });
    }

    createProjectsDatasource = (): source.DataSource => {
        var datasource = new source.DataSource(projectDatasource, {
            cluster: true,
            clusterProperties: {
                projectEntityType: ['+', ['case', ['==', ['get', 'EntityType'], projectEntityType], 1, 0]]
            }
        });
        return datasource;
    }

    removeProjectDatasource = (map: AzureMap): void => {
        var projectPinSymbolLayer = map.layers.getLayerById(projectPinSymbolLayerId);
        if (projectPinSymbolLayer) {
            map.layers.remove(projectPinSymbolLayerId);

            map.events.remove('mouseover', projectPinSymbolLayer, (e) => this.handleProjectMouseover(e as MapMouseEvent, map, this.popup));
            map.events.remove('mouseout', projectPinSymbolLayer, () => this.handleProjectMouseout(map, this.popup));
            map.events.remove('mousedown', projectPinSymbolLayer, (e) => this.handleProjectMouseDown(e as MapMouseEvent));
        }

        var datasource = map.sources.getById(projectDatasource) as source.DataSource;
        datasource.clear();
        map.sources.remove(datasource);
    }

    createProjectPin = (project: ProjectVersion, datasource: source.DataSource): void => {
        var pin = new data.Feature(new data.Point(project.locationGeometry.coordinates), { EntityType: projectEntityType, projectLabel: project.label }, project.projectVersionId);
        datasource.add(pin);
    }

    createProjectPinSymbolLayer = (map: AzureMap, datasource: source.DataSource): void => {
        if (!map.layers.getLayerById(projectPinSymbolLayerId)) {
            const symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, projectPinSymbolLayerId, {
                iconOptions: {
                    image: 'azure-map-pin-icon'
                },
                textOptions: {
                    textField: ['get', 'point_count_abbreviated'],
                    allowOverlap: true,
                    size: 16,
                    offset: [0, -0.5],
                    color: 'red'
                }
            });

            map.imageSprite
                .add('azure-map-pin-icon', azureMapPinIcon)
                .then(() => {
                    map.sources.add(datasource);
                    map.layers.add(symbolLayer);
                    map.events.add('mouseover', symbolLayer, (e: MapMouseEvent) => this.handleProjectMouseover(e, map, this.popup));
                    map.events.add('mouseout', symbolLayer, () => this.handleProjectMouseout(map, this.popup));
                    map.events.add('mousedown', symbolLayer, (e: MapMouseEvent) => this.handleProjectMouseDown(e));
                });

            this.popup = new Popup({ closeButton: false });
        }
    }

    handleProjectMouseover = (e: MapMouseEvent, map: AzureMap, popup: Popup): void => {
        this.setMapCursor(map, MapCursorMode.Pointer);
        var properties = (e.shapes[0] as any).properties;
        var isPinProject: boolean = properties.EntityType === projectEntityType;
        if (isPinProject) {
            popup.setOptions({
                content: `<div style="padding:10px;">${properties.projectLabel}</div>`,
                position: e.position,
                pixelOffset: [0, -18]
            });
        }
        else {
            popup.setOptions({
                content: `<div style="padding:10px;">${Translate.Resources.UI_Home_Map_Popup_Tooltip_SeveralExaminationOnArea}</div>`,
                position: e.position,
                pixelOffset: [0, -18]
            });
        }
        popup.open(map);
    }

    handleProjectMouseout = (map: AzureMap, popup: Popup): void => {
        this.setMapCursor(map, MapCursorMode.Auto);
        popup.close();
    }

    setMapCameraInAverageLocationOfProjects = (map: AzureMap, projectsGpsCoordinates: GpsCoordinates[]): void => {
        if (projectsGpsCoordinates.length === 1) {
            map.setCamera({ zoom: 13, center: [projectsGpsCoordinates[0].latitude, projectsGpsCoordinates[0].longitude], type: "ease", duration: 2000 });
        }
        else if (projectsGpsCoordinates.length > 1) {
            map.setCamera({
                bounds: data.BoundingBox.fromPositions([...projectsGpsCoordinates.map(x => [x.latitude, x.longitude])]),
                padding: 70,
                type: "ease",
                duration: 2000
            });
        }
    }

    setMapCursor = (map: AzureMap, cursor: MapCursorMode): void => {
        var classList = map.getCanvasContainer().classList;

        const cursorPointerClassName = "cursor-pointer";
        const cursorAutoClassName = "cursor-auto";

        let cursorClassName = null;

        switch (cursor) {
            case MapCursorMode.Pointer:
                cursorClassName = cursorPointerClassName;
                break;
            case MapCursorMode.Auto:
            default:
                cursorClassName = cursorAutoClassName;
                break;
        }

        if (cursorClassName !== cursorAutoClassName && classList.contains(cursorAutoClassName)) {
            classList.remove(cursorAutoClassName);
        }
        if (cursorClassName !== cursorPointerClassName && classList.contains(cursorPointerClassName)) {
            classList.remove(cursorPointerClassName);
        }

        if (!classList.contains(cursorClassName)) {
            classList.add(cursorClassName);
        }
    }

    clearSearchText = (): void => {
        this.inputSearchProjectsRef.current.value = "";
        this.refreshProjects("", this.map);
    }

    handleChangeSearchText = debounce((value: string): void => {
        this.refreshProjects(value, this.map);
    }, 500);

    handleProjectMouseDown = (e: MapMouseEvent): void => {
        var shape = e.shapes[0] as any;
        var properties = shape.properties;
        var isPinProject: boolean = properties.EntityType === projectEntityType;
        if (isPinProject) {
            if (properties.EntityType === projectEntityType) {
                var projectVersionId = shape.id as number;
                if (projectVersionId) {
                    var project = this.state.projects.find(x => x.projectVersionId === projectVersionId);
                    this.handleProjectClick(project);
                }
            }
        }
    }

    handleProjectClick = (project: ProjectThumbnailModel): void => {
        var urlRedirect = "/RoadsCondition";
        var routeLocationState: RouteLocationStateModel = {
            projectId: project.projectId,
            projectVersionId: project.projectVersionId,
            label: project.label,
            locationGeometry: project.locationGeometry,
            isCurrentVersion: project.isCurrentVersion
        };

        //juste une solution Pour le POC marquage et panneaux
        //sauvegarder routeLocationState pour qu'il soit accessible dans la page caché
        sessionStorage.setItem("routeLocationState", JSON.stringify(routeLocationState));

        this.props.navigate(urlRedirect, { state: routeLocationState });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.map?.dispose();
    }

    render() {
        return (
            <div className="home">
                <img src={infracareLogo} alt="infraCare Logo" className="logo-img" />
                {this._environmentLabel !== "" &&
                    <span style={{ color: "red" }} className="environment-label"><b>{this._environmentLabel}</b></span>
                }
                <div id="project-map"></div>
                <Box className="projects" display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" alignItems="center" className="search-element">
                        <Input disableUnderline className="input-search-projects" inputRef={this.inputSearchProjectsRef}
                            endAdornment={<>
                                <InputAdornment position="end" classes={{ root: 'input-icon-close-root' }}>
                                    <FontAwesomeIcon icon={faTimes} onClick={this.clearSearchText} />
                                </InputAdornment>
                                <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputAdornment>
                            </>}
                            id="input-search-projects-text"
                            placeholder={Translate.Resources.UI_Home_SearchProject_PlaceHolder_Search}
                            onChange={(event) => this.handleChangeSearchText(event.target.value)}
                        />
                    </Box>
                    <Box mt="16px" display="flex" flexDirection="row" className="projects-cards">
                        {this.state.loading &&
                            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                                <ScaleLoader
                                    width={5}
                                    height={20}
                                    radius={50}
                                    color="#000000"
                                    loading={this.state.loading}
                                />
                            </Box>
                        }
                        {!this.state.loading && this.state.projects.map((project: ProjectThumbnailModel) => {
                            return (
                                <Box key={`project_${project.projectId}`} title={project.label} className="project-item-card" onClick={() => this.handleProjectClick(project)}>
                                    {project.projectmageBlob && <img id={`image_${project.projectId}`} src={URL.createObjectURL(project.projectmageBlob)} alt={`image_${project.projectId}`} height="100%" width="100%" />}
                                    {!project.projectmageBlob && <Box className="project-item-without-image" height="100%" width="100%" bgcolor="gray"> </Box>}
                                    <Box className="project-label">
                                        <span>{project.label}</span>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </div>
        );
    }
}

export default React.forwardRef(withRouter(HomeView));