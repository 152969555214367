import axios, { AxiosResponse } from 'axios';
import { ClientAppSettings } from './dataContracts/controller/ClientAppSettings';

const controllerUrl = 'api/settings/';

export class SettingsApiClient {
    
    static Get = ()
        : Promise<AxiosResponse<ClientAppSettings>> => {
        return axios.get(controllerUrl);
    }
}