export const attributesLayers: { [id: string]: string } = {
    municipality: "municipalityLayer",
    district: "districtLayer",
    collaborativeDevelopmentZone: "collaborativeDevelopmentZoneLayer",
    hierarchy: "hierarchyLayer",
    traffic: "trafficLayer",
    environment: "environmentLayer",
    manager: "managerLayer",
    bus: "busLayer",
    bikeLase: "bikeLaseLayer",
    border: "borderLayer",
    ditch: "ditchLayer",
    side: "sideLayer",
}

export enum Hierarchy {
    main = "Main",
    local = "Local",
    residential = "Residential",
}

export enum Environment {
    agglomeration = "Agglomeration",
    cityCenter = "CityCenter",
    countrySide = "CountrySide",
}

export enum Traffic {
    low = "Low",
    medium = "Medium",
    strong = "Strong",
}

export enum Manager {
    municipality = "Municipality",
    communityOfMunicipalities = "CommunityOfMunicipalities",
    agglomerationCommunity = "AgglomerationCommunity",
    metropolis = "Metropolis",
    department = "Department",
    state = "State",
    private = "Private",
}

export const attributeEmpty: string = "empty";
export const attributeEmptyColor: string = "#6D8296";

export const hierarchyColors: { [id: string | null]: string } = {
    [Hierarchy.main.toString()]: "#33DBAA",
    [Hierarchy.local.toString()]: "#E36900",
    [Hierarchy.residential.toString()]: "#004191",
}

export const environmentColors: { [id: string | null]: string } = {
    [Environment.agglomeration.toString()]: "#21A1FF",
    [Environment.cityCenter.toString()]: "#4A16B3",
    [Environment.countrySide.toString()]: "#67E755",
}

export const trafficColors: { [id: string | null]: string } = {
    [Traffic.strong.toString()]: "#00D0FF",
    [Traffic.medium.toString()]: "#F24D00",
    [Traffic.low.toString()]: "#7768F6",
}

export const managerColors: { [id: string | null]: string } = {
    [Manager.municipality.toString()]: "#3EB9AB",
    [Manager.communityOfMunicipalities.toString()]: "#9300D3",
    [Manager.agglomerationCommunity.toString()]: "#337DAA",
    [Manager.metropolis.toString()]: "#6C2090",
    [Manager.department.toString()]: "#556B2F",
    [Manager.state.toString()]: "#AE61D3",
    [Manager.private.toString()]: "#F26184",
}

export const busColors: { [id: string | null]: string } = {
    ["true"]: "#FF4384",
    ["false"]: "#063971",
}

export const bikeLaseColors: { [id: string | null]: string } = {
    ["true"]: "#A656FF",
    ["false"]: "#705335",
}
export const borderColors: { [id: string | null]: string } = {
    ["true"]: "#FFA15C",
    ["false"]: "#DE4C8A",
}

export const ditchColors: { [id: string | null]: string } = {
    ["true"]: "#51D884",
    ["false"]: "#E63244",
}

export const sideColors: { [id: string | null]: string } = {
    ["true"]: "#00B9F1",
    ["false"]: "#89AC76",
}

let computedMunicipalityColors: { [id: string | null]: string } = {}
const municipalityColors: string[] = [
    "#9E45F3",
    "#84C247",
    "#4F8BF9",
    "#D1519E",
    "#CCBC67",
    "#DE5337",
    "#1A60D4",
    "#6F00C4",
    "#D800E6",
    "#AB3D3A",
]

let computedDistrictColors: { [id: string | null]: string } = {}
const districtColors: string[] = [
    "#28ADFC",
    "#EEA900",
    "#4D9B80",
    "#1338F5",
    "#7768F6",
    "#7F0AAA",
    "#CD3C29",
    "#224080",
    "#B6A14F",
    "#9AF200",
]

let computedCollaborativeDevelopmentZoneColorsColors: { [id: string | null]: string } = {}
const collaborativeDevelopmentZoneColors: string[] = [
    "#7768F6",
    "#ED00ED",
    "#00FFB3",
    "#9AF200",
    "#DDA900",
    "#4A16D8",
    "#6C2090",
    "#9E1E77",
    "#F24D00",
    "#514561",
]

export const getColors = (attributes: string, values: string[], reCompute: boolean = false) => {
    if (attributes === attributesLayers["municipality"]) {
        if (reCompute || Object.keys(computedMunicipalityColors).length === 0) {
            computedMunicipalityColors = computeColors(municipalityColors, values, computedMunicipalityColors);
        }
        return computedMunicipalityColors;
    }
    if (attributes === attributesLayers["district"]) {
        if (reCompute || Object.keys(computedDistrictColors).length === 0) {
            computedDistrictColors = computeColors(districtColors, values, computedDistrictColors);
        }
        return computedDistrictColors;
    }
    if (attributes === attributesLayers["collaborativeDevelopmentZone"]) {
        if (reCompute || Object.keys(computedCollaborativeDevelopmentZoneColorsColors).length === 0) {
            computedCollaborativeDevelopmentZoneColorsColors = computeColors(collaborativeDevelopmentZoneColors, values, computedCollaborativeDevelopmentZoneColorsColors);
        }
        return computedCollaborativeDevelopmentZoneColorsColors;
    }
    return [];
}
const computeColors = (colors: string[], values: string[], colorsDictionnary: { [id: string | null]: string }) => {
    colorsDictionnary = {};
    values.forEach((value, index) => colorsDictionnary[value] = getColor(colors, index));
    return colorsDictionnary;
}
const getColor = (colors: string[], index: number): string => {
    let colorIndex = index >= colors.length ? index - colors.length : index;
    return colors[colorIndex];
}

