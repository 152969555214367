import Translate from '../localization/Localization';
import { BusinessMessage, CommandResult } from '../shared/models/CommandResult';

export default class BusinessMessages {

    private static readonly _emptyMessages: string[] = [];

    private static getTranslations = <T>(businessMessages: BusinessMessage[], commandResultIndex?: number, extraResult?: T, applyDelegate?: (extraResult: T, index: number) => string) => {
        if (businessMessages.length > 0) {
            let translatedMessages: string[] = [];
            businessMessages.forEach(messageId => {
                let messageType = messageId.type.slice(messageId.type.lastIndexOf('.') + 1, messageId.type.length);
                let messageText = Translate.Resources[`${messageType}_${messageId.name}`];
                messageText = messageText ? messageText : messageId.name;

                if (extraResult && applyDelegate) {
                    let errorPrefix = (extraResult && applyDelegate ? `${applyDelegate(extraResult, commandResultIndex)}: ` : '');
                    translatedMessages.push(errorPrefix + messageText);
                }
                else {
                    translatedMessages.push(messageText);
                }
            });

            return translatedMessages;
        }

        return BusinessMessages._emptyMessages;
    }

    public static GetErrors = (data: CommandResult): string[] => {
        if (data && data.hasBusinessErrors) {
            let errors: string[] = null;
            errors = BusinessMessages.getTranslations(data.businessErrors);
            return errors ?? BusinessMessages._emptyMessages;
        }

        return BusinessMessages._emptyMessages;
    }

    public static GetWarnings = (data: CommandResult): string[] => {
        if (data && data.hasBusinessWarnings) {
            let warnings: string[] = null;
            warnings = BusinessMessages.getTranslations(data.businessWarnings);
            return warnings ?? BusinessMessages._emptyMessages;
        }

        return BusinessMessages._emptyMessages;
    }
}