import axios from 'axios';
import { SettingsProvider } from '../../SettingsProvider';

const baseUrl = "https://atlas.microsoft.com/";

export interface GpsCoordinates {
    longitude: number,
    latitude: number
}

export interface Address {
    id?: string,
    freeformAddress?: string,
    position?: GpsCoordinates
}

interface IAzureMapsAddress {
    entityType?: string,
    postalCode: string,
    freeformAddress: string
}

export class MapsService {

    private static GetAzureMapsApiKey = (): string => { return SettingsProvider.Get().azureMapsApiKey; }

    public static SearchAddress(searchText: string): Promise<Address[]> {
        const url = `${baseUrl}search/address/json?api-version=1.0&subscription-key=${MapsService.GetAzureMapsApiKey()}&limit=100&query=${encodeURIComponent(searchText)}`;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            const addresses: Address[] = [];

            const results = res.data?.results;
            if (results && results.length > 0) {
                for (const x of results) {
                    const entityType = x.entityType;
                    const address: IAzureMapsAddress = x.address;
                    const addressPosition = x.position;
                    if (address && address.postalCode) {
                        address.entityType = entityType;
                        const addressItem: Address = MapsService.GetAddress(address);
                        const position: GpsCoordinates = {
                            latitude: addressPosition?.lat,
                            longitude: addressPosition?.lon
                        };
                        addressItem.id = x.id;
                        addressItem.position = position;

                        addresses.push(addressItem);
                        if (addresses.length >= 5)
                            break;
                    }
                }
            }

            return addresses;
        });
    }

    private static GetAddress = (address: IAzureMapsAddress): Address => {
        let formatedFreeformAddress = "";
        formatedFreeformAddress = address.freeformAddress;

        const addressItem: Address = {
            freeformAddress: formatedFreeformAddress,
        };

        return addressItem;
    }

}
