import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, NativeSelect, Paper, PaperProps } from '@mui/material';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import Draggable from 'react-draggable';
import allProjectsVersionsIcon from 'src/assets/icons/icon-all-projects-versions.svg';
import Translate from '../../../localization/Localization';
import { ProjectVersion } from '../../../views/Home/services/dataContracts/queryStack/ProjectVersion';
import { RouteLocationStateModel } from '../../../views/RoadsCondition/models/RouteLocationStateModel';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import './AllProjectsVersionsDialogStyles.scss';

interface AllProjectsVersionsDialogProps {
    projects: ProjectVersion[],
    isOpen: boolean
    onCancel: () => void,
    onValidate: () => void
}

const AllProjectsVersionsDialog = (props: AllProjectsVersionsDialogProps & RouteComponentProps): JSX.Element => {

    const [selectedProject, setSelectedProject] = useState<ProjectVersion>(null);

    const PaperComponent = (props: PaperProps) => {
        return (
            <Draggable handle="#all-projects-versions-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
                <Paper {...props} />
            </Draggable>
        );
    }

    const handleAuscultationChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        var project = props.projects.find(x => x.projectVersionId === Number(event.target.value));
        if (project) {
            setSelectedProject(project);
        } else {
            setSelectedProject(null);
        }
    }

    const handleValidate = (): void => {
        props.onValidate();
        let urlRedirect = "/RoadsCondition";
        let routeLocationState: RouteLocationStateModel = {
            projectId: selectedProject.projectId,
            projectVersionId: selectedProject.projectVersionId,
            label: selectedProject.label,
            locationGeometry: selectedProject.locationGeometry,
            isCurrentVersion: selectedProject.isCurrentVersion
        };
        props.navigate(urlRedirect, { state: routeLocationState });
    }

    return (
        <Dialog
            disableEscapeKeyDown
            aria-modal="false"
            open={props.isOpen}
            onClose={props.onCancel}
            scroll="paper"
            className="all-projects-versions-dialog"
            PaperComponent={PaperComponent}
            aria-labelledby="all-projects-versions-dialog-title"
        >
            <DialogTitle id="all-projects-versions-dialog-title" >
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box className="dialog-title">
                        <img className="icon" src={allProjectsVersionsIcon} alt="all projects versions icon" />
                        {Translate.Resources.UI_NavMenu_ClickedZone_ExaminationsChoice}
                    </Box>
                    <Box>
                        <IconButton onClick={props.onCancel}>
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" className="clicked-zone-content">
                    <Box mb="15px">
                        <NativeSelect
                            value={selectedProject ? selectedProject.projectVersionId : ""}
                            onChange={(e) => handleAuscultationChange(e)}
                            name="auscultationChoice"
                            inputProps={{ 'aria-label': 'auscultationChoice' }}
                        >
                            <option value=""></option>
                            {orderBy(props.projects, [(p: ProjectVersion) => { return p.projectId.toLowerCase() }], ['asc']).map((project: ProjectVersion, index: number) => {
                                return (
                                    <option key={`auscultation-option-${index}`} value={project.projectVersionId}>
                                        {`${project.projectId} v${project.versionNumber} ${project.isCurrentVersion ? '*' : ''}`}
                                    </option>
                                )
                            })}
                        </NativeSelect>
                    </Box>
                    <Box display="flex" flexDirection="row" alignSelf="flex-end">
                        <Button variant="contained" color="primary" disabled={selectedProject === null} onClick={handleValidate}>{Translate.Resources.UI_NavMenu_ClickedZone_Button_Validate}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default React.forwardRef(withRouter(AllProjectsVersionsDialog));