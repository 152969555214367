import { Box } from '@mui/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { RoadTrunkLabelScoreExtended } from '../../models/RoadTrunkLabelScoreExtended';
interface CustomRoadHeaderCheckboxProps extends GridHeaderCellProps {
    sort?: SortDescriptor[],
    sortingField: string,
    filteredLabelsScores: RoadTrunkLabelScoreExtended[],
    handleHeaderCheckboxChanged: (checked: boolean) => void,
    handleSortColumnChange?: (sortItems: SortDescriptor[]) => void
}

export const CustomRoadHeaderCheckbox = (props: CustomRoadHeaderCheckboxProps) => {

    const { filteredLabelsScores } = props;

    let sortIconClass = "k-icon k-checkbox-header k-i-sort-not-sorted-sm";
    if (props.sort?.length > 0) {
        let currentSortIndex: number = props.sort.findIndex(x => x.field === props.sortingField);
        if (currentSortIndex !== -1) {
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ? "k-icon k-checkbox-header k-i-sort-asc-sm" : "k-icon k-checkbox-header k-i-sort-desc-sm";
        }
    }

    let checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        let dataGrid = filteredLabelsScores.filter(x => x.isSelected || x.isVisible);
        let isIndeterminate = dataGrid.every(x => x.isSelected === true) || dataGrid.every(x => x.isSelected === false) ? false : true;
        checkboxRef.current.indeterminate = isIndeterminate;
    }, []);

    const handleSortChange = () => {
        let sortItems: SortDescriptor[] = null;
        if (props.sort.length > 0 && props.sort[0].dir === 'asc' && props.sort[0].field === props.sortingField) {
            sortItems = [{ field: props.sortingField, dir: 'desc' }];
        } else if (props.sort.length > 0 && props.sort[0].dir === 'desc' && props.sort[0].field === props.sortingField) {
            sortItems = [];
        } else {
            sortItems = [{
                field: props.sortingField, dir: 'asc'
            }];
        }
        props.handleSortColumnChange(sortItems);
    }

    let dataGrid = filteredLabelsScores.filter(x => x.isSelected || x.isVisible);
    let checked: boolean = dataGrid.every(x => x.isSelected === true) ? true : (dataGrid.every(x => x.isSelected === false) ? false : null);

    return (
        <Box display="flex" flexDirection="row">
            <input type="checkbox" className="k-checkbox" ref={checkboxRef} checked={checked} onChange={(e) => props.handleHeaderCheckboxChanged(e.target.checked)} />
            <Box className="column-mouse-hover" onClick={handleSortChange}>
                <Box className={sortIconClass}></Box>
            </Box>
        </Box>
    );
}
