import { Box, Button } from '@mui/material';
import React from 'react';
import illustrationImage from 'src/assets/images/illustration.svg';
import undrawAddFilesImage from 'src/assets/images/undraw_add_files.svg';
import Translate from '../../../../localization/Localization';
import './ScenarioModuleDescriptionStyles.scss';

interface ScenarioModuleDescriptionComponentProps {
    handleCreateScenarioClicked: () => void
}

export const ScenarioModuleDescriptionComponent = (props: ScenarioModuleDescriptionComponentProps): JSX.Element => {

    return (
        <Box className="description">
            <Box className="description-text">
                <div className="text s1">{Translate.Resources.UI_Scenarios_Description_ThisModuleHelpsYouSelectTheSections}</div>
                <div className="text s2">{Translate.Resources.UI_Scenarios_Description_InfracareCanMakeYouAProposal}</div>
                <div className="text s3">
                    {Translate.Resources.UI_Scenarios_Description_YouHaveNotCreatedAnyScenariosYet}
                    <Button className="btn-primary" onClick={props.handleCreateScenarioClicked}>
                        {Translate.Resources.UI_Scenarios_Description_button_CreateScenario}
                    </Button>
                </div>
            </Box>
            <img src={undrawAddFilesImage} className="img undraw-add-files" alt="undraw add files" />
            <img src={illustrationImage} className="img illustration" alt="illustration" />
        </Box>
    );
}