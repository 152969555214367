import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Translate from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { QualityType } from '../../../RoadsCondition/models/QualityType';
import { RoadSection } from '../../models/RoadSection';
import { QualityDetailsItem } from './QualityDetailsItem';
import { SectionsSummaryModel } from './SectionsSummaryModel';
import './SectionsSummaryStyles.scss';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';

interface SectionsSummaryComponentProps {
    selectedScenario: Scenario,
    sectionsSummaryModel: SectionsSummaryModel,
    currency: string,
    sections: Map<number, RoadSection>,
    inEdit: boolean,
    measurementSystemType: MeasurementSystemType
}

export const SectionsSummaryComponent = (props: SectionsSummaryComponentProps): JSX.Element => {

    const [sectionsCostSum, setSectionsCostSum] = useState<number>(0);

    useEffect(() => {
        let sectionsCostSum = 0;
        props.sections.forEach((section) => {
            if ((props.inEdit && section.isSelected) || !props.inEdit) {
                sectionsCostSum += section.cost ?? 0;
            }
        });

        setSectionsCostSum(sectionsCostSum);
    }, [props.sections, props.measurementSystemType]);

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    return (
        <Box className="sections-summary">
            <Box className="qualities-details">
                <QualityDetailsItem
                    quality={QualityType.rehabilitation}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}
                    colorClassName="rehabilitation"
                    selectedScenario={props.selectedScenario}
                    currency={props.currency}
                    qualityBudgetPercent={props.selectedScenario ? props.selectedScenario.rehabilitationPercentage : null}
                    qualitySelectedSectionsLengthInMeters={props.sectionsSummaryModel ? props.sectionsSummaryModel.rehabilitationQualitySelectedSectionsLengthInMeters : null}
                    qualitySelectedSectionsSurface={props.sectionsSummaryModel ? props.sectionsSummaryModel.rehabilitationQualitySelectedSectionsSurface : null}
                    qualitySelectedSectionsBudget={props.sectionsSummaryModel ? props.sectionsSummaryModel.rehabilitationQualitySelectedSectionsCost : null}
                    qualitySelectedSectionsBudgetPercent={props.sectionsSummaryModel && props.selectedScenario?.totalAmount ? props.sectionsSummaryModel.rehabilitationQualitySelectedSectionsCost * 100 / props.selectedScenario.totalAmount : null}
                />
                <QualityDetailsItem
                    quality={QualityType.reinforcement}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}
                    colorClassName="reinforcement"
                    selectedScenario={props.selectedScenario}
                    currency={props.currency}
                    qualityBudgetPercent={props.selectedScenario ? props.selectedScenario.reinforcementPercentage : null}
                    qualitySelectedSectionsLengthInMeters={props.sectionsSummaryModel ? props.sectionsSummaryModel.reinforcementQualitySelectedSectionsLengthInMeters : null}
                    qualitySelectedSectionsSurface={props.sectionsSummaryModel ? props.sectionsSummaryModel.reinforcementQualitySelectedSectionsSurface : null}
                    qualitySelectedSectionsBudget={props.sectionsSummaryModel ? props.sectionsSummaryModel.reinforcementQualitySelectedSectionsCost : null}
                    qualitySelectedSectionsBudgetPercent={props.sectionsSummaryModel && props.selectedScenario?.totalAmount ? props.sectionsSummaryModel.reinforcementQualitySelectedSectionsCost * 100 / props.selectedScenario.totalAmount : null}
                />
                <QualityDetailsItem
                    quality={QualityType.generalMaintenance}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}
                    colorClassName="general-maintenance"
                    selectedScenario={props.selectedScenario}
                    currency={props.currency}
                    qualityBudgetPercent={props.selectedScenario ? props.selectedScenario.generalMaintenancePercentage : null}
                    qualitySelectedSectionsLengthInMeters={props.sectionsSummaryModel ? props.sectionsSummaryModel.generalMaintenanceQualitySelectedSectionsLengthInMeters : null}
                    qualitySelectedSectionsSurface={props.sectionsSummaryModel ? props.sectionsSummaryModel.generalMaintenanceQualitySelectedSectionsSurface : null}
                    qualitySelectedSectionsBudget={props.sectionsSummaryModel ? props.sectionsSummaryModel.generalMaintenanceQualitySelectedSectionsCost : null}
                    qualitySelectedSectionsBudgetPercent={props.sectionsSummaryModel && props.selectedScenario?.totalAmount ? props.sectionsSummaryModel.generalMaintenanceQualitySelectedSectionsCost * 100 / props.selectedScenario.totalAmount : null}
                />
                <QualityDetailsItem
                    quality={QualityType.localizedRepair}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}
                    colorClassName="localized-repair"
                    selectedScenario={props.selectedScenario}
                    currency={props.currency}
                    qualityBudgetPercent={props.selectedScenario ? props.selectedScenario.localizedRepairPercentage : null}
                    qualitySelectedSectionsLengthInMeters={props.sectionsSummaryModel ? props.sectionsSummaryModel.localizedRepairQualitySelectedSectionsLengthInMeters : null}
                    qualitySelectedSectionsSurface={props.sectionsSummaryModel ? props.sectionsSummaryModel.localizedRepairQualitySelectedSectionsSurface : null}
                    qualitySelectedSectionsBudget={props.sectionsSummaryModel ? props.sectionsSummaryModel.localizedRepairQualitySelectedSectionsCost : null}
                    qualitySelectedSectionsBudgetPercent={props.sectionsSummaryModel && props.selectedScenario?.totalAmount ? props.sectionsSummaryModel.localizedRepairQualitySelectedSectionsCost * 100 / props.selectedScenario.totalAmount : null}
                />
                <QualityDetailsItem
                    quality={QualityType.monitoring}
                    qualityLabel={Translate.Resources.UI_ActionsMenu_Quality_Monitoring}
                    colorClassName="monitoring"
                    selectedScenario={props.selectedScenario}
                    currency={props.currency}
                    qualityBudgetPercent={props.selectedScenario ? props.selectedScenario.monitoringPercentage : null}
                    qualitySelectedSectionsLengthInMeters={props.sectionsSummaryModel ? props.sectionsSummaryModel.monitoringQualitySelectedSectionsLengthInMeters : null}
                    qualitySelectedSectionsSurface={props.sectionsSummaryModel ? props.sectionsSummaryModel.monitoringQualitySelectedSectionsSurface : null}
                    qualitySelectedSectionsBudget={props.sectionsSummaryModel ? props.sectionsSummaryModel.monitoringQualitySelectedSectionsCost : null}
                    qualitySelectedSectionsBudgetPercent={props.sectionsSummaryModel && props.selectedScenario?.totalAmount ? props.sectionsSummaryModel.monitoringQualitySelectedSectionsCost * 100 / props.selectedScenario.totalAmount : null}
                />
            </Box>
            <Box className="budget-summary">
                <Box className="scenario-budget">
                    <div className="summary-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Scenario} / ${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Budget}`}</div>
                    <div className="summary-value">
                        <span>{`${sectionsCostSum.toFixedLocalized(0)} ${props.currency}`}</span>
                        <span className="scenario-budget-value-s1">{` / ${(props.selectedScenario?.totalAmount)?.toFixedLocalized(0)} ${props.currency}`}</span>
                    </div>
                </Box>
                <Box className="pie-chart">
                    {props.sectionsSummaryModel &&
                        <PieChartComponent
                            data={{
                                monitoringSectionsPercent: props.sectionsSummaryModel.monitoringQualitySelectedSectionsPercent,
                                localizedRepairSectionsPercent: props.sectionsSummaryModel.localizedRepairQualitySelectedSectionsPercent,
                                generalMaintenanceSectionsPercent: props.sectionsSummaryModel.generalMaintenanceQualitySelectedSectionsPercent,
                                reinforcementSectionsPercent: props.sectionsSummaryModel.reinforcementQualitySelectedSectionsPercent,
                                rehabilitationSectionsPercent: props.sectionsSummaryModel.rehabilitationQualitySelectedSectionsPercent
                            }}
                            innerRadius={8}
                            outerRadius={25}
                            paddingAngle={0}
                            cornerRadius={0}
                            startAngle={-180}
                            endAngle={180}
                            cx={20}
                            cy={20}
                        />
                    }
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="summary-title">{Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Sections}</div>
                    <div className="summary-value">{props.sectionsSummaryModel ? props.sectionsSummaryModel.totalSelectedSectionsCount : ''}</div>
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="summary-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Linear} (${symbolOfLengthUnit})`}</div>
                    <div className="summary-value">{props.sectionsSummaryModel ? MeasurementSystem.getLengthInMetersOrYards(props.sectionsSummaryModel.totalSelectedSectionsLengthInMeters).toFixedLocalized(0) : ''}</div>
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="summary-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Surface} (${symbolOfSurfaceUnit})`}</div>
                    <div className="summary-value">{props.sectionsSummaryModel ? MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(props.sectionsSummaryModel.totalSelectedSectionsSurface).toFixedLocalized(0) : ''}</div>
                </Box>
            </Box>
        </Box>
    )
}