import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Utilities from 'src/utils/Utilities';
import { useEffect } from 'react';

export interface Item {
    label: string,
    key: string,
    color: string,
}

export type DraggableListItemProps = {
    onClick: (item: string[]) => void,
    uncollapsed: () => void,
    items: Item[],
    label: string;
    index: number;
    visibleByDefault: boolean,
    isCollapsed: boolean,
};
const DraggableListItem = (props: DraggableListItemProps) => {

    const [open, setOpen] = React.useState(props.visibleByDefault);
    const [displayedItems, setDisplayedItem] = React.useState<{ [id: string]: boolean }>({});
    const [isAllHided, setIsAllHided] = React.useState<boolean>(true);
    const [items, setItems] = React.useState<Item[]>(props.items);

    useEffect(() => {

        if (props.visibleByDefault) {
            updateStateItem(props.items.map(e => e.key), true);
        }
    }, []);

    useEffect(() => {
        if (props.isCollapsed) {
            setOpen(false);
        }
    }, [props.isCollapsed]);

    //keep state of remaining elements, remove old elements and set new elements non visible.
    useEffect(() => {

        if (Object.keys(displayedItems).length > 0) {
            let newDisplayedObjects: { [id: string]: boolean } = {};

            props.items.forEach(item => {
                newDisplayedObjects[item.key] = displayedItems[item.key]
            })
            setDisplayedItem(newDisplayedObjects);
        }
    }, [JSON.stringify(items)]);

    useEffect(() => {

        setItems(props.items);
    }, [JSON.stringify(props.items)]);

    useEffect(() => {
        if (Object.keys(displayedItems).filter(e => displayedItems[e]).length === 0) {
            setIsAllHided(true);
        }
        else {
            setIsAllHided(false);
        }

    }, [JSON.stringify(displayedItems)]);

    const updateStateItem = (ids: string[], display: boolean) => {
        let updatedDisplayItems = Utilities.deepClone(displayedItems);
        ids.forEach(e =>
            updatedDisplayItems[e] = display
        );

        setDisplayedItem(updatedDisplayItems);

        props.onClick(Object.keys(updatedDisplayItems).filter(e => updatedDisplayItems[e]));
    }

    const handleClickExpand = (handleClick: boolean) => {
        if (handleClick) {
            setOpen(!open);
            props.uncollapsed();
        }
    }
    const onVisibilityClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, visible: boolean) => {
        event.stopPropagation();
        updateStateItem(props.items.map(e => e.key), visible)
    }

    const getItemList = (item: Item): JSX.Element =>

        <ListItemButton key={"listItemButton-" + item.key} sx={{ pl: 4 }}>
            <ListItemIcon>
                {
                    displayedItems[item.key] ?
                        <RemoveRedEyeIcon onClick={() => updateStateItem([item.key], false)} />
                        :
                        <VisibilityOffIcon onClick={() => updateStateItem([item.key], true)} />
                }

            </ListItemIcon>
            <div style={{ backgroundColor: item.color, width: "18px", height: "18px", marginRight: "20px" }} />
            <ListItemText primary={item.label} />
        </ListItemButton>;

    return (

        <Draggable draggableId={props.label} index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <ListItemButton onClick={(e) => handleClickExpand(true)}>
                        <ListItemIcon >
                            <DragHandleIcon onClick={(e) => { e.stopPropagation() }} style={{ marginRight: "5px" }} />
                            {
                                !isAllHided ?
                                    <RemoveRedEyeIcon onClick={(e) => onVisibilityClick(e, false)} />
                                    :
                                    <VisibilityOffIcon onClick={(e) => onVisibilityClick(e, true)} />
                            }
                        </ListItemIcon>
                        <ListItemText primary={props.label} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <List component="div" disablePadding >
                            {props.items.map(e => getItemList(e))}
                        </List>
                    </Collapse>
                </div>
            )}
        </Draggable>
    );
};

export default DraggableListItem;

// https://codesandbox.io/p/sandbox/draggable-material-ui-oj3wz?file=%2Fsrc%2Fcomponents%2FDraggableList.tsx%3A17%2C5-28%2C23