import Translate from "../../../localization/Localization"
import { ProgrammingAreaStatus } from "../services/dataContracts/queryStack/ProgrammingAreaStatus"

export const getProgrammingAreaStatusTextValues = () => {
    return (
        new Map<ProgrammingAreaStatus, string>([
            [ProgrammingAreaStatus.draft, Translate.Resources.UI_Programming_Area_Status_Draft],
            [ProgrammingAreaStatus.workToDo, Translate.Resources.UI_Programming_Area_Status_ToBeCarriedOut],
            [ProgrammingAreaStatus.workDone, Translate.Resources.UI_Programming_Area_Status_Finished]
        ])
    )
}

export const getProgrammingAreaStatusCssClassValues = () => {
    return (
        new Map<ProgrammingAreaStatus, string>([
            [ProgrammingAreaStatus.draft, 'draft'],
            [ProgrammingAreaStatus.workToDo, 'to-be-carried-out'],
            [ProgrammingAreaStatus.workDone, 'finished']
        ])
    )
}