import { Box } from '@mui/material';
import { data, MapMouseEvent } from 'azure-maps-control';
import { debounce, groupBy } from 'lodash';
import React, { Component } from 'react';
import Translate, { Localization } from '../../../../localization/Localization';
import { ProgrammingStatus } from '../../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { PageLoaderComponent } from '../../../../shared/components/PageLoader/PageLoaderComponent';
import { RoadSectionDetailsComponent } from '../../../../shared/components/RoadSectionDetails/RoadSectionDetailsComponent';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { Point } from '../../../../shared/models/Point';
import { ShapeEntityType } from '../../../../shared/models/ShapeEntityType';
import ToastService from '../../../../ToastService';
import BusinessMessages from '../../../../utils/BusinessMessages';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../../../withRouter';
import { ProjectVersion } from '../../../Home/services/dataContracts/queryStack/ProjectVersion';
import { GetRoadWorksRequestArgs } from '../../../Programmings/services/dataContracts/controller/GetRoadWorksRequestArgs';
import { ProgrammingAreaRequestArgs } from '../../../Programmings/services/dataContracts/controller/ProgrammingAreaRequestArgs';
import { RequestProgrammingRequestArgs } from '../../../Programmings/services/dataContracts/controller/RequestProgrammingRequestArgs';
import { FilteredProgramming } from '../../../Programmings/services/dataContracts/queryStack/FilteredProgramming';
import { ProgrammingsApiClient } from '../../../Programmings/services/ProgrammingsApiClient';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { ImageExtended } from '../../../RoadsCondition/models/ImageExtended';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { OtherAttributes } from '../../../RoadsCondition/models/OtherAttributes';
import { RoadSectionViewData } from '../../../RoadsCondition/models/RoadSectionViewData';
import { RouteLocationStateModel } from '../../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { Environment } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { StepImageAnomalies } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { RoadsConditionApiClient } from '../../../RoadsCondition/services/RoadsCondition/RoadsConditionApiClient';
import { RoadSection } from '../../models/RoadSection';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import { ActionsMenuComponent } from '../CommonComponents/ActionsMenuComponent';
import { HeaderComponent } from '../CommonComponents/HeaderComponent';
import { SectionsSummaryComponent } from '../CommonComponents/SectionsSummaryComponent';
import { SectionsSummaryModel } from '../CommonComponents/SectionsSummaryModel';
import ScenariosUtilities from '../ScenariosUtilities';
import { ScenarioMapComponent } from './components/ScenarioMapComponent';
import { SectionsComponent } from './components/SectionsComponent';
import './ScenarioSectionsVisualisationStyles.scss';

interface ScenarioSectionsVisualisationViewState {
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    mergedProject: MergedProjectVersion,
    selectedScenario: Scenario,
    selectedSectionsIds: Set<number>,
    relatedScenarioSections: Map<number, RoadSection>,
    currentCurrency: string,
    filterdSectionsIds: Set<number>,
    filterdSectionsPositions: data.Position[],
    sectionsSummaryModel: SectionsSummaryModel,
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    costRatios: CostRatio[],
    measurementSystemType: MeasurementSystemType,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    perStepImagesAnomalies: Map<number, StepImageAnomalies[]>,
    filteredProgrammingsFromFilter: FilteredProgramming[],
    projectVersionAnomalies: Map<string, string>,
    isRoadSectionDetailsOpened: boolean,
    selectedImage: ImageExtended,
    selectedRoadSection: RoadSectionViewData,
    lastSelectedSection: number
}

const initialState: ScenarioSectionsVisualisationViewState = {
    loading: false,
    isSectionsDrawerOpened: true,
    mergedProject: null,
    selectedScenario: null,
    selectedSectionsIds: new Set<number>(),
    relatedScenarioSections: new Map<number, RoadSection>(),
    currentCurrency: null,
    filterdSectionsIds: new Set<number>(),
    filterdSectionsPositions: [],
    sectionsSummaryModel: null,
    activeAnomalies: new Set<string>(),
    activeQualities: new Set<number>([]),
    activeMunicipalities: new Set<string>(),
    activeDistricts: new Set<string>(),
    activeCollaborativeDevelopmentZones: new Set<string>(),
    activeHierarchies: new Set<Hierarchy>(),
    activeTraffics: new Set<Traffic>(),
    activeEnvironments: new Set<Environment>(),
    activeManagers: new Set<Manager>(),
    activeImportances: new Set<string>(),
    activeOtherAttributes: new Set<string>(),
    costRatios: [],
    measurementSystemType: null,
    selectedProgrammings: [],
    selectedYears: [],
    activeStatus: new Set<ProgrammingStatus>(),
    perStepImagesAnomalies: new Map<number, StepImageAnomalies[]>(),
    filteredProgrammingsFromFilter: null,
    projectVersionAnomalies: new Map<string, string>(),
    isRoadSectionDetailsOpened: false,
    selectedImage: null,
    selectedRoadSection: null,
    lastSelectedSection: null
}

interface ScenarioSectionsVisualisationViewProps {
    role: string
}

export class ScenarioSectionsVisualisationView extends Component<ScenarioSectionsVisualisationViewProps & RouteComponentProps, ScenarioSectionsVisualisationViewState> {
    _isMounted: boolean;
    projectId: string;
    projectVersionId: number;
    locationGeometry: Point;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>;
    hasScoreAnalysisAccess: boolean;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.inputSearchRoadsRef = React.createRef();
        this.hasScoreAnalysisAccess = this.props.role === "ADM" || this.props.role === "ATXPLUS" || this.props.role === "CLIPLUS" || this.props.role === "CLITECH";

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();
        initialState.activeQualities = RoadsConditionAndScenariosShared.getInitialActiveQualities();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.setState({
            loading: true
        });

        this.projectId = locationState.projectId;
        this.projectVersionId = locationState.projectVersionId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const scenarioId = Number(query.get('scenarioId'));

        let data = await ScenariosUtilities.getScenarioAndProjectSettingsData(this.projectId, scenarioId, this.mergedProjectAuscultationsCache, this.projectVersionsCache);

        let scenario = data.scenario;
        let mergedProject = data.mergedProject;
        let projectCurrency = data.projectCurrency;
        let workPriorities = data.workPriorities;
        let costRatios = data.costRatios;

        let relatedScenarioSections = new Map<number, RoadSection>();
        let filterdSectionsIds = new Set<number>();

        mergedProject.roadsSections.forEach((element) => {
            let sectionId = element.roadSectionId;
            let section = { ...element } as RoadSection;
            if (scenario.sections.map(e => e.roadSectionId).includes(sectionId)) {
                let sectionData = ScenariosUtilities.getSectionDataWhenHasImportance(section.importance, section.score, section.traffic, workPriorities, costRatios);
                ScenariosUtilities.updateSectionData(section, sectionData);
                section.isVisible = true;
                section.isSelected = false;

                relatedScenarioSections.set(sectionId, section);
                filterdSectionsIds.add(sectionId)
            }
        });

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(filterdSectionsIds, mergedProject.roadsSections, relatedScenarioSections);

        this.setState({
            mergedProject,
            selectedScenario: scenario,
            relatedScenarioSections: relatedScenarioSections,
            currentCurrency: projectCurrency,
            filterdSectionsIds: filterdSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            costRatios: costRatios,
            loading: false
        });
    }

    handleSectionsDrawerClosed = (): void => {
        this.setState({
            isSectionsDrawerOpened: false
        });
    }

    handleSectionsDrawerOpened = (): void => {
        this.setState({
            isSectionsDrawerOpened: true
        });
    }

    handleChangeRoadsSearchText = debounce((value: string, state: ScenarioSectionsVisualisationViewState): void => {
        let inputValue = value;
        let relatedScenarioSections = state.relatedScenarioSections;
        let filterdSectionsIds = new Set<number>();
        if (inputValue.length > 2) {
            filterdSectionsIds = this.updateSectionsVisibility(relatedScenarioSections, state.activeQualities, inputValue.trim().toLowerCase().removeDiacritics(), state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        }
        else if (inputValue.length === 0) {
            filterdSectionsIds = this.updateSectionsVisibility(relatedScenarioSections, state.activeQualities, "", state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        }

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(filterdSectionsIds, state.mergedProject.roadsSections, relatedScenarioSections);

        let filterdSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdSectionsIds, state.mergedProject.roadsSections);

        this.setState({
            relatedScenarioSections: relatedScenarioSections,
            filterdSectionsIds: filterdSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            filterdSectionsPositions: filterdSectionsPositions
        });
    }, 500);

    handleDisplayDetections = (activeAnomalies: Set<string>): void => {
        this.setState({
            activeAnomalies: activeAnomalies
        });
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>, state: ScenarioSectionsVisualisationViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let relatedScenarioSections = state.relatedScenarioSections;
        let filterdSectionsIds = this.updateSectionsVisibility(relatedScenarioSections, activeQualities, inputValue, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filterdSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdSectionsIds, state.mergedProject.roadsSections);

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(filterdSectionsIds, state.mergedProject.roadsSections, relatedScenarioSections);

        this.setState({
            relatedScenarioSections: relatedScenarioSections,
            filterdSectionsIds: filterdSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            filterdSectionsPositions: filterdSectionsPositions,
            activeQualities: activeQualities
        });
    }

    handleDisplaySectionsFromGeographiesFilters = (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, state: ScenarioSectionsVisualisationViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let relatedScenarioSections = state.relatedScenarioSections;
        let filterdSectionsIds = this.updateSectionsVisibility(relatedScenarioSections, state.activeQualities, inputValue, activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filterdSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdSectionsIds, state.mergedProject.roadsSections);

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(filterdSectionsIds, state.mergedProject.roadsSections, relatedScenarioSections);

        this.setState({
            relatedScenarioSections: relatedScenarioSections,
            filterdSectionsIds: filterdSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            filterdSectionsPositions: filterdSectionsPositions,
            activeMunicipalities: activeMunicipalities,
            activeDistricts: activeDistricts,
            activeCollaborativeDevelopmentZones: activeCollaborativeDevelopmentZones
        });
    }

    handleDisplaySectionsFromAttributesFilters = (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: ScenarioSectionsVisualisationViewState): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let relatedScenarioSections = state.relatedScenarioSections;
        let filterdSectionsIds = this.updateSectionsVisibility(relatedScenarioSections, state.activeQualities, inputValue, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state);
        let filterdSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdSectionsIds, state.mergedProject.roadsSections);

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(filterdSectionsIds, state.mergedProject.roadsSections, relatedScenarioSections);

        this.setState({
            relatedScenarioSections: relatedScenarioSections,
            filterdSectionsIds: filterdSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            filterdSectionsPositions: filterdSectionsPositions,
            activeHierarchies: activeHierarchies,
            activeTraffics: activeTraffics,
            activeEnvironments: activeEnvironments,
            activeManagers: activeManagers,
            activeImportances: activeImportances,
            activeOtherAttributes: activeOtherAttributes
        });
    }

    updateSectionsVisibility = (relatedScenarioSections: Map<number, RoadSection>, activeQualities: Set<number>, inputSearchText: string, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: ScenarioSectionsVisualisationViewState): Set<number> => {
        let filterdSectionsIds = new Set<number>(state.filterdSectionsIds);

        relatedScenarioSections.forEach((section) => {
            if ((activeQualities.size > 0 && activeQualities.has(section.score)) &&
                (section.labelLowerWithoutDiacritics?.includes(inputSearchText)) &&
                ((activeMunicipalities.size > 0 && activeMunicipalities.has(section.municipality)) || activeMunicipalities.size === 0) &&
                ((activeDistricts.size > 0 && activeDistricts.has(section.district)) || activeDistricts.size === 0) &&
                ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(section.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                ((activeHierarchies.size > 0 && activeHierarchies.has(section.hierarchy)) || activeHierarchies.size === 0) &&
                ((activeTraffics.size > 0 && activeTraffics.has(section.traffic)) || activeTraffics.size === 0) &&
                ((activeEnvironments.size > 0 && activeEnvironments.has(section.environment)) || activeEnvironments.size === 0) &&
                ((activeManagers.size > 0 && activeManagers.has(section.manager)) || activeManagers.size === 0) &&
                ((activeImportances.size > 0 && activeImportances.has(section.importance ? section.importance.toString() : null)) || activeImportances.size === 0) &&
                ((activeOtherAttributes.size > 0 && (
                    (section.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                    (section.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                    (section.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                    (section.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                    (section.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                    (!section.bus && !section.bikeLase && !section.border && !section.ditch && !section.side && activeOtherAttributes.has(null))
                )) || activeOtherAttributes.size === 0)) {
                section.isVisible = true;

                if (!filterdSectionsIds.has(section.roadSectionId)) {
                    filterdSectionsIds.add(section.roadSectionId);
                }
            }
            else {
                section.isVisible = false;

                if (filterdSectionsIds.has(section.roadSectionId)) {
                    filterdSectionsIds.delete(section.roadSectionId);
                }
            }
        });

        return filterdSectionsIds;
    }

    handleUpdateScenarioSectionsClicked = (state: ScenarioSectionsVisualisationViewState): void => {
        let urlRedirect = `/ScenarioSectionsManagement?scenarioId=${state.selectedScenario.scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleCancel = (): void => {
        let urlRedirect = `/ScenariosManagement`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleUpdateScenarioClicked = (state: ScenarioSectionsVisualisationViewState): void => {
        let urlRedirect = `/ScenariosManagement?scenarioId=${state.selectedScenario.scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType
        });
    }

    handleRequestProgrammingClicked = async (state: ScenarioSectionsVisualisationViewState): Promise<void> => {
        let scenario = state.selectedScenario;

        let sections: RoadSectionViewData[] = [];
        scenario.sections.forEach((x) => {
            let section = state.mergedProject.roadsSections.get(x.roadSectionId);
            sections.push(section);
        });

        let areas: ProgrammingAreaRequestArgs[] = [];
        let sectionsByLabelGroup = groupBy(sections, x => x.roadLabel);
        Object.entries(sectionsByLabelGroup).forEach(([key, value]) => {

            let sumOfLengthInMeters = 0;
            let sumOfSurfaces = 0;
            let budgetAmount = 0;
            let sectionsId: number[] = [];

            value.forEach((section) => {
                let lengthInMeters = Math.round(section.lengthInMeters);
                let surface = Math.round(lengthInMeters * section.widthInMeters);

                sumOfLengthInMeters += (lengthInMeters ?? 0);
                sumOfSurfaces += (surface ?? 0);

                let costRatio: CostRatio = state.costRatios.find(x => x.traffic === section.traffic && x.score === section.score);
                let sectionCostRatio = costRatio ? costRatio.costRatioValue : null;
                let sectionBudget = surface && sectionCostRatio ? surface * sectionCostRatio : null;
                budgetAmount += (sectionBudget ?? 0);

                sectionsId.push(section.roadSectionId);
            });

            let areaCostRatio = sumOfSurfaces ? budgetAmount / sumOfSurfaces : null;

            areas.push({
                label: key,
                costRatio: areaCostRatio,
                budgetAmount: budgetAmount,
                lengthInLinearMeters: sumOfLengthInMeters,
                areaInSquareMeters: sumOfSurfaces,
                sectionsId: sectionsId
            });
        });

        let args: RequestProgrammingRequestArgs = {
            scenarioId: scenario.scenarioId,
            label: `${scenario.label} - ${new Date().toLocaleString()}`,
            ianaTimeZoneId: Localization.ianaTimeZoneId,
            projectId: this.projectId,
            projectVersionId: scenario.projectVersionId,
            year: scenario.year,
            areas: areas
        };

        await ProgrammingsApiClient.RequestProgramming(args)
            .then((res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                ToastService.showSuccessToast(Translate.Resources.UI_ScenarioSectionsVisualisationView_TheScenarioWasSuccessfullySentToProgramming);

                let urlRedirect = `/ScenariosManagement`;
                let locationState = this.props.location.state as RouteLocationStateModel;
                this.props.navigate(urlRedirect, { state: locationState });
            });
    }

    handleDisplayAreasFromWorksFilter = (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>): void => {
        this.setState({
            loading: true
        });

        let status: number[] = Array.from(activeStatus).map(x => {
            return x === ProgrammingStatus.finished ? 2 : (x === ProgrammingStatus.toBeCompleted ? 1 : null);
        });

        let data: GetRoadWorksRequestArgs = {
            years: selectedYears,
            labels: selectedProgrammings,
            status
        };

        ProgrammingsApiClient.GetRoadWorksByFilters(this.projectId, data)
            .then((res) => {
                this.setState({
                    filteredProgrammingsFromFilter: res.data,
                    selectedProgrammings,
                    selectedYears,
                    activeStatus,
                    loading: false
                });
            });
    }

    setAnomaliesData = ({ anomalies, perStepImagesAnomaliesMap }: { anomalies: Map<string, string>, perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]> }): void => {
        this.setState({
            projectVersionAnomalies: anomalies,
            perStepImagesAnomalies: perStepImagesAnomaliesMap
        })
    }

    handleCloseRoadSectionDetails = (): void => {
        this.setState({
            selectedImage: null,
            selectedRoadSection: null,
            isRoadSectionDetailsOpened: false
        });
    }

    handleImageChanged = async (imageId: number, state: ScenarioSectionsVisualisationViewState): Promise<void> => {
        if (imageId === null)
            return;

        let image: ImageExtended = state.mergedProject.imagesDico.get(imageId);
        if (image) {
            let roadSection = state.mergedProject.roadsSections.get(image.roadSectionId);

            if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
            }

            this.showImageAndSectionDetails(image, state.mergedProject);
        }
        else {
            await RoadsConditionApiClient.GetPerRoadSectionImagesFromImageId(imageId)
                .then((res) => {
                    let images = res.data as ImageExtended[];
                    let roadSection = state.mergedProject.roadsSections.get(images[0].roadSectionId);
                    RoadsConditionAndScenariosShared.buildViewDataFromSectionImages(state.mergedProject, images, roadSection);

                    image = state.mergedProject.imagesDico.get(imageId);

                    if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                        RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
                    }

                    this.showImageAndSectionDetails(image, state.mergedProject);
                });
        }
    }

    handleStepChanged = (selectedImage: ImageExtended, searchByNext: boolean, state: ScenarioSectionsVisualisationViewState): void => {
        let image: ImageExtended = state.mergedProject.imagesDico.get(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId);
        while (image?.roadStepId === selectedImage.roadStepId) {
            image = state.mergedProject.imagesDico.get(searchByNext ? image.nextImageId : image.previousImageId);
        }

        if (image) {
            this.handleImageChanged(image?.imageId, state);
        }
        else {
            this.handleImageChanged(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId, state);
        }
    }

    showImageAndSectionDetails = (image: ImageExtended, mergedProject: MergedProjectVersion): void => {
        this.setState((prevState, props) => {
            let roadSection = mergedProject.roadsSections.get(image.roadSectionId);

            return {
                selectedImage: image,
                selectedRoadSection: roadSection,
                isRoadSectionDetailsOpened: true,
                mergedProject: mergedProject,
                isSectionsDrawerOpened: false
            };
        });
    }

    handleDisplayImageFromSectionClicked = async (e: MapMouseEvent): Promise<void> => {
        let mergedProject = this.mergedProjectAuscultationsCache.get(this.projectVersionId);

        let isAltKeyPressed = (e.originalEvent as any).altKey;
        let isCtrlKeyPressed = (e.originalEvent as any).ctrlKey;
        let isShiftKeyPressed = (e.originalEvent as any).shiftKey;

        let clickedSectionId: number = null;
        for (var i = 0; i < e.shapes.length; i++) {
            let shape = (e.shapes[i] as any);
            let entityType = shape?.data?.properties?.EntityType;
            if (entityType === ShapeEntityType.section) {
                clickedSectionId = shape?.data?.properties?.RoadSectionId;
                break;
            }
        }

        let section: RoadSectionViewData = mergedProject.roadsSections.get(clickedSectionId);
        if (!section || section.roadSectionScoreId === null)
            return;

        console.log("section : ");
        console.log(section);

        let clickedPosition: data.Position = e.position;
        if (clickedPosition) {
            if (!isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
                //Click simple: dans ce cas il faut positionner l'icone voiture dans la position de l'image la plus proche de la sélection
                let image = await RoadsConditionAndScenariosShared.handleSectionClicked(section, clickedPosition, mergedProject, this.hasScoreAnalysisAccess, false);
                this.showImageAndSectionDetails(image, mergedProject);
                return;
            }
        }
    }

    updateSelectedSections = (selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>, lastSelectedSection: number): void => {
        this.setState({
            relatedScenarioSections: sections,
            selectedSectionsIds: selectedSectionsIds,
            lastSelectedSection: lastSelectedSection
        });
    }

    render() {
        const state = this.state;

        return (
            <Box className="scenario-visualisation">
                {this.state.loading ? <PageLoaderComponent /> : null}
                <HeaderComponent selectedScenario={state.selectedScenario} loading={state.loading} handleCancel={() => this.handleCancel()} handleUpdateScenarioClicked={() => this.handleUpdateScenarioClicked(state)} />
                <ActionsMenuComponent
                    inputRef={this.inputSearchRoadsRef}
                    projectId={this.projectId}
                    activeAnomalies={state.activeAnomalies}
                    activeQualities={state.activeQualities}
                    activeMunicipalities={state.activeMunicipalities}
                    activeDistricts={state.activeDistricts}
                    activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                    activeHierarchies={state.activeHierarchies}
                    activeTraffics={state.activeTraffics}
                    activeEnvironments={state.activeEnvironments}
                    activeManagers={state.activeManagers}
                    activeImportances={state.activeImportances}
                    activeOtherAttributes={state.activeOtherAttributes}
                    mergedProject={state.mergedProject}
                    loading={state.loading}
                    isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    isRoadSectionDetailsOpened={state.isRoadSectionDetailsOpened}
                    handleSectionsDrawerClosed={this.handleSectionsDrawerClosed}
                    handleSectionsDrawerOpened={this.handleSectionsDrawerOpened}
                    handleSearchTextChanged={(value) => this.handleChangeRoadsSearchText(value, state)}
                    handleDisplayDetections={(activeAnomalies: Set<string>) => this.handleDisplayDetections(activeAnomalies)}
                    handleDisplaySections={(activeQualities: Set<number>) => this.handleDisplaySectionsFromQualityFilters(activeQualities, state)}
                    handleDisplaySectionsFromGeographiesFilters={(activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => this.handleDisplaySectionsFromGeographiesFilters(activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state)}
                    handleDisplaySectionsFromAttributesFilters={(activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => this.handleDisplaySectionsFromAttributesFilters(activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state)}
                    handleDisplayAreasFromWorksFilter={this.handleDisplayAreasFromWorksFilter}
                    setAnomaliesData={this.setAnomaliesData}
                    projectVersionAnomalies={state.projectVersionAnomalies}
                />
                <Box className="main-content">
                    {state.isRoadSectionDetailsOpened &&
                        <RoadSectionDetailsComponent
                            selectedRoadSection={state.selectedRoadSection}
                            selectedImage={state.selectedImage}
                            hasScoreAnalysisAccess={this.hasScoreAnalysisAccess}
                            role={this.props.role}
                            shouldDisplayDetails={true}
                            handleStepChanged={(selectedImage: ImageExtended, searchByNext: boolean) => this.handleStepChanged(selectedImage, searchByNext, state)}
                            handleImageChanged={(imageId) => this.handleImageChanged(imageId, state)}
                            onClose={() => this.handleCloseRoadSectionDetails()}
                        />
                    }
                    <Box className="sections-and-map-content">
                        {state.isSectionsDrawerOpened &&
                            <SectionsComponent selectedScenario={state.selectedScenario}
                                scenarioSections={state.relatedScenarioSections}
                                selectedSectionsIds={state.selectedSectionsIds}
                                currency={state.currentCurrency}
                                loading={state.loading}
                                isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                                measurementSystemType={state.measurementSystemType}
                                handleUpdateScenarioSectionsClicked={() => this.handleUpdateScenarioSectionsClicked(state)}
                                handleRequestProgrammingClicked={() => this.handleRequestProgrammingClicked(state)}
                                updateSelectedSections={this.updateSelectedSections}
                            />
                        }
                        {this.locationGeometry &&
                            <ScenarioMapComponent
                                locationGeometry={this.locationGeometry}
                                scenarioSections={state.relatedScenarioSections}
                                mergedProject={state.mergedProject}
                                filterdSectionsIds={state.filterdSectionsIds}
                                filterdSectionsPositions={state.filterdSectionsPositions}
                                activeAnomalies={state.activeAnomalies}
                                loading={state.loading}
                                isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                                currentMeasurementSystemType={state.measurementSystemType}
                                filteredProgrammingsFromFilter={state.filteredProgrammingsFromFilter}
                                perStepImagesAnomalies={state.perStepImagesAnomalies}
                                selectedSectionsIds={state.selectedSectionsIds}
                                selectedImage={state.selectedImage}
                                lastSelectedSection={state.lastSelectedSection}
                                handleDisplayImageFromSectionClicked={this.handleDisplayImageFromSectionClicked}
                                handleCLoseRoadSectionDetails={this.handleCloseRoadSectionDetails}
                            />
                        }
                        {state.sectionsSummaryModel && <SectionsSummaryComponent selectedScenario={state.selectedScenario} sectionsSummaryModel={state.sectionsSummaryModel} currency={state.currentCurrency} sections={state.relatedScenarioSections} inEdit={false} measurementSystemType={state.measurementSystemType} />}
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ScenarioSectionsVisualisationView));
