import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import Translate from '../../../../localization/Localization';
import { getManagerList } from '../../../../shared/models/Attributes/AttributesLabels';
import { RoadSectionGridViewData } from '../../models/RoadSectionGridViewData';

interface CustomManagerCellComponentProps {
    exitEdit: () => void
}

export const CustomManagerCellComponent = (props: GridCellProps & CustomManagerCellComponentProps): JSX.Element => {
    const dataItem = props.dataItem as RoadSectionGridViewData;

    const [newValue, setNewValue] = useState<string>(dataItem.changes?.get(props.field));

    let defaultRendering: JSX.Element = null;

    const handleChange = (event: DropDownListChangeEvent, props: GridCellProps & CustomManagerCellComponentProps): void => {
        dataItem.inEdit = props.field;
        const newValue = event.value != null ? event.value.value : dataItem[props.field];
        dataItem.changes.set(props.field, newValue);
        setNewValue(event.value.value);
        props.exitEdit();
    }

    if (!dataItem.inEdit || dataItem.inEdit !== props.field) {
        const oldValue = dataItem[props.field];
        if (newValue === undefined || newValue === oldValue) {
            defaultRendering = (
                <td >
                    {getManagerList().find((c) => c.value === oldValue)?.text}
                </td>
            );
        }
        else {
            defaultRendering = (
                <td className={"field-changed"}>
                    {!newValue ? '' : getManagerList().find((c) => c.value === newValue)?.text}
                </td>
            );
        }
    } else {
        let defaultValue = newValue === undefined ? props.dataItem[props.field] : newValue;
        let SortedValues = getManagerList();
        SortedValues.push({ text: Translate.Resources.UI_Highways_Grid_Value_Empty, value: null });
        defaultRendering = (
            <td>
                <DropDownList
                    onChange={(event) => handleChange(event, props)}
                    value={getManagerList().find((c) => c.value === defaultValue)}
                    data={SortedValues}
                    textField="text"
                />
            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}