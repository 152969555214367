import { AxiosResponse } from "axios";

export const getFileName = (response: AxiosResponse<Blob>): string => {
    var filename: string = null;
    if (response && response.headers && response.headers) {
        var disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            //On tente de recupèrer la valeur du filename* si elle existe
            var filenameRegex = /filename\*=(UTF-8['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[2]) {
                filename = decodeURIComponent(matches[2]);
            }
            else {
                //Sinon on tente de récuperer la valeur du filename
                filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
        }
    }
    return filename;
}

export const downloadFileFromBlob = (blob: Blob, fileName: string): void => {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) //For IE
    {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
