export { }

declare global {
    interface String {
        removeDiacritics(): string;
    }
}

String.prototype.removeDiacritics = function () {
    //see: from https://stackoverflow.com/a/37511463
    return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}